import React, { memo, useCallback, useEffect, useMemo, useState } from 'react';
import { connect } from 'react-redux';
import AssignGeneralTags from '../../AssignGeneralTags';
import ArchiveMediaItemAsset from './ArchiveMediaItemAsset';
import { getBlobDuration, secondsToHms } from '../../../../actions/voiceover.actions';
import { fileTypeList } from '../../../../actions/general.actions';
import { upsertMediaInit } from '../../../../actions/media.actions';


const ArchiveMediaItem = props => {
    const {
        showRadioInit,
        onRadioInit,
        upsertMediaInit,
        updateGeneralState,
        fallbackImage2,
        fallbackImage,
        itemsSelected,
        simplified,
        profile,
        newUpload,
        onRemove,
        onSelect,
        media
    } = props;
    
    const descriptionLabel = useMemo(() => `${(media.slides || []).length} Slide${(media.slides || []).length > 0 ? 's' : ''}`, [(media.slides || []).length]);

    const { thumb_url, image_src, file_type, file_extension } = useMemo(
        () => {
            const base_split_url = media.url.split('.');
            const file_extension = base_split_url[base_split_url.length - 1].toLowerCase();

            const base_s3_url = 'https://dgpv43ylujmyh.cloudfront.net';
            const url_to_check = media.url.toLowerCase();
            const split_url = url_to_check.split(
                url_to_check.indexOf('processed-voiceover') > -1
                ? '/processed-voiceover/undefined/'
                : url_to_check.indexOf('dgpv43ylujmyh.cloudfront.net') >
                    -1
                ? '/undefined/'
                : '/athletic-outlook/undefined/'
            );
            const base_key = `${split_url[split_url.length - 1]}`;
            const s3_key = `undefined/${base_key.split('.')[0]}`;

            return {
                file_type: fileTypeList[file_extension] || 'other',
                image_src: (`undefined/${base_key}`).replace('undefined/undefined/', 'undefined/'),
                thumb_url: `${base_s3_url}/thumb/${s3_key}.0000001.jpg`,
                file_extension
            };
        }, [media.url]
    );

    const fileInfoObject = useMemo(() => media.fileInfo && JSON.parse(media.fileInfo), [media.fileInfo]);

    const secondsToHmsInit = useCallback(secondsToHms, []);

    const defaultStillProcessing = useMemo(() => 'video' === file_type && !media.fileInfo, [file_type, media.fileInfo]);
    const defaultDurationToPass = fileInfoObject ? secondsToHmsInit(fileInfoObject.duration) : null;

    const [processingConfirmed, updateProcessingConfirmed] = useState();
    const [stillProcessing, updateStillProcessing] = useState(defaultStillProcessing);
    const [durationToPass, updateDurationToPass] = useState(defaultDurationToPass);
    const [mounted, updateMounted] = useState();

    const initializeMedia = useCallback(
        async () => {
            let direct_url_to_pass = media.url;

            if (direct_url_to_pass) {
                direct_url_to_pass = direct_url_to_pass.toLowerCase();
    
                direct_url_to_pass = direct_url_to_pass.split('.');
                const extension = direct_url_to_pass.pop();
                direct_url_to_pass = direct_url_to_pass.join('.');
    
                if (direct_url_to_pass.indexOf('/evaluation/') === -1) {
                    if (direct_url_to_pass.indexOf('/video-desktop/') === -1) {
                        direct_url_to_pass = direct_url_to_pass.replace(
                            '/undefined/',
                            '/video-desktop/undefined/'
                        );
                    }
                } else if (direct_url_to_pass.indexOf('/processed-voiceover/') === -1 && extension !== 'mp4') {
                    direct_url_to_pass = direct_url_to_pass.replace(
                        '/undefined/',
                        '/processed-voiceover/undefined/'
                    );
                };
    
                if (
                    direct_url_to_pass.indexOf(
                    's3.us-west-2.amazonaws.com/athletic-outlook'
                    ) > -1
                ) {
                    direct_url_to_pass = direct_url_to_pass.replace(
                    's3.us-west-2.amazonaws.com/athletic-outlook',
                    'dgpv43ylujmyh.cloudfront.net'
                    );
                } else if (
                    direct_url_to_pass.indexOf('athletic-outlook.s3.amazonaws.com') > -1
                ) {
                    direct_url_to_pass = direct_url_to_pass.replace(
                    'athletic-outlook.s3.amazonaws.com',
                    'dgpv43ylujmyh.cloudfront.net'
                    );
                }
    
                direct_url_to_pass = `${direct_url_to_pass}.mp4`;
            };
    
            const blobDurationToPass = await getBlobDuration(`${direct_url_to_pass}`);
            
            if(blobDurationToPass){
                await upsertMediaInit({
                    id: media.id,
                    label: media.label,
                    fileInfo: JSON.stringify({ duration: blobDurationToPass }),
                    noStateAdjustment: true
                });
    
                updateStillProcessing(false);
                updateProcessingConfirmed(false);

                updateDurationToPass(secondsToHmsInit(blobDurationToPass));
            } else {
                updateStillProcessing(true);
                updateProcessingConfirmed(true);
            };        
        }, [media]
    );

    useEffect(() => {
        const base_split_url = media.url.split('.');
        const file_extension = base_split_url[base_split_url.length - 1].toLowerCase();
        const file_type = fileTypeList[file_extension] || 'other';

        'video' === file_type && !media.fileInfo && initializeMedia();
    }, []);

    const isActive = useMemo(() => itemsSelected.find(item => item.id === media.id), [itemsSelected]);

    const containerClassName = useMemo(() => `complete-container-of-media-item ${newUpload ? 'new' : file_type}-file-type ${isActive && 'active-selection'}`, [newUpload, file_type, simplified, isActive]);

    const onMainClick = useCallback(
        () => (
            !simplified && isActive
                ? onRemove(media)
                : onSelect(media)
        ), [itemsSelected, simplified, isActive, media]
    );

    const processingActionDignifierRender = useMemo(
        () => processingConfirmed && <p className={`use-unprocessed ${!simplified && 'move-to-right'}`}>Try Unprocessed</p>,
        [processingConfirmed, simplified]
    );

    const processingDignifierRender = useMemo(
        () => (
            stillProcessing  && <div className="processing-still-media-gallery-item-overlay">
                <div className="appLoading">
                    {processingConfirmed && <p>Processing</p>}
                </div>                            
            </div>
        ),
        [processingConfirmed, stillProcessing]
    );
    
    const videoDignifierRender = useMemo(
        () => (
            media.isLesson ? (
                <div className="is-video-dig lesson-specific">
                    <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M4.3335 12.9998L5.5735 9.6665" stroke="white" strokeLinecap="round" strokeLinejoin="round"></path><path d="M9.66676 12.9998L8.42676 9.6665" stroke="white" strokeLinecap="round" strokeLinejoin="round"></path><path d="M4 6.99984V5.6665" stroke="white" strokeLinecap="round" strokeLinejoin="round"></path><path d="M6 6.99984V3.6665" stroke="white" strokeLinecap="round" strokeLinejoin="round"></path><path d="M8 7V5" stroke="white" strokeLinecap="round" strokeLinejoin="round"></path><path d="M10 7.00013V3.9668" stroke="white" strokeLinecap="round" strokeLinejoin="round"></path><path d="M11.6667 1H2.33333C1.59695 1 1 1.59695 1 2.33333V8.33333C1 9.06971 1.59695 9.66667 2.33333 9.66667H11.6667C12.403 9.66667 13 9.06971 13 8.33333V2.33333C13 1.59695 12.403 1 11.6667 1Z" stroke="white" strokeLinecap="round" strokeLinejoin="round"></path></svg>
                </div>
            ) : file_type === 'video' && (
                <div className="is-video-dig">
                    <svg
                        width="13"
                        height="16"
                        viewBox="0 0 13 16"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path
                        d="M11.7821 7.17278C12.3663 7.56956 12.3663 8.43044 11.7821 8.82722L1.81188 15.5994C1.14794 16.0503 0.249999 15.5748 0.249999 14.7722L0.25 1.22785C0.25 0.42523 1.14793 -0.0503457 1.81188 0.400628L11.7821 7.17278Z"
                        fill="white"
                        />
                    </svg>
                    {durationToPass && <span className="duration-ref">{durationToPass}</span>}
                </div>
            )
        ), [file_type, durationToPass]
    );

    const onUserClick = useCallback(
        profileId => updateGeneralState({
            showUserOptions: true,
            activeUserBasedTags: { [profileId]: true }
        }), []
    );

    const getDisplayName = useCallback(
        (user) => (
            user.firstName || user.lastName
                    ? `${user.firstName} ${user.lastName}`
                    : user.email || user.phone
        ), []
    );

    const userBasedDignifier = useMemo(
        () => media.isLesson ? (
            <button
                className="user-tag-over-top-of-media"
                onClick={() => onUserClick(media.profile.id)}
            >
                {descriptionLabel}
            </button>            
        ) : media.profile && media.profile.user && media.profile.id !== profile.id && (
            <button
                className="user-tag-over-top-of-media"
                onClick={() => onUserClick(media.profile.id)}
            >
                {getDisplayName(media.profile.user)}
            </button>
        ), [media.profile, descriptionLabel]
    );

    return (
        <div className={containerClassName}>
            <AssignGeneralTags
                activeTags={media.tags || []}
                assetId={media.id}
                origins={['lessons', 'media']}
                {...media.isLesson ? {
                    originRef: "lessonId"
                } : {
                    originRef: "mediaId"
                }}
            />
            
            <div className="main-media-section-of-item">
                <div
                    className={'fill-the-area'}
                    onClick={onMainClick}
                >
                    {!simplified && (
                        <span className={`multi-select-radio-delegation`}>
                            <span></span>
                        </span>
                    )}                      
                    {processingActionDignifierRender}
                    {processingDignifierRender}
                    {videoDignifierRender}
                    <ArchiveMediaItemAsset
                        file_type={file_type}
                        image_src={image_src}
                        thumb_url={thumb_url}
                        fallbackImage2={fallbackImage2}
                        fallbackImage={fallbackImage}
                        file_extension={file_extension}
                    />
                </div>

                {showRadioInit && simplified && (
                    <span className={`multi-select-radio-delegation simplified show-on-hover`} onClick={() => onRadioInit(media)}>
                        <span></span>
                    </span>
                )}                

                {userBasedDignifier}
            </div>
            <div
                className="bottom-media-section-of-item"
                onClick={onMainClick}
            >                    
                <p className="label">{newUpload && <span className="new-specific">NEW</span>}{media.label || 'Untitled'}</p>
            </div>
        </div>
    );
};


const mapDispatchToProps = dispatch => ({
    upsertMediaInit: variables => dispatch(upsertMediaInit(variables))
});

export default connect(null, mapDispatchToProps)(memo(ArchiveMediaItem));