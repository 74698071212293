import React, { memo } from 'react';
import UsersMainTagSpecific from '../Manage/Users/UsersMainTagSpecific';

const UserBasedTagsRender = ({ onMainClick, origin, profile, requester, showUserOptions }) => (
  showUserOptions
    ? <>
        <div className="child-tags user-specific">                          
          <UsersMainTagSpecific
            {...(origin === 'sessions'
              ? { sessionSpecific: true }
              : { mediaSpecific: true })}
            profile={profile}
            requester={requester}
            hideArchiveButton={origin === 'media'}
            onMainClick={onMainClick}
          />                                  
        </div>
      </> 
    : <></>
);

export default memo(UserBasedTagsRender);
