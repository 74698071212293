import React, { memo, useMemo } from 'react'
import { isMobileSafari } from 'react-device-detect'
import ParentRouter from '../routers/ParentRouter'
import AppHelmet from './appHelmet'
import './addToHomeScreen.scss'

const App = _ => {
  const containerClassName = useMemo(() => `${localStorage.getItem('active_theme') || 'light'} parent-app-js-container ${isMobileSafari && !('standalone' in window.navigator && window.navigator.standalone) && 'is-safari-mobile-specific'}`, [localStorage.getItem('active_theme')]);
  
  return (
    <section className={containerClassName}>
      <AppHelmet />      
      <ParentRouter />
    </section>
  )
}

export default memo(App)