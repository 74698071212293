import React, { useCallback, useContext, useMemo } from 'react';
import { connect } from 'react-redux';
import { useQuery } from 'react-apollo';
import GeneralImage from '../../../Shared/GeneralImage';
import GeneralLoader from '../../../Shared/GeneralLoader';
import GeneralConfirmation from '../../../Shared/GeneralConfirmation';
import { RequesterContext } from '../../../../context/requester.context';
import { updateGeneralState } from '../../../../actions/general.actions';
import { CurrentUserQuery_ProfileGroups } from '../../../../apollo.queries';
import { getLogoKey, removeProfileGroupInit, tenantPrefixUrlDeterminant } from '../../../../actions/user.actions';

const Groups = ({ generalState, updateGeneralState, removeProfileGroupInit }) => {
  const requester = useContext(RequesterContext)
  const { activeGroup } = requester || {}

  const network_results = useQuery(CurrentUserQuery_ProfileGroups, { fetchPolicy: "network-only" })
  const cache_results = useQuery(CurrentUserQuery_ProfileGroups, { fetchPolicy: "cache-only" })
  
  const { showDeleteConfirmation, removingGroup } = generalState;

  let forGroupRef;

  const finalProfileGroups = network_results?.data?.profileGroups || cache_results?.data?.profileGroups || []
  
  const activeGroup_found = useMemo(() => finalProfileGroups.find(({ group }) => group?.id === activeGroup?.id), [finalProfileGroups, activeGroup])

  const mapGroup = useCallback(({ role, group, id, isActiveGroup }, i) => group?.tenant && !generalState[`removed_group_${group?.id}`] && (
    <div className="for-group-relativity" key={`admin-group-connection-id-${id || i}`}>
      <div
        className="clickable-group-section"
        onClick={() =>
          !isActiveGroup && (window.location = `${tenantPrefixUrlDeterminant(
            group.tenant.code,
            group.code
          )}/programs`)
        }
        style={{ ...isActiveGroup && { pointerEvents: "none" } }}
      >
        {group.tenant.logo ? (
          <GeneralImage
            imageResolution={'150x'}
            originalKey={getLogoKey(
              group.tenant.logo,
              group.tenant.id
            )}
          />
        ) : (
          <img
            className="fallback-logo"
            src="/images/android-chrome-192x192.png"
          />
        )}

        <div className="text-section-org-footer">
          <h2>{group.name}</h2>
          <h3>
            {isActiveGroup ? (
              <span className="role" style={{ color: "#3FD39E" }}>Selected</span>
            ) : ['Athlete', 'Parent'].indexOf(role) > -1 ? (
              <span className="role user">User</span>
            ) : (
              <span className="admin role">Admin</span>
            )}
            {group.tenant.name}
          </h3>
        </div>
      </div>
      <button
        className="leave-specific-button"
        onClick={() => {
          forGroupRef = group;

          updateGeneralState({ showDeleteConfirmation: group });
        }}
      >
        <svg width="18" height="20" viewBox="0 0 18 20" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M1 4H17M13 4L12.7294 3.18807C12.4671 2.40125 12.3359 2.00784 12.0927 1.71698C11.8779 1.46013 11.6021 1.26132 11.2905 1.13878C10.9376 1 10.523 1 9.69357 1H8.30643C7.47705 1 7.06236 1 6.70951 1.13878C6.39792 1.26132 6.12208 1.46013 5.90729 1.71698C5.66405 2.00784 5.53292 2.40125 5.27064 3.18807L5 4M15 4V14.2C15 15.8802 15 16.7202 14.673 17.362C14.3854 17.9265 13.9265 18.3854 13.362 18.673C12.7202 19 11.8802 19 10.2 19H7.8C6.11984 19 5.27976 19 4.63803 18.673C4.07354 18.3854 3.6146 17.9265 3.32698 17.362C3 16.7202 3 15.8802 3 14.2V4M11 8V15M7 8V15" stroke="#131A29" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>
      </button>
    </div>
  ), [generalState])

  const { admins, athletes } = useMemo(() => finalProfileGroups.reduce((r, g) => {
    const keyRef = ['Master', 'Admin'].includes(g?.role) ? 'admins' : 'athletes' 

    return {
      ...r,
      ...(activeGroup_found?.group?.id !== g?.group?.id) && { [keyRef]: [...r[keyRef] || [], g] }
    }
  }, { admins: [], athletes: [] }), [finalProfileGroups, activeGroup_found])

  if(network_results?.loading && finalProfileGroups?.length === 0){
    return (
      <div className="for-group-relativity" key={`id-loader`}>
        <GeneralLoader />
      </div>
    )
  }
  
  return <>
    {activeGroup_found && (
      <div className="active-group-selected" style={{ marginTop: -10 }}>
        <div className="immedaite-settings-main-component group-selection-specific">
          <div className="group-iterate">
            {mapGroup({ ...activeGroup_found, isActiveGroup: true })}   
          </div>
        </div>
      </div>
    )}    

    {admins?.length > 0 && <>
      <h3 className={`group-type-title ${!activeGroup_found && 'no-active-group'}`}>Admin Group{admins.length > 1 ? 's' : ''}</h3>
      <div className="immedaite-settings-main-component group-selection-specific">
        <div className="group-iterate">
          {admins.map(mapGroup)}
        </div>
      </div>
    </>}

    {athletes?.length > 0 && <>
      <h3 className={`group-type-title ${!activeGroup_found && 'no-active-group'}`}>Athlete Group{athletes.length > 1 ? 's' : ''}</h3>
      <div className="immedaite-settings-main-component group-selection-specific">
        <div className="group-iterate">
          {athletes.map(mapGroup)}
        </div>
      </div>
    </>}

    {removingGroup && (
      <div className="general-tag-loading-specific">
        <div className="appLoading">
          <svg
            className="simple-line-loader-for-app-loading css-primary-hex-stroke"
            viewBox="0 0 50 50"
          >
            <circle
              className="path css-primary-hex-stroke"
              cx="25"
              cy="25"
              r="20"
              fill="none"
              strokeWidth="5"
            ></circle>
          </svg>
        </div>
      </div>
    )}

    {(showDeleteConfirmation || removingGroup) && (
      <GeneralConfirmation
        question={`Are you sure you want to delete "${
          (showDeleteConfirmation || forGroupRef || {}).name
        }/${
          ((showDeleteConfirmation || forGroupRef || {}).tenant || {}).name
        }"?`}
        loading={removingGroup ? true : false}
        onConfirm={() => {
          updateGeneralState({ showDeleteConfirmation: false });
          removeProfileGroupInit(
            (showDeleteConfirmation || forGroupRef || {}).id
          );
        }}
        onCancel={() =>
          updateGeneralState({ showDeleteConfirmation: false })
        }
        confirmText={'Yes, delete'}
        cancelText={'No'}
      />
    )}
  </>
}

const mapStateToProps = state => ({
  generalState: state.websiteState
})

const mapDispatchToProps = dispatch => ({
  updateGeneralState: variables => dispatch(updateGeneralState(variables)),
  removeProfileGroupInit: variables => dispatch(removeProfileGroupInit(variables))
})

export default connect(mapStateToProps, mapDispatchToProps)(Groups);