import React, { memo, useCallback } from 'react';
import ChildTagRender from './ChildTagRender';

const ChildTagsMapper = ({ origin, is_active, assignSpecific, childTags_Reduced, itemsSelected, categoryLocal, onChildTagClick, onChildDeleteInit, untaggedAndDbConditional }) => {
  const keyExtractor = useCallback(categoryLocalChildId => `${categoryLocalChildId || 'untagged'}-gallery-tag`, []);

  return <>
    {childTags_Reduced[categoryLocal.id] && is_active && (
      <div className="child-tags">
        {childTags_Reduced[categoryLocal.id].map(
          categoryLocalChild => (
            <ChildTagRender
              key={keyExtractor(categoryLocalChild.id)}
              origin={origin}
              is_active={is_active}
              assignSpecific={assignSpecific}
              itemsSelected={itemsSelected}
              categoryLocal={categoryLocal}
              categoryLocalChild={categoryLocalChild}
              onChildTagClick={onChildTagClick}
              onChildDeleteInit={onChildDeleteInit}
              untaggedAndDbConditional={untaggedAndDbConditional}
            />
          )
        )}
      </div>
    )}
  </>;
};

export default memo(ChildTagsMapper);
