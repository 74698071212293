import BLOCK_TYPES from './blockTypes';
import FIELD_TEMPLATE_TYPES from './fieldTemplateTypes';

const BlockIconRef = BLOCK_TYPES.reduce(
  (results, { types = [] }) => ({
    ...results,
    ...types.reduce(
      (resultsLocal, currentValue) => ({
        ...resultsLocal,
        [currentValue.type]: { backgroundColor: currentValue.backgroundColor, title: currentValue.title, icon: currentValue.icon }
      }), {}
    )
  }), FIELD_TEMPLATE_TYPES
);

export default BlockIconRef;