import React, { memo, useCallback } from 'react';
import GalleryMainMediaMapper from './GalleryMainMediaMapper';

const GalleryMainRenderMapper = ({ profile, hideNewFiles, onRadioInit, fallbackFirstConditional, fallbackSecondConditional, onFileDropChange, onSelect, onRemove, simplified, itemsSelected, fallbackImage1, fallbackImage2, generalState, updateGeneralState, filteredFilesUploading_Finalized = [], filterImageOnly_QUERIED = [] }) => {
  const fallbackRender = useCallback(
    () => (
      fallbackFirstConditional ? (
        ''
      ) : fallbackSecondConditional && (
        <div
          key={`media-inline-add-media`}
          className={`complete-container-of-media-item inline-add-media video-file-type`}
        >
          <div className="main-media-section-of-item">
            <svg width="20" height="18" viewBox="0 0 20 18" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M17 3.94H10.529C10.198 3.94 9.888 3.776 9.702 3.502L8.297 1.437C8.111 1.164 7.802 1 7.471 1H3C1.895 1 1 1.895 1 3V15C1 16.105 1.895 17 3 17H17C18.105 17 19 16.105 19 15V5.94C19 4.836 18.105 3.94 17 3.94V3.94Z" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path><path d="M9.98999 7.62012V13.1201" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path><path d="M7.23999 10.3701H12.74" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path></svg>
            <input type="file" multiple onChange={onFileDropChange} />
          </div>
        </div>
      )
    ), [fallbackFirstConditional, fallbackSecondConditional]
  );

  return <>
    {
      (filteredFilesUploading_Finalized.length > 0 || filterImageOnly_QUERIED.length > 0) ? (
        <GalleryMainMediaMapper 
          onSelect={onSelect}
          onRemove={onRemove}
          onRadioInit={onRadioInit}
          simplified={simplified}
          hideNewFiles={hideNewFiles}
          itemsSelected={itemsSelected}
          fallbackImage1={fallbackImage1}
          fallbackImage2={fallbackImage2}
          generalState={generalState}
          updateGeneralState={updateGeneralState} 
          profile={profile}
          filteredFilesUploading_Finalized={filteredFilesUploading_Finalized}
          filterImageOnly_QUERIED={filterImageOnly_QUERIED} 
        />
      ) : fallbackRender()
    }
  </>;
};

export default memo(GalleryMainRenderMapper);
