import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import PhoneInput from 'react-phone-number-input';
import { updateFormState } from '../../../../../../../../actions/form.actions';

class BlockFormPhoneInputManip extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {};
  }

  render() {
    const { block, formState, updateFormState } = this.props;
    const { id, label, title, required = 1 } = block;
    const { formResponses = {}, reducedRequired = {} } = formState;
    const currentValue = (formResponses[id] || {}).text || '';

    return <>
      <div  className={`general-form-block-render-container phone-type`}>

        <div className="ao-input phone-specific">
            <label style={{ display: "block" }} className="question-name">{label || title || `Phone Number`}{required ? '*' : ''}</label>
            
            {reducedRequired[block.id] ? <div className="invalid-currently-dignifier">Required*</div> : <></>}

            <PhoneInput
              value={currentValue}
              defaultCountry={'US'}
              placeholder="(000) - 000 - 0000"
              onChange={val => (
                updateFormState({
                  ...reducedRequired[block.id] && { reducedRequired: { ...reducedRequired, [block.id]: undefined } },
                  formResponses: {
                    ...formResponses,
                    [id]: { text: val }
                  }              
                })
              )}
            />
          </div> 
      </div>
    </>;
  }
};

const mapStateToProps = state => ({
  formState: state.formState
});

const mapDispatchToProps = dispatch => ({
  updateFormState: updatedStates => dispatch(updateFormState(updatedStates))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(BlockFormPhoneInputManip);