import gql from 'graphql-tag';
import { useQuery } from 'react-apollo';
import { upsertMedia, removeMedia } from '../apollo.mutations';
import { instagramMediaGallery } from '../apollo.queries';
import ACTIONS from '../constants/program/actions';
import apolloClient from '../apollo.client';

/** Start of Instagram Actions **/

export const InstagramMedia_QueryConcat = () => {
  const limit = 20;

  const Results_CacheOnly = useQuery(
    instagramMediaGallery,
    { fetchPolicy: "cache-only" }
  );

  /****/

  const Results = useQuery(
    instagramMediaGallery,
    { fetchPolicy: "network-only"  }
  );

  return {
    data: (Results.data || {}).instagramMediaGallery || (Results_CacheOnly.data || {}).instagramMediaGallery,
    loading: Results.loading
  };
};

/** End of Instagram Actions **/

const checkAndRerunImageOpt = gql`
  mutation checkAndRerunImageOpt($key: String!) {
    checkAndRerunImageOpt(key: $key)
  }
`;

export const checkAndRerunImageOptInit = key => {
  apolloClient.mutate({
    mutation: checkAndRerunImageOpt,
    variables: { key  }
  });
  
  return;
}

/** Start of Mutations **/

export const removeMediaInit = (mediaId, dontDispatch) => async dispatch => {
  if(!dontDispatch){
    dispatch({
      type: ACTIONS.UPDATE_STATE,
      payload: {
        deletingMedia: true
      }
    });
  };

  await apolloClient.mutate({
    mutation: removeMedia,
    variables: {
      input: { id: mediaId }
    }
  });

  if(!dontDispatch){
    dispatch({
      type: ACTIONS.UPDATE_STATE,
      payload: {
        viewMediaPop: undefined,
        deletingMedia: false
      }
    });
  };

  return true;
};

export const upsertMediaInit = media => async dispatch => {
  const {
    id,
    label,
    fileInfo,
    noStateAdjustment
  } = media;

  !noStateAdjustment && dispatch({
    type: ACTIONS.UPDATE_STATE,
    payload: {
      updatingMedia: true
    }
  });

  await apolloClient.mutate({
    mutation: upsertMedia,
    variables: {
      input: {
        id,
        label,
        fileInfo
      }
    }
  });

  !noStateAdjustment && dispatch({
    type: ACTIONS.UPDATE_STATE,
    payload: {
      forceRefresh: new Date().getTime(),
      updatingMedia: false,
      viewMediaPop: {
        ...media,
        valueChanged: false
      }
    }
  });

  return true;
};

/** End of Mutations **/