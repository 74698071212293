import React, { PureComponent } from 'react';

class BlockFormDescription extends PureComponent {
  constructor(props) {
    super(props);
  }

  render() {
    const { block } = this.props;

    const { label, title } = block;

    return <>
      <div  className={`general-form-block-render-container description-type`}>
        <p>{label || title || "Plain text..."}</p>
      </div>
    </>;
  }
};

export default BlockFormDescription;
