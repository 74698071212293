import React, { memo, useCallback, useMemo } from 'react';
import { connect } from 'react-redux';
import NavigationContainer from '../../../../../components/Shared/NavigationContainer';
import { isVideoFull, updateGeneralState } from '../../../../../actions/general.actions';
import { mediaSelectedConfirmation, updateVoiceoverState } from '../../../../../actions/voiceover.actions';

const MenuItems = props => {
  const { updateVoiceoverState, urlToUse, menuPrefix, isSMSVideo, voiceoverState, showQuickActionsMenu, mediaSelectedConfirmation, updateGeneralState } = props;

  let { recordingSlides } = voiceoverState;

  const menuArray = useMemo(
    () => [
      {
        label: "Analyze",
        icon: <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M7.33496 11.5L11.665 9L7.33496 6.5V11.5Z" stroke="#666666" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path><path d="M5.24991 1.50007H12.7499C14.8166 1.50007 16.4916 3.17756 16.4916 5.24673V12.7467C16.4916 14.8134 14.8082 16.4884 12.7416 16.4884H5.23324C3.15824 16.4884 1.48657 14.8051 1.48657 12.7384V5.2334C1.48657 3.1584 3.16157 1.4834 5.23657 1.4834L5.24991 1.50007Z" stroke="#666666" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path></svg>,
        beforeNavigate: () => {
          updateVoiceoverState({            
            fromRedirect: true,
            status: 'IN_PROGRESS',
            multipleFileProgress: 0,
            multipleFileCount: 1
          });
        },
        toPath: `${menuPrefix}/video/create`,
        afterNavigate: async () => {
          const downloadedFiles = await mediaSelectedConfirmation(
            {
              filesSelected: {
                [urlToUse]: {
                  url: urlToUse,
                  isSMSVideo,
                  imageSpecific: !isVideoFull(urlToUse)
                }
              }
            }
          );
    
          recordingSlides[0].mediaSections[0] = {
            ...recordingSlides[0].mediaSections[0],
            fallbackIndexRef: 0,
            ...downloadedFiles[0]
          };
    
          updateGeneralState({ viewMediaPop: false });
    
          updateVoiceoverState({
            recordingSlides,
            status: 'COMPLETED',
            activeMasterElement: { mediaSection: recordingSlides[0].mediaSections.length - 1 }
          });
        }
      },
      {
        label: "Download",
        icon: <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M12.5563 8.11108L9.00034 11.6671L5.44434 8.11108" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path><path d="M9 1V11.667" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path><path d="M17 13.4441C17 14.3872 16.6254 15.2917 15.9585 15.9586C15.2916 16.6254 14.3871 17.0001 13.444 17.0001H4.556C3.61289 17.0001 2.70841 16.6254 2.04153 15.9586C1.37465 15.2917 1 14.3872 1 13.4441V13.4441" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path></svg>,                                    
        toPath: null,
        afterNavigate: () => {
          const isExternalBased = urlToUse.toLowerCase().indexOf('instagram.com') > -1 || urlToUse.toLowerCase().indexOf('api.twilio.com') > -1;

          window.open(
            isExternalBased
              ? urlToUse
              : urlToUse.replace('athletic-outlook.s3.amazonaws.com', 's3.us-west-2.amazonaws.com/athletic-outlook')
          )
        }
      }     
    ], [menuPrefix]
  );
  
  const renderMenuItem = useCallback(
    ({ icon, label, toPath, afterNavigate, beforeNavigate }, index) => (
      <NavigationContainer
        key={`master-navigation-item-${index}`}
        toPath={toPath} 
        beforeNavigate={beforeNavigate}
        afterNavigate={afterNavigate} 
        className="menu-items" 
        child={<>{icon}{label}</>} 
      />
    ), []
  );

  const iterateRender = useMemo(() => menuArray.map(renderMenuItem), [menuArray]);

  return (
    <>
      {showQuickActionsMenu && (
        <div className="quick-actions-menu">
          {iterateRender}
        </div>
      )}   
    </>
  );
};

const mapStateToProps = state => ({
  voiceoverState: state.voiceoverState
});

const mapDispatchToProps = dispatch => ({
  updateGeneralState: variables => dispatch(updateGeneralState(variables)),
  updateVoiceoverState: variables => dispatch(updateVoiceoverState(variables)),
  mediaSelectedConfirmation: variables => dispatch(mediaSelectedConfirmation(variables))
});

export default connect(mapStateToProps, mapDispatchToProps)(memo(MenuItems));