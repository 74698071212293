import React, { Component } from 'react';
import BlockViewerRoutingChild from './BlockViewerRoutingChild';

class BlockViewerRouting extends Component {
  constructor(props) {
    super(props);
  }

  shouldComponentUpdate(otherProps){
    const { block } = this.props;

    return otherProps.block.id !== block.id;
  }  

  render() {
    const { block } = this.props;
    
    return (
      <BlockViewerRoutingChild key={`block_render_specific_button_${block.id}`} block={block} />
    );
  }
};

export default BlockViewerRouting;