import React, { memo, useEffect } from 'react';
import replaceall from 'replaceall';
import { useSearchParams } from 'react-router-dom';
import Settings from '../../containers/Public/Settings';
import OriginGuestRoutes from '../../routes/origin/guestRoutes';
import { ProfileContext } from '../../context/profile.context';
import GeneralLoader from '../../components/Shared/GeneralLoader';

const hostName = window.location.hostname.toLowerCase();
const hostNameSlugToUse = replaceall('.', '-', hostName);
const originHost = hostName.indexOf('coachiq.io') > -1 || hostName.indexOf('localhost') > -1 || hostName.indexOf('127.0.0.1') > -1;

const DefaultHelper = ({ currentProfile, fallbackSlugToUse, requesterOrg }) => {
  const [ searchParams ] = useSearchParams()

  const pathNameToLowerCase = window.location.pathname.toLowerCase().split('/')

  const slugToCheck = pathNameToLowerCase[pathNameToLowerCase.length - 1].length > 0
      ? pathNameToLowerCase[pathNameToLowerCase.length - 1]
      : pathNameToLowerCase[pathNameToLowerCase.length - 2];

  const isStripeConnected = slugToCheck && ['stripe-connected'].indexOf(slugToCheck) > -1;

  const lastActiveGroupPrefix = localStorage.getItem(`${hostNameSlugToUse}-last-active-group-prefix-v5`);

  useEffect(() => {
    if(!requesterOrg){
      if(searchParams.get('already-refreshed')){
        if(lastActiveGroupPrefix) localStorage.removeItem(`${hostNameSlugToUse}-last-active-group-prefix-v5`)
        
        window.location = '/login?already-refreshed=true'
      } else if(lastActiveGroupPrefix) {
        window.location = `${lastActiveGroupPrefix}/join?already-refreshed=true`
      }
    } else if(!(isStripeConnected && originHost)){
      if(requesterOrg.activeGroup){
        window.location = originHost ? `/${requesterOrg.activeGroup.tenant.code}/${requesterOrg.activeGroup.code}/${fallbackSlugToUse}` : '/programs'
      } else if(currentProfile){
        alert('fallback needed other')
      } else {
        window.location = '/login?already-refreshed=true'
      } 
    }
  }, [originHost, requesterOrg, currentProfile, searchParams, hostNameSlugToUse, lastActiveGroupPrefix])

  if(!requesterOrg){
    if(lastActiveGroupPrefix) {
      return <GeneralLoader />
    }

    return (
      <section
        id="main-container-website-main-frame"
        className={`light-theme-immediate fallback-helper-container outer-settings-specific`}
      >
        <Settings />
      </section>        
    )
  }

  if(isStripeConnected && originHost){
    return (
      <ProfileContext.Provider value={currentProfile}>
        <React.Fragment><OriginGuestRoutes /></React.Fragment>
      </ProfileContext.Provider>
    )
  } else if (requesterOrg.activeGroup) {
    return <GeneralLoader />
  } else if (currentProfile){
    return <GeneralLoader />
  } else {
    return <GeneralLoader />
  }
};

export default memo(DefaultHelper);
