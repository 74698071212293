import React, { memo, useCallback, useMemo } from 'react';
import ParentTagRender from './ParentTagRender';

const ParentTagsMapper = ({ origin, categories_to_iterate, itemsSelected, untaggedActive, onParentTagClick, onTagDeleteClick, onChildTagClick, onChildDeleteInit, assignSpecific }) => {
  const itemCheck = useCallback((item, categoryLocalId) => item.id === categoryLocalId, []);
  const checkSelectedItems = useCallback(categoryLocalId => itemsSelected.find(item => itemCheck(item, categoryLocalId)), [itemsSelected]);
  const isActive = useCallback(
    categoryLocalId => categoryLocalId &&  itemsSelected && checkSelectedItems(categoryLocalId),
    [itemsSelected]
  );
  const keyExtractor = useCallback(categoryLocalId => `${categoryLocalId}-tag-container`, []);

  const parentTags = useMemo(
    () => categories_to_iterate.filter(parentCategory => !parentCategory.parentId),
    [categories_to_iterate]
  );
  const childTags = useMemo(
    () => categories_to_iterate.filter(categoryLocal => categoryLocal.parentId),
    [categories_to_iterate]
  );

  const reduceItem = useCallback(
    (accumulator, currentValue) => ({
      ...accumulator,
      [currentValue.parentId]: [
        ...(accumulator[currentValue.parentId] || []),
        currentValue
      ]
    }), []
  );

  const childTags_Reduced = useMemo(
    () => 
      childTags && childTags.length > 0
        ? childTags.reduce(reduceItem, {})
        : {},
    [childTags]
  );

  return (
    parentTags.map(categoryLocal => {
      const is_active = isActive(categoryLocal.id);

      return (
        <ParentTagRender
          key={keyExtractor(categoryLocal.id || 'untagged')}
          origin={origin}
          is_active={is_active}
          itemsSelected={itemsSelected}
          categoryLocal={categoryLocal}
          assignSpecific={assignSpecific}
          untaggedActive={untaggedActive}
          onParentTagClick={onParentTagClick}
          onTagDeleteClick={onTagDeleteClick}
          onChildTagClick={onChildTagClick}
          onChildDeleteInit={onChildDeleteInit}
          childTags_Reduced={childTags_Reduced}
          untaggedAndDbConditional={untaggedActive || categories_to_iterate.length === 0}
        />
      );
    })
  );
};

export default memo(ParentTagsMapper);
