import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Query } from 'react-apollo';
import Autocomplete, {
  createFilterOptions
} from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import { groupTags } from '../../apollo.queries';
import Chip from '@material-ui/core/Chip';

const filter = createFilterOptions({
  stringify: option => option.label
});

class GeneralTags_AutoComplete extends Component {
  constructor(props) {
    super(props);

    this.accumulator = 0;

    this.state = {};
  }

  getRawValue = () =>
    this.state.fixedItem ||
    (this.state.parentTag &&
      this.state.parentTag.inputValue && {
        id: `new_${this.accumulator}`,
        label: this.state.parentTag.inputValue
      });

  render() {
    const {
      loadingChange,
      single,
      placeholder,
      cachePolicy,
      updateTagItems,
      activeTags
    } = this.props;

    const { parentTag = { inputValue: '' } } = this.state;

    return (
      <Query query={groupTags} fetchPolicy={cachePolicy || 'cache-and-network'}>
        {({ loading, data = {} }) => {
          if (loading && !data.groupTags) {
            return (
              <div className="general-tag-loading-specific">
                <div className="appLoading">
                  <svg
                    className="simple-line-loader-for-app-loading css-primary-hex-stroke"
                    viewBox="0 0 50 50"
                  >
                    <circle
                      className="path css-primary-hex-stroke"
                      cx="25"
                      cy="25"
                      r="20"
                      fill="none"
                      strokeWidth="5"
                    ></circle>
                  </svg>
                </div>
              </div>
            );
          }

          const categories_to_iterate = data.groupTags || [];

          return (
            <div
              className={`immediate-gallery-tags-container-autocomplete ${(
                parentTag.inputValue || ''
              ).length === 0 && 'no-value-exists-within'}`}
            >
              <label>Tags</label>
              <Autocomplete
                id="tags-standard"
                freeSolo={true}
                noOptionsText="No tags..."
                options={categories_to_iterate}
                getOptionLabel={option =>
                  option.inputLabel || option.label || ''
                }
                {...(single
                  ? {
                      disablePortal: true,
                      filterOptions: (options, params) => {
                        const filtered = filter(options, parentTag);

                        if (
                          (parentTag.inputValue || parentTag.label || '')
                            .length > 0 &&
                          !filtered.find(
                            option =>
                              option.label.toLowerCase() ===
                              parentTag.inputValue.toLowerCase()
                          )
                        ) {
                          this.accumulator++;

                          filtered.push({
                            id: `new_${this.accumulator}`,
                            label: parentTag.inputValue || parentTag.label,
                            inputLabel: `Add "${parentTag.inputValue}"`
                          });
                        }

                        return filtered;
                      },
                      value: parentTag,
                      inputValue: parentTag.inputValue,
                      onChange: (e, parentTag, reason) =>
                        reason === 'select-option' &&
                        this.setState({
                          fixedItem: parentTag,
                          parentTag: { inputValue: parentTag.label }
                        }),
                      onInputChange: (e, parentTag, reason) =>
                        reason !== 'reset' &&
                        this.setState({
                          fixedItem: false,
                          parentTag: { inputValue: parentTag }
                        })
                    }
                  : {
                      ...(activeTags && { value: activeTags }),
                      multiple: true,
                      renderTags: (value, getTagProps) => (
                        <div className="container-of-tags-to-position-at-bottom">
                          {value.map((option, index) => (
                            <Chip
                              variant="outlined"
                              label={option.label}
                              {...getTagProps({ index })}
                            />
                          ))}
                        </div>
                      ),
                      onChange: (e, tagItems) => updateTagItems(tagItems),
                      filterOptions: (options, params) => {
                        let filtered = filter(options, params);

                        if ((params.inputValue || '').length > 0) {
                          this.accumulator++;

                          filtered.push({
                            id: `new_${this.accumulator}`,
                            label: params.inputValue || params.label,
                            inputLabel: `Add ${params.inputValue}`
                          });
                        }

                        return filtered;
                      }
                    })}
                renderInput={params => (
                  <TextField
                    {...params}
                    variant="standard"
                    placeholder={placeholder || 'Add Tag'}
                  />
                )}
              />
              {loadingChange && (
                <div className="general-tag-loading-specific">
                  <div className="appLoading">
                    <svg
                      className="simple-line-loader-for-app-loading css-primary-hex-stroke"
                      viewBox="0 0 50 50"
                    >
                      <circle
                        className="path css-primary-hex-stroke"
                        cx="25"
                        cy="25"
                        r="20"
                        fill="none"
                        strokeWidth="5"
                      ></circle>
                    </svg>
                  </div>
                </div>
              )}
            </div>
          );
        }}
      </Query>
    );
  }
}

GeneralTags_AutoComplete.propTypes = {
  cachePolicy: PropTypes.string,
  single: PropTypes.bool,
  placeholder: PropTypes.string,
  updateTagItems: PropTypes.func,
  activeTags: PropTypes.array,
  loadingChange: PropTypes.bool
};

export default GeneralTags_AutoComplete;
