const personIcon = <svg width="18" height="19" viewBox="0 0 18 19" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M17 18V17.25C17 14.9 15.09 13 12.75 13H5.25001H5.24001C2.89001 13 0.990005 14.9 0.990005 17.25V17.99" stroke="#0292FF" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/><path d="M9 1C7.93913 1 6.92172 1.42143 6.17157 2.17157C5.42143 2.92172 5 3.93913 5 5C5 6.06087 5.42143 7.07828 6.17157 7.82843C6.92172 8.57857 7.93913 9 9 9C10.0609 9 11.0783 8.57857 11.8284 7.82843C12.5786 7.07828 13 6.06087 13 5C13 3.93913 12.5786 2.92172 11.8284 2.17157C11.0783 1.42143 10.0609 1 9 1Z" stroke="#0292FF" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/></svg>;
const emailIcon = <svg width="14" height="12" viewBox="0 0 14 12" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M11.6667 10.6534H2.33333C1.59667 10.6534 1 10.0534 1 9.32009V2.67342C1 1.93342 1.59667 1.34009 2.33333 1.34009H11.6667C12.4 1.34009 13 1.93342 13 2.67342V9.31342C13 10.0468 12.4 10.6401 11.6667 10.6401V10.6534Z" stroke="#0292FF" strokeLinecap="round" strokeLinejoin="round"/><path d="M10.3333 4L7 6L3.66666 4" stroke="#0292FF" strokeLinecap="round" strokeLinejoin="round"/></svg>;
const phoneIcon = <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M10.4667 10.64L11.0333 10.0667H11.0267C11.4133 9.67335 11.4133 9.04001 11.0267 8.64668L9.74667 7.36668L9.74 7.36001C9.21334 6.83335 8.37334 6.83335 7.85334 7.35335C7.84667 7.35335 7.84667 7.35335 7.84667 7.35335L6.84 8.35335L6.83334 8.34668C5.32667 7.78001 4.14 6.59335 3.57334 5.08668L4.57334 4.08001V4.07334C5.09334 3.54668 5.09334 2.70668 4.56667 2.18668L3.28667 0.906679C2.89334 0.513345 2.26 0.513345 1.86667 0.900012C1.86 0.900012 1.86 0.900012 1.86 0.900012L1.28667 1.46668H1.28C0.833337 1.90668 0.58667 2.50668 0.58667 3.13335H0.580003C0.573337 7.62001 4.20667 11.26 8.69334 11.2667C8.69334 11.2667 8.7 11.2667 8.70667 11.26V11.2593C9.32667 11.2527 9.92667 11.006 10.3733 10.566L10.4667 10.64Z" stroke="#0292FF" strokeLinecap="round" strokeLinejoin="round"/></svg>;
const mapIcon = <svg width="12" height="14" viewBox="0 0 12 14" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M1.41333 5.69663V5.57663C1.41333 3.0433 3.46467 0.994629 5.99333 0.994629C8.52 0.994629 10.5733 3.04596 10.5733 5.57663V5.68996C10.5733 7.99663 7.652 11.47 6.47467 12.7766C6.21467 13.0633 5.76733 13.0633 5.508 12.7766C4.33067 11.47 1.40933 7.99663 1.40933 5.6833L1.41333 5.69663Z" stroke="#0292FF" strokeLinecap="round" strokeLinejoin="round"/><path d="M4.66666 5.63319C4.66666 6.36653 5.26 6.96653 6 6.96653C6.73333 6.96653 7.33333 6.36653 7.33333 5.63319V5.60653C7.33333 4.86653 6.73333 4.27319 6 4.27319C5.26 4.27319 4.66666 4.86653 4.66666 5.60653" stroke="#0292FF" strokeLinecap="round" strokeLinejoin="round"/></svg>;
const socialIcon = <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M7 1C10.32 1 13 3.67867 13 7C13 10.32 10.32 13 7 13" stroke="#0292FF" strokeLinecap="round" strokeLinejoin="round"/><path d="M7 13C3.67867 13 1 10.32 1 7C1 3.67867 3.67867 1 7 1" stroke="#0292FF" strokeLinecap="round" strokeLinejoin="round"/><path d="M5.77866 1.70658C3.852 4.91992 3.852 9.07325 5.77866 12.2939C6.33866 13.2339 7.652 13.2339 8.21866 12.2939C10.1453 9.07392 10.1453 4.92058 8.21866 1.70658C7.652 0.759915 6.33866 0.759915 5.772 1.70658H5.77866Z" stroke="#0292FF" strokeLinecap="round" strokeLinejoin="round"/><path d="M1 7H13" stroke="#0292FF" strokeLinecap="round" strokeLinejoin="round"/></svg>;
const mainIcon = <svg width="18" height="21" viewBox="0 0 18 21" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M10.47 9.90798V5.45998C10.47 4.63998 9.79996 3.97998 8.98996 3.97998C8.16996 3.97998 7.50996 4.63998 7.50996 5.44998V8.88998V13.967L4.86396 12.607C4.25396 12.297 3.52896 12.407 3.04396 12.887C2.41096 13.517 2.43396 14.547 3.10396 15.147L7.92896 19.447C8.28896 19.767 8.76896 19.947 9.25896 19.947H13.889C14.819 19.947 15.629 19.287 15.839 18.377L17.028 13.017C17.278 11.887 16.518 10.777 15.378 10.607L10.438 9.83698L10.47 9.90798Z" stroke="#0292FF" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/><path d="M10.64 7H14.99C16.09 7 16.99 6.1 16.99 5V3C16.99 1.895 16.09 1 14.99 1H2.98999C1.88499 1 0.98999 1.895 0.98999 3V5C0.98999 6.1 1.88499 7 2.98999 7H7.33999" stroke="#0292FF" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/></svg>;

const BlockContactInfoTypes = {
  sectionTitle: "Contact Info",
  backgroundColor: "#E6F4FF",
  icon: personIcon,
  types: [
    {
      backgroundColor: "#E6F4FF",
      icon: personIcon,
      title: 'Athlete First Name',
      type: 'athlete_first_name_default'
    },
    {
      backgroundColor: "#E6F4FF",
      icon: personIcon,
      title: 'Athlete Last Name',
      type: 'athlete_last_name_default'
    },
    {
      backgroundColor: "#E6F4FF",
      icon: emailIcon,
      title: 'Athlete Email',
      type: 'athlete_email_default'
    },
    {
      backgroundColor: "#E6F4FF",
      icon: phoneIcon,
      title: 'Athlete Phone',
      type: 'athlete_tel_default'
    },
    {
      backgroundColor: "#E6F4FF",
      icon: personIcon,
      title: 'Athlete Age',
      type: 'athlete_age_default'
    },
    {
      backgroundColor: "#E6F4FF",
      icon: personIcon,
      title: 'Parent First Name',
      type: 'parent_first_name_default'
    },
    {
      backgroundColor: "#E6F4FF",
      icon: personIcon,
      title: 'Parent Last Name',
      type: 'parent_last_name_default'
    },
    {
      backgroundColor: "#E6F4FF",
      icon: emailIcon,
      title: 'Parent Email',
      type: 'parent_email_default'
    },
    {
      backgroundColor: "#E6F4FF",
      icon: phoneIcon,
      title: 'Parent Phone',
      type: 'parent_tel_default'
    },
    {
      backgroundColor: "#E6F4FF",
      icon: mapIcon,
      title: 'Zip Code',
      type: 'zip_default'
    },
    {
      backgroundColor: "#E6F4FF",
      icon: mapIcon,
      title: 'Address',
      type: 'address_default'
    },
    {      
      backgroundColor: "#E6F4FF",
      icon: mainIcon,
      title: 'Country',
      type: 'country_input'
    },
    {
      backgroundColor: "#E6F4FF",
      icon: socialIcon,
      title: 'Instagram',
      type: 'instagram_default'
    },
    {
      backgroundColor: "#E6F4FF",
      icon: socialIcon,
      title: 'Twitter',
      type: 'twitter_default'
    },
  ]
};

export default BlockContactInfoTypes;
