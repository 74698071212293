import apolloClient from '../apollo.client';
import gql from 'graphql-tag';
import { parallel, mapLimit } from 'async-es';
import {
  /** New **/
  packageQuery,
  getPrivatePrograms,
  searchPageSections,
  searchPageSectionItems,

  /** Legacy **/
  getPublicInstance
} from '../apollo.queries';
import {
  /** New **/
  upsertPage,
  deletePackage,
  upsertPageSections,
  upsertPageSectionItems,
  removePageSections,
  removePageSectionItems,

  /** Legacy **/
  updateWebsitePage,
  updateOrg
} from '../apollo.mutations';
import { uploadWebsiteFile } from './upload.actions';
import ACTIONS from '../constants/website/actions';
import { tenantPrefixUrlDeterminant } from './user.actions';
import { useQuery } from 'react-apollo';
import { useMemo } from 'react';

/** Start of Constants **/

const chunks = (array, size) => {
  if (!array.length) {
    return [];
  }
  const head = array.slice(0, size);
  const tail = array.slice(size);

  return [head, ...chunks(tail, size)];
};

export const fallbackPage = activeSlug => ({
  id: 'temp-page-reference',
  type: activeSlug || 'programs',
  settings: JSON.stringify({
    featuredImage: {
      x: 0,
      y: 0,
      scale: 1
    }
  })
});

/** New Functions **/

/** Start of Mutations **/

export const updateOrgInit = ({
  id,
  groupId,
  name,
  code,
  domain,
  logo,
  currency,
  groupCode,
  groupName,
  replyToEmail
}) => async dispatch => {
  if (!(name, code, groupCode, groupName)) {
    alert('Make sure all the needed information is filled out.');

    return;
  }

  dispatch({
    type: ACTIONS.UPDATE_STATE,
    payload: {
      upsertingOrg: true
    }
  });

  const { error, success } = (
    (await apolloClient.mutate({
      mutation: updateOrg,
      variables: {
        input: {
          id,
          groupId,
          name,
          code,
          domain,
          logo,
          currency,
          groupCode,
          groupName,
          replyToEmail
        }
      }
    })) || {}
  ).data.updateOrg;

  dispatch({
    type: ACTIONS.UPDATE_STATE,
    payload: {
      updatedOrg: {
        id,
        groupId,
        name,
        code,
        currency,
        domain,
        logo
      },
      updatedGroup: {
        code: groupCode,
        name: groupName,
        replyToEmail
      },
      upsertingOrg: false,
      orgUpdated: error ? false : true
    }
  });

  if (error) {
    alert(error);

    return;
  }

  window.location = `${tenantPrefixUrlDeterminant(
    code,
    groupCode
  )}/admin/settings?orgUpdated=true`;

  return { success };
};

export const upsertPageInit = async ({
  page,
  sectionItemsToRemove = [],
  sectionsToRemove = []
}) => {
  try {
    const { id, type, settings, sections = [] } = page;

    const newPage =
      !id &&
      (await apolloClient.mutate({
        mutation: upsertPage,
        variables: {
          input: {
            type,
            settings: JSON.stringify(settings || {})
          }
        }
      }));
  
    const pageId = newPage ? newPage.data.upsertPage.success : id;
  
    const sectionItems_flattened = sections
      .map(({ items = [], id }) =>
        items.map((item, index) => ({
          ...item,
          index,
          settings: item.settings ? JSON.stringify(item.settings) : null,
          sectionId: id,
          packageId: item.packageItem ? item.packageItem.id : null,
          programId: item.program ? item.program.id : null,
          packageItem: undefined,
          program: undefined,
          __typename: undefined
        }))
      )
      .flat(1);
  
    const sectionItems_grouped = sectionItems_flattened.reduce(
      (obj, item) => {
        const refName = item.sectionId.includes('new-section') ? 'new' : 'old';
  
        return {
          ...obj,
          [refName]: [...obj[refName], item]
        };
      },
      { new: [], old: [] }
    );
  
    const {
      upsertedPage,
      upsertedPageSections,
      upsertedPageSectionItems,
      sectionsRemoved,
      sectionItemsRemoved
    } = await new Promise(resolve =>
      parallel(
        {
          upsertedPage: callback =>
            setTimeout(
              async () =>
                callback(
                  null,
                  newPage
                    ? newPage.data.upsertPage.success
                    : (
                        await apolloClient.mutate({
                          mutation: upsertPage,
                          variables: {
                            input: {
                              id,
                              type,
                              settings: JSON.stringify(settings || {})
                            }
                          }
                        })
                      ).data.upsertPage.success
                ),
              50
            ),
          upsertedPageSections: callback =>
            setTimeout(
              async () =>
                callback(
                  null,
                  await new Promise(resolveChild =>
                    mapLimit(
                      chunks(
                        sections.map(({ id, settings, title }, index) => ({
                          id,
                          index,
                          title,
                          settings: JSON.stringify(settings || {}),
                          pageId
                        })),
                        10
                      ),
                      3,
                      (sections, callback) =>
                        setTimeout(
                          async () =>
                            callback(
                              null,
                              (
                                await apolloClient.mutate({
                                  mutation: upsertPageSections,
                                  variables: {
                                    input: { sections }
                                  }
                                })
                              ).data.upsertPageSections.success
                            )
                        , 10),
                      (err, results) => resolveChild((results || []).flat(1))
                    )
                  )
                ),
              40
            ),
          upsertedPageSectionItems: callback =>
            setTimeout(
              async () =>
                callback(
                  null,
                  await new Promise(resolveChild =>
                    mapLimit(
                      chunks(sectionItems_grouped.old, 10),
                      3,
                      (sectionItems, callback) =>
                        setTimeout(
                          async () =>
                            callback(
                              null,
                              (
                                await apolloClient.mutate({
                                  mutation: upsertPageSectionItems,
                                  variables: {
                                    input: { sectionItems }
                                  }
                                })
                              ).data.upsertPageSectionItems.success
                            )
                        , 10),
                      (err, results) => resolveChild((results || []).flat(1))
                    )
                  )
                ),
              30
            ),
          sectionsRemoved: callback =>
            setTimeout(
              async () =>
                callback(
                  null,
                  await new Promise(resolveChild =>
                    mapLimit(
                      chunks(
                        sectionsToRemove.map(section => ({ id: section.id })),
                        10
                      ),
                      3,
                      (sections, callback) =>
                        setTimeout(
                          async () =>
                            callback(
                              null,
                              (
                                await apolloClient.mutate({
                                  mutation: removePageSections,
                                  variables: {
                                    input: { sections }
                                  }
                                })
                              ).data.removePageSections.success
                            )
                        , 10),
                      (err, results) => resolveChild((results || []).flat(1))
                    )
                  )
                ),
              20
            ),
          sectionItemsRemoved: callback =>
            setTimeout(
              async () =>
                callback(
                  null,
                  await new Promise(resolveChild =>
                    mapLimit(
                      chunks(
                        sectionItemsToRemove.map(item => ({ id: item.id })),
                        10
                      ),
                      3,
                      (sectionItems, callback) =>
                        setTimeout(
                          async () =>
                            callback(
                              null,
                              (
                                await apolloClient.mutate({
                                  mutation: removePageSectionItems,
                                  variables: {
                                    input: {
                                      sectionItems
                                    }
                                  }
                                })
                              ).data.removePageSectionItems.success
                            )
                        , 10),
                      (err, results) => resolveChild((results || []).flat(1))
                    )
                  )
                ),
              10
            )
        },
        async (err, results) =>
          resolve({
            ...results,
            upsertedPageSectionItems: [
              ...results.upsertedPageSectionItems,
              ...await new Promise(resolveChild =>
                mapLimit(
                  chunks(
                    sectionItems_grouped.new.map(item => ({
                      ...item,
                      sectionId: (
                        results.upsertedPageSections.find(
                          section => section.prevId === item.sectionId
                        ) || {}
                      ).id
                    })),
                    10
                  ),
                  3,
                  (sectionItems, callback) =>
                    setTimeout(
                      async () =>
                        callback(
                          null,
                          (
                            await apolloClient.mutate({
                              mutation: upsertPageSectionItems,
                              variables: {
                                input: {
                                  sectionItems
                                }
                              }
                            })
                          ).data.upsertPageSectionItems.success
                        )
                    , 10),
                  (err, results) => resolveChild((results || []).flat(1))
                )
              )
            ]
          })                       
      )
    );

    return {
      sectionsRemovedResponse: sectionsRemoved,
      sectionItemsRemovedResponse: sectionItemsRemoved,
      upsertedPageResponse: upsertedPage,
      upsertedPageSectionsResponse: upsertedPageSections,
      upsertedPageSectionItemsResponse: upsertedPageSectionItems
    };
  } catch (e){
    console.log(e);
  };  
};

const getPageData = async pageId =>
  await new Promise(resolve =>
    parallel(
      {
        pageSectionsResponse: callback =>
          setTimeout(
            async () =>
              callback(
                null,
                (await apolloClient.query({
                  query: searchPageSections,
                  fetchPolicy: 'network-only',
                  variables: { pageId }
                })) || []
              ),
            200
          ),
        pageSectionItemsResponse: callback =>
          setTimeout(
            async () =>
              callback(
                null,
                (await apolloClient.query({
                  query: searchPageSectionItems,
                  fetchPolicy: 'network-only',
                  variables: { pageId }
                })) || []
              ),
            100
          )
      },
      (err, { pageSectionsResponse, pageSectionItemsResponse }) => {
        const reducedSectionItems = (
          pageSectionItemsResponse.data.searchPageSectionItems || []
        ).reduce(
          (result, sectionItem) => ({
            ...result,
            [sectionItem.sectionId]: [
              ...(result[sectionItem.sectionId] || []),
              {
                ...sectionItem,
                settings:
                  (sectionItem.settings && JSON.parse(sectionItem.settings)) ||
                  {}
              }
            ]
          }),
          {}
        );

        resolve(
          (pageSectionsResponse.data.searchPageSections || []).map(section => ({
            ...section,
            settings: (section.settings && JSON.parse(section.settings)) || {},
            items: reducedSectionItems[section.id] || []
          }))
        );
      }
    )
  );

export const initWebsiteBuilder = (pages = {}) => async dispatch => {
  let theme, showLogo, primaryHex, showTagsTOC;
  const pagesToPass = [ "sign_up", "programs", "packages" ].reduce(
    (results, currentValue) => {
      const currentSettings = (pages[currentValue] && pages[currentValue].settings && JSON.parse(pages[currentValue].settings)) || {};

      if(currentSettings.theme) theme = currentSettings.theme;
      if(currentSettings.primaryHex) primaryHex = currentSettings.primaryHex;
      if(currentSettings.showTagsTOC) showTagsTOC = currentSettings.showTagsTOC;

      showLogo = currentSettings.showLogo;

      return {
        ...results,
        ...pages[currentValue] && {
          [currentValue]: {
            ...pages[currentValue],
            settings: {
              ...currentSettings,
              featuredImage: {
                x: 0,
                y: 0,
                scale: 1,
                ...currentSettings.featuredImage || {}
              }
            }
          }
        }            
      }
    }, {}
  );

  dispatch({
    type: ACTIONS.UPDATE_STATE,
    payload: {
      ...pagesToPass,
      ...theme && { theme },
      ...primaryHex && { primaryHex },
      ...showTagsTOC && { showTagsTOC },
      showLogo
    }
  });

  dispatch({
    type: ACTIONS.UPDATE_STATE,
    payload: {
      ...await new Promise(resolve =>
        parallel(
          {
            ...[ "sign_up", "programs", "packages" ].reduce(
              (results, currentValue, currentIndex) => {
                const currentSettings = (pages[currentValue] && pages[currentValue].settings && JSON.parse(pages[currentValue].settings)) || {};
  
                return {
                  ...results,
                  [currentValue]: callback =>
                                    setTimeout(
                                      async () =>
                                        callback(
                                          null,
                                          pages[currentValue]
                                            ? {
                                                ...pages[currentValue],
                                                settings: {
                                                  ...currentSettings,
                                                  featuredImage: {
                                                    x: 0,
                                                    y: 0,
                                                    scale: 1,
                                                    ...currentSettings.featuredImage || {}
                                                  }
                                                },
                                                sections: (await getPageData(pages[currentValue].id)) || [
                                                  {
                                                    id: `new-section-1-${currentValue}`,
                                                    title: '',
                                                    items: []
                                                  }
                                                ]
                                              }
                                            : {
                                                type: currentValue,
                                                settings: { featuredImage: { x: 0, y: 0, scale: 1 } },
                                                sections: [
                                                  {
                                                    id: `new-section-1-${currentValue}`,
                                                    title: '',
                                                    items: []
                                                  }
                                                ]
                                              }
                                        ),
                                      (currentIndex + 1) * 100
                                    )
                }
              }, {}
            )
          },
          (err, results) => resolve(results || {})
        )
      )
    }
  });

  return
};

export const removeSection = ({
  activePageObject,
  activePage,
  sectionsToRemove,
  sectionToRemove
}) => dispatch => {
  let sections = activePageObject.sections;
  const section_ref = sections[sectionToRemove];

  sections.splice(sectionToRemove, 1);

  dispatch({
    type: ACTIONS.UPDATE_STATE,
    payload: {
      sectionsToRemove: [...sectionsToRemove, section_ref],
      [activePage]: {
        ...activePageObject,
        sections
      },
      activeSection: 0
    }
  });
};

export const removeSectionItem = ({
  activePageObject,
  activePage,
  activeSection,
  sectionItemToRemove,
  sectionItemsToRemove
}) => dispatch => {
  let activePageObject_ref = activePageObject;
  let sectionItems = activePageObject.sections[activeSection].items;
  const sectionItem_ref = sectionItems[sectionItemToRemove];

  sectionItems.splice(sectionItemToRemove, 1);

  activePageObject_ref.sections[activeSection].items = sectionItems.map(
    (sectionItem, index) => ({
      ...sectionItem,
      index
    })
  );

  dispatch({
    type: ACTIONS.UPDATE_STATE,
    payload: {
      activeSectionItem: 0,
      [activePage]: activePageObject_ref,
      sectionItemsToRemove: [...sectionItemsToRemove, sectionItem_ref]
    }
  });
};

/** Legacy Functions **/

const getSignedUrl = gql`
  query($fileName: String!) {
    websiteFileSignedUrl(fileName: $fileName)
  }
`;

export const updateWebsiteState = updatedStates => dispatch =>
  dispatch({
    type: ACTIONS.UPDATE_STATE,
    payload: updatedStates
  });

export const resetWebsiteState = () => dispatch =>
  dispatch({
    type: ACTIONS.RESET_STATE,
    payload: null
  });

export const uploadCoverPhoto = ({
  file,
  websiteFileSignedUrl
}) => async dispatch => {
  const res = await dispatch(uploadWebsiteFile(file, websiteFileSignedUrl));

  dispatch({
    type: ACTIONS.UPDATE_STATE,
    payload: {
      coverPhoto: res,
      tempFileUrl: null,
      tempFile: null,
      uploading: false
    }
  });

  if (res) {
    dispatch({
      type: ACTIONS.UPDATE_STATE,
      payload: { reupload: false }
    });
  }
};

export const getSignedUrlAndUploadFile = ({ file }) => dispatch => {
  const file_name_split = file.name.split('.');
  const file_name_to_pass = `${new Date().getTime()}.${
    file_name_split[file_name_split.length - 1]
  }`;

  apolloClient
    .query({
      query: getSignedUrl,
      variables: {
        fileName: file_name_to_pass
      },
      fetchPolicy: 'network-only'
    })
    .then(({ data }) => {
      // if signed url, start uploading to s3. when that's done, mark voiceover as completed. ref uploadCourseMediaFile
      if (data.websiteFileSignedUrl && data.websiteFileSignedUrl.url) {
        dispatch(
          uploadCoverPhoto({
            file,
            websiteFileSignedUrl: data.websiteFileSignedUrl
          })
        );
      }
    })
    .catch(e => alert(e.message.split(': ')[1]));
};

export const onDrop = ({ files, tempFileUrl }) => dispatch => {
  if (
    files[0] &&
    files[0].name
      .split('.')
      .slice(-1)[0]
      .toLowerCase() === 'svg'
  ) {
    alert('Try uploading an png or jpg file type.');
  } else if (tempFileUrl) {
    alert('Please let the previous upload finish first, thanks!');
  } else {
    if (files.length === 1) {
      dispatch({
        type: ACTIONS.UPDATE_STATE,
        payload: {
          tempFileUrl: URL.createObjectURL(files[0]),
          tempFile: files[0],
          uploading: true
        }
      });
      dispatch(getSignedUrlAndUploadFile({ file: files[0] }));
    } else {
      alert('File type not accepted');
    }
  }
};

export const updateWebsitePageInit = dataToPass => dispatch => {
  const {
    pageId,
    enabled,
    refresh,
    headerTitle,
    headerSubTitle,
    slug,
    coverPhoto,
    headerOpacity,
    headerBGColor,
    headerTitleStyle,
    headerSubTitleStyle
  } = dataToPass;

  const updateEnabled = typeof enabled !== 'undefined' && enabled !== null;

  dispatch({
    type: ACTIONS.UPDATE_STATE,
    payload: {
      loadingWithin: true,
      initBGColorPicker: null
    }
  });

  apolloClient
    .mutate({
      mutation: updateWebsitePage,
      variables: {
        pageId,
        slug,
        coverPhoto,
        headerOpacity,
        headerBGColor,
        headerTitle,
        headerSubTitle,
        ...(headerTitleStyle && {
          headerTitleStyle: JSON.stringify(headerTitleStyle)
        }),
        ...(headerSubTitleStyle && {
          headerSubTitleStyle: JSON.stringify(headerSubTitleStyle)
        }),
        ...(enabled !== null && { enabled })
      },
      update: (cache, { data }) => {
        if (data.updateWebsitePage && data.updateWebsitePage.id) {
          const tenantCode = dataToPass.getPublicInstance.group.tenant.code;
          const groupCode = dataToPass.getPublicInstance.group.code;

          const prevPublicInstance = cache.readQuery({
            query: getPublicInstance,
            variables: { groupCode, tenantCode }
          });

          if (
            !(
              prevPublicInstance &&
              prevPublicInstance.getPublicInstance &&
              prevPublicInstance.getPublicInstance.group &&
              prevPublicInstance.getPublicInstance.group.websitePages
            )
          ) {
            prevPublicInstance = {
              ...prevPublicInstance.getPublicInstance,
              group: {
                ...prevPublicInstance.getPublicInstance.group,
                websitePages: []
              }
            };
          }

          const indexFound = prevPublicInstance.getPublicInstance.group.websitePages.findIndex(
            page => page.type === pageId
          );

          if (indexFound > -1) {
            prevPublicInstance.getPublicInstance.group.websitePages[
              indexFound
            ] = data.updateWebsitePage;
          } else {
            prevPublicInstance.getPublicInstance.group.websitePages.unshift(
              data.updateWebsitePage
            );
          }

          cache.writeQuery({
            query: getPublicInstance,
            variables: { groupCode, tenantCode },
            data: prevPublicInstance
          });
        }
      }
    })
    .then(({ data }) => {
      if (data.updateWebsitePage && data.updateWebsitePage.id) {
        if (refresh) {
          window.location.reload();
        }

        dispatch({
          type: ACTIONS.UPDATE_STATE,
          payload: {
            loadingWithin: false,
            error: null,
            success: `Page ${
              updateEnabled ? (enabled ? 'Enabled' : 'Disabled') : 'Updated'
            }`
          }
        });
      } else {
        dispatch({
          type: ACTIONS.UPDATE_STATE,
          payload: {
            loadingWithin: false,
            success: null,
            error:
              'There was an error with your request. Please try again and/or reach out to our support team.'
          }
        });
      }

      setTimeout(
        () =>
          dispatch({
            type: ACTIONS.UPDATE_STATE,
            payload: {
              success: false,
              error: false
            }
          }),
        3000
      );
    })
    .catch(e => {
      dispatch({
        type: ACTIONS.UPDATE_STATE,
        payload: {
          loadingWithin: false,
          error: e.message.split(': ')[1]
        }
      });

      setTimeout(
        () =>
          dispatch({
            type: ACTIONS.UPDATE_STATE,
            payload: {
              success: false,
              error: false
            }
          }),
        3000
      );
    });
};

export const initPackageBuilder = ({ packageId, groupId }) => async dispatch => {
  const { data = {} } =
    (await apolloClient.query({
      query: packageQuery,
      fetchPolicy: 'network-only',
      variables: { packageId, groupId }
    })) || {};

  if (!data.packageQuery) {
    alert('Package not found.');
    window.location = '';
    return;
  }

  dispatch({
    type: ACTIONS.UPDATE_STATE,
    payload: {
      activePackage: {
        ...data.packageQuery,
        stripeAccountExists: true,
        features: data.packageQuery.features && JSON.parse(data.packageQuery.features)
      }
    }
  });
};

export const removePackageInit = packageId => async dispatch => {
  dispatch({
    type: ACTIONS.UPDATE_STATE,
    payload: {
      removingPackages: true
    }
  });

  const removePackageData = (
    (await apolloClient.mutate({
      mutation: deletePackage,
      variables: { id: packageId }
    })) || {}
  ).data.deletePackage;

  dispatch({
    type: ACTIONS.UPDATE_STATE,
    payload: {
      removingPackages: false,
      [`package_removed_${packageId}`]: true
    }
  });

  return { removePackageData };
};

export const PackagesPageInit = ({ pageId }) => {
  const searchPageSectionItems_CacheOnly = useQuery(
    searchPageSectionItems,
    { variables: { pageId }, fetchPolicy: "cache-only" }
  );

  const searchPageSections_CacheOnly = useQuery(
    searchPageSections,
    { variables: { pageId }, fetchPolicy: "cache-only" }
  );

  /****/

  const searchPageSectionItems_Results = useQuery(
    searchPageSectionItems,
    { variables: { pageId }, fetchPolicy: "network-only" }
  );

  const searchPageSections_Results = useQuery(
    searchPageSections,
    { variables: { pageId }, fetchPolicy: "network-only" }
  );

  /****/

  const loading = searchPageSectionItems_Results.loading || searchPageSections_Results.loading

  const searchPageSectionItems_Data = searchPageSectionItems_Results?.data?.searchPageSectionItems || searchPageSectionItems_CacheOnly?.data?.searchPageSectionItems || []

  const searchPageSections_Data = searchPageSections_Results?.data?.searchPageSections || searchPageSections_CacheOnly?.data?.searchPageSections || []

  return { loading, searchPageSectionItems_Data, searchPageSections_Data }
};

export const ProgramsPageInit = ({ pageId }) => {
  const searchPageSectionItems_CacheOnly = useQuery(
    searchPageSectionItems,
    { variables: { pageId }, fetchPolicy: "cache-only" }
  )

  const searchPageSections_CacheOnly = useQuery(
    searchPageSections,
    { variables: { pageId }, fetchPolicy: "cache-only" }
  )

  const assignedPrograms_CacheOnly = useQuery(
    getPrivatePrograms,
    { fetchPolicy: "cache-only" }
  )

  /****/

  const searchPageSectionItems_Results = useQuery(
    searchPageSectionItems,
    { variables: { pageId }, fetchPolicy: "network-only" }
  )

  const searchPageSections_Results = useQuery(
    searchPageSections,
    { variables: { pageId }, fetchPolicy: "network-only" }
  )

  const assignedPrograms_Results = useQuery(
    getPrivatePrograms,
    { fetchPolicy: "network-only" }
  )

  /****/

  const loading = assignedPrograms_Results.loading || searchPageSectionItems_Results.loading || searchPageSections_Results.loading

  const assignedPrograms = assignedPrograms_Results?.data?.getPrivatePrograms || assignedPrograms_CacheOnly?.data?.getPrivatePrograms || []

  const searchPageSectionItems_Data = searchPageSectionItems_Results?.data?.searchPageSectionItems || searchPageSectionItems_CacheOnly?.data?.searchPageSectionItems || []

  const searchPageSections_Data = searchPageSections_Results?.data?.searchPageSections || searchPageSections_CacheOnly?.data?.searchPageSections || []

  return { loading, assignedPrograms, searchPageSectionItems_Data, searchPageSections_Data }
}

export const getParsedPageUpdate = ({
  upsertedPageSectionItemsResponse = [],
  upsertedPageSectionsResponse = [],
  upsertedPageResponse,
  sectionItemsToRemove = [],
  activePage_Object = {},
  sectionsToRemove = [],
  showTagsTOC,
  activePage,
  primaryHex,
  showLogo,
  theme
}) => ({
  [activePage]: {
    ...activePage_Object,
    id: activePage_Object.id || upsertedPageResponse.id,
    sections: (activePage_Object.sections || []).map(section => ({
      ...section,
      settings: {
        ...(activePage_Object.settings || { featuredImage: { x: 0, y: 0, scale: 1 } }),
        showTagsTOC,
        showLogo,
        theme,
        primaryHex
      },
      id: section.id.includes('new-section')
        ? (
            upsertedPageSectionsResponse.find(
              pageSectionsResponse =>
                pageSectionsResponse && pageSectionsResponse.prevId === section.id
            ) || { id: section.id }
          ).id
        : section.id,
      items: (section.items || []).map(sectionItem => ({
        ...sectionItem,
        id: sectionItem.id.includes('new-section-item')
          ? (
              upsertedPageSectionItemsResponse.find(
                pageSectionItemsResponse =>
                  pageSectionItemsResponse && pageSectionItemsResponse.prevId === sectionItem.id
              ) || { id: sectionItem.id }
            ).id
          : sectionItem.id
      }))
    }))
  },
  sectionsToRemove: sectionsToRemove.filter(
    section =>
      !sectionsToRemove.find(
        existingSection => section.id === existingSection.id
      )
  ),
  sectionItemsToRemove: sectionItemsToRemove.filter(
    sectionItem =>
      !sectionItemsToRemove.find(
        existingSectionItem => sectionItem.id === existingSectionItem.id
      )
  )
})

export const postPageUpsert = async ({ activeGroup, programs, packages, sign_up }) => {
  const data = await apolloClient.readQuery({
    query: getPublicInstance,
    variables: {
      groupCode: activeGroup.code,
      tenantCode: activeGroup.tenant.code
    }
  });

  if(data && data.getPublicInstance){
    let { activeGroup: { pages = [] } } = data.getPublicInstance;

    pages = JSON.parse(JSON.stringify(pages));

    for(let page of [programs, packages, sign_up]){
      const { type, settings } = page;

      const currentPage = pages.findIndex(page => type === page.type);

      if(currentPage > -1){
        pages[currentPage] = {
          ...pages[currentPage],
          ...page,
          settings: settings ? JSON.stringify(settings) : ''
        };
      } else {
        pages.push({
          ...page,
          settings: settings ? JSON.stringify(settings) : ''
        });
      }
    }

    apolloClient.writeQuery({
      query: getPublicInstance,
      data: {
        getPublicInstance: {
          ...data.getPublicInstance,
          activeGroup: {
            ...data.getPublicInstance.activeGroup,
            pages
          }
        }
      }
    })
  }
}