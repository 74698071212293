import React, { PureComponent } from 'react';
import GeneralLoader from '../../../../components/Shared/GeneralLoader';
import apolloClient from '../../../../apollo.client';
import { connectInstagram, disconnectInstagram } from '../../../../apollo.mutations';
import { parseSearchParams } from '../../../../helper';

import './instagramAuth.scss';

class InstagramAuth extends PureComponent {
  constructor(props) {
    super(props);

    const { disconnectSpecific } = props;

    this.already_connected = false;

    this.state = { error: null };

    const search = window.location.search;
    let { code = false } = search && search !== '' ? parseSearchParams(search) : {};

    if(disconnectSpecific){
      this.disconnectinstagramConnection();
    } else if (code) {
      this.updateinstagramConnection(code);
    } else {
      window.location = `/`;

      return <div>Redirecting...</div>;
    }
  }

  disconnectinstagramConnection = async () => {
    try {
      const data = (
        (await apolloClient.mutate({
          mutation: disconnectInstagram
        })) || {}
      ).data || {};

      if (data.disconnectInstagram) {
        this.setState({ instagram_response_result: true });
      } else {
        alert('There was an error with your request...');

        window.location = `/`;
      }
    } catch (e) {
      alert('There was an error with your request...');

      window.location = `/`;
    }
  };

  updateinstagramConnection = async code => {
    try {
      const data = (
        (await apolloClient.mutate({
          mutation: connectInstagram,
          variables: { code }
        })) || {}
      ).data || {};

      if (data.connectInstagram) {
        this.setState({ instagram_response_result: true });
      } else {
        alert('There was an error with your request...');

        window.location = `/`;
      }
    } catch (e) {
      alert('There was an error with your request...');

      window.location = `/`;
    }
  };

  render() {
    const { disconnectSpecific } = this.props;
    const { instagram_response_result } = this.state;

    return (
      <div className="success-connect-container">
        <div className="success-connect-white-container">
          {instagram_response_result ? (
            <>
              <img src="/images/process-complete.gif" />
              <h1>Success!</h1>
              <p>Instagram is now {disconnectSpecific ? "disconnected" : "connected"}</p>
              <button onClick={() => (window.location = `/`)}>Continue</button>
            </>
          ) : (
            <GeneralLoader />
          )}
        </div>
      </div>
    );
  }
};

export default InstagramAuth;
