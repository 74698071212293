import React, { memo, useEffect, useMemo } from 'react';
import replaceall from 'replaceall';
import { useSearchParams } from 'react-router-dom';
import { RequesterContext } from '../context/requester.context';
import AuthRouter from '../routers/AuthRouter';
import { fallbackPage } from '../actions/website.actions';
import OrganizationRouterMapperHelmet from './OrganizationRouterMapperHelmet';

const hostName = window.location.hostname.toLowerCase();
const originHost = hostName.indexOf('coachiq.io') > -1 || hostName.indexOf('localhost') > -1 || hostName.indexOf('127.0.0.1') > -1;
const hostNameSlugToUse = replaceall('.', '-', hostName);

const isLoggedIn = localStorage.getItem(`${hostNameSlugToUse}-auth-token-v3-12-13-21`); //token exists
const publicRoutesToExlude = ['terms', 'privacy', 'stripe-connected', 'create',  ...isLoggedIn ? [] : ['forgot', 'login']];

const OrganizationRouterMapper = ({ getPublicInstance_JSON, activeSlug }) => {
  const  [ searchParams ] = useSearchParams()
  const lowerCase_PathName = window.location.pathname.toLowerCase()
  
  useEffect(() => {    
    const split_pathName = lowerCase_PathName.toLowerCase().split('/')
    const cachedRef = `${hostNameSlugToUse}${originHost ? `-${split_pathName[0]}-${split_pathName[1]}` : ''}-active-group-id-v5`

    const isPublicRoute = publicRoutesToExlude.find(slug => lowerCase_PathName.includes(`/${slug}`)) ? true : false

    if(getPublicInstance_JSON.activeGroup.id !== localStorage.getItem(cachedRef) && !isPublicRoute){
      localStorage.setItem(cachedRef, getPublicInstance_JSON.activeGroup.id);            
    }
    
    const lowerCase_GroupCode = (getPublicInstance_JSON.activeGroup.code || '').toLowerCase()
    const lowerCase_TenantCode = (getPublicInstance_JSON.activeGroup.tenant.code || '').toLowerCase()
    
    if(lowerCase_GroupCode.length > 0 && lowerCase_TenantCode.length > 0){
      const activeGroupPrefix = originHost ? `/${lowerCase_TenantCode}/${lowerCase_GroupCode}` : '/';
      if(localStorage.getItem(`${hostNameSlugToUse}-last-active-group-prefix-v5`) !== activeGroupPrefix){
        localStorage.setItem(`${hostNameSlugToUse}-last-active-group-prefix-v5`, activeGroupPrefix);
      }
    }

    if(originHost){
      if(!lowerCase_PathName.includes(`${lowerCase_TenantCode}/${lowerCase_GroupCode}`.toLowerCase()) && !isPublicRoute){
        window.location = `/${lowerCase_TenantCode}/${lowerCase_GroupCode}/programs`;
      }
    }
  }, [searchParams, lowerCase_PathName, getPublicInstance_JSON.activeGroup]);
  
  const activeSettings = useMemo(() => {
    const activePage = getPublicInstance_JSON.activeGroup.pagesObject[activeSlug === 'settings' ? 'programs' : activeSlug] || fallbackPage(activeSlug);
    
    return (getPublicInstance_JSON.activeGroup.pagesObject['sign_up'] || activePage).settings || {};
  }, [getPublicInstance_JSON.activeGroup.pagesObject]);

  const { theme = 'light' } = activeSettings;

  return (
    <RequesterContext.Provider value={getPublicInstance_JSON}>
      <div className={`org-router-based class-specific-for-theme-${theme}`}>
        <OrganizationRouterMapperHelmet 
          activeSettings={activeSettings}
          activeGroupName={getPublicInstance_JSON.activeGroup.name} 
          tenantViaGroup={getPublicInstance_JSON.activeGroup.tenant} 
          primaryHexDefault={getPublicInstance_JSON.activeGroup.tenant.primaryHex}
        />
        <AuthRouter key={`auth-router-${getPublicInstance_JSON.activeGroup.id}`} requesterOrg={getPublicInstance_JSON} />
      </div>
    </RequesterContext.Provider>
  );
};

export default memo(OrganizationRouterMapper);