import { mapLimit } from 'async-es';
import React, { PureComponent } from 'react';
import ReactDOM from 'react-dom';
import { connect } from 'react-redux';
import { isPossiblePhoneNumber } from 'react-phone-number-input';
import GeneralLoader from '../../../../Shared/GeneralLoader';
import { updateFormState, uploadFormFiles } from '../../../../../actions/form.actions';
import { chunks } from '../../../../../actions/lesson.actions';
import { upsertFormBlockResponses } from '../../../../../apollo.mutations';
import { validEmail } from '../../../../../helper';
import apolloClient from '../../../../../apollo.client';
import { getLogoKey, tenantPrefixUrlDeterminant } from '../../../../../actions/user.actions';
import GeneralImage from '../../../../Shared/GeneralImage';

class FormSubmit extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {};
  }

  submitResponses = async () => {
    const { formSubmitted } = this.state;
    const { updateFormState, formState, previewSpecific, uploadFormFiles, form = {}, firstPageSuccess, formBlockGuestId, onSecondPageSuccess } = this.props;
    const { formResponses = {}, filesUploading } = formState;
    const { blocks = [], settings = {} } = form;
    const { pid } = settings || {};

    if(filesUploading) {
      alert('Files uploading...');

      return;
    };

    if(formSubmitted) {
      alert('Already submitted...');

      return;
    };

    this.setState({ formSubmitting: true });

    const reducedRequired = blocks.reduce(
      (results, { id, required = 1, type }) => {
        const {
          media,
          country = {},
          text = '',          
          choiceId = '',
          choiceIds = {}
        } = formResponses[id] || {};

        const filteredChoices = Object.values(choiceIds).filter(answer => answer);
        let responseStillNeeded = required && ['media_default','heading_default','description_default','number_heading_default'].indexOf(type) === -1 && !country.label && !((media || {}).id || (media || {}).size) && (text || '').length === 0 && (choiceId || '').length === 0 && filteredChoices.length === 0;

        if(!responseStillNeeded && ['athlete_email_default', 'parent_email_default'].indexOf(type) > -1 && !validEmail(text)){
          responseStillNeeded = true;
        };

        if(!responseStillNeeded && ['athlete_tel_default', 'parent_tel_default'].indexOf(type) > -1 && !isPossiblePhoneNumber(text)){
          responseStillNeeded = true;
        };

        return {
          ...results,
          ...responseStillNeeded && { [id]: true }
        };
      }, {}
    );    

    if(Object.keys(reducedRequired).length > 0){
      updateFormState({ reducedRequired });

      this.setState({ formSubmitting: false });

      return;
    };

    if(previewSpecific){
      if(pid){
        onSecondPageSuccess(true);

        return;
      };

      alert('Visit the live form to test submissions...');

      this.setState({ formSubmitting: false });

      return;
    };

    let fomattedResponsesIterate = Object.keys(formResponses).map(
      id => {
        const {
          media,
          country = {},
          text = '',
          choiceId = '',
          choiceIds = {}
        } = formResponses[id] || {};

        return {
          formBlockId: id,
          formBlockResponseChoiceIds: choiceId.length > 0 ? [choiceId] : Object.keys(choiceIds).filter(choiceId_local => choiceIds[choiceId_local]),
          formBlockResponseText: country.label ||  text,
          ...media && media.id
            ? { formBlockResponseMediaId: media.id }
            : { formBlockResponseMedia: media }
        };
      }
    );

    const mediaFiles = fomattedResponsesIterate.filter(({ formBlockResponseMedia }) => formBlockResponseMedia);

    if(mediaFiles.length > 0){
      const mediaUploadsToSync = await uploadFormFiles(mediaFiles) || [];

      fomattedResponsesIterate = fomattedResponsesIterate.map(
        (responseToPass) => ({
          ...responseToPass,
          ...!(responseToPass.formBlockResponseMedia || {}).id && {
            formBlockResponseMediaId: (mediaUploadsToSync.find(({ formBlockId }) => formBlockId === responseToPass.formBlockId) || {}).formBlockResponseMediaId,
            formBlockResponseMedia: undefined
          }
        })
      );
    };

    let formResponseIds;
    try {
      let sendEmail = true;
      
      formResponseIds = await new Promise(resolve =>
        mapLimit(
          chunks(fomattedResponsesIterate, 10),
          5,
          (blocks, callback) =>
              setTimeout(
                async () => {
                  const isSendEmail = sendEmail;
                  sendEmail = false;

                  callback(
                    null,
                    (
                      await apolloClient.mutate({
                        mutation: upsertFormBlockResponses,
                        variables: { 
                          input: { 
                            formBlockGuestId,
                            formBlocks: (blocks || []), 
                            sendEmail: isSendEmail ? true : false,
                            ...firstPageSuccess && { profileToken: firstPageSuccess }
                          } 
                        }
                      })
                    ).data.upsertFormBlockResponses
                  );
                }                
              , 10),
          (_, results) => resolve((results || []).flat())
        )
      );
    } catch (e) {
      console.log(e);
    };

    if(pid){
      this.setState({ formSubmitting: false });

      if((formResponseIds || []).length === 0) alert("There were no successful form responses.");

      onSecondPageSuccess(formResponseIds[0]);

      return;
    };

    this.setState({ formSubmitting: false, formSubmitted: true });

    return;
  }

  render() {
    const { formSubmitted, formSubmitting } = this.state;
    const { form = {}, formState } = this.props;
    const { reducedRequired = {}, uploadingFiles, fileUpload_Count = 0, fileUpload_Total = 0, fileUpload_Progress = 0 } = formState;
    const { group = {}, settings = {}, buttonText = "Complete Form" } = form;
    const { buttonTextColor = '#fff', buttonColor = "#000", url = '', destination = 'thank_you' } = settings || {};
    const { code, name, tenant = {} } = group;

    const requiredCount = Object.values(reducedRequired).filter(exists => exists).length > 0;

    const isToUrl = destination === 'to_url';

    return <>
      {requiredCount && <p className="required-needed">Some fields require a response before a submission is allowed.</p>}      
      <div 
        onClick={this.submitResponses}
        className="button-manip-at-bottom" 
        style={{ backgroundColor: buttonColor, color: buttonTextColor }}
      >{buttonText || "Complete Form"}</div>        

      {
        (formSubmitted || formSubmitting) && (
          <div className="container-of-top-for-loader-and-confirmation">
            {(formSubmitted) && (
              <div className="submission-success-over-top">
                {
                  destination === 'thank_you'
                    ? <div className="immediate-asset-container">
                        {(tenant || {}).logo ? (
                          <GeneralImage
                            imageResolution={'350x'}
                            originalKey={getLogoKey(tenant.logo, tenant.id)}
                          />
                        ) : (
                          <img src={`/images/android-chrome-512x512.png`} />
                        )}
                        <p>Form Submitted</p>           
                        <div className="org-and-group-text"></div>
                      </div>
                    : <div className="immediate-asset-container">
                        {(tenant || {}).logo ? (
                          <GeneralImage
                            imageResolution={'350x'}
                            originalKey={getLogoKey(tenant.logo, tenant.id)}
                          />
                        ) : (
                          <img src={`/images/android-chrome-512x512.png`} />
                        )}
                        <p>Form Submitted</p>           
                        <div className="org-and-group-text">
                          {
                            !isToUrl && <>
                              <h2>{(tenant || {}).name || 'N/A'}</h2>
                              <p className="group-name">{name || 'N/A'}</p>
                            </>
                          }                                             
                        </div>                             
                        <p
                          style={{ marginTop: 0 }}
                          ref={() => {
                            if(!this.hasntRanYet){
                              this.hasntRanYet = true;                            

                              setTimeout(() => window.location = isToUrl ? `${(url || '').indexOf('://') > -1 ? url : `http://${url}`}` : `${tenantPrefixUrlDeterminant((tenant || {}).code, code)}/join`, 2000);
                            }
                          }} 
                        >You will be redirected shortly.</p>
                      </div>
                }                
              </div>
            )}

            {formSubmitting && <GeneralLoader />}
          </div>            
        )
      }      

      {
        uploadingFiles && ReactDOM.createPortal(
          <div className="downloads-in-progress-overlay-for-media-outer">
            <div className="downloads-in-progress-overlay-for-media-inner">
              <div className={`circle-uploading-loader`}></div>
              <div className="progress-bar-digits">
                {fileUpload_Progress && !isNaN(parseInt(fileUpload_Progress))
                  ? parseInt(fileUpload_Progress) >= 100 ? '' : (parseInt(fileUpload_Progress) + ' %')
                  : '0 %'}
              </div>
              <div className="files-complete">
                Uploading Files:{' '}
                {`${fileUpload_Count} / ${fileUpload_Total}`}
              </div>
            </div>
          </div>,
          document.getElementById('outsideModalPortal-z2')
        )
      }
    </>;
  }
};

const mapStateToProps = state => ({
  formState: state.formState
});

const mapDispatchToProps = dispatch => ({
  updateFormState: variables => dispatch(updateFormState(variables)),
  uploadFormFiles: variables => dispatch(uploadFormFiles(variables))
});

export default connect(mapStateToProps, mapDispatchToProps)(FormSubmit);