import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Query } from 'react-apollo';
import { Elements, StripeProvider } from 'react-stripe-elements';
import { loadStripe } from '@stripe/stripe-js';

import apolloClient from '../../../../../apollo.client';

import StripeForm from '../../../Purchase/MainFrame/Sidebar/ParentStripeForm/MainMapper/StripeForm-legacy';

import { updateGeneralState } from '../../../../../actions/general.actions';
import { getSavedCards } from '../../../../../apollo.queries';
import { addSavedCard, removeSavedCard } from '../../../../../apollo.mutations';

const stripePromise = loadStripe('pk_live_0uqRmD0g012sAQC3OmP20w1n00s9XddMKA');

class SavedCards extends Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  addCard = token =>
    this.setState({ loadingUpdatedCards: true }, async () => {
      try {
        await apolloClient.mutate({
          mutation: addSavedCard,
          variables: { token }
        });
      } catch (e) {
        console.log(e);
      }

      this.setState({ resetCards: true }, () =>
        setTimeout(
          () =>
            this.setState({
              loadingUpdatedCards: false,
              showAddCard: false,
              resetCards: false
            }),
          250
        )
      );
    });

  removeCard = token =>
    this.setState({ loadingUpdatedCards: true }, async () => {
      try {
        await apolloClient.mutate({
          mutation: removeSavedCard,
          variables: { token }
        });
      } catch (e) {
        console.log(e);
      }

      this.setState({ resetCards: true }, () =>
        setTimeout(
          () =>
            this.setState({
              loadingUpdatedCards: false,
              showAddCard: false,
              resetCards: false
            }),
          250
        )
      );
    });

  addCardSpecific = () => {
    const { requester } = this.props;

    const pages = (requester && requester.activeGroup.pages) || [];

    const activePage = pages[0];

    const activePage_Prepped = activePage
      ? {
          ...activePage,
          settings:
            (activePage.settings && JSON.parse(activePage.settings)) || {}
        }
      : {};

    const { theme = 'light' } = activePage_Prepped.settings || {};

    return (
      <div className="add-card-specific">
        <div className="window-separation">
          <StripeProvider apiKey="pk_live_0uqRmD0g012sAQC3OmP20w1n00s9XddMKA">
            <Elements stripe={stripePromise}>
              <StripeForm
                whiteColor={theme !== 'light' ? true : false}
                onRef={stripeRef => (this.stripeRef = stripeRef)}
                onError={e =>
                  updateGeneralState({
                    error: e,
                    loadingPurchaseInit: false
                  })
                }
                onToken={stripeToken => {
                  if (stripeToken) {
                    this.addCard(stripeToken.id);
                  }
                }}
                showCardInput={true}
              />
            </Elements>
          </StripeProvider>
        </div>
        <button
          className="add-card-to-top-right"
          onClick={() =>
            this.setState({ loadingUpdatedCards: true }, () =>
              this.stripeRef._submit()
            )
          }
        >
          Save Card
        </button>
      </div>
    );
  };

  render() {
    const { resetCards, showAddCard, loadingUpdatedCards } = this.state;

    return (
      <div className="saved-cards-outer-container">
        {loadingUpdatedCards && <div className="window-separation">...</div>}
        <div style={{ display: loadingUpdatedCards ? 'none' : 'block' }}>
          {!resetCards &&
            (showAddCard ? (
              this.addCardSpecific()
            ) : (
              <Query query={getSavedCards} fetchPolicy="network-only">
                {({ loading, data = {} }) => {
                  if (loading && !data.getSavedCards) {
                    return (
                      <div className="window-separation">
                        <p className="no-card-notice">Loading Cards</p>
                      </div>
                    );
                  }

                  return (data.getSavedCards || []).length > 0 ? (
                    <div className="cards-exist">
                      <div className="window-separation">
                        <div className="immediate-card-iterate">
                          {(data.getSavedCards || []).map((card, index) => (
                            <div
                              key={`saved-card-${index}`}
                              className={`complete-card-choice-button`}
                            >
                              <div className="text-to-left">
                                {card.brand}
                                <div>{card.last4}</div>
                              </div>
                              <button
                                onClick={() => this.removeCard(card.id)}
                                className="delete-to-right"
                              >
                                <svg
                                  width="18"
                                  height="20"
                                  viewBox="0 0 18 20"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    d="M12.5405 18.9999H5.45047C4.27047 18.9899 3.29047 18.0899 3.20047 16.9199L2.23047 4.36987H15.7305L14.7605 16.9099V16.8999C14.6605 18.0699 13.6905 18.9699 12.5105 18.9699L12.5405 18.9999Z"
                                    stroke="#ff0000"
                                    strokeWidth="1.5"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                  ></path>
                                  <path
                                    d="M17 4.37988H1"
                                    stroke="#ff0000"
                                    strokeWidth="1.5"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                  ></path>
                                  <path
                                    d="M6.18004 0.99998H11.805H11.795C12.415 0.98998 12.915 1.49998 12.915 2.11998V4.36998H5.04004V2.11998V2.10998C5.04004 1.47998 5.54004 0.97998 6.16004 0.97998L6.18004 0.99998Z"
                                    stroke="#ff0000"
                                    strokeWidth="1.5"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                  ></path>
                                </svg>
                              </button>
                            </div>
                          ))}
                        </div>
                      </div>
                      <button
                        className="add-card-to-top-right"
                        onClick={() => this.setState({ showAddCard: true })}
                      >
                        Add Card
                      </button>
                    </div>
                  ) : (
                    this.addCardSpecific()
                  );
                }}
              </Query>
            ))}
        </div>
      </div>
    );
  }
}

SavedCards.propTypes = {
  profile: PropTypes.object.isRequired,
  updateWebsiteState: PropTypes.func.isRequired
};

const mapDispatchToProps = dispatch => ({
  updateGeneralState: variables => dispatch(updateGeneralState(variables))
});

export default connect(null, mapDispatchToProps)(SavedCards);
