import React, { memo, useContext, useEffect, useMemo } from 'react';
import { useQuery } from 'react-apollo';
import { useIntercom } from 'react-use-intercom';
import ProfileHeaderButton from './ProfileButton';
import { ProfileContext } from '../../../../../context/profile.context';
import { CurrentUserQuery_UserInfo } from '../../../../../apollo.queries';

const ProfileHeaderButtonFeed = (props = {}) => {
    const profile = useContext(ProfileContext)

    const { isActive, updateIntercom } = props

    const { update } = updateIntercom ? useIntercom() : {}

    const result = useQuery(CurrentUserQuery_UserInfo, { fetchPolicy: "network-only" })
    const cachedResult = useQuery(CurrentUserQuery_UserInfo, { fetchPolicy: "cache-only" })

    const { user, loading } = useMemo(() => ({ user: (result.data || {}).user || (cachedResult.data || {}).user, loading: result.loading }), [result.data, cachedResult.data, result.loading])

    useEffect(() => {
        if(user){
            const { firstName, lastName, email, phone } = user

            if(updateIntercom){
                update({
                    name: firstName || lastName ? `${firstName} ${lastName}` : `No name`,
                    email,
                    phone
                })
            }
        }
    }, [user, updateIntercom]);

    if(!user && loading) return <>...</>;

    return <ProfileHeaderButton key={`profile-header-button-${user.id}`} isActive={isActive} profile={profile} user={user} />
};

export default memo(ProfileHeaderButtonFeed);
