import React, { memo, useState } from 'react';
import CreateTag from '../CreateTag';

const CreateTagMapper = ({ assignSpecific, origin, refetch }) => {
  const [createActive, updateCreateActive] = useState();


  return (
    <div className="create-tag-outer-container">
      {!assignSpecific &&
        (createActive ? (
          <CreateTag
            origin={origin}
            refetch={refetch}
            onClose={() => updateCreateActive(false)}
          />
        ) : (
          <button
            onClick={() => updateCreateActive(true)}
            className={`create-specific-gallery-tag create-tag-specific`}
          >
            <svg
              width="12"
              height="12"
              viewBox="0 0 12 12"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M9.99984 11.3334H1.99984C1.25984 11.3334 0.666504 10.7334 0.666504 10.0001V2.00008C0.666504 1.26008 1.25984 0.666748 1.99984 0.666748H9.99984C10.7332 0.666748 11.3332 1.26008 11.3332 2.00008V10.0001C11.3332 10.7334 10.7332 11.3334 9.99984 11.3334Z"
                stroke="#666666"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M6 3.3335V8.66683"
                stroke="#666666"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M8.66634 6H3.33301"
                stroke="#666666"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
            Create tag
          </button>
        ))}
    </div>
  );
};

export default memo(CreateTagMapper);
