import React, { memo, useMemo, useCallback, useState, useEffect, useRef } from 'react';
import { isMobile } from 'react-device-detect';
import VideoCustomControls from './VideoCustomControls';
import LogoLoader from '../LogoLoader';

const CustomVideoRenderChild = props => {
  const {
    requester,
    updateLessonState,
    builderSpecific,
    videoUrl,
    thumbUrl,
    onTimeInit = () => null,
    mediaFit,
    blockSettings,
    onFinish = () => null,
    originalUrl,
    videoUrlOverride,
    onLoadedData,
    hideExpand,
    fullScreenActive,
    updateFullScreenActive
  } = props;
  
  const videoElement = useRef();

  const [isMuted, updateIsMuted] = useState(blockSettings.mute);
  const [videoPaused, updateVideoPaused] = useState(true);
  const [currentTime, updateCurrentTime] = useState(0);
  const [currentDuration, updateCurrentDuration] = useState(0);
  const [stillLoading, updateStillLoading] = useState(false);
  const [activateOverlay, updateActivateOverlay] = useState();
  const [localVideoOverride, updateLocalVideoOverride] = useState();

  useEffect(() => {
    updateVideoPaused(true);
    updateLocalVideoOverride(false);

    videoElement.current && videoElement.current.pause();
  }, [videoUrl, videoUrlOverride]);


  const onMobileClick = useCallback(() => {
    if(videoElement.current && !videoPaused && !activateOverlay){
      videoElement.current.pause();
      updateLessonState({ videoPlaying: false });
      updateActivateOverlay(true);
    };
  }, [videoPaused]);

  const onDesktopClick = useCallback(() => {
    if(videoElement.current && !videoPaused){
      videoElement.current.pause();
      updateLessonState({ videoPlaying: false });
    };
  }, [videoPaused]);

  const dynamicProps = useMemo(() => (
    isMobile
      ? { onClick: onMobileClick }
      : {
          onClick: onDesktopClick,
          onMouseEnter: () => updateActivateOverlay(true),
          onMouseLeave: () => updateActivateOverlay(false)
        }
  ), [videoPaused]);

  const containerClassName = useMemo(() => `custom-video-component-container ${(activateOverlay || videoPaused) && 'overlay-is-active'}`, [activateOverlay || videoPaused])

  const onError = useCallback(e => {
    if(originalUrl){
      if(
        !localVideoOverride &&
        (e.target.src.toLowerCase().indexOf('/video-desktop') > -1 || e.target.src.toLowerCase().indexOf('/processed-voiceover') > -1)
      ){
        updateLocalVideoOverride(
          originalUrl.indexOf('processed-voiceover') > -1
            ? originalUrl.replace('/processed-voiceover', '').replace('.mp4', '.webm')
            : originalUrl
        );

        return;
      }
    };
  }, [originalUrl, localVideoOverride]);

  const mappedSrc = useMemo(() => localVideoOverride || videoUrlOverride || videoUrl, [localVideoOverride || videoUrlOverride || videoUrl]);

  const bgImageRender = useMemo(() => (
    <div style={{ backgroundImage: `url(${thumbUrl})` }} className="blurred-image-bg"></div>
  ), [thumbUrl]);

  const isLoop = useMemo(() => blockSettings.loop ? true : false, [blockSettings.loop]);

  const onLoadedDataInit = useCallback(() => {
    if(videoElement){
      updateStillLoading(true);

      if (videoElement.current) {
        if(currentTime && isFinite(currentTime)){
          videoElement.current.currentTime = currentTime;
        };

        updateCurrentDuration(videoElement.current.duration);

        onTimeInit({
          slideTimerTotal: videoElement.current.duration,
          slideTimerCount: videoElement.current.currentTime
        });
      };

      if(!builderSpecific && blockSettings.autoPlay && !isMobile){
        videoElement.current.muted = true;
        updateIsMuted(true);
        
        videoElement.current.play();
        videoElement.current.pause();
        videoElement.current.play();

        updateLessonState({ videoPlaying: true });

        if(!blockSettings.mute){
          videoElement.current.muted = false;
          updateIsMuted(false);
        };
      };

      updateStillLoading(false);

      if (onLoadedData) onLoadedData();
    }
  }, [currentTime]);

  const onPlayInit = useCallback(() => {
    if(isMobile) updateActivateOverlay(false);

    updateVideoPaused(false);
    updateStillLoading(false);

    onTimeInit({
      slideTimerTotal: videoElement.current.duration,
      slideTimerCount: videoElement.current.currentTime
    });
  }, []);

  const onPauseInit = useCallback(() => updateVideoPaused(true), []);

  const videoStyle = useMemo(() => ({ objectFit: mediaFit }), [mediaFit]);
  
  const onTimeUpdateInit = useCallback(() => {    
    updateCurrentTime(videoElement.current.currentTime);
    onTimeInit({ slideTimerTotal: videoElement.current.duration, slideTimerCount: videoElement.current.currentTime });
  }, [])

  /** Controls Based **/

  const onPlay_CONTROLS = useCallback(() => {
    videoElement.current && videoElement.current.play();
    updateVideoPaused(false);
    updateStillLoading(false);
  }, []);

  const onPause_CONTROLS = useCallback(() => videoElement.current && videoElement.current.pause(), []);

  const onMute_CONTROLS = useCallback(() => updateIsMuted(!isMuted), []);

  const onFullscreen_CONTROLS = useCallback(() => {
    videoElement.current && videoElement.current.pause();
    
    if (fullScreenActive) {
      if (document.mozCancelFullScreen) {
        document.mozCancelFullScreen();
      } else if (document.webkitExitFullscreen) {
        document.webkitExitFullscreen();
      } else if (document.msExitFullscreen) {
        document.msExitFullscreen();
      };
    } else {
      const elem = document.documentElement;
      if (elem.mozRequestFullScreen) {
        elem.mozRequestFullScreen();
      } else if (elem.webkitRequestFullscreen) {
        elem.webkitRequestFullscreen();
      } else if (elem.msRequestFullscreen) {
        elem.msRequestFullscreen();
      };
    };

    updateFullScreenActive(!fullScreenActive);
    updateVideoPaused(true);                   
  }, []);

  const onProgress_CONTROLS = useCallback(newValue => {
    if(videoElement.current && isFinite(newValue)){
      updateCurrentTime(newValue);
      videoElement.current.currentTime = newValue;
    };
  }, [videoElement.current]);

  const currentProgress = useMemo(() => (
    videoElement
      ? currentTime / ((videoElement.current || {}).duration || 0)
      : 0
  ), [currentTime]);

  const isMuted_CONTROLS = useMemo(() => builderSpecific ? blockSettings.mute : isMuted, [builderSpecific, isMuted, blockSettings.mute]);

  return (
    <div {...dynamicProps} className={containerClassName}>
      {bgImageRender}
      <video
        ref={videoElement}
        muted={isMuted}
        src={mappedSrc}
        loop={isLoop}
        onError={onError}
        controls={false}
        onLoadedData={onLoadedDataInit}
        onEnded={onFinish}
        onPlay={onPlayInit}
        onPause={onPauseInit}
        style={videoStyle}            
        onTimeUpdate={onTimeUpdateInit}
        playsInline
        preload="auto"
      />
      {blockSettings.controls && (
        <VideoCustomControls
          onPlay={onPlay_CONTROLS}
          onPause={onPause_CONTROLS}
          onMute={onMute_CONTROLS}
          onFullScreen={onFullscreen_CONTROLS}
          onProgress={onProgress_CONTROLS}
          currentDuration={currentDuration}
          currentProgress={currentProgress}
          builderSpecific={builderSpecific}
          currentTime={currentTime}
          videoPaused={videoPaused}
          thumbUrl={thumbUrl}
          mediaFit={mediaFit}
          isMuted={isMuted_CONTROLS}
          hideExpand={hideExpand}
        />
      )}

      {stillLoading && (
        <LogoLoader
          breathe={true}
          tenant={requester.activeGroup.tenant}
        />
      )}
    </div>
  );
};

export default memo(CustomVideoRenderChild);