import React, { memo } from 'react';

const ActionCenter = props => {
  const { updateGeneralState, goBack } = props;
  
  return <>
    <button
      className="add-media-specific"
      onClick={() => updateGeneralState({ addMediaPop: true })}
    >
      <svg
        width="18"
        height="18"
        viewBox="0 0 18 18"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M15 17H3C1.89 17 1 16.1 1 15V3C1 1.89 1.89 1 3 1H15C16.1 1 17 1.89 17 3V15C17 16.1 16.1 17 15 17Z"
          stroke="white"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M9 5V13"
          stroke="white"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M13 9H5"
          stroke="white"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
      Add Media
    </button>

    <button className="x-to-top-right" onClick={goBack}>
      <svg
        width="8"
        height="7"
        viewBox="0 0 8 7"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M1.33337 0.833252L6.66671 6.16659"
          stroke="#666666"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M6.66671 0.833252L1.33337 6.16659"
          stroke="#666666"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </button>
  </>;
};

export default memo(ActionCenter);
