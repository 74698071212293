import ACTIONS from '../constants/website/actions';

const initialState = {};

export default (state = initialState, action) => {
  switch (action.type) {
    case ACTIONS.RESET_STATE:
      return {
        ...state
      };

    case ACTIONS.UPDATE_STATE:
      return {
        ...state,
        ...action.payload
      };

    default:
      return state;
  }
};
