import React, { PureComponent } from 'react';
import GeneralImage from '../../../../../../../Shared/GeneralImage';
import CustomVideoRender from '../../../../../../../Shared/CustomVideoRender';
import { fileTypeList } from '../../../../../../../../actions/general.actions';
import { getBlockVideoSources } from '../../../../../../../../actions/lesson.actions';

class BlockFormDescription extends PureComponent {
  constructor(props) {
    super(props);
  }

  render() {
    const { block } = this.props;

    const { media: { url = ' '} } = block;
    
    const base_split_url = url.split('.');
    const file_extension = base_split_url[base_split_url.length - 1].toLowerCase();
    
    const url_to_check = url.toLowerCase();
    const split_url = url_to_check.split(
        url_to_check.indexOf('processed-voiceover') > -1
        ? '/processed-voiceover/undefined/'
        : url_to_check.indexOf('dgpv43ylujmyh.cloudfront.net') >
            -1
        ? '/undefined/'
        : '/athletic-outlook/undefined/'
    );
    const base_key = `${split_url[split_url.length - 1]}`;

    const file_type = fileTypeList[file_extension] || 'other',
          image_src = `undefined/${base_key}`;


    return <>
      <div className={`general-form-block-render-container media-type`}>
        <div className='immediate-media-preview-container'>
          {file_type === 'image' ? (
            <GeneralImage
              imageResolution={'700x'}
              originalKey={image_src}
            />
          ) : file_type === 'video' && (
            <CustomVideoRender
              blockSettings={{ controls: true }}
              {...getBlockVideoSources(url)}
            />
          )}
        </div>
      </div>
    </>;
  }
};

export default BlockFormDescription;
