import React, { Component } from 'react';
import { connect } from 'react-redux';
import ReactDOM from 'react-dom';
import TextInput from '../Shared/TextInput';
import GeneralTags_AutoComplete from './GeneralTags_AutoComplete';
import { upsertTagInit } from '../../actions/general.actions';
import apolloClient from '../../apollo.client';
import { Categories } from '../../apollo.queries';

class CreateTag extends Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  shouldComponentUpdate(prevProps, prevState){
    const { newTag } = this.state;
    const { generalState } = this.props;
    const { upsertTagInit } =  generalState;

    return upsertTagInit !== prevProps.generalState.upsertingTag || newTag !== prevState.newTag;
  }

  createTag = async parentTag => {
    const { origin = 'media', onClose, refetch, upsertTagInit } = this.props;
    const { newTag = '' } = this.state;

    if(!newTag || newTag.length === 0){
      alert('Add a tag');
      return;
    };

    if(parentTag && parentTag.label.toLowerCase() === newTag.toLowerCase()){
      alert('The parent and child tag are the same.');
      return;
    };

    const currentCategories = { Categories: [] } || apolloClient.readQuery({
      query: Categories
    });

    if(currentCategories.Categories.find(categoryLocal => categoryLocal.label.toLowerCase() === newTag)){
      alert('This tag already exists.');
      return;
    } else {
      await upsertTagInit({
        origin,
        newTag: {
          label: newTag,
          ...parentTag && parentTag.label.length > 0 && { parentLabel: parentTag.label }
        }
      });

      onClose();

      refetch();
    };
  }

  render() {
    const { newTag = '' } = this.state;
    const { onClose, origin = 'media', generalState } = this.props;
    const { upsertingTag } = generalState;

    return <>
      {
        ReactDOM.createPortal(
          <div className="outer-create-tag-pop">
            <div className="create-tag-pop">
              <div className="header-of-pop">
                <h3>Create tag</h3>
                <button onClick={onClose}>
                  <svg width="8" height="7" viewBox="0 0 8 7" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M1.33337 0.833252L6.66671 6.16659" stroke="#666666" strokeLinecap="round" strokeLinejoin="round"/>
                    <path d="M6.66671 0.833252L1.33337 6.16659" stroke="#666666" strokeLinecap="round" strokeLinejoin="round"/>
                  </svg>
                </button>
              </div>

              <div className="body-inputs">
                <TextInput 
                  placeholder="Tag Name"
                  value={newTag}
                  onChange={newTag => this.setState({ newTag })} 
                />

                <GeneralTags_AutoComplete 
                  ref={ref => this.autoCompleteRef = ref}
                  origin={origin}
                  single={true} 
                  placeholder={'Nest Tag Under'} 
                  cachePolicy="cache-first"
                />
              </div>
              
              <div className="footer-button">
                <button onClick={() => this.createTag(this.autoCompleteRef.getRawValue())}>Create</button>
              </div>

              {
                upsertingTag && (
                  <div className="general-tag-loading-specific">
                    <div className="appLoading">
                      <svg className="simple-line-loader-for-app-loading css-primary-hex-stroke" viewBox="0 0 50 50">
                        <circle className="path css-primary-hex-stroke" cx="25" cy="25" r="20" fill="none" strokeWidth="5"></circle>
                      </svg>
                    </div>
                  </div>
                )
              }
            </div>
          </div>,
          document.getElementById('outsideModalPortal-z1')
        )
      }      
    </>;
  }
};

const mapStateToProps = state => ({
  generalState: state.generalState
});

const mapDispatchToProps = dispatch => ({
  upsertTagInit: variables => dispatch(upsertTagInit(variables))
});

export default connect(mapStateToProps, mapDispatchToProps)(CreateTag);
