const mainIcon = <svg width="18" height="14" viewBox="0 0 18 14" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M6.95 1H17.12" stroke="#E7A720" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/><path d="M12 13V1" stroke="#E7A720" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/><path d="M4 13V6" stroke="#E7A720" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/><path d="M1 6H7" stroke="#E7A720" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/></svg>;
const headingIcon = <svg width="9" height="10" viewBox="0 0 9 10" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M7.83333 1V9" stroke="#F9B016" strokeLinecap="round" strokeLinejoin="round"/><path d="M1.16666 3V1" stroke="#F9B016" strokeLinecap="round" strokeLinejoin="round"/><path d="M5.83333 5H1.16666V9" stroke="#F9B016" strokeLinecap="round" strokeLinejoin="round"/></svg>;
const plaintextIcon = <svg width="13" height="12" viewBox="0 0 13 12" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M1.16666 1H11.8333" stroke="#F9B016" strokeLinecap="round" strokeLinejoin="round"/><path d="M1.16666 4.33325H9.16666" stroke="#F9B016" strokeLinecap="round" strokeLinejoin="round"/><path d="M1.16666 7.66675H11.8333" stroke="#F9B016" strokeLinecap="round" strokeLinejoin="round"/><path d="M1.16666 11H6.5" stroke="#F9B016" strokeLinecap="round" strokeLinejoin="round"/></svg>;
const mediaIcon = <svg style={{ marginLeft: "1px" }} width="14" height="16" viewBox="0 0 14 16" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M12.2711 6.74453L3.25422 1.22093C2.268 0.615859 1 1.32487 1 2.48304L1 13.5154C1 14.6736 2.268 15.3863 3.26163 14.7775L12.2881 9.25168C13.2373 8.67259 13.2373 7.29169 12.2881 6.7126L12.2711 6.74453Z" stroke="#F9B016" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path></svg>;
const fileIcon = <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M10.5067 7.01264L11.66 5.85931V5.85264C12.7867 4.75264 12.8133 2.95264 11.72 1.82598C10.62 0.692642 8.82 0.665975 7.69333 1.75931C7.66667 1.77931 7.64667 1.79931 7.62667 1.81931L5.16 4.28598V4.27931C4.04667 5.38598 4.04667 7.18598 5.15333 8.29931L5.14 8.30598C5.36667 8.52598 5.63333 8.71264 5.92667 8.84598" stroke="#F9B016" strokeLinecap="round" strokeLinejoin="round"/><path d="M7.03334 5.12874L7.02667 5.12207C7.31334 5.2554 7.58 5.4354 7.81334 5.66207C8.92 6.76874 8.92 8.56874 7.81334 9.68207C7.80667 9.68207 7.80667 9.68207 7.80667 9.68207L5.34 12.1487V12.1421C4.20667 13.2354 2.40667 13.2087 1.31333 12.0754C0.240002 10.9687 0.240002 9.20874 1.30667 8.10874L2.46 6.9554" stroke="#F9B016" strokeLinecap="round" strokeLinejoin="round"/></svg>;
const checkboxIcon = <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M7.5 12.9999H2.83333L2.82667 12.9992C2.08667 12.9926 1.49333 12.3992 1.49333 11.6592V2.32589C1.48667 1.58589 2.08667 0.985895 2.82 0.985895H12.1533H12.1467C12.88 0.979228 13.48 1.57923 13.48 2.31256V7.94589" stroke="#F9B016" strokeLinecap="round" strokeLinejoin="round"/><path d="M7.5 9.6665H4.16666" stroke="#F9B016" strokeLinecap="round" strokeLinejoin="round"/><path d="M10.1667 11.6335L11.1667 12.6335L12.8333 10.9668" stroke="#F9B016" strokeLinecap="round" strokeLinejoin="round"/><path d="M10.8333 7H4.16666" stroke="#F9B016" strokeLinecap="round" strokeLinejoin="round"/><path d="M10.8333 4.33301H4.16666" stroke="#F9B016" strokeLinecap="round" strokeLinejoin="round"/></svg>;

const BlockGeneralTypes = {
  sectionTitle: 'General',
  backgroundColor: "#FEF9C3",
  icon: mainIcon,
  types: [
    {
      backgroundColor: "#FEF9C3",
      icon: mediaIcon,
      title: 'Media',
      type: 'media_default'
    },
    {
      backgroundColor: "#FEF9C3",
      icon: headingIcon,
      title: 'Heading',
      type: 'heading_default'
    },
    {
      backgroundColor: "#FEF9C3",
      icon: plaintextIcon,
      title: 'Plain Text',
      type: 'description_default'
    },    
    {
      backgroundColor: "#FEF9C3",
      icon: fileIcon,
      title: 'Upload File',
      type: 'file_upload_default'
    },
    {
      backgroundColor: "#FEF9C3",
      icon: checkboxIcon,
      title: 'Accept Terms',
      label: 'Description of terms.',
      type: 'terms_default',
      choices: [ 
        { id: `new-block-${new Date().getTime()}`, label: "Accept terms" }
      ]
    }
  ]
};

export default BlockGeneralTypes;