import React, { memo, useCallback, useEffect, useMemo, useState } from 'react';
import ReactDOM from 'react-dom';
import InfiniteScroll from 'react-infinite-scroller';
import stringSimilarity from 'string-similarity';
import SingleMedia from '../../../Public/Media/SingleMedia';
import GalleryMainRenderMapper from './GalleryMainRenderMapper';
import {  isVideoFull, filterAssets_MULTIPLE_ORIGIN, untaggedFilter_MULTIPLE_ORIGIN } from '../../../../actions/general.actions';
import { getLogoKey } from '../../../../actions/user.actions';

const S3_Prefix = 'https://dgpv43ylujmyh.cloudfront.net/';

const GalleryMainRenderParser = props => {
  const {
    profile,
    requester,
    onRemove,
    onSelect,
    loadingParent,
    local_changingViewMedia,
    update_local_changingViewMedia,
    itemsSelected = [],
    simplified,
    imagesOnly,
    videosOnly,
    generalState,
    updateGeneralState,
    profileId = null,
    parentData = [],
    additionalItems = [],
    markAsDone,
    onRadioInit,
    onEndReached,
    hasMore,
    hideNewFiles,
    lessonsExist,
    onFileDropChange,
    loadingAdditionalItems
  } = props;

  const {
    untaggedActive,
    activeSidebarCategories = [],
    updatingMedia,
    deletingMedia,
    changeViewMedia = -1,
    filesUploading = {},
    searchInput,
    activeUserBasedTags = {}
  } = generalState;

  const mediaIterate = useMemo(
    () => (
      lessonsExist
        ? [
            ...parentData,
            ...additionalItems
          ].sort((a, b) => a.timeStamp < b.timeStamp)
        : [
          ...parentData,
          ...additionalItems
          ]
    ), [lessonsExist, parentData, additionalItems]
  );

  const tenantToUse = (((requester || {}).activeGroup || {}).tenant || {});

  const [showWelcomeVideo, updateShowWelcomeVideo] = useState();

  const {
    fallbackImage1,
    fallbackImage2
  } = useMemo(
    () => {
      let splitLogo = tenantToUse.logo && tenantToUse.logo.split('.');
      if (splitLogo) {
        splitLogo.pop();
        splitLogo = splitLogo.join('.');
      };

      return {
        fallbackImage1: splitLogo
          ? `${S3_Prefix}optimized-images/350x/${(
              getLogoKey(splitLogo, tenantToUse.id) || ''
            ).replace('undefined/', '')}.jpg`
          : '/images/android-chrome-512x512.png',
        fallbackImage2: tenantToUse.logo
          ? `${S3_Prefix}${getLogoKey(tenantToUse.logo, tenantToUse.id)}`
          : '/images/android-chrome-512x512.png'
      };      
    }, [tenantToUse.logo, tenantToUse.id]
  );

  const findMediaTag = useCallback(
    mediaCategory => activeSidebarCategories.find(
      category => category.id === mediaCategory.tag.id
    ), [activeSidebarCategories]
  );

  const mediaTagFilter = useCallback(media => media.tags && media.tags.find(findMediaTag), [findMediaTag]);

  const filterMedia = useCallback(
    () => Object.values(filesUploading).filter(mediaTagFilter), 
    [mediaTagFilter, filesUploading]
  );

  const filteredFilesUploading = useMemo(
    () => (
      activeSidebarCategories.length > 0
        ? filterMedia()
        : Object.values(filesUploading)
    ),
    [filterMedia, Object.values(filesUploading)]
  );

  const getStateTagsToFilter = useCallback(
    (media) => (
      (generalState[`${media.id}_tagsSelected`] || []).filter(
        ({ tag }) =>
          !(media.tags || []).find(
            queryBased =>
              queryBased.tag.label.toLowerCase() ===
              tag.label.toLowerCase()
          )
      )
    ), [generalState]
  );

  const parentMediaFilter_item = useCallback(
    (tagId, mediaId) => (generalState[`${mediaId}_tagsSelected_removed`] || []).indexOf(tagId) === -1, 
    [generalState]
  );

  const parentMediaFilter = useCallback(
    (mediaIterate, mediaId) => (
      mediaIterate.filter(({ tag }) => parentMediaFilter_item(tag.id, mediaId))
    ), [parentMediaFilter_item]
  );

  const getMediaTagFilter = useCallback(
    media => parentMediaFilter([
      ...(media.tags || []),
      ...getStateTagsToFilter(media)
    ], media.id), [getStateTagsToFilter, parentMediaFilter]
  );

    const parsedMedia = useMemo(
      () => (
        mediaIterate.map((media, index) => ({
          ...media,
          index,
          tags: getMediaTagFilter(media)
        }))
      ), [getMediaTagFilter, mediaIterate]
    );
    
    const untaggedFilter_init_MULTIPLE_ORIGIN = useCallback(untaggedFilter_MULTIPLE_ORIGIN, []);

    const untaggedFiltered_result = useMemo(
      () => (
        untaggedActive
          ? untaggedFilter_init_MULTIPLE_ORIGIN({ assetsToFilter: parsedMedia, origins: ['media', ...lessonsExist ? ['lessons'] : []] })
          : parsedMedia
      ), [untaggedActive, lessonsExist, parsedMedia]
    );

    const userBasedTagsExist = useMemo(() => Object.keys(activeUserBasedTags).length > 0, [Object.keys(activeUserBasedTags).length]);
    
    const filterAssets_init_MULTIPLE_ORIGIN = useCallback(filterAssets_MULTIPLE_ORIGIN, []);

    const filteredFiles = useMemo(
      () => (
        activeSidebarCategories.length > 0 || userBasedTagsExist
          ? filterAssets_init_MULTIPLE_ORIGIN({
              assetsToFilter: untaggedFiltered_result,
              activeSidebarCategories,
              generalState,
              activeUserBasedTags,
              origins: ['media', ...lessonsExist ? ['lessons'] : []]
            })
          : untaggedFiltered_result
      ), [lessonsExist, untaggedFiltered_result, activeSidebarCategories, activeUserBasedTags, userBasedTagsExist, generalState]
    );

    const filterImageOnly_TEMP = useMemo(
      () => (
        videosOnly
          ? filteredFilesUploading.filter(media => media.isTempVideo)
          : imagesOnly
            ? filteredFilesUploading.filter(media => !media.isTempVideo)
            : filteredFilesUploading
      ), [imagesOnly, videosOnly, filteredFilesUploading]
    );

    const mixedFilter = useCallback(
      () => filterImageOnly_TEMP.filter(
        tempMedia =>
          !filteredFiles.find(
            media =>
              generalState[tempMedia.id] &&
              (generalState[tempMedia.id].serverResponse || {}).id === media.id
          ) && (!userBasedTagsExist || activeUserBasedTags[profile.id])
      ), [filterImageOnly_TEMP, userBasedTagsExist, generalState, activeUserBasedTags, filteredFiles]
    );

    const filteredFilesUploading_Finalized = useMemo(
      () => (
        filterImageOnly_TEMP.length > 0 && filteredFiles.length > 0
          ? mixedFilter()
          : filterImageOnly_TEMP
      ), [filterImageOnly_TEMP, filteredFiles, mixedFilter]
    );

    const isVideoFullInit = useCallback(isVideoFull, []);
    const imageOnlyFilter = useCallback(media => !isVideoFullInit(media.url), []);
    const videoOnlyFilter = useCallback(media => isVideoFullInit(media.url), []);

    const filterImageOnly_QUERIED_pre_search = useMemo(
      () => (
        videosOnly
          ? filteredFiles.filter(videoOnlyFilter)
          : imagesOnly
            ? filteredFiles.filter(imageOnlyFilter)
            : filteredFiles
      ), [imagesOnly, videosOnly, filteredFiles]
    );

    const prepForSearch = useCallback((l) => l.toLowerCase().trim(), []);

    const startsWithCheck = useCallback(l => prepForSearch(l).startsWith(prepForSearch(searchInput)), [searchInput]);
    const compareTwoWithCheck = useCallback(l => stringSimilarity.compareTwoStrings(prepForSearch(l), prepForSearch(searchInput)) > 0.75, [searchInput]);
    const searchItemFilter = useCallback(
      media => startsWithCheck(media.label || 'Untitled') || compareTwoWithCheck(media.label || 'Untitled'), 
      [compareTwoWithCheck, startsWithCheck]
    );

    const searchBasedFilter = useCallback(
      () => (
        filterImageOnly_QUERIED_pre_search.filter(searchItemFilter)
      ), [filterImageOnly_QUERIED_pre_search, searchInput]
    );

    const filterImageOnly_QUERIED = useMemo(
      () => (
        searchInput && searchInput.length > 0
          ? searchBasedFilter()
          : filterImageOnly_QUERIED_pre_search
      ), [searchBasedFilter]
    );

    useEffect(
      () => {
        if(!loadingParent && !loadingAdditionalItems){
          if(filterImageOnly_QUERIED.length < 24){
            onEndReached();
          };
        };
      }, [loadingParent, loadingAdditionalItems, parentData.length, filterImageOnly_QUERIED.length]
    );

    useEffect(
      () => {
        if(changeViewMedia > -1 && !local_changingViewMedia){
          update_local_changingViewMedia(true);
          if (parsedMedia[changeViewMedia]) {
            updateGeneralState({
              viewMediaPop: parsedMedia[changeViewMedia],
              changeViewMedia: -1
            });
            update_local_changingViewMedia(false);
          } else if (!hasMore) {
            updateGeneralState({
              changeViewMedia: -1,
              viewMediaPop: { ...parsedMedia[0], index: 0 }
            });
            update_local_changingViewMedia(false);
          } else {
            updateGeneralState({ viewMediaPop: { loading: true } });
            onEndReached();
          };
        };
      }, [hasMore, parsedMedia, changeViewMedia, local_changingViewMedia]
    );

    const loader = useMemo(
      () => (
        updatingMedia || deletingMedia && <>
          <div
            key={`media-item-placeholder-1`}
            className={`complete-container-of-media-item preloader-specific`}
          ></div>
          <div
            key={`media-item-placeholder-2`}
            className={`complete-container-of-media-item preloader-specific`}
          ></div>
        </>
      ), [updatingMedia, deletingMedia]
    );

    const iterateLoader = useMemo(
      () => <>
        {
          loadingAdditionalItems && <>
            <div
              key={`media-item-placeholder-1`}
              className={`complete-container-of-media-item preloader-specific`}
            ></div>
            <div
              key={`media-item-placeholder-2`}
              className={`complete-container-of-media-item preloader-specific`}
            ></div>
          </>
        }        
      </>, [loadingAdditionalItems]
    );

    const fallbackFirstConditional = useMemo(
      () => (
        !loadingAdditionalItems && (
          userBasedTagsExist ||
          profileId ||
          untaggedActive ||
          activeSidebarCategories.length > 0 ||
          (searchInput && searchInput.length > 0)
        )
      ), [loadingAdditionalItems, userBasedTagsExist, profileId, untaggedActive, activeSidebarCategories.length > 0, searchInput && searchInput.length > 0]
    );

    const fallbackSecondConditional = useMemo(
      () => !loadingParent && (
        activeSidebarCategories.length === 0 && 
        !userBasedTagsExist &&
        !profileId && !untaggedActive && 
        (!searchInput || searchInput.length === 0)
      ), [loadingParent, activeSidebarCategories.length, userBasedTagsExist, profileId, untaggedActive, (!searchInput || searchInput.length === 0)]
    );
    
    const welcomeVideoRender = useMemo(
      () => (
        showWelcomeVideo &&
          ReactDOM.createPortal(
            <div className="outer-public-v2-container-container preview-specific single-lesson-specific">
              <SingleMedia
                requester={requester}
                profile={profile}
                independantMedia={{
                  id: 'independant-media-file-1',
                  url: '/images/welcome-video-media.mp4',
                  label: `Welcome`
                }}
                goBack={() => updateShowWelcomeVideo(false)}
              />
            </div>,
            document.getElementById('outsideModalPortal-z1')
          )
      ), [showWelcomeVideo]
    );

    return <>
      <section className="for-scroll-ref">
        <InfiniteScroll
          pageStart={0}
          initialLoad={false}
          loadMore={onEndReached}
          hasMore={hasMore}
          loader={iterateLoader}
          useWindow={false}
        >  
          <GalleryMainRenderMapper
            generalState={generalState}
            fallbackImage1={fallbackImage1}
            fallbackImage2={fallbackImage2}
            itemsSelected={itemsSelected}
            simplified={simplified}
            onSelect={onSelect}
            onRemove={onRemove}
            hideNewFiles={hideNewFiles}
            profile={profile}
            onFileDropChange={onFileDropChange}
            updateGeneralState={updateGeneralState}
            onRadioInit={onRadioInit}
            initWelcomeVideo={() => updateShowWelcomeVideo(true)}
            fallbackFirstConditional={fallbackFirstConditional}
            fallbackSecondConditional={fallbackSecondConditional}
            filteredFilesUploading_Finalized={filteredFilesUploading_Finalized}
            filterImageOnly_QUERIED={filterImageOnly_QUERIED}
          />
        </InfiniteScroll>
        {welcomeVideoRender}
      </section>
      {loader}
    </>;
};

export default memo(GalleryMainRenderParser);
