import React, { memo, useCallback, useMemo } from 'react';
import { FileIcon, defaultStyles } from 'react-file-icon';
import GeneralImage from '../../../Shared/GeneralImage';
import CustomVideoRender from '../../../Shared/CustomVideoRender';
import { getBlockVideoSources } from '../../../../actions/lesson.actions';
import { getLogoKey } from '../../../../actions/user.actions';

const MediaAsset = props => {
  const {
    requester,
    independantMedia,
    showSingleLesson = {}
  } = props;

  const mediaToUse = independantMedia || showSingleLesson.media;

  const { fallbackImage1, fallbackImage2 } = useMemo(() => {
    const tenantToUse = requester.activeGroup.tenant;

    let splitLogo = tenantToUse.logo && tenantToUse.logo.split('.');
    if (splitLogo) {
      splitLogo.pop();
      splitLogo = splitLogo.join('.');
    };

    return {
      fallbackImage1: splitLogo ? `${S3_Prefix}optimized-images/350x/${(getLogoKey(splitLogo, tenantToUse.id) || '').replace('undefined/', '')}.jpg` : '/images/android-chrome-512x512.png',
      fallbackImage2: tenantToUse.logo ? `${S3_Prefix}${getLogoKey(tenantToUse.logo, tenantToUse.id)}` : '/images/android-chrome-512x512.png'
    };
  }, [requester.activeGroup.tenant.id]);

  const onError = useCallback(e => {
    if (e.target.className.indexOf('image-doesnt-exist') > -1) {
      e.target.onerror = null;
      e.target.src = fallbackImage2;
    } else {
      e.target.className += 'fallback-logo image-doesnt-exist';
      e.target.src = fallbackImage1;
    };
  }, [mediaToUse.url]);

  

  if(mediaToUse.sms){
    return mediaToUse.label.includes('video') ? (
      <CustomVideoRender 
        blockSettings={{ controls: true }}
        {...additionalPropsToPass}
        videoUrlOverride={mediaToUse.url}
      /> 
    ) : (
      <GeneralImage overwriteUrl={mediaToUse.url} />
    );
  };

  const { thumb_url, image_src, file_type, file_extension } = useMemo(() => {
    const base_split_url = mediaToUse.url.split('.');
    const file_extension = base_split_url[base_split_url.length - 1].toLowerCase();
    const file_type = file_type_list[file_extension] || 'other';
  
    const base_s3_url = 'https://dgpv43ylujmyh.cloudfront.net';
    const url_to_check = mediaToUse.url.toLowerCase();
    const split_url = url_to_check.split(
      url_to_check.indexOf('processed-voiceover') > -1
        ? '/processed-voiceover/undefined/'
        : url_to_check.indexOf('dgpv43ylujmyh.cloudfront.net') > -1
        ? '/undefined/'
        : '/athletic-outlook/undefined/'
    );
    const base_key = `${split_url[split_url.length - 1]}`;
    const image_src = `undefined/${base_key}`;
    const s3_key = `undefined/${base_key.split('.')[0]}`;
    const thumb_url = `${base_s3_url}/thumb/${s3_key}.0000001.jpg`;

    return { thumb_url, image_src, file_type, file_extension };
  }, []);

  const additionalPropsToPass = useMemo(() => file_type === 'other' ? defaultStyles[file_extension] || {} : (independantMedia ? { videoUrlOverride: mediaToUse.url } : getBlockVideoSources(mediaToUse.url)) || {}, [file_type, file_extension, mediaToUse.url, independantMedia]);

  return (
    file_type === 'other' ? (
      <FileIcon
        extension={file_extension}
        {...additionalPropsToPass}
      />
    ) : file_type === 'image' ? (
      <GeneralImage imageResolution={'1200x'} originalKey={image_src} />
    ) : file_type === 'video' ? (
      <CustomVideoRender
        blockSettings={{ controls: true }}
        {...additionalPropsToPass}
      />
    ) : (
      <img
        src={thumb_url}
        onError={onError}
      />
    )
  );
};

const S3_Prefix = 'https://dgpv43ylujmyh.cloudfront.net/';

const file_type_list = {
  bmp: 'image',
  gif: 'image',
  ico: 'image',
  jpeg: 'image',
  jpg: 'image',
  png: 'image',
  svg: 'image',
  tif: 'image',
  tiff: 'image',
  webp: 'image',
  webm: 'video',
  mpg: 'video',
  mp2: 'video',
  mpeg: 'video',
  mpe: 'video',
  mpv: 'video',
  ogg: 'video',
  mp4: 'video',
  m4p: 'video',
  m4v: 'video',
  avi: 'video',
  wmv: 'video',
  mov: 'video',
  qt: 'video',
  flv: 'video',
  swf: 'video',
  avchd: 'video',
'3gp': 'video'
};

export default memo(MediaAsset);
