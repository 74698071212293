import React, { Component } from 'react';
import OriginGuestRoutes from '../routes/origin/guestRoutes';
import ExternalGuestRoutes from '../routes/external/guestRoutes';
import { tenantPrefixUrlDeterminant } from '../actions/user.actions';

const hostName = window.location.hostname.toLowerCase();
const originHost = hostName.indexOf('coachiq.io') > -1 || hostName.indexOf('localhost') > -1 || hostName.indexOf('127.0.0.1') > -1;

class NotLoggedInRouter extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loadingInit: true
    };
  }

  render() {
    const { requesterOrg } = this.props;

    return (
      <div
        className={`logged-in-section-wrapper-container not-logged-in-specific`}
      >
        <React.Fragment>
          {originHost
            ? <OriginGuestRoutes redirectPrefix={tenantPrefixUrlDeterminant(requesterOrg.activeGroup.tenant.code, requesterOrg.activeGroup.code )} />
            : <ExternalGuestRoutes />}
        </React.Fragment>
      </div>
    );
  }
}

export default NotLoggedInRouter;
