import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { FileIcon, defaultStyles } from 'react-file-icon';
import { updateFormState } from '../../../../../../../../actions/form.actions';

const defaultProp = e => {
  e.preventDefault();
  e.stopPropagation();

  return true;
};

class BlockFormUploadInput extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {};
  }

  render() {
    const { block, formState, updateFormState } = this.props;
    const { title, required = 1 } = block;
    const { formResponses = {}, reducedRequired = {} } = formState;
    const { url, type, label, name } = (formResponses[block.id] || {}).media || {};
    const { fileDragging } = this.state;

    let fileExtension;
    if(url){
      const urlToUse = url.toLowerCase();
      const base_split_url = urlToUse.split('.');
      
      fileExtension = base_split_url[base_split_url.length - 1].toLowerCase();
    } else if(type){
      const typeToUse = type.toLowerCase();
      const base_split_type = typeToUse.split('/');
      
      fileExtension = base_split_type[base_split_type.length - 1].toLowerCase();
    };

    return <>
      <div className={`general-form-block-render-container file-upload-type`}>
        <label className="question-name">{block.label || title || `Question Name`}{required ? '*' : ''}</label>

        {reducedRequired[block.id] ? <div className="invalid-currently-dignifier">Required*</div> : <></>}        

        <div
          onDragOver={e =>
            defaultProp(e) &&
            !fileDragging &&
            this.setState({ fileDragging: true })
          }
          onDragLeave={e =>
            defaultProp(e) &&
            fileDragging &&
            this.setState({ fileDragging: false })
          }
          onDragEnd={e =>
            defaultProp(e) &&
            fileDragging &&
            this.setState({ fileDragging: false })
          }
          className={`file-drop-area ${fileExtension && 'file-exists'} ${fileDragging && 'file-is-dragging'}`}
        >
            <span className="centered-icon">
              {
                fileExtension
                  ? <>
                      <FileIcon
                        extension={fileExtension}
                        {...defaultStyles[fileExtension]}
                      />
                      <span className="filename">{label || name || 'No file name...'}</span>
                    </>
                  : <svg
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M13.87 8.37021L8.38004 13.8502C7.55004 14.6702 7.55004 16.0102 8.38004 16.8402C9.20004 17.6602 10.54 17.6602 11.37 16.8402L18.6 9.60021C20.11 8.08021 20.11 5.62921 18.6 4.11021C17.08 2.59021 14.629 2.59021 13.11 4.11021L5.87004 11.3402C3.66604 13.5402 3.66604 17.1162 5.87004 19.3202C8.07004 21.5202 11.64 21.5202 13.85 19.3202L18.23 14.9302"
                        stroke="white"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
              }              
            </span>

            <input
              type="file"
              onChange={e => {
                this.setState({ fileDragging: false },
                  () => updateFormState({
                    ...reducedRequired[block.id] && { reducedRequired: { ...reducedRequired, [block.id]: undefined } },
                    formResponses: {
                      ...formResponses,
                      [block.id]: { media: e.target.files[0] }
                    }              
                  })
                )
              }}
            />
        </div>
      </div>
    </>;
  }
};

const mapStateToProps = state => ({
  formState: state.formState
});

const mapDispatchToProps = dispatch => ({
  updateFormState: updatedStates => dispatch(updateFormState(updatedStates))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(BlockFormUploadInput);
