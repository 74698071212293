import React, { memo } from 'react';
import OriginAdminRoutes from '../routes/origin/adminRoutes';
import OriginBasicRoutes from '../routes/origin/basicRoutes';
import ExternalAdminRoutes from '../routes/external/adminRoutes';
import ExternalBasicRoutes from '../routes/external/basicRoutes';

import { RequesterContext } from '../context/requester.context';
import { ProfileContext } from '../context/profile.context';

import '../containers/Admin/adminBuilder.scss';
import '../containers/Admin/adminGeneral.scss';
import '../containers/Public/publicSite.scss';

const hostName = window.location.hostname.toLowerCase();
const originHost = hostName.indexOf('coachiq.io') > -1 || hostName.indexOf('localhost') > -1 || hostName.indexOf('127.0.0.1') > -1;

const ParentRouter = () => (
  <ProfileContext.Consumer>
    {({ role }) => 
      <RequesterContext.Consumer>
        {({ activeGroup }) => {
          const redirectPrefix = `${activeGroup.tenant.code}/${activeGroup.code}`;
          
          return originHost
            ? role && ['Admin', 'Master'].indexOf(role) > -1
              ? <OriginAdminRoutes redirectPrefix={redirectPrefix} />
              : <OriginBasicRoutes redirectPrefix={redirectPrefix} />
            : role && ['Admin', 'Master'].indexOf(role) > -1
              ? <ExternalAdminRoutes />
              : <ExternalBasicRoutes />;
        }}
      </RequesterContext.Consumer>
    }
  </ProfileContext.Consumer>
);;

export default memo(ParentRouter);