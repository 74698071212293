import React, { memo, useEffect, useMemo } from 'react';
import { useIntercom } from 'react-use-intercom';
import { tenantPrefixUrlDeterminant } from '../../../../actions/user.actions';

const AdminMenu = props => {
  const {
    activeSlug,
    groupCode,
    tenantCode,
    onMenuItemClick
  } = props;
  
  const { boot, shutdown } = useIntercom();

  useEffect(() => { if(activeSlug === 'inbox') { shutdown() } else { boot() }; return () => { shutdown() }; }, [activeSlug === 'inbox']);

  const finalMenuSlug = useMemo(() =>(menuItems.find(({ menuItems }) => menuItems.find(({ locationTo }) => `/${activeSlug}` === locationTo)) || {}).slug, [activeSlug, menuItems]);
  
  return menuItems.map((completeSection, parentIndex) => (
    <div className={`complete-admin-menu-section ${completeSection.slug}-slug ${(completeSection.slug === finalMenuSlug) && 'active'}`} key={`admin-menu-item-parent-${parentIndex}`}>
      <h3>
        {completeSection.icon}
        {completeSection.header}
      </h3>
      
      <div className="active-sub-menu">
        {completeSection.menuItems.map((menuItem, childIndex) => (
          <button
            key={`admin-menu-item-child-${childIndex}`}
            className={`${menuItem.locationTo === `/${activeSlug}` && 'active-menu-item'}`}
            onClick={() =>
              onMenuItemClick(
                `${tenantPrefixUrlDeterminant(
                  tenantCode,
                  groupCode
                )}/admin${menuItem.locationTo}`
              )
            }
          >
            {menuItem.label}
          </button>
        ))}
      </div>          
    </div>
  ));
};

const website_and_app_icon = (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M16.6667 8.75V4.16667C16.6667 3.24583 15.9167 2.5 15 2.5H4.16667C3.24583 2.5 2.5 3.24583 2.5 4.16667V12.5"
      stroke="#666666"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M12.4998 15H2.49984C2.03984 15 1.6665 14.625 1.6665 14.1667V13.3333C1.6665 12.8667 2.03317 12.5 2.49984 12.5H12.4998"
      stroke="#666666"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M17.5 17.5H13.3333C12.8667 17.5 12.5 17.125 12.5 16.6667V9.58333C12.5 9.11667 12.8667 8.75 13.3333 8.75H17.5C17.9583 8.75 18.3333 9.11667 18.3333 9.58333V16.6667C18.3333 17.125 17.9583 17.5 17.5 17.5Z"
      stroke="#666666"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M12.5 15H18.3333"
      stroke="#666666"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

const coaching_icon = (
  <svg
    width="18"
    height="18"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M7.33496 11.5L11.665 9L7.33496 6.5V11.5Z"
      stroke="#666666"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M5.24991 1.50007H12.7499C14.8166 1.50007 16.4916 3.17756 16.4916 5.24673V12.7467C16.4916 14.8134 14.8082 16.4884 12.7416 16.4884H5.23324C3.15824 16.4884 1.48657 14.8051 1.48657 12.7384V5.2334C1.48657 3.1584 3.16157 1.4834 5.23657 1.4834L5.24991 1.50007Z"
      stroke="#666666"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

const manage_icon = (
  <svg
    width="19"
    height="18"
    viewBox="0 0 19 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M7.7501 14H3.16677L3.15843 13.9992C2.23343 13.9908 1.49177 13.2492 1.49177 12.3242V3.1575C1.48343 2.2325 2.23343 1.4825 3.1501 1.4825H12.3168H12.3084C13.2251 1.47417 13.9751 2.22417 13.9751 3.14083V7.72417"
      stroke="#666666"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M7.75 1.5V14"
      stroke="#666666"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M14.2442 16.2558L17.0859 13.4058H17.0776C17.4026 13.0725 17.4026 12.5475 17.0776 12.2225L15.7526 10.8975C15.4192 10.5642 14.8942 10.5642 14.5692 10.8892L11.7192 13.7308V13.7225C11.5609 13.8725 11.4692 14.0892 11.4692 14.3058V16.4558H13.6192V16.455C13.8359 16.4467 14.0442 16.3633 14.2026 16.205L14.2442 16.2558Z"
      stroke="#666666"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

const sales_icon = (
  <svg
    width="16"
    height="18"
    viewBox="0 0 16 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M4.66699 4.83333H8.00033"
      stroke="#666666"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M4.66699 8.16666H8.00033"
      stroke="#666666"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M4.66699 11.5H8.00033"
      stroke="#666666"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M7.81344 16.4L5.03844 15.0083C4.79678 14.8833 4.52178 14.8833 4.28844 15.0083L1.93011 16.1833C1.64678 16.3167 1.32178 16.1167 1.32178 15.8083V2.30833C1.32178 1.84833 1.68844 1.475 2.15511 1.475H13.8218C14.2801 1.475 14.6551 1.84167 14.6551 2.30833V15.8C14.6551 16.1083 14.3218 16.3083 14.0468 16.1667L11.6884 14.9833C11.4468 14.8583 11.1718 14.8583 10.9384 14.9833L8.16344 16.3667C8.03844 16.425 7.90511 16.425 7.78844 16.3667L7.81344 16.4Z"
      stroke="#666666"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M11.3336 4.825C11.3252 4.825 11.3252 4.825 11.3252 4.825V4.81667C11.3252 4.80833 11.3169 4.80833 11.3169 4.80833"
      stroke="#666666"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M11.3336 8.15833C11.3252 8.15833 11.3252 8.15833 11.3252 8.15833V8.15C11.3252 8.14166 11.3169 8.14166 11.3169 8.14166"
      stroke="#666666"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M11.3336 11.4917C11.3252 11.4917 11.3252 11.4917 11.3252 11.4917V11.4833C11.3252 11.475 11.3169 11.475 11.3169 11.475"
      stroke="#666666"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

const menuItems = [
  {
    header: 'Website & App',
    slug: 'website-and-app',
    icon: website_and_app_icon,
    menuItems: [
      {
        label: 'Dashboard',
        locationTo: '/dashboard'
      },
      {
        label: 'Inbox',
        locationTo: '/inbox'
      }
    ]
  },
  {
    header: 'Manage',
    slug: 'manage',
    icon: manage_icon,
    menuItems: [
      {
        label: 'People',
        locationTo: '/people'
      },
      {
        label: 'Forms',
        locationTo: '/forms'
      }
    ]
  },
  {
    header: 'Coaching',
    slug: 'coaching',
    icon: coaching_icon,
    menuItems: [
      {
        label: 'Media',
        locationTo: '/media'
      },
      {
        label: 'Programs',
        locationTo: '/programs'
      },      
      {
        label: 'Video Center',
        locationTo: '/video'
      }
    ]
  },        
  {
    header: 'Payment',
    slug: 'payment',
    icon: sales_icon,
    menuItems: [
      {
        label: 'Products',
        locationTo: '/products'
      },
      {
        label: 'Coupons',
        locationTo: '/coupons'
      },
      {
        label: 'Orders',
        locationTo: '/orders'
      }
    ]
  }
];

export default memo(AdminMenu);
