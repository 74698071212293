import ACTIONS from '../constants/course/actions';

const initialState = {
  theme: 'light',
  showLogo: true,
  activePage: 'programs',
  defaultFallback: {
    settings: { featuredImage: { x: 0, y: 0, scale: 1 } },
    sections: [
      {
        id: 'new-section-2',
        title: '',
        items: []
      }
    ]
  }
};

export default (state = initialState, action) => {
  switch (action.type) {
    case ACTIONS.RESET_STATE:
      return {
        ...state
      };

    case ACTIONS.UPDATE_STATE:
      return {
        ...state,
        ...action.payload
      };

    default:
      return state;
  }
};
