import React, { memo, useCallback } from 'react';
import CurrencyFormat from 'react-currency-format';

const InfoSection = props => {
  const { packageItem, requester } = props;
  const { recurring, processingFee, price = '0', label = '', description, intervalCount = 1, interval = 'month' } = packageItem || {};

  const renderText = useCallback(value => (
    <h3>
      {value}
      {recurring 
        ? <span>
            /
            {intervalCount > 1
              ? `${intervalCount} ${interval}s`
              : interval}
          </span> 
        : ''
      }
    </h3>
  ), [recurring, intervalCount, interval]);

  return (
    <div className="info-section">
      <h2>{label || 'Product Name'}</h2>
      {description && <p>{description || 'No description'}</p>}
      <CurrencyFormat
        value={processingFee  ? (parseFloat(price) + parseFloat(price) * .029).toFixed(2) : price}
        displayType={'text'}
        thousandSeparator={true}
        prefix={requester.currencyPrefix}
        renderText={renderText}
      />
    </div>
  );
};

export default memo(InfoSection);
