import React, { memo, useEffect } from 'react'
import Loadable from 'react-loadable'
import { IntercomProvider } from 'react-use-intercom'
import { Route, Routes, Navigate  } from 'react-router-dom'
import AdminNav from '../../components/AdminChildren/Nav/AdminNav'
import LogoLoader from '../../components/Shared/LogoLoader'
import GeneralLoader from '../../components/Shared/GeneralLoader'
import GeneralConfirmation_PurchaseSuccess from '../../containers/Admin/Sales/Packages/StripeSuccess/GeneralConfirmation_PurchaseSuccess'

const hostName = window.location.hostname.toLowerCase()

const Terms = Loadable({
  loader: () => import('../../containers/Legal/Terms'),
  loading: () => <LogoLoader />
})

const Privacy = Loadable({
  loader: () => import('../../containers/Legal/Privacy'),
  loading: () => <LogoLoader />
})

const AdminGallery = Loadable({
  loader: () => import('../../containers/Admin/Coaching/Media'),
  loading: () => <GeneralLoader />
})

const AdminForms = Loadable({
  loader: () => import('../../containers/Admin/Coaching/Forms'),
  loading: () => <GeneralLoader />
})

const AdminLessonBuilder = Loadable({
  loader: () => import('../../containers/Admin/Coaching/Lessons/LessonBuilder'),
  loading: () => <GeneralLoader />
})

const AdminFormBuilder = Loadable({
  loader: () => import('../../containers/Admin/Coaching/Forms/FormBuilder'),
  loading: () => <GeneralLoader />
})

const AdminVideo = Loadable({
  loader: () => import('../../containers/Admin/Coaching/Video'),
  loading: () => <GeneralLoader />
})

const AdminDashboard = Loadable({
  loader: () => import('../../containers/Admin/WebsiteApp/Dashboard'),
  loading: () => <GeneralLoader />
})

const AdminWebAppBuilder = Loadable({
  loader: () => import('../../containers/Admin/WebsiteApp/WebAppBuilder'),
  loading: () => <GeneralLoader />
})

const AdminPrograms = Loadable({
  loader: () => import('../../containers/Admin/WebsiteApp/Programs'),
  loading: () => <GeneralLoader />
})

const AdminProgramBuilder = Loadable({
  loader: () => import('../../containers/Admin/WebsiteApp/Programs/ProgramBuilder'),
  loading: () => <GeneralLoader />
})

const AdminOneOnOne = Loadable({
  loader: () => import('../../containers/Admin/Manage/Inbox'),
  loading: () => <GeneralLoader />
})

const AdminUsers = Loadable({
  loader: () => import('../../containers/Admin/Manage/Users'),
  loading: () => <GeneralLoader />
})

const AdminSingleUser = Loadable({
  loader: () => import('../../containers/Admin/Manage/Users/SingleUser'),
  loading: () => <GeneralLoader />
})

const AdminPackages = Loadable({
  loader: () => import('../../containers/Admin/Sales/Packages'),
  loading: () => <GeneralLoader />
})

const AdminPackageBuilder = Loadable({
  loader: () => import('../../containers/Admin/Sales/Packages/PackageBuilder'),
  loading: () => <GeneralLoader />
})

const AdminCoupons = Loadable({
  loader: () => import('../../containers/Admin/Sales/Coupons'),
  loading: () => <GeneralLoader />
})

const AdminOrders = Loadable({
  loader: () => import('../../containers/Admin/Sales/Orders'),
  loading: () => <GeneralLoader />
})

const AdminSettings = Loadable({
  loader: () => import('../../containers/Admin/Manage/Settings'),
  loading: () => <GeneralLoader />
})

const PublicParentMapper = Loadable({
  loader: () => import('../../containers/Public/ParentMapper'),
  loading: () => <LogoLoader />
})

const PublicProgramViewer = Loadable({
  loader: () => import('../../containers/Public/Programs/Single'),
  loading: () => <LogoLoader />
})

const PublicPackageViewer = Loadable({
  loader: () => import('../../containers/Public/Packages/Single'),
  loading: () => <LogoLoader />
})

const PublicAccessCodeViewer = Loadable({
  loader: () => import('../../containers/Public/Packages/AccessCode'),
  loading: () => <LogoLoader />
})

const OrgCreation = Loadable({
  loader: () => import('../../containers/Public/OrgCreation'),
  loading: () => <LogoLoader />
})

const OrgMembershipPurchase = Loadable({
  loader: () => import('../../containers/Admin/WebsiteApp/MembershipPurchase'),
  loading: () => <LogoLoader />
})

const Twilio10DLC = Loadable({
  loader: () => import('../../containers/Admin/Manage/Twilio10DLC'),
  loading: () => <GeneralLoader />
})

const prefix = ':tenantCode/:groupCode'

const adminRoutes = ({ redirectPrefix = false }) => {
  useEffect(() => {
    if(localStorage.getItem(`${hostName}-getting-started-admin-redirect`)){
      localStorage.removeItem(`${hostName}-getting-started-admin-redirect`);
      window.location = `/${redirectPrefix}/admin/dashboard`;
    };
  }, [])

  return (    
    <IntercomProvider appId={"jtml4dq2"}>
      <Routes>
        <Route exact path="terms" element={<Terms />} />
        <Route exact path="privacy" element={<Privacy />} />
        <Route exact path={`create`} element={<OrgCreation />} />
        <Route exact path={`stripe-connected`} element={<GeneralConfirmation_PurchaseSuccess />} />

        <Route
          exact
          path={`${prefix}/access-code`}
          element={<PublicAccessCodeViewer />}
        />

        <Route
          exact
          path={`${prefix}/programs/:programId`}
          element={<PublicProgramViewer />}
        />

        <Route
          exact
          path={`${prefix}/products/:packageId`}
          element={<PublicPackageViewer />}
        />

        <Route
          exact
          path={`${prefix}/packages/:packageId`}
          element={<PublicPackageViewer />}
        />

        <Route
          exact
          path={`${prefix}/join/:packageId`}
          element={<PublicPackageViewer />}
        />

        <Route
          exact
          path={`${prefix}/admin/10dlc`}
          element={<Twilio10DLC />}
        />

        <Route
          path={`${prefix}/admin/*`}
          element={
            <section className={`admin-parent-mapper-container`}>
              <Routes>
                <Route
                  exact
                  path={`dashboard`}
                  element={<AdminNav />}
                />              
                
                <Route
                  exact
                  path={`website`}
                  element={<AdminNav />}
                />

                <Route
                  exact
                  path={`programs`}
                  element={<AdminNav />}
                />

                <Route
                  exact
                  path={`media`}
                  element={<AdminNav />}
                />
                
                <Route
                  exact
                  path={`lessons`}
                  element={<AdminNav />}
                />
                
                <Route
                  exact
                  path={`forms`}
                  element={<AdminNav />}
                />

                <Route
                  exact
                  path={`video`}
                  element={<AdminNav />}
                />

                <Route
                  exact
                  path={`people`}
                  element={<AdminNav />}
                />

                <Route
                  exact
                  path={`people/:profileId`}
                  element={<AdminNav />}
                />

                <Route
                  exact
                  path={`products`}
                  element={<AdminNav />}
                />

                <Route
                  exact
                  path={`coupons`}
                  element={<AdminNav />}
                />

                <Route
                  exact
                  path={`orders`}
                  element={<AdminNav />}
                />

                <Route
                  exact
                  path={`inbox`}
                  element={<AdminNav />}
                />

                <Route
                  exact
                  path={`settings`}
                  element={<AdminNav />}
                />

                <Route
                  exact
                  path={`programs/builder/new`}
                  element={<AdminNav />}
                />

                <Route
                  exact
                  path={`lessons/builder/new`}
                  element={<AdminNav />}
                />
              </Routes>

              <Routes>
                <Route
                  exact
                  path={`dashboard`}
                  element={<AdminDashboard />}
                />

                <Route
                  exact
                  path={`dashboard/builder`}
                  element={<AdminWebAppBuilder />}
                />

                <Route
                  exact
                  path={'membership-purchase'}
                  element={<OrgMembershipPurchase />}
                />

                <Route
                  exact
                  path={`website`}
                  element={<Navigate replace={true} to={redirectPrefix ? `/${redirectPrefix}/admin/dashboard` : `/admin/dashboard`} />}
                />

                <Route
                  exact
                  path={`programs`}
                  element={<AdminPrograms />}
                />

                <Route
                  exact
                  path={`programs/builder/:programId`}
                  element={<AdminProgramBuilder />}
                />

                <Route
                  exact
                  path={`media`}
                  element={<AdminGallery />}
                />
                
                <Route
                  exact
                  path={`lessons`}
                  element={<Navigate replace={true} to={redirectPrefix ? `/${redirectPrefix}/admin/media` : `/admin/media`} />}
                />

                <Route
                  exact
                  path={`forms`}
                  element={<AdminForms />}
                />

                <Route
                  exact
                  path={`lessons/builder/:lessonId`}
                  element={<AdminLessonBuilder />}
                />

                <Route
                  exact
                  path={`forms/builder/:formId`}
                  element={<AdminFormBuilder />}
                />

                <Route
                  exact
                  path={`video`}
                  element={<AdminVideo />}
                />

                <Route
                  exact
                  path={`video/:creationType`}
                  element={<AdminVideo />}
                />

                <Route
                  exact
                  path={`people`}
                  element={<AdminUsers />}
                />
                <Route
                  exact
                  path={`people/:profileId`}
                  element={<AdminSingleUser />}
                />

                <Route
                  exact
                  path={`products`}
                  element={<AdminPackages />}
                />
                <Route
                  exact
                  path={`products/builder/:packageId`}
                  element={<AdminPackageBuilder />}
                />

                <Route
                  exact
                  path={`coupons`}
                  element={<AdminCoupons />}
                />

                <Route
                  exact
                  path={`orders`}
                  element={<AdminOrders />}
                />

                <Route
                  exact
                  path={`inbox`}
                  element={<AdminOneOnOne />}
                />

                <Route
                  exact
                  path={`settings`}
                  element={<AdminSettings />}
                />

                <Route
                  path="*"
                  element={<Navigate replace={true} to={`dashboard`} />}
                />
              </Routes>
            </section>
          }
        />

        <Route exact path={`${prefix}/*`} element={<PublicParentMapper />} />

        <Route
          path="*"
          element={<Navigate replace={true} to={redirectPrefix ? `/${redirectPrefix}/admin/dashboard` : `/admin/dashboard`} />}
        />
      </Routes>
    </IntercomProvider>    
  )
}

export default memo(adminRoutes);