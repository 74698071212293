import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import TextInput from '../../../../../../../Shared/TextInput';
import { updateFormState } from '../../../../../../../../actions/form.actions';
import { validEmail } from '../../../../../../../../helper';

class BlockFormEmailInput extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {};
  }

  render() {
    const { block, formState, updateFormState } = this.props;
    const { id, label, title, required = 1 } = block;
    const { formResponses = {}, reducedRequired = {} } = formState;
    const currentValue = (formResponses[id] || {}).text || '';

    return <>
      <div  className={`general-form-block-render-container text-type`}>
        {(reducedRequired[block.id] && (currentValue.length === 0 || !validEmail(currentValue))) ? <div className="invalid-currently-dignifier">A valid email is required*</div> : <></>}
        <TextInput
          id={`label-render-${block.id}`}
          className={`label-specific`}
          placeholder={`${label || title || "Enter label here..."}${required ? '*' : ''}`}
          onChange={e => 
            updateFormState({
              ...reducedRequired[block.id] && validEmail(currentValue) && { reducedRequired: { ...reducedRequired, [block.id]: undefined } },
              formResponses: {
                ...formResponses,
                [id]: { text: e }
              }              
            })
          }
          value={currentValue}
          type="email"
        />
      </div>
    </>;
  }
};

const mapStateToProps = state => ({
  formState: state.formState
});

const mapDispatchToProps = dispatch => ({
  updateFormState: updatedStates => dispatch(updateFormState(updatedStates))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(BlockFormEmailInput);
