import React from 'react';

export default {
  defaultTemplate: {
    id: `new-slide-${new Date().getTime()}`,
    type: 'defaultTemplate',
    label: 'Default',
    currentTime: 0,
    activeHeight: 720,
    canvasBgColor: '#000',
    icon: (
      <svg
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M16 19.0098H4C2.343 19.0098 1 17.6668 1 16.0098V4.00977C1 2.35277 2.343 1.00977 4 1.00977H16C17.657 1.00977 19 2.35277 19 4.00977V16.0098C19 17.6668 17.657 19.0098 16 19.0098Z"
          stroke="#666666"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        ></path>
      </svg>
    ),
    chronAlpha: [],
    chronNum: [],
    lines: [],
    textAssets: [],
    mediaSections: [
      {
        fallbackIndexRef: 0,
        mediaDimensions: {
          x: 0,
          y: 0,
          height: 720,
          width: 1280,
          landscape: { x: 0, y: 0, height: 720, width: 1280 },
          square: { x: 0, y: 0, height: 720, width: 720 },
          portrait: { x: 0, y: 0, height: 720, width: 404 }
        },
        originManip: {
          scale: 1,
          x: 0,
          y: 0,
          landscape: { scale: 1, x: 0, y: 0 },
          square: { scale: 1, x: 0, y: 0 },
          portrait: { scale: 1, x: 0, y: 0 }
        },
        blobUrl: null
      }
    ],
    watermarkOriginManip: { scale: 1, x: 15, y: 15 },
    webcamMediaDimensions: { x: 0, y: 0, height: 550, width: 550 },
    webcamOriginManip: { scale: 0.35, x: 10, y: 515 }
  },

  /**Split Media Template**/
  customTemplate: {
    id: `new-slide-${new Date().getTime()}`,
    type: 'customTemplate',
    label: 'Custom',
    currentTime: 0,
    activeHeight: 720,
    canvasBgColor: '#000',
    icon: (
      <svg
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M16 19.0098H4C2.343 19.0098 1 17.6668 1 16.0098V4.00977C1 2.35277 2.343 1.00977 4 1.00977H16C17.657 1.00977 19 2.35277 19 4.00977V16.0098C19 17.6668 17.657 19.0098 16 19.0098Z"
          stroke="#666666"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        ></path>
        <path
          d="M10 1V19"
          stroke="#666666"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        ></path>
      </svg>
    ),
    chronAlpha: [],
    chronNum: [],
    lines: [],
    textAssets: [],
    mediaSections: [],
    watermarkOriginManip: { scale: 1, x: 15, y: 15 },
    webcamMediaDimensions: { x: 0, y: 0, height: 550, width: 550 },
    webcamOriginManip: { scale: 0.35, x: 10, y: 500 }
  }
};
