import React, { useMemo, useState } from 'react';
import { connect } from 'react-redux';
import ReactDOM from 'react-dom';
import PhoneInput, { parsePhoneNumber } from 'react-phone-number-input';

import GalleryChoice from '../../../../AdminChildren/WebsiteApp/Programs/PopUps/GalleryChoice';
import GeneralImage from '../../../../Shared/GeneralImage';
import TextInput from '../../../../Shared/TextInput';

import { updateUserInit, getAvatarKey } from '../../../../../actions/user.actions';

import 'react-phone-number-input/style.css';

const AccountSettings = ({ user, generalState, updateUserInit }) => {
  const { userUpdated, upsertingUser } = generalState

  const [email, update_email] = useState(user.email)

  const [avatar, update_avatar] = useState(user.avatar)

  const [firstName, update_firstName] = useState(user.firstName)

  const [lastName, update_lastName] = useState(user.lastName)
  
  const [showMediaSelectionPop, update_showMediaSelectionPop] = useState()

  const parsedNumber = useMemo(() => (user.phone && parsePhoneNumber(`+${user.phone.replace('+', '')}`)) || {}, [user.phone])

  const [phone, update_phone] = useState(parsedNumber?.number || '+1')
  const [country] = useState(parsedNumber?.country || 'US')

  return (
    <div className="immedaite-settings-main-component">
      <div
        className="avatar-upload-section"
        onClick={() => update_showMediaSelectionPop(true)}
      >
        {avatar ? (
          <GeneralImage
            className="avatar-specific"
            imageResolution={'350x'}
            originalKey={getAvatarKey(avatar, user.id)}
          />
        ) : (
          <img
            className="avatar-specific fallback-logo"
            src="/images/android-chrome-192x192.png"
          />
        )}

        <span>Upload photo</span>
      </div>

      <TextInput
        className="auth-input-and-label-container"
        label="First Name"
        placeholder="First Name"
        value={firstName}
        onChange={update_firstName}
      />

      <TextInput
        className="auth-input-and-label-container"
        label="Last Name"
        placeholder="Last Name"
        value={lastName}
        onChange={update_lastName}
      />

      <TextInput
        className="auth-input-and-label-container"
        label="Email"
        placeholder="Email"
        value={email}
        type={'email'}
        onChange={update_email}
      />

      <div className="auth-input-and-label-container">
        <label>Phone Number</label>
        <PhoneInput
          value={phone}
          country={country}
          onChange={update_phone}
          defaultCountry={country}
          placeholder="(000) - 000 - 0000"
        />
      </div>

      <button
        className="submit-specific"
        onClick={() =>
          updateUserInit({
            firstName,
            lastName,
            email,
            phone,
            avatar
          })
        }
      >
        {userUpdated ? 'User Updated' : 'Save Changes'}
      </button>

      {upsertingUser && (
        <div className="general-tag-loading-specific">
          <div className="appLoading">
            <svg
              className="simple-line-loader-for-app-loading css-primary-hex-stroke"
              viewBox="0 0 50 50"
            >
              <circle
                className="path css-primary-hex-stroke"
                cx="25"
                cy="25"
                r="20"
                fill="none"
                strokeWidth="5"
              ></circle>
            </svg>
          </div>
        </div>
      )}


      {showMediaSelectionPop && ReactDOM.createPortal(
        <div className="outer-container-of-program-addition-pop-up">
          <GalleryChoice
            goBack={() => update_showMediaSelectionPop(false)}
            simplified={true}
            imagesOnly={true}
            onSelect={gallerySelected => {
              update_avatar(gallerySelected.url.split('/athletic-outlook/')[1])
              update_showMediaSelectionPop(false)
            }}
          />
        </div>,
        document.getElementById('outsideModalPortal-z2')
      )}
    </div>
  )
}

const mapStateToProps = state => ({
  generalState: state.generalState
})

const mapDispatchToProps = dispatch => ({
  updateUserInit: variables => dispatch(updateUserInit(variables))
})

export default connect(mapStateToProps, mapDispatchToProps)(AccountSettings)