import React, { useEffect } from 'react';
import { useNavigate } from 'react-router';

const NavigateTo = ({ toPath = '' }) => {
  const navigate = useNavigate();
  
  useEffect(() => {    
    navigate(toPath, { replace: true }) 
  });
  
  return <></>; 
};

export default NavigateTo;