import React from 'react';
import { connect } from 'react-redux';
import AccountSettings from './Main/AccountSettings';
import Billing from './Main/Billing';
import Groups from './Main/Groups';

const Main = ({ generalState, profile, requester }) => {
  const { activeSettingSection } = generalState;

  return (
    <div className="settings-main-section-router">
      {activeSettingSection === 'account' ? (
        <AccountSettings />
      ) : activeSettingSection === 'billing' ? (
        <Billing requester={requester} profile={profile} />
      ) : (
        <Groups requester={requester} profile={profile} />            
      )}
    </div>
  )
}

const mapStateToProps = state => ({
  generalState: state.generalState
})

export default connect(mapStateToProps, null)(Main)
