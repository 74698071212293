import React, { memo } from 'react';
import Main_REDUX from './MainFrame/Main_REDUX';
import ParentStripeForm from './MainFrame/Sidebar/ParentStripeForm';

import './purchaseEmbed.scss';

const MainFrame = props => {
  const { requester, lightTheme, purchaseConfirmed, activeItem, buttonColor, buttonTextColor, destination, destinationUrl, secondPageSuccess } = props;

  return activeItem.loading ? (
    <div className="general-tag-loading-specific">
      <div className="appLoading">
        <svg
          className="simple-line-loader-for-app-loading css-primary-hex-stroke"
          viewBox="0 0 50 50"
        >
          <circle
            className="path css-primary-hex-stroke"
            cx="25"
            cy="25"
            r="20"
            fill="none"
            strokeWidth="5"
          ></circle>
        </svg>
      </div>
    </div>
  ) : (
    <div className="external-embed-purchase-container">
      <Main_REDUX requester={requester} activeItem={activeItem} />
      <ParentStripeForm 
        requester={requester} 
        destination={destination} 
        destinationUrl={destinationUrl} 
        purchaseConfirmed={purchaseConfirmed} 
        lightTheme={lightTheme} 
        activeItem={activeItem} 
        buttonColor={buttonColor} 
        buttonTextColor={buttonTextColor} 
        secondPageSuccess={secondPageSuccess} 
        accessCodeSpecific={activeItem?.accessCode_CONFIG === 'only-accessCodes'}
      />
    </div>
  );
};

export default memo(MainFrame);
