import React, { memo, useCallback, useState } from 'react';
import SearchArea from './ParentMapperChild/SearchArea';
import ActionCenter from './ParentMapperChild/ActionCenter';
import GalleryMain from '../../../../Coaching/Gallery/GalleryMain';
import GeneralTags from '../../../../GeneralTags';

const GalleryChoice = props => {
  const {
    requester,
    profile,
    updateGeneralState,
    imagesOnly,
    generalState,
    simplified,
    onSelect,
    goBack,
    onTagSelect,
    onUntagged,
    onTagRemove,    
    pendingSets = 0,
    showInstagram,
    fileUpload_onChange,
    hideTags,
    parentData = [],
    loadingParent,
    maxSelection = 0
  } = props;

  const {
    filesUploading = {},
    activeSidebarCategories,
    untaggedActive,
    searchInput
  } = generalState;

  const [mediaSelected, updateMediaSelected] = useState([]);

  const onFileDropChange = useCallback(
    e => {
      const currentTimestamp = new Date().getTime();

      fileUpload_onChange({
        pendingSets: pendingSets + 1,
        currentFiles: filesUploading,
        files: [...e.target.files].map((file, index) => ({
          file,
          tempUrl: URL.createObjectURL(file),
          progress: 0,
          id: `${currentTimestamp}-${index}`
        }))
      })
    }, [filesUploading, pendingSets]
  );

  return <>
    <div id="header-of-inner-admin-content">
      <h2 className="page-title">Select Media</h2>

      <div className="page-action-items-to-the-right">
        <SearchArea searchInput={searchInput} updateGeneralState={updateGeneralState} />
        <ActionCenter updateGeneralState={updateGeneralState} goBack={goBack} />
      </div>
    </div>

    <div id="complete-inner-main-admin-content">
      {
        !hideTags && (
          <div id="admin-content-inner-sidebar">
            <GeneralTags
              activateUsers={true}
              itemsSelected={activeSidebarCategories || []}
              untaggedActive={untaggedActive}
              onUntagged={() =>
                onUntagged(
                  (activeSidebarCategories || []).reduce(
                    (result, currentValue) => ({
                      ...(result || {}),
                      ...(generalState[
                        `${currentValue.id}_children`
                      ] && {
                        [`${currentValue.id}_children`]: undefined
                      })
                    }),
                    {}
                  )
                )
              }
              onSelect={toAdd =>
                onTagSelect({
                  toAdd,
                  activeSidebarCategories,
                  parentChildren:
                    generalState[`${toAdd.parentId}_children`] || []
                })
              }
              onRemove={toRemove =>
                onTagRemove({
                  toRemove,
                  rootChildren: generalState[`${toRemove.id}_children`],
                  parentChildren:
                    generalState[`${toRemove.parentId}_children`],
                  activeSidebarCategories
                })
              }
              origin="media"
            />
          </div>
        )
      }                  

      <div id="admin-content-inner-content-with-sidebar" className={`${hideTags && 'hide-tags-specific full-width-specific'}`}>
        <GalleryMain
          onSelect={itemSelected =>
            (itemSelected.media_type && !showInstagram)
              ? alert("Selection of instagram items are not enabled here yet.")
              : itemSelected.children
                ? alert("To use album items in the creation center => Select album items in the media gallery view for now until the ui/ux for album selection is finished.")
                : simplified
                  ? onSelect(itemSelected)
                  : updateMediaSelected([ ...mediaSelected, itemSelected ])
          }
          onRemove={itemSelected =>
            updateMediaSelected(
              mediaSelected.filter(
                lesson => lesson.id !== itemSelected.id
              )
            )
          }
          onFileDropChange={onFileDropChange}
          editMassMediaInit={true}
          parentData={parentData}
          loadingParent={loadingParent}
          itemsSelected={mediaSelected}
          imagesOnly={imagesOnly}
          profile={profile}
          requester={requester}
          tags={null}
        />
      </div>
    </div>

    {!simplified && (
      <div className="program-builder-selection-footer">
        <p className="selection-description-to-left">
          {mediaSelected.length}
          {maxSelection > 0 ? ` of ${maxSelection} ` : ' '}selected
        </p>
        <div className="action-items-to-the-right">
          <button className="go-back inline" onClick={goBack}>
            <svg
              width="6"
              height="10"
              viewBox="0 0 6 10"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M5 1L1 5L5 9"
                stroke="white"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
            Back
          </button>
          <button
            onClick={() => mediaSelected.length > 0 && onSelect(mediaSelected)}
            className={`add-program-buider-materials ${mediaSelected.length === 0 && 'no-lessons-selected'}`}
          >
            <svg
              width="18"
              height="18"
              viewBox="0 0 18 18"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M15 17H3C1.89 17 1 16.1 1 15V3C1 1.89 1.89 1 3 1H15C16.1 1 17 1.89 17 3V15C17 16.1 16.1 17 15 17Z"
                stroke="white"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M9 5V13"
                stroke="white"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M13 9H5"
                stroke="white"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
            Add Selected
          </button>
        </div>
      </div>
    )}
  </>;
};

export default memo(GalleryChoice);
