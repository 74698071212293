import React from 'react';
import { useNavigate } from 'react-router';

const NaviationContainer = ({ 
  toPath, 
  beforeNavigate = () => null, 
  afterNavigate = () => null, 
  child = <></>, 
  className = "",
  style
}) => {  
  const navigate = useNavigate();
  
  return (
    <div 
      {...style && { style }}
      className={className} 
      onClick={() => {
        beforeNavigate();

        if(toPath) navigate(toPath);
        
        afterNavigate();
      }}
    >
      {child}
    </div> 
  );
};

export default NaviationContainer;