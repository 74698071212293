import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import GeneralTags from './GeneralTags';
import {
  upsertTagConnectionInit,
  removeTagConnectionInit,
  updateGeneralState
} from '../../actions/general.actions';

class AssignGeneralTags extends PureComponent {
  constructor(props) {
    super(props);
  }

  render() {
    const {
      origins = [],
      origin = 'media',
      activeTags = [],
      assetId,
      generalState,
      updateGeneralState,
      upsertTagConnectionInit,
      removeTagConnectionInit,
      originRef = 'mediaId'
    } = this.props;

    const { activeDropDown } = generalState;

    const originKeyRef = origins.length > 0 ? `${assetId}-${origins.join('-')}` : `${assetId}-${origin}`;

    const dropDownIsActive = activeDropDown === originKeyRef;

    const assetRefName = `${assetId}_tagsSelected`;

    const filteredTags = origins.length > 0 ? activeTags.filter(
      ({ tag }) => origins.find(
        origin => tag[origin] && (
          !tag.parentId || activeTags.find(
            categoryLocalChild =>
              categoryLocalChild.tag[origin] &&
              categoryLocalChild.tag.id === tag.parentId
          )
        )
      )        
    ) : activeTags.filter(
      ({ tag }) =>
        tag[origin] &&
        (!tag.parentId ||
          activeTags.find(
            categoryLocalChild =>
              categoryLocalChild.tag[origin] &&
              categoryLocalChild.tag.id === tag.parentId
          ))
    );

    return (
      <>
        <div
          className={`container-of-tag-drop-down ${dropDownIsActive && 'drop-down-is-active'}`}
        >
          <button
            className="init-dropdown"
            onClick={() => updateGeneralState({ activeDropDown: dropDownIsActive ? null : originKeyRef })}
          >
            {filteredTags.length > 0
              ? filteredTags
                  .map(categoryLocal => categoryLocal.tag.label)
                  .join(', ')
              : 'No tag'}
            <svg
              width="8"
              height="4"
              viewBox="0 0 8 4"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M1.33325 0.666748L3.99992 3.33341L6.66659 0.666748"
                stroke="white"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </button>
        </div>
                
        {dropDownIsActive && (
          <GeneralTags
            origin={origin}
            origins={origins}
            cachePolicy="cache-first"
            onSelect={async itemSelected =>
              updateGeneralState({
                [`${assetRefName}_removed`]: (
                  generalState[`${assetRefName}_removed`] || []
                ).filter(id => id !== itemSelected.id),
                [assetRefName]: [
                  ...(generalState[assetRefName] || []),
                  {
                    id: await upsertTagConnectionInit({
                      tagId: itemSelected.id,
                      assetId,
                      originRef
                    }),
                    tag: itemSelected
                  }
                ]
              })
            }
            onRemove={async itemSelected => {
              updateGeneralState({
                [`${assetRefName}_removed`]: [
                  ...(generalState[`${assetRefName}_removed`] || []),
                  itemSelected.id
                ],
                [assetRefName]: (generalState[assetRefName] || []).filter(
                  courseCategory => courseCategory.tag.id !== itemSelected.id
                )
              });

              await removeTagConnectionInit({
                id: (
                  (activeTags || []).find(
                    courseCategory => courseCategory.tag.id === itemSelected.id
                  ) || {}
                ).id
              });
            }}
            itemsSelected={(activeTags || []).map(({ tag }) => tag)}
            assignSpecific={true}
          />
        )}
      </>
    );
  }
};

const mapStateToProps = state => ({
  generalState: state.generalState
});

const mapDispatchToProps = dispatch => ({
  updateGeneralState: variables => dispatch(updateGeneralState(variables)),
  upsertTagConnectionInit: variables =>
    dispatch(upsertTagConnectionInit(variables)),
  removeTagConnectionInit: variables =>
    dispatch(removeTagConnectionInit(variables))
});

export default connect(mapStateToProps, mapDispatchToProps)(AssignGeneralTags);
