import React, { useCallback, memo } from 'react';
import ArchiveMediaItem from './ArchiveMediaItem';

const GalleryMainMediaMapper_EXISTING = ({ profile, onRadioInit, onRemove, onSelect, simplified, itemsSelected, fallbackImage1, fallbackImage2, updateGeneralState, filterImageOnly_QUERIED = [] }) => {
  const keyExtractor = useCallback((mediaId, fileIndex) => `media-item-${fileIndex}-${mediaId}`, []);

  const renderItem = useCallback(
    (media, fileIndex) => ( 
      <ArchiveMediaItem
        key={keyExtractor(media.id, fileIndex)}                      
        media={media}
        profile={profile}
        onRemove={onRemove}
        onSelect={onSelect}
        showRadioInit={true}
        onRadioInit={onRadioInit}
        simplified={simplified}
        itemsSelected={itemsSelected}
        fallbackImage={fallbackImage1}
        fallbackImage2={fallbackImage2}
        updateGeneralState={updateGeneralState}
      />
    ), [simplified, itemsSelected]
  );

  return filterImageOnly_QUERIED.map(renderItem);
};

export default memo(GalleryMainMediaMapper_EXISTING);
