import React from 'react';
import { connect } from 'react-redux';
import { useQuery } from 'react-apollo';

import ChildMapper from './AccountSettings/ChildMapper';
import GeneralLoader from '../../../Shared/GeneralLoader';

import { updateUserInit } from '../../../../actions/user.actions';
import { CurrentUserQuery_UserInfo } from '../../../../apollo.queries';

import 'react-phone-number-input/style.css';

const AccountSettings = (props) => {
  const {loading, data = {}} = useQuery(CurrentUserQuery_UserInfo, { fetchPolicy: "network-only" })

  if(loading && !data?.user) return <GeneralLoader />

  const user = data?.user || {}

  return <ChildMapper {...{ ...props, user }} />
}

const mapStateToProps = state => ({
  generalState: state.generalState
})

const mapDispatchToProps = dispatch => ({
  updateUserInit: variables => dispatch(updateUserInit(variables))
})

export default connect(mapStateToProps, mapDispatchToProps)(AccountSettings)