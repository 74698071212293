import React, { memo } from 'react';
import Select from 'react-select';
import countryList from 'react-select-country-list';

const options = countryList().getData();

const CountryPicker = ({ value = '', onChange, styles }) => {
  return <Select {...styles && { styles }} options={options} value={value} onChange={onChange} classNamePrefix="react-select" />;
};

export default memo(CountryPicker);