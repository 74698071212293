import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
  fileUpload_onChange,
  updateGeneralState
} from '../../../../../actions/general.actions';
import { ProfileContext } from '../../../../../context/profile.context';
import { RequesterContext } from '../../../../../context/requester.context';
import Instagram from './ThirdParty/Instagram';

class AddMedia extends Component {
  constructor(props) {
    super(props);

    this.state = {
      searchInput: '',
      activeMethod: 'device'
    };
  }

  render() {
    const { activeMethod } = this.state;

    const {
      fileUpload_onChange,
      updateGeneralState,
      generalState
    } = this.props;

    const { pendingSets, filesUploading } = generalState;

    return (
      <RequesterContext.Consumer>
        {requester => (
          <ProfileContext.Consumer>
            {profile => (
              <div className="add-media-outer-popup-container add-media-specific">
                <div
                  className="fill-behind-general-for-close"
                  onClick={() => updateGeneralState({ addMediaPop: undefined })}
                ></div>
                <div className="add-media-white-popup">
                  <div id="sidebar-of-inner-admin-content">
                    <h2 className="page-title">Upload Media</h2>
                    <div className="immediate-method-list">
                      {additionMethods.map(method => (
                        <button
                          className={`${method.slug === activeMethod &&
                            'enabled'}`}
                          onClick={() =>
                            this.setState({ activeMethod: method.slug })
                          }
                        >
                          {method.icon}
                          {method.label}
                        </button>
                      ))}
                    </div>
                  </div>

                  <div id="complete-inner-main-admin-content">
                    {activeMethod === 'instagram' && false ? (
                      <Instagram profile={profile} requester={requester} />
                    ) : (
                      <div className="drop-media-specific">
                        <input
                          type="file"      
                          id={"trigger-this-input"}
                          accept="audio/*,video/*,image/*"
                          multiple
                          onChange={e => {
                            const currentTimestamp = new Date().getTime();

                            this.setState({ fileDragging: false }, () => {
                              updateGeneralState({ addMediaPop: undefined });
                              fileUpload_onChange({
                                pendingSets: pendingSets + 1,
                                currentFiles: filesUploading,
                                files: [...e.target.files].map(
                                  (file, index) => ({
                                    file,
                                    tempUrl: URL.createObjectURL(file),
                                    progress: 0,
                                    id: `${currentTimestamp}-${index}`
                                  })
                                )
                              });
                            });
                          }}
                        />
                        <div className="drop-media-to-center">
                          <div className="icon">
                            <svg
                              width="20"
                              height="18"
                              viewBox="0 0 20 18"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M17 3.94H10.529C10.198 3.94 9.888 3.776 9.702 3.502L8.297 1.437C8.111 1.164 7.802 1 7.471 1H3C1.895 1 1 1.895 1 3V15C1 16.105 1.895 17 3 17H17C18.105 17 19 16.105 19 15V5.94C19 4.836 18.105 3.94 17 3.94V3.94Z"
                                stroke="white"
                                strokeWidth="1.5"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              />
                              <path
                                d="M9.98999 7.62012V13.1201"
                                stroke="white"
                                strokeWidth="1.5"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              />
                              <path
                                d="M7.23999 10.3701H12.74"
                                stroke="white"
                                strokeWidth="1.5"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              />
                            </svg>
                          </div>
                          <p>Press button and select files on your device</p>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            )}
          </ProfileContext.Consumer>
        )}
      </RequesterContext.Consumer>
    );
  }
};

const file_icon = (
  <svg
    width="18"
    height="16"
    viewBox="0 0 18 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M14.8333 3.78328H9.44084C9.1575 3.78328 8.90667 3.64162 8.75167 3.41662L7.57667 1.69162C7.41834 1.45828 7.16 1.32495 6.885 1.32495H3.15C2.22917 1.32495 1.48334 2.06662 1.48334 2.99162V12.9916C1.48334 13.9083 2.22917 14.6583 3.15 14.6583H14.8167C15.7333 14.6583 16.4833 13.9083 16.4833 12.9916V5.44162C16.4833 4.52162 15.7333 3.77495 14.8167 3.77495L14.8333 3.78328Z"
      stroke="black"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

const instagram_icon = (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.24667 2.49994H13.7467C15.8133 2.49994 17.4883 4.17494 17.4883 6.2466V13.7466C17.4883 15.8133 15.805 17.4883 13.7383 17.4883H6.225C4.15 17.4883 2.47833 15.8049 2.47833 13.7383V6.22494C2.47833 4.14994 4.15333 2.47827 6.225 2.47827L6.24667 2.49994Z"
      stroke="#000"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M14.1167 5.59406C13.9583 5.59406 13.8333 5.71906 13.8333 5.86906C13.8333 6.01906 13.9583 6.14406 14.1083 6.14406C14.2583 6.14406 14.3833 6.01073 14.3833 5.86073C14.3833 5.70239 14.25 5.57739 14.1 5.57739"
      stroke="#000"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M12.1167 7.87479C13.2833 9.04146 13.2833 10.9415 12.1167 12.1165C10.9417 13.2831 9.04167 13.2831 7.86667 12.1165C6.69168 10.9415 6.69168 9.04146 7.86584 7.86646C9.03251 6.69146 10.9325 6.69146 12.1075 7.86563"
      stroke="#000"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

const google_drive_icon = (
  <svg
    width="18"
    height="16"
    viewBox="0 0 18 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M9 6.01673L4.10917 14.4834L1.5 9.9584L6.39083 1.4834"
      stroke="#000"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M6.71833 9.9751H16.5L13.8833 14.4918L4.1 14.4834"
      stroke="#000"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M11.275 9.975L6.38334 1.5H11.6L16.4908 9.97167"
      stroke="#000"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

const additionMethods = [
  {
    enabled: true,
    icon: file_icon,
    label: 'Your Device',
    slug: 'device'
  },
  {
    enabled: false,
    icon: instagram_icon,
    label: 'Instagram (Coming soon)',
    slug: 'instagram'
  },
  {
    enabled: false,
    icon: google_drive_icon,
    label: 'Google Drive (Coming soon)',
    slug: 'google_drive'
  }
];

AddMedia.propTypes = {
  updateGeneralState: PropTypes.func.isRequired
};

const mapStateToProps = state => ({
  generalState: state.generalState
});

const mapDispatchToProps = dispatch => ({
  updateGeneralState: updatedStates =>
    dispatch(updateGeneralState(updatedStates)),
  fileUpload_onChange: updatedStates =>
    dispatch(fileUpload_onChange(updatedStates))
});

export default connect(mapStateToProps, mapDispatchToProps)(AddMedia);
