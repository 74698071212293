import React, { memo, useEffect } from "react";
import moment from "moment";
import { Helmet } from 'react-helmet';
import { Provider } from 'react-redux';
import { ApolloProvider } from 'react-apollo';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import InstagramAuth from './containers/Admin/Coaching/Gallery/InstagramAuth';
import FacebookAuth from './containers/Admin/Coaching/Gallery/FacebookAuth';
import DirectFormEmbedView from './containers/Public/DirectFormEmbedView';
import DirectMediaView from './containers/Public/DirectMediaView';
import DirectFormView from './containers/Public/DirectFormView';
import apolloClient from './apollo.client';
import store from './reducers/store';
import App from './containers/app';

import 'normalize.css/normalize.css';

const AppMapper = () => {
  useEffect(() => {
    moment.updateLocale('en', {
      relativeTime : {
          future: "in %s",
          past:   "%s ",
          s:  "1s",
          m:  "1m",
          mm: "%dm",
          h:  "1h",
          hh: "%dh",
          d:  "1d",
          dd: "%dd",
          M:  "1 month",
          MM: "%d months",
          y:  "1y",
          yy: "%dy"
      }
    })
  }, [])

  return (
    <Provider store={reduxStore}>
      <ApolloProvider client={apolloClient}>
        <BrowserRouter>
          <Helmet>
            <meta 
              http-equiv="origin-trial" 
              content={originTrialKeyToPass}
            />
          </Helmet>
          <Routes>
            <Route exact path="instagram-auth" element={<InstagramAuth />} />
            <Route exact path="instagram-disable" element={<InstagramAuth disconnectSpecific={true} />} />
            <Route exact path="facebook-disable" element={<FacebookAuth disconnectSpecific={true} />} />
            <Route exact path="media/:id" element={<DirectMediaView />} />
            <Route exact path="form/:id" element={<DirectFormView />} />
            <Route exact path="form-embed/:id" element={<DirectFormEmbedView formEmbed={true} />} />
            <Route path="*" element={<App />} />
          </Routes>
        </BrowserRouter>
      </ApolloProvider>
    </Provider> 
  )
}

const reduxStore = store(),
      hostname = window.location.hostname.toLowerCase(),
      originTrialKeyToPass = hostname.indexOf('coachiq') > -1 ? "AqTbUFLFIoQPJPWY4D8hO7JPh9Ms3ettUzN/dPuoIco5T2b/Iqsr1wHjV4L/vEgXNpeSBIn8jmaPbaUBDfvFSg8AAAB4eyJvcmlnaW4iOiJodHRwczovL2FwcC5jb2FjaGlxLmlvOjQ0MyIsImZlYXR1cmUiOiJVbnJlc3RyaWN0ZWRTaGFyZWRBcnJheUJ1ZmZlciIsImV4cGlyeSI6MTcwOTg1NTk5OSwiaXNTdWJkb21haW4iOnRydWV9" : "AmhyeqeHcnPBoTsGjB3qwJvuig12W9Sf2vDN8p++bX/va1flM0BrD1PRDR6F+5v2UOvJ8eMnPJKKHG47pt91WAMAAABgeyJvcmlnaW4iOiJodHRwOi8vbG9jYWxob3N0OjMwMDAiLCJmZWF0dXJlIjoiVW5yZXN0cmljdGVkU2hhcmVkQXJyYXlCdWZmZXIiLCJleHBpcnkiOjE3MDk4NTU5OTl9"

export default memo(AppMapper)