const mainIcon = <svg width="18" height="21" viewBox="0 0 18 21" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M10.47 9.90798V5.45998C10.47 4.63998 9.79996 3.97998 8.98996 3.97998C8.16996 3.97998 7.50996 4.63998 7.50996 5.44998V8.88998V13.967L4.86396 12.607C4.25396 12.297 3.52896 12.407 3.04396 12.887C2.41096 13.517 2.43396 14.547 3.10396 15.147L7.92896 19.447C8.28896 19.767 8.76896 19.947 9.25896 19.947H13.889C14.819 19.947 15.629 19.287 15.839 18.377L17.028 13.017C17.278 11.887 16.518 10.777 15.378 10.607L10.438 9.83698L10.47 9.90798Z" stroke="#EE8524" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/><path d="M10.64 7H14.99C16.09 7 16.99 6.1 16.99 5V3C16.99 1.895 16.09 1 14.99 1H2.98999C1.88499 1 0.98999 1.895 0.98999 3V5C0.98999 6.1 1.88499 7 2.98999 7H7.33999" stroke="#EE8524" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/></svg>;

const FieldTemplateTypes = {
  text_input: {
    title: 'Text',
    icon: mainIcon,
    backgroundColor: "#FFEDD5" 
  },
  phone_input: {
    title: 'Phone',
    icon: mainIcon,
    backgroundColor: "#FFEDD5" 
  },
  text_area: {
    title: 'Text Area',
    icon: mainIcon,
    backgroundColor: "#FFEDD5" 
  },
  checkbox_input: {
    title: 'Checkbox',
    icon: mainIcon,
    backgroundColor: "#FFEDD5" 
  },
  select_input: {
    title: 'Select Box',
    icon: mainIcon,
    backgroundColor: "#FFEDD5" 
  },
  radio_input: {
    title: 'Radio Buttons',
    icon: mainIcon,
    backgroundColor: "#FFEDD5" 
  }
};

export default FieldTemplateTypes;
