import ACTIONS from '../constants/actions';
import STATUS from '../constants/status';
import { INITIAL_STATE } from '../constants/voiceover/initialState';

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case ACTIONS.RESET_STATE:
      return {
        ...state
      };

    case ACTIONS.UPDATE_STATE:
      return {
        ...state,
        ...action.payload,        
      };

    // File download
    case ACTIONS.DOWNLOAD_PROGRESS:
      return {
        ...state,
        ...(state.downloadProgress < action.payload && {
          downloadProgress: action.payload
        })
      };

    case ACTIONS.DOWNLOAD_FILE_IN_PROGRESS:
      return {
        ...state,
        url: action.payload,
        status: STATUS.IN_PROGRESS
      };

    case ACTIONS.SET_PRIMARY_VIDEO:
      return {
        ...state,
        primaryVideoBlob: action.payload
      };

    case ACTIONS.SET_RIGHT_VIDEO:
      return {
        ...state,
        rightVideoBlob: action.payload
      };

    case ACTIONS.REMOVE_RIGHT_VIDEO:
      return {
        ...state,
        rightVideoBlob: null
      };

    case ACTIONS.ACTIVATE_RECORDING_QUEUE:
      return {
        ...state,
        activeRecordingQueue: true
      };

    case ACTIONS.DEACTIVATE_RECORDING_QUEUE:
      return {
        ...state,
        activeRecordingQueue: false
      };

    case ACTIONS.ADD_TO_RECORDING_QUEUE: {
      const { recordingSlides = [] } = state;      

      return {
        ...state,
        recordingSlides: [ ...recordingSlides, action.payload.slide ]
      };
    }

    case ACTIONS.UPDATE_RECORDING_QUEUE: {
      let { recordingSlides } = state;

      const removed = recordingSlides.splice(action.payload.source, 1)[0];
      recordingSlides.splice(action.payload.destination, 0, removed);

      return {
        ...state,
        recordingSlides
      };
    }

    case ACTIONS.REMOVE_VIDEO_FROM_QUEUE: {
      let { recordingSlides } = state;

      recordingSlides.splice(action.payload, 1);

      return {
        ...state,
        recordingSlides
      };
    }

    case ACTIONS.CHANGE_BLOB: {
      if (action.payload) {
        let { downloadedFiles, recordingSlides } = state;

        const indexFound = recordingSlides.findIndex(
          item => item.url === action.payload.url
        );

        if (indexFound > -1) {
          recordingSlides[indexFound].blob = action.payload.blob;
          downloadedFiles[action.payload.key] = action.payload.blob;

          return {
            ...state,
            downloadedFiles,
            recordingSlides
          };
        }
      }
    }

    case ACTIONS.DOWNLOAD_FILE_COMPLETED: {
      if (action.payload && action.payload.url) {
        let {
          downloadedFiles,
          primaryVideoBlob,
          activeRecordingQueue,
          recordingSlides,
          rightVideoBlob
        } = state;

        const splitScreen = action.payload.splitScreen;

        const currentDate = new Date();

        const blob_assigned =
          action.payload.res &&
          action.payload.res.toString().indexOf('blob:') > -1 &&
          action.payload.res;

        let blob_to_pass = null;
        try {
          blob_to_pass = blob_assigned
            ? `${blob_assigned}${currentDate.getTime()}`
            : `${window.URL.createObjectURL(
                action.payload.res
              )}#timeStamp=${currentDate.getTime()}`;
        } catch (e) {
          console.log(e);
        }

        downloadedFiles[action.payload.url] = blob_to_pass;

        let setNewVideoActive = false;
        if (activeRecordingQueue) {
          recordingSlides.push({
            url: action.payload.url,
            blob: blob_to_pass
          });
        }

        return {
          ...state,
          ...(((!rightVideoBlob && !splitScreen && !primaryVideoBlob) ||
            (splitScreen && splitScreen === 'left') ||
            setNewVideoActive) && {
            primaryVideoBlob: blob_to_pass
          }),
          ...(splitScreen &&
            splitScreen === 'right' && {
              primaryVideoBlob: blob_to_pass,
              rightVideoBlob: blob_to_pass
            }),
          downloadedFiles,
          status: STATUS.COMPLETED,
          downloadProgress: 0,
          recordingSlides
        };
      } else {
        return {
          ...state,
          status: null
        };
      }
    }

    case ACTIONS.DOWNLOAD_FILE_COMPLETED_V2: {
      let { downloadedFiles, recordingSlides } = state;

      if (action.payload && action.payload.url) {
        const currentDate = new Date();

        const imageSpecific = action.payload.imageSpecific;

        const blob_assigned =
          !imageSpecific &&
          action.payload.res &&
          action.payload.res.toString().indexOf('blob:') > -1 &&
          action.payload.res;

        let blob_to_pass = imageSpecific ? action.payload.res : null;
        try {
          blob_to_pass = blob_assigned
            ? `${blob_assigned}${currentDate.getTime()}`
            : `${window.URL.createObjectURL(
                action.payload.res
              )}#timeStamp=${currentDate.getTime()}`;
        } catch (e) {
          console.log(e);
        }

        downloadedFiles[action.payload.url] = blob_to_pass;

        recordingSlides.push({
          url: action.payload.url,
          label: action.payload.label,
          duration: action.payload.duration,
          durationFormatted: action.payload.durationFormatted,
          blob: blob_to_pass,
          imageSpecific
        });
      }

      return {
        ...state,
        downloadedFiles,
        downloadProgress: 0,
        recordingSlides
      };
    }

    case ACTIONS.DOWNLOAD_FILE_ERROR:
      return {
        ...state,
        url: '',
        status: STATUS.ERROR
      };

    // Recording state
    case ACTIONS.START_RECORDING:
      return {
        ...state,
        isRecording: true,
        timeElapsed: 0
      };

    case ACTIONS.PAUSE_RECORDING:
      return {
        ...state,
        isPaused: true,
        isRecording: false
      };

    case ACTIONS.RESUME_RECORDING:
      return {
        ...state,
        isPaused: false,
        isRecording: true
      };

    case ACTIONS.STOP_RECORDING: {
      const recordedFile = action.payload.video.blob;
      const previewUrl = action.payload.video.url;

      return {
        ...state,
        isRecording: false,
        recordedFile,
        previewUrl
      };
    }

    case ACTIONS.DISCARD_RECORDING:
      URL.revokeObjectURL(state.previewUrl);
      return {
        ...state,
        recordedFile: null,
        previewUrl: '',
        isRecording: false,
        timeElapsed: 0
      };

    case ACTIONS.TIME_ELAPSED:
      return {
        ...state,
        timeElapsed: state.timeElapsed + 1
      };

    case ACTIONS.UPLOAD_COMPLETED: {
      if (action.payload.isOriginal) {
        return { ...state, url: '' };
      } else {
        return state;
      }
    }
    default:
      return state;
  }
};
