import React from 'react';
import { RequesterContext } from '../../context/requester.context';
import './noItems.scss';

const NoItems = ({
  className = '',
  icon = <></>,
  title = '',
  message = ''
}) => (
  <RequesterContext.Consumer>
    {requester => (
      <div className={`container-of-empty-section ${className} ${((requester.activeGroup.pagesObject['programs'] || {}).settings || {}).theme || 'light'}-theme`}>
        {icon}
        <h2>{title}</h2>
        <p>{message}</p>
      </div>
    )}
  </RequesterContext.Consumer>
);

export default NoItems;