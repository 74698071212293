const ACTIONS = {
  IN_PROGRESS: 'IN_PROGRESS',
  RESET_STATUS: 'RESET_STATUS',
  ADD_TOAST: 'ADD_TOAST',
  ON_DISMISS_TOAST: 'ON_DISMISS_TOAST',

  // UPLOAD
  UPDATE_UPLOAD_TITLE: 'UPDATE_UPLOAD_TITLE',
  UPDATE_HUDL_URL: 'UPDATE_HUDL_URL',
  UPDATE_UPLOAD_TYPE: 'UPDATE_UPLOAD_TYPE',
  UPDATE_UPLOAD_FILE: 'UPDATE_UPLOAD_FILE',

  // Dialog
  SHOW_DIALOG: 'SHOW_DIALOG',
  HIDE_DIALOG: 'HIDE_DIALOG',

  UPLOAD_IN_PROGRESS: 'UPLOAD_IN_PROGRESS',
  UPLOAD_PROGRESS: 'UPLOAD_PROGRESS',
  UPLOAD_COMPLETED: 'UPLOAD_COMPLETED',
  UPLOAD_ERROR: 'UPLOAD_ERROR',

  // Voiceover
  DOWNLOAD_PROGRESS: 'DOWNLOAD_PROGRESS',
  DOWNLOAD_FILE_IN_PROGRESS: 'DOWNLOAD_FILE_IN_PROGRESS',
  DOWNLOAD_FILE_COMPLETED: 'DOWNLOAD_FILE_COMPLETED',
  DOWNLOAD_FILE_COMPLETED_V2: 'DOWNLOAD_FILE_COMPLETED_V2',
  DOWNLOAD_FILE_ERROR: 'DOWNLOAD_FILE_ERROR',
  SET_PRIMARY_VIDEO: 'SET_PRIMARY_VIDEO',
  SET_RIGHT_VIDEO: 'SET_RIGHT_VIDEO',
  REMOVE_RIGHT_VIDEO: 'REMOVE_RIGHT_VIDEO',
  CHANGE_BLOB: 'CHANGE_BLOB',

  ACTIVATE_RECORDING_QUEUE: 'ACTIVATE_RECORDING_QUEUE',
  DEACTIVATE_RECORDING_QUEUE: 'DEACTIVATE_RECORDING_QUEUE',
  REMOVE_VIDEO_FROM_QUEUE: 'REMOVE_VIDEO_FROM_QUEUE',
  UPDATE_RECORDING_QUEUE: 'UPDATE_RECORDING_QUEUE',
  ADD_TO_RECORDING_QUEUE: 'ADD_TO_RECORDING_QUEUE',

  RESET_STATE: 'RESET_STATE',
  START_RECORDING: 'START_RECORDING',
  STOP_RECORDING: 'STOP_RECORDING',
  PAUSE_RECORDING: 'PAUSE_RECORDING',
  RESUME_RECORDING: 'RESUME_RECORDING',
  DISCARD_RECORDING: 'DISCARD_RECORDING',
  TIME_ELAPSED: 'TIME_ELAPSED',

  UPDATE_STATE: 'UPDATE_STATE'
};

export default ACTIONS;
