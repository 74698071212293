import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { TextareaAutosize } from '@material-ui/core';
import { updateFormState } from '../../../../../../../../actions/form.actions';

class BlockFormTextArea extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {};
  }

  render() {
    const { block, formState, updateFormState } = this.props;
    const { id, label, title, required = 1 } = block;
    const { formResponses = {}, reducedRequired = {} } = formState;
    const currentValue = (formResponses[id] || {}).text || '';

    return <>
      <div  className={`general-form-block-render-container textarea-type`}>      
        {reducedRequired[block.id] ? <div className="invalid-currently-dignifier">Required*</div> : <></>}
        
        <TextareaAutosize
          id={`label-render-${block.id}`}
          className={`label-specific`}
          maxLength={500}
          placeholder={`${label || title || "Enter label here..."}${required ? '*' : ''}`}
          onChange={e => updateFormState({
            ...reducedRequired[block.id] && { reducedRequired: { ...reducedRequired, [block.id]: undefined } },
            formResponses: {
              ...formResponses,
              [block.id]: { text: e.target.value }
            }              
          })}
          value={currentValue}
        />       
      </div>
    </>;
  }
};

const mapStateToProps = state => ({
  formState: state.formState
});

const mapDispatchToProps = dispatch => ({
  updateFormState: updatedStates => dispatch(updateFormState(updatedStates))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(BlockFormTextArea);
