import React, { memo } from 'react';
import GeneralConfirmation from '../../Shared/GeneralConfirmation';

const ActionLoader = ({ deactivatingTag, showDeleteConfirmation, onDeleteConfirm, updateShowDeleteConfirmation }) => (
  showDeleteConfirmation && (
    <GeneralConfirmation
      question={`Are you sure you want to delete "${showDeleteConfirmation.label}"?`}
      loading={deactivatingTag ? true : false}
      onConfirm={onDeleteConfirm}
      onCancel={() => updateShowDeleteConfirmation(false)}
      confirmText={'Yes, delete'}
      cancelText={'No'}
      {...(showDeleteConfirmation.isParent && {
        message: 'All subtags will be removed as well'
      })}
    />
  )
);

export default memo(ActionLoader);
