import React, { memo } from 'react';
import Loadable from 'react-loadable';
import { Route, Routes, Navigate } from 'react-router-dom';
import LogoLoader from '../../components/Shared/LogoLoader';

const Terms = Loadable({
  loader: () => import('../../containers/Legal/Terms'),
  loading: () => <LogoLoader />
});

const OrgCreation = Loadable({
  loader: () => import('../../containers/Public/OrgCreation'),
  loading: () => <LogoLoader theme="light" />
});

const Privacy = Loadable({
  loader: () => import('../../containers/Legal/Privacy'),
  loading: () => <LogoLoader />
});

const PublicParentMapper = Loadable({
  loader: () => import('../../containers/Public/ParentMapper'),
  loading: () => <LogoLoader />
});

const PublicProgramViewer = Loadable({
  loader: () => import('../../containers/Public/Programs/Single'),
  loading: () => <LogoLoader />
});

const PublicPackageViewer = Loadable({
  loader: () => import('../../containers/Public/Packages/Single'),
  loading: () => <LogoLoader />
});

const PublicAccessCodeViewer = Loadable({
  loader: () => import('../../containers/Public/Packages/AccessCode'),
  loading: () => <LogoLoader />
});

const prefix = '/:tenantCode/:groupCode';

const basicRoutes = ({ redirectPrefix = false }) => (
  <Routes>
    <Route exact path="terms" element={<Terms />} />
    <Route exact path="privacy" element={<Privacy />} />

    <Route exact path={`create`} element={<OrgCreation />} />

    <Route
      exact
      path={`${prefix}/access-code`}
      element={<PublicAccessCodeViewer />}
    />

    <Route
      exact
      path={`${prefix}/programs/:programId`}
      element={<PublicProgramViewer />}
    />

    <Route
      exact
      path={`${prefix}/products/:packageId`}
      element={<PublicPackageViewer />}
    />

    <Route
      exact
      path={`${prefix}/packages/:packageId`}
      element={<PublicPackageViewer />}
    />

    <Route
      exact
      path={`${prefix}/join/:packageId`}
      element={<PublicPackageViewer />}
    />

    <Route exact path={`${prefix}/*`} element={<PublicParentMapper />} />
    
    <Route
      path="*"
      render={<Navigate to={redirectPrefix ? `${redirectPrefix}/programs` : `/`} />}
    />
  </Routes>
);

export default memo(basicRoutes);