import React, { useState } from 'react';
import replaceall from 'replaceall';
import { ApolloConsumer } from 'react-apollo';
import { useNavigate } from 'react-router-dom';
import GeneralConfirmation from '../../../Shared/GeneralConfirmation';
import ActionCenter from '../../WebsiteApp/Dashboard/ActionCenter';
import ProfileButtonFeed from '../../../Public/Header/ProfileSection/LoggedInMapper/ProfileButtonFeed';
import { tenantPrefixUrlDeterminant } from '../../../../actions/user.actions';

const hostName = window.location.hostname.toLowerCase();
const hostNameSlugToUse = replaceall('.', '-', hostName);
const tokenName = `${hostNameSlugToUse}-auth-token-v3-12-13-21`;

const AdminHeader = props => {
  const { requester } = props;
  
  const navigate = useNavigate();
  
  const [ showConfirmation, showConfirmationInit ] = useState(false);
  
  return (
    <section id="top-locked-admin-header">
      <img
        id="logo-to-the-left"
        src="/images/dark_horiz_logo_small.png"
        alt="CoachIQ Logo"
      />
      
      <div id="container-of-the-right-side-of-header">
        <ActionCenter requester={requester} />
        <button 
          className="media-link"
          onClick={() => navigate(`${tenantPrefixUrlDeterminant(
            requester.activeGroup.tenant.code,
            requester.activeGroup.code
          )}/admin/media`)}
        >
          <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M7.33496 11.5L11.665 9L7.33496 6.5V11.5Z" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M5.25015 1.50007H12.7501C14.8168 1.50007 16.4918 3.17756 16.4918 5.24673V12.7467C16.4918 14.8134 14.8085 16.4884 12.7418 16.4884H5.23348C3.15848 16.4884 1.48682 14.8051 1.48682 12.7384V5.2334C1.48682 3.1584 3.16182 1.4834 5.23682 1.4834L5.25015 1.50007Z" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
          </svg>
          All Media
        </button>
        <div id="container-of-avatar-and-dropdown">
          <div className="avatar-and-name">
            <ProfileButtonFeed updateIntercom={true} />
          </div>

          <div className="drop-down-container-for-nav-hover">
            <a
              onClick={() => navigate(`${tenantPrefixUrlDeterminant(
                requester.activeGroup.tenant.code,
                requester.activeGroup.code
              )}/admin/settings`)}
            >
              <svg
                width="14"
                height="14"
                viewBox="0 0 14 14"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M8.27275 5.72716C8.97569 6.43011 8.97569 7.5698 8.27275 8.27275C7.5698 8.97569 6.43011 8.97569 5.72716 8.27275C5.02421 7.5698 5.02421 6.43011 5.72716 5.72716C6.43011 5.02421 7.5698 5.02421 8.27275 5.72716"
                  stroke="black"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                ></path>
                <path
                  d="M2.49999 7C2.49999 7.198 2.51799 7.396 2.54199 7.588L1.48333 8.416C1.24866 8.6 1.18466 8.92867 1.33399 9.18667L2.27533 10.8153C2.42399 11.0733 2.73999 11.182 3.01666 11.0713L3.96466 10.6907C4.15199 10.6153 4.36066 10.6453 4.52866 10.7567C4.67533 10.854 4.82733 10.9433 4.98466 11.0233C5.16466 11.1147 5.29533 11.278 5.32399 11.478L5.46866 12.4867C5.51066 12.7813 5.76333 13 6.06066 13H7.93866C8.23599 13 8.48866 12.7813 8.53066 12.4867L8.67533 11.4787C8.70399 11.2787 8.83599 11.114 9.01666 11.0233C9.17333 10.9447 9.32466 10.856 9.47066 10.7593C9.63999 10.6473 9.84933 10.6153 10.0373 10.6913L10.9833 11.0713C11.2593 11.182 11.5753 11.0733 11.7247 10.8153L12.666 9.18667C12.8153 8.92867 12.7513 8.59933 12.5167 8.416L11.458 7.588C11.482 7.396 11.5 7.198 11.5 7C11.5 6.802 11.482 6.604 11.458 6.412L12.5167 5.584C12.7513 5.4 12.8153 5.07133 12.666 4.81333L11.7247 3.18467C11.576 2.92667 11.26 2.818 10.9833 2.92867L10.0373 3.30867C9.84933 3.384 9.63999 3.35267 9.47066 3.24067C9.32466 3.144 9.17333 3.05533 9.01666 2.97667C8.83599 2.886 8.70399 2.72133 8.67533 2.52133L8.53133 1.51333C8.48933 1.21867 8.23666 1 7.93933 1H6.06133C5.76399 1 5.51133 1.21867 5.46933 1.51333L5.32399 2.52267C5.29533 2.722 5.16399 2.886 4.98466 2.97733C4.82733 3.05733 4.67533 3.14733 4.52866 3.244C4.35999 3.35467 4.15133 3.38467 3.96399 3.30933L3.01666 2.92867C2.73999 2.818 2.42399 2.92667 2.27533 3.18467L1.33399 4.81333C1.18466 5.07133 1.24866 5.40067 1.48333 5.584L2.54199 6.412C2.51799 6.604 2.49999 6.802 2.49999 7V7Z"
                  stroke="black"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                ></path>
              </svg>
              Settings
            </a>
            <ApolloConsumer>
              {client => (
                <>
                  <button
                    className="log-out-specific"
                    onClick={() => showConfirmationInit(true)}
                  >
                    <svg
                      width="24"
                      height="31"
                      viewBox="0 0 24 31"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M6 12.5V8C6 4.685 8.685 2 12 2C15.3 2 18 4.685 18 8V12.5"
                        stroke="black"
                        strokeWidth="3"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M12 18.5V23"
                        stroke="black"
                        strokeWidth="3"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M19.5 29H4.5C2.835 29 1.5 27.65 1.5 26V15.5C1.5 13.835 2.835 12.5 4.5 12.5H19.5C21.15 12.5 22.5 13.835 22.5 15.5V26C22.5 27.65 21.15 29 19.5 29Z"
                        stroke="black"
                        strokeWidth="3"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                    Log Out
                  </button>
                  {showConfirmation && (
                    <GeneralConfirmation
                      question={`Are you sure?`}
                      onConfirm={() => {
                        localStorage.removeItem(tokenName);
                        client.resetStore();
                        window.location = '/login';

                        showConfirmationInit(false);
                      }}
                      onCancel={() => showConfirmationInit(false)}
                      confirmText={'Yes, log out'}
                      cancelText={'No'}
                    />
                  )}
                </>
              )}
            </ApolloConsumer>
          </div>
        </div>
      </div>
    </section>
  );
};

export default AdminHeader;
