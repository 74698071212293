import React, { memo, useMemo } from 'react';
import InfoSection from './Main/InfoSection';

const Main = props => {
  const { requester, activeItem, updatedTotal } = props;
    
  const mappedPackage = useMemo(() => ({ ...activeItem, features: (activeItem.features && typeof activeItem.features === 'string') ? JSON.parse(activeItem.features) : activeItem.features, price: updatedTotal || activeItem.price  }), [updatedTotal, activeItem]);

  return (
    <InfoSection requester={requester} packageItem={mappedPackage} />
  );
};

export default memo(Main);
