import React, { memo, useCallback, useMemo } from 'react';
import { useNavigate } from 'react-router';
import { tenantPrefixUrlDeterminant } from '../../../../actions/user.actions';

const MobileActionCenter = props => {
  const {
    requester,
    updateProgramState,
    independantMedia,
    passedPaywall,
    currentPrimaryHex,
    nextCheckpoint,
    showSingleLesson = {}
  } = props;  

  const navigate = useNavigate();

  const { nextCheckpoint_auth, nextShowSingleLesson } = useMemo(() => {
    const nextCheckpoint_auth = !independantMedia && nextCheckpoint && (nextCheckpoint.series !== 'drip' || nextCheckpoint.dripAuth > 0);

    const nextShowSingleLesson = nextCheckpoint_auth && {
      preview: showSingleLesson.preview,
      complete: nextCheckpoint.complete,
      ...nextCheckpoint.lesson
      ? {
          ...nextCheckpoint,
          ...nextCheckpoint.lesson,
          index: nextCheckpoint.index
        }
      : nextCheckpoint,
      storiesBased: showSingleLesson.storiesBased
    };

    return { nextCheckpoint_auth, nextShowSingleLesson };
  }, [independantMedia, nextCheckpoint, showSingleLesson]);
  
  const onNext = useCallback(() => (
    passedPaywall || nextCheckpoint.free
      ? updateProgramState({
          singleLesson: undefined,
          showSingleLesson: nextShowSingleLesson
        })
      : updateProgramState({
          singleLesson: undefined,
          showSingleLesson: undefined,
          showProgramPurchase: {
            selectedPackage: false,
            checkpoint: {
              showSingleLesson: nextShowSingleLesson
            }
          }
      })
  ), []);

  const buttonStyle = useMemo(() => ({ backgroundColor: currentPrimaryHex }), [currentPrimaryHex]);

  const onBackToPrograms = useCallback(() => {
    updateProgramState({ 
      program: undefined, 
      singleLesson: undefined,
      showSingleLesson: undefined,
      showProgramPurchase: undefined
    });

    navigate(`${tenantPrefixUrlDeterminant(requester.activeGroup.tenant.code, requester.activeGroup.code)}/programs`);
  }, []);

  return (
    <div className="info-for-mobile-media-specific show-when-mobile-enacted">
      <div className="header-of-mobile-single-lesson">
        <p>Lesson {showSingleLesson.index + 1}</p>
        <h2>{showSingleLesson.label || 'Media'}</h2>
      </div>

      {nextCheckpoint_auth ? (
        <button
          style={buttonStyle}
          onClick={onNext}
        >
          Next Lesson
        </button>
      ) : (
        <button
          style={buttonStyle}
          className={"back-to-programs"}
          onClick={onBackToPrograms}
        >
          Back to Programs
        </button>
      )}
    </div>
  );
};

export default memo(MobileActionCenter);
