import React, { memo, useMemo } from 'react';
import ChildTagsMapper from './ChildTagsMapper';

const ParentTagRender = ({ origin, is_active, untaggedAndDbConditional, onChildDeleteInit, itemsSelected, categoryLocal, assignSpecific, onChildTagClick, onTagDeleteClick, onParentTagClick, childTags_Reduced }) => {
  const relativityClassName = useMemo(() => `for-relativity ${childTags_Reduced[categoryLocal.id] && 'children-exists'}`, [categoryLocal.id, childTags_Reduced]);

  const parentTagImmediateClassName = useMemo(() => `gallery-tag-immediate ${(categoryLocal.id ? is_active : untaggedAndDbConditional) && 'active-selection'}`, [categoryLocal.id, is_active, untaggedAndDbConditional]);

  const deleteRender = useMemo(
    () => !assignSpecific && categoryLocal.id && categoryLocal.id !== 'a6fa2c2e-5beb-4bd9-ba3b-5516ea06bac1' && (
      <button
        onClick={() => onTagDeleteClick(categoryLocal, childTags_Reduced[categoryLocal.id] ? true : false)}
        className="tag-delete"
      >
        {deleteIcon}
      </button>
    ), [assignSpecific, categoryLocal, childTags_Reduced]
  );

  const activeDignifierRender = useMemo(
    () => !assignSpecific && childTags_Reduced[categoryLocal.id] && (
      <div
        className={`${is_active && 'is-active'} parent-arrow-dignfier`}
      >
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M8 10L12 14L16 10"
            stroke="#666666"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          ></path>
        </svg>
      </div>
    ), [assignSpecific, is_active, categoryLocal.id, childTags_Reduced]
  );

  return <>
    <div className="tag-container">
      <div className={relativityClassName}>
        <button
          className={parentTagImmediateClassName}
          onClick={() => onParentTagClick(categoryLocal, is_active)}
        >
          {categoryLocal.label || 'Untagged'}
          {(assignSpecific && categoryLocal.label) && (
            <span className="check-mark-to-right">
              <svg
                width="10"
                height="8"
                viewBox="0 0 10 8"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M9 1.33325L3.5 6.66659L1 4.24234"
                  stroke="#0292FF"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </span>
          )}
        </button>
        {deleteRender}
        {activeDignifierRender}
      </div>
      <ChildTagsMapper
        origin={origin}
        is_active={is_active}
        childTags_Reduced={childTags_Reduced}
        assignSpecific={assignSpecific}
        itemsSelected={itemsSelected}
        categoryLocal={categoryLocal}
        onChildTagClick={onChildTagClick}
        onChildDeleteInit={onChildDeleteInit}
        untaggedAndDbConditional={untaggedAndDbConditional}
      />
    </div>
  </>;
};

const deleteIcon = (
  <svg
    width="12"
    height="14"
    viewBox="0 0 12 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M8.35991 12.9999H3.63324C2.84657 12.9932 2.19324 12.3932 2.13324 11.6132L1.48657 3.24658H10.4866L9.83991 11.6066V11.5999C9.77324 12.3799 9.12657 12.9799 8.33991 12.9799L8.35991 12.9999Z"
      stroke="#EC4C4C"
      strokeLinecap="round"
      strokeLinejoin="round"
    ></path>
    <path
      d="M11.3333 3.25342H0.666626"
      stroke="#EC4C4C"
      strokeLinecap="round"
      strokeLinejoin="round"
    ></path>
    <path
      d="M4.11999 1.00015H7.86999H7.86332C8.27665 0.993483 8.60999 1.33348 8.60999 1.74682V3.24682H3.35999V1.74682V1.74015C3.35999 1.32015 3.69332 0.986816 4.10665 0.986816L4.11999 1.00015Z"
      stroke="#EC4C4C"
      strokeLinecap="round"
      strokeLinejoin="round"
    ></path>
  </svg>
);

export default memo(ParentTagRender);
