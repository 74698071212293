import React, { memo, useContext } from 'react';
import { useQuery } from 'react-apollo';
import MainFrame from '../../../../../../Public/PurchaseEmbed/MainFrame';
import { packageQuery } from '../../../../../../../apollo.queries';
import { RequesterContext } from '../../../../../../../context/requester.context';

const FormProductRender = ({ id, activeGroup, lightTheme, buttonColor, destination, destinationUrl, buttonTextColor, formId, firstPageSuccess, secondPageSuccess, formBlockGuestId }) => {
  const requester = useContext(RequesterContext);

  const defaultVar = { groupId: activeGroup ? activeGroup.id : requester.activeGroup.id, packageId: id };

  const Results_CacheOnly = useQuery(
    packageQuery,
    { variables: defaultVar, fetchPolicy: "cache-only" }
  );

  /****/

  const Results = useQuery(
    packageQuery,
    { variables: defaultVar, fetchPolicy: "network-only"  }
  );

  const data = (Results.data || {}).formFieldTemplates || (Results_CacheOnly.data || {}).packageQuery || { loading: true };

  return (
    <MainFrame 
      formId={formId}
      activeItem={data} 
      lightTheme={lightTheme}
      destinationUrl={destinationUrl}
      destination={destination}
      buttonColor={buttonColor} 
      buttonTextColor={buttonTextColor} 
      formBlockGuestId={formBlockGuestId}
      firstPageSuccess={firstPageSuccess}
      secondPageSuccess={secondPageSuccess}
      requester={(
        activeGroup 
          ? { 
              activeGroup, 
              currencyPrefix: (activeGroup.tenant.currency && (
                activeGroup.tenant.currency === 'usd' || activeGroup.tenant.currency === 'cad'
                  ? '$'
                  : activeGroup.tenant.currency === 'gbp'
                    ? '£'
                    : activeGroup.tenant.currency === 'eur' && '€'
              )) || '$' 
            } 
          : requester
      )} 
    />
  );
};

export default memo(FormProductRender);