import React, { memo, useCallback, useMemo } from 'react';
import Slider from '@material-ui/core/Slider';

const VideoCustomControls = props => {
  const {
    currentDuration = 0,
    currentProgress = 0,
    currentTime = 0,
    videoPaused,
    isMuted,
    onMute,
    onPlay,
    hideExpand,
    onPause,
    onProgress,
    onFullScreen
  } = props;

  const parseIntInit = useCallback(parseInt, []);

  const current_time_seconds = parseIntInit(currentTime % 60);

  const overlayRender = useMemo(() => videoPaused && <div onClick={onPlay} className="black-overlay-and-play-init"></div>, [videoPaused]);

  const onRewind = useCallback(() => onProgress(currentTime - 5), [currentTime]);
  const onForward = useCallback(() => onProgress(currentTime + 5), [currentTime]);

  const playPauseRender = useMemo(() => videoPaused ? (
    <button onClick={onPlay} className="pause">
      <img src="/images/course-builder/icons/play-white.png" />
    </button>
  ) : (
    <button onClick={onPause} className="pause">
      <img src="/images/course-builder/icons/video-controls/pause.png" />
    </button>
  ), [videoPaused]);

  const mutedSrc = useMemo(() => `/images/course-builder/icons/video-controls/volume_${isMuted ? 'off' : 'on'}.png`, [isMuted]);

  const durationRender = useMemo(() => `${parseIntInit(currentTime / 60)}:${current_time_seconds < 10 ? `0${current_time_seconds}` : current_time_seconds}`, [current_time_seconds, currentTime])

  const onSliderChange = useCallback((_ , newValue) => onProgress(newValue * currentDuration), [currentDuration]);

  const fullScreenRender = useMemo(() => !hideExpand && (
    <div className="right-of-scrollbar">
      <button
        onClick={onFullScreen}
        className="init-full-screen-media-block"
      >
        <img src="/images/course-builder/icons/video-controls/fullscreen.png" />
      </button>
    </div>
  ), [hideExpand]);

  return (
    <div className="custom-video-controls-component-container">
      {overlayRender}
      <div className="immediate-container-of-video-controls centered-centered swiper-no-swiping">
        <button onClick={onRewind} className="rewind">
          <img src="/images/course-builder/icons/video-controls/rewind.png" />
        </button>
        {playPauseRender}
        <button onClick={onForward} className="fastforward">
          <img src="/images/course-builder/icons/video-controls/fastforward.png" />
        </button>
      </div>
      <div className="immediate-container-of-video-controls top-right swiper-no-swiping">
        <button onClick={onMute} className="pause">
          <img src={mutedSrc} />
        </button>
      </div>
      <div className="immediate-container-of-video-controls centered-bottom swiper-no-swiping">
        <div className="left-of-scrollbar">
          <p className="duration">{durationRender}</p>
        </div>
        <div className="scrollbar-container">
          <Slider
            className="progress-slider-specific"
            orientation="horizontal"
            value={currentProgress}
            onChange={onSliderChange}
            min={0}
            step={0.01}
            max={1}
            defaultValue={0}
            aria-labelledby="horizontal-slider"
          />
        </div>
        {fullScreenRender}
      </div>
    </div>
  );
};

export default memo(VideoCustomControls);