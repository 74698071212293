import React from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import './generalConfirmation.scss';

const GeneralConfirmation = ({ loading, question, message, confirmText, cancelText, onConfirm, onCancel }) => <>
  {ReactDOM.createPortal(
    <div className={`general-confirmation-outer-container`}>
      <div className="bg-for-close" onClick={onCancel}></div>
      {
        loading
          ? <section className="appLoading">
              <svg
                className="simple-line-loader-for-app-loading css-primary-hex-stroke"
                viewBox="0 0 50 50"
              >
                <circle
                  className="path css-primary-hex-stroke"
                  cx="25"
                  cy="25"
                  r="20"
                  fill="none"
                  strokeWidth="5"
                ></circle>
              </svg>
            </section>
          : <div className="immediate-white-inner">
              <h2>{question}</h2>
              {message && <p>{message}</p>}
              <div className="confirm-and-cancel-buttons">
                <button onClick={onCancel}>{cancelText || 'No'}</button>
                <button onClick={onConfirm}>{confirmText || 'Yes'}</button>
              </div>
            </div>
      }
    </div>
    , document.getElementById('outsideModalPortal-z2')
  )}
</>;

export default GeneralConfirmation;
