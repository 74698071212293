import gql from 'graphql-tag';

/*** New Schema ***/

export const rootSMS_parentMapper = gql`
  query rootSMS_parentMapper {
    rootSMS_parentMapper {
      dlc10Status
      smsNumber
    }
  }
`;

export const twilio10DLC_status = gql`
  query twilio10DLC_status {
    twilio10DLC_status {
      id
      
      business_type
      usage_level

      brandRegistration_status
      campaignSubmission_status
      customerProfileSubmission_status
      trustBundleSubmission_status

      messagingService_exists
      trustBundleEndUser_exists
      customerProfileAssignment_exists
      customerProfileAddress_exists
      customerDocument_exists
      customerProfile_exists
      businessProfile_exists
      authorizedRep_exists
      trustBundle_exists
    }
  }
`;

export const twilio_10dlc_evaluateCustomerProfileSubmission = gql`
  query twilio_10dlc_evaluateCustomerProfileSubmission {
    twilio_10dlc_evaluateCustomerProfileSubmission
  }
`;

export const twilio_10dlc_evaluateTrustBundleSubmission = gql`
  query twilio_10dlc_evaluateTrustBundleSubmission {
    twilio_10dlc_evaluateTrustBundleSubmission
  }
`;

export const twilio_10dlc_fetchBrandRegistrationStatus = gql`
  query twilio_10dlc_fetchBrandRegistrationStatus {
    twilio_10dlc_fetchBrandRegistrationStatus
  }
`;

export const getAccessCodeUse = gql`
  query getAccessCodeUse($id: ID!){
    getAccessCodeUse(id: $id){
      id
      createdAt
      profile {
        id
        user {
          id
          email
          avatar
          firstName
          lastName
        }
      }
    }
  }
`;

export const getSectionMedia = gql`
  query getSectionMedia($tags: [ID]!){
    getSectionMedia(tags: $tags){
      id
      createdAt
      label
      url
    }
  }
`;

export const rootWebsite = gql`
  query rootWebsite($hostname: String, $groupId: ID){
    rootWebsite(hostname: $hostname, groupId: $groupId){
      id
      type
      theme
      primaryHex
      primaryHex_textColor
      enabled      
      group {
        id
        name
        code
      }
      org {
        id
        name
        code
        logo
      }
      header {
        id
        bg
        logo
        navButtons {
          id
          asset
          type
          label
          disabled
          isImmutable
        }
        navLinks {
          id
          asset
          type
          label
          disabled
          isImmutable
        }
      }
      footer {
        id
        bg
        logo
        navLabel
        description        
        nav {
          id
          asset
          type
          label
          disabled
        }
        social {
          id
          f
          t
          i
          tt
          y
        }
        contact {
          id
          phone
          email
          address
        }
      }
      page {
        id
        type
        meta { 
          id
          title
          description
          keywords
        }
        sections {
          id
          bg
          type
          bgSrc
          bgColor
          displayLabel
          contentMedia {
            id
            reverse
            disabled
            src
            poster
            sub
            header
            description
            buttons {
              id
              asset
              type
              label
              disabled
              disabledLabel
            }
          }
          stats {
            id
            number
            description
          }
          cta {
            id
            sub
            header
            type
            disabled
            media {
              id
              label
              url
            }
            testimonials {
              id
              name
              title
              testimonial
            }
            features {
              id
              label
              description
            }
            products {
              id
              url
              label
              custom
              features
              useProduct
              hidePrice
              lType
              buttonText
              product {
                id
                label
                priceText
                recurringText
                features
              }
            }
            steps {
              id
              label
              description
            }
            tags {
              id
              tag {
                id
                label
              }
            }
            instagram {
              id
            }
            form {
              id
              form {
                id
                label
                settings
                buttonText
                createdAt
                formBlocks {
                  id
                  index
                  type
                  label
                  media {
                    id
                    url
                    label
                  }
                  choices {
                    id
                    index
                    label
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

export const getCustomDomainRequests = gql`
  query getCustomDomainRequests($active: Boolean){
    getCustomDomainRequests(active: $active) {
      id
      domain
    }
  }
`;

export const isWebsiteEnabled = gql`
  query isWebsiteEnabled($groupId: ID){
    isWebsiteEnabled(groupId: $groupId)
  }
`;

export const MasterSavedCards_Active = gql`
  query {
    MasterSavedCards_Active {
      defaultSource
      sources {
        id
        brand
        last4
      }
    }
  }
`;

export const MasterPurchases_Active = gql`
  query {
    MasterPurchases_Active {
      id
      label
      createdAt
      isSingle
      amountText
      defaultSource
    }
  }
`;

export const Public_MasterProducts_Single = gql`
  query($id: ID){
    Public_MasterProducts_Single(id: $id){
      id
      label
      features
      priceText
      description
      recurringText
      confirmationUrl
      confirmationPage
    }
  }
`;

/** Custom Tools **/
export const exportProgramAnswersToJSON = gql`
  query exportProgramAnswersToJSON($start: String!, $end: String!) {
    exportProgramAnswersToJSON(start: $start, end: $end) {
      id
      name
      profiles {
        id
        displayName
        programs {
          id
          title
          responses {
            id
            createdAt
            type
            contentShort
            response
            answer
            actualAnswer
          }
        }
      }
    }
  }
`;

export const generateLesson_OPENAI = gql`
  query($description: String!) {
    generateLesson_OPENAI(description: $description) {
      lessonTitle
      ideas {
        title
        image_description
        idea_summary
      }
    }
  }
`;

/** Dashboard **/

export const activityFeed_UserUploads_Admin = gql`
  query($profileId: ID, $groupId: ID!, $limit: Int!, $page: Int!, $timeFilter: String) {
    activityFeed_UserUploads_Admin(profileId: $profileId, groupId: $groupId, limit: $limit, page: $page, timeFilter: $timeFilter) {
      id
      url
      label
      fileInfo
      createdAt
      profileId
      groupId
      profile {
        id
        user {
          id
          avatar
          firstName
          lastName
          email
        }
      }
    }
  }
`;

export const activityFeed_NewUsers_Admin = gql`
  query($groupId: ID!, $limit: Int!, $page: Int!, $timeFilter: String) {
    activityFeed_NewUsers_Admin(groupId: $groupId, limit: $limit, page: $page, timeFilter: $timeFilter) {
      id
      createdAt
      user {
        id
        email
        firstName
        lastName
        avatar        
      }
    }
  }
`;

export const activityFeed_Forms_Admin = gql`
  query($profileId: ID, $groupId: ID!, $limit: Int!, $page: Int!, $timeFilter: String) {
    activityFeed_Forms_Admin(profileId: $profileId, groupId: $groupId, limit: $limit, page: $page, timeFilter: $timeFilter) {
      id      
      formId
      formTitle
      createdAt
      formResponseId
      purchases {
        id
        createdAt
        updatedAt
        amount
        coupon
        stripeStatus
      }
      formProfile {
        id
        user {
          id
          email
          firstName
          lastName
        }
      }
    }
  }
`;

export const activityFeed_ProgramCompletions_Admin = gql`
  query($profileId: ID, $groupId: ID!, $limit: Int!, $page: Int!, $timeFilter: String) {
    activityFeed_ProgramCompletions_Admin(profileId: $profileId, groupId: $groupId, limit: $limit, page: $page, timeFilter: $timeFilter) {
      id      
      views
      createdAt
      programId
      programTitle
      assetId
      assetUrl
      assetType
      assetTitle
      otherViews {
        id     
        views
        createdAt
        programId
        programTitle
        assetId
        assetUrl
        assetType
        assetTitle
      }
      profile {
        id
        user {
          id
          email
          firstName
          lastName
        }
      }
    }
  }
`;

export const activityFeed_ProgramResponses_Admin = gql`
  query($profileId: ID, $groupId: ID!, $limit: Int!, $page: Int!, $timeFilter: String) {
    activityFeed_ProgramResponses_Admin(profileId: $profileId, groupId: $groupId, limit: $limit, page: $page, timeFilter: $timeFilter) {
      id      
      createdAt
      programId
      programTitle
      lessonId
      lessonLabel
      question      
      response
      profile {
        id
        user {
          id
          email
          firstName
          lastName
        }
      }
    }
  }
`;

export const activityFeed_Orders_Admin = gql`
  query($profileId: ID, $groupId: ID!, $limit: Int!, $page: Int!, $timeFilter: String) {
    activityFeed_Orders_Admin(profileId: $profileId, groupId: $groupId, limit: $limit, page: $page, timeFilter: $timeFilter) {
      id      
      amount
      coupon
      createdAt
      package {
        id
        label
        recurring
        interval
        intervalCount
      }
      profile {
        id
        user {
          id
          email
          firstName
          lastName
          avatar
        }
      }
    }
  }
`;

export const adminStatsFeed = gql`
  query($groupId: ID!, $timeFilter: String) {
    adminStatsFeed(groupId: $groupId, timeFilter: $timeFilter) {
      userCount
      salesTotal
      completedCheckpoints

      userCount_difference
      salesTotal_difference
      completedCheckpoints_difference
    }
  }
`;

/** Instagram Specific **/

export const instagramStories = gql`
  query($username: String, $next: String) {
    instagramStories(username: $username, next: $next) {
      next
      user {
        id
        username
      }
      stories {
        id
        caption
        media_url
        media_type
        thumbnail_url
        children {
          id
          media_url
          media_type
          thumbnail_url
        }
      }
    }
  }
`;

export const instagramMediaGallery = gql`
  query($username: String, $next: String) {
    instagramMediaGallery(username: $username, next: $next) {
      next
      user {
        id
        username
      }
      media {
        id
        caption
        media_url
        media_type
        thumbnail_url
        children {
          id
          media_url
          media_type
          thumbnail_url
        }
      }
    }
  }
`;

export const instagramAlbum = gql`
  query($id: ID!) {
    instagramAlbum(id: $id) {
      id
      caption
      media_url
      media_type
      thumbnail_url
    }
  }
`;


/** One On One Specific **/

export const getOrgSmsNumber = gql`
  query { getOrgSmsNumber }
`;

export const getOrgCallForwardingNumber = gql`
  query { getOrgCallForwardingNumber }
`;

export const messageAttachements = gql`
  query($id: ID!, $sms: Boolean) {
    messageAttachements(id: $id, sms: $sms) {
      id
      url
      label
    }
  }
`;

export const messageFormAttachments = gql`
  query($id: ID!) {
    messageFormAttachments(id: $id) {
      id
      label      
      createdAt
      questionCount
    }
  }
`;

export const messagePackageAttachments = gql`
  query($id: ID!) {
    messagePackageAttachments(id: $id) {
      id
      packageId
      label
      price
      description
      coverPhoto
      purchaseLimit
      recurring
      interval
      intervalCount
      processingFee      
      accessCode_CONFIG
    }
  }
`;

export const messageReactions = gql`
  query($id: ID!) {
    messageReactions(id: $id) {
      type
      count
      active
    }
  }
`;

export const oneOnOneQuery_AdminFeed = gql`
  query($sessionSpecific: Boolean, $mediaSpecific: Boolean, $inboxSpecific: Boolean, $limit: Int!, $page: Int!) {
    oneOnOneQuery_AdminFeed(sessionSpecific: $sessionSpecific, mediaSpecific: $mediaSpecific, inboxSpecific: $inboxSpecific, limit: $limit, page: $page) {
      id
      type
      createdAt
      profile {
        id
        mediaCount
        sessionCount
        user {
          id
          firstName
          lastName
          email
          phone
          avatar
        }
      }
    }
  }
`;

export const oneOnOneQuery_AdminFeed_ArchiveCount = gql`
  query {
    oneOnOneQuery_AdminFeed_ArchiveCount
  }
`;

export const oneOnOneQuery_AdminFeed_LastAnnouncement = gql`
  query {
    oneOnOneQuery_AdminFeed_LastAnnouncement {
      id      
      updatedAt
      contentShort
    }
  }
`;

export const oneOnOneQuery_AdminSMSFeed_Inbox = gql`
  query($archiveSpecific: Boolean) {
    oneOnOneQuery_AdminSMSFeed_Inbox(archiveSpecific: $archiveSpecific) {
      id
      type
      unread
      createdAt
      lastMessage
      profile {
        id
        mediaCount
        sessionCount
        user {
          id
          firstName
          lastName
          email
          phone
          avatar
        }
      }
    }
  }
`;

export const oneOnOneQuery_AdminFeed_Inbox = gql`
  query($archiveSpecific: Boolean, $limit: Int!, $page: Int!) {
    oneOnOneQuery_AdminFeed_Inbox(archiveSpecific: $archiveSpecific, limit: $limit, page: $page) {
      id
      type
      unread
      createdAt      
      lastMessage
      profile {
        id
        mediaCount
        sessionCount
        user {
          id
          firstName
          lastName
          email
          phone
          avatar
        }
      }
    }
  }
`;


const OneOnOneNative = `{
  id
  url
  type  
  status
  shareType
  contentShort
  contentMedium
  contentLong
  originProfile {
    id
    role
    user {
      id
      email
      firstName
      lastName
      avatar
    }
  }
  destinationProfile {
    id
    role
    user {
      id
      email
      firstName
      lastName
      avatar
    }
  }
  mediaId
  sessionId
  createdAt
  updatedAt
  sharedWithCount
  attachmentCount
  formAttachmentCount
  packageAttachmentCount
}`;

export const newMessageSent_Subscription = gql`
  subscription Inbox {
    newMessageSent {
      mediaMessages ${OneOnOneNative}
      textMessage ${OneOnOneNative}
    }
  }
`;


export const oneOnOneQuery_Purchases = gql`
  query($limit: Int!, $page: Int!) {
    oneOnOneQuery_Purchases(limit: $limit, page: $page) {
      id
      createdAt
      updatedAt
      amount
      coupon
      status
      stripeStatus
      package {
        id
        label
        recurring
        interval
        intervalCount
        coverPhoto
      }
      profile {
        id
        role
        user {
          id
          email
          firstName
          lastName
          avatar
        }
      }
    }
  }
`;

export const oneOnOneQuery_PrivatePrograms = gql`
  query($limit: Int!, $page: Int!) {
    oneOnOneQuery_PrivatePrograms(limit: $limit, page: $page){
      id
      title
      updatedAt
      programId
      featuredImage
    }
  }
`;

export const oneOnOneQuery_Drip = gql`
  query($limit: Int!, $page: Int!) {
    oneOnOneQuery_Drip(limit: $limit, page: $page){
      id
      type
      label
      featuredImage
      assetTitle
      programTitle
      programId
      updatedAt
    }
  }
`;

export const lastAnnouncement = gql`
  query {
    lastAnnouncement ${OneOnOneNative}
  }
`;

export const oneOnOneQuery_Native = gql`
  query($lessThan: BigInt, $greaterThan: BigInt, $limit: Int!, $page: Int!) {
    oneOnOneQuery_Native(lessThan: $lessThan, greaterThan: $greaterThan, limit: $limit, page: $page) ${OneOnOneNative}
  }
`;

export const oneOnOneQuery_NativeMultiple = gql`
  query($lessThan: BigInt, $greaterThan: BigInt, $limit: Int!, $page: Int!) {
    oneOnOneQuery_NativeMultiple(lessThan: $lessThan, greaterThan: $greaterThan, limit: $limit, page: $page) ${OneOnOneNative}
  }
`;

export const oneOnOneQuery_Announcements = gql`
  query($limit: Int!, $page: Int!) {
    oneOnOneQuery_Announcements(limit: $limit, page: $page) ${OneOnOneNative}
  }
`;

export const oneOnOneQuery_AnnouncementSingle = gql`
  query($announcementId: ID!) {
    oneOnOneQuery_AnnouncementSingle(announcementId: $announcementId) ${OneOnOneNative}
  }
`;

export const oneOnOneQuery_AnnouncementsMultiple = gql`
  query($limit: Int!, $page: Int!) {
    oneOnOneQuery_AnnouncementsMultiple(limit: $limit, page: $page) ${OneOnOneNative}
  }
`;

export const oneOnOneQuery_Sessions = gql`
  query($limit: Int!, $page: Int!) {
    oneOnOneQuery_Sessions(limit: $limit, page: $page) {
      id
      title
      notes
      dueDate
      status
      createdAt
      updatedAt
      completedDate
      athleteId
      coachId
      athlete {
        id
        user {
          id
          firstName
          lastName
          email
          phone
          avatar
        }
      }
      coach {
        id
        user {
          id
          firstName
          lastName
          email
          phone
          avatar
        }
      }
      voiceover {
        id
        videoUrl
        url
        processedUrl
        jobStatus
        createdAt
        updatedAt
      }
    }
  }
`;

export const oneOnOneQuery_SMS_AdminNative = gql`
  query($phone: String!, $lessThan: BigInt, $greaterThan: BigInt, $limit: Int, $page: Int) {
    oneOnOneQuery_SMS_AdminNative(phone: $phone, lessThan: $lessThan, greaterThan: $greaterThan, limit: $limit, page: $page) ${OneOnOneNative}
  }
`;

export const oneOnOneQuery_AdminNative = gql`
  query($lessThan: BigInt, $greaterThan: BigInt, $announcementsOnly: Boolean, $profileId: ID, $limit: Int!, $page: Int!) {
    oneOnOneQuery_AdminNative(lessThan: $lessThan, greaterThan: $greaterThan, announcementsOnly: $announcementsOnly, profileId: $profileId, limit: $limit, page: $page) ${OneOnOneNative}
  }
`;

export const oneOnOneQuery_AdminSessions = gql`
  query($lessThan: BigInt, $greaterThan: BigInt, $programId: ID, $profileId: ID, $limit: Int!, $page: Int!) {
    oneOnOneQuery_AdminSessions(
      lessThan: $lessThan
      greaterThan: $greaterThan
      programId: $programId
      profileId: $profileId
      limit: $limit
      page: $page
    ) {
      id
      title
      notes
      dueDate
      status
      createdAt
      updatedAt
      blockId
      completedDate
      athleteId
      coachId
      athlete {
        id
        user {
          id
          firstName
          lastName
          email
          phone
          avatar
        }
      }
      coach {
        id
        user {
          id
          firstName
          lastName
          email
          phone
          avatar
        }
      }
      voiceover {
        id
        videoUrl
        url
        processedUrl
        jobStatus
        createdAt
        updatedAt
      }
    }
  }
`;

export const oneOnOneQuery_AdminPurchases = gql`
  query($profileId: ID, $limit: Int!, $page: Int!) {
    oneOnOneQuery_AdminPurchases(
      profileId: $profileId
      limit: $limit
      page: $page
    ) {
      id
      createdAt
      updatedAt
      amount
      coupon
      status
      stripeStatus
      package {
        id
        label
        recurring
        interval
        intervalCount
        coverPhoto
      }
      profile {
        id
        role
        user {
          id
          email
          firstName
          lastName
          avatar
        }
      }
    }
  }
`;

/** User Specific **/

export const signedUrl_BASIC = gql`
  query($fileName: String!) {
    signedUrl_BASIC(fileName: $fileName)
  }
`;

/** Anaytic Specific **/

export const getAnalyticEvents = gql`
  query($programId: ID, $lessonId: ID, $mediaId: ID, $type: String) {
    getAnalyticEvents(
      programId: $programId
      lessonId: $lessonId
      mediaId: $mediaId
      type: $type
    ) {
      id
      type
      profileId
      groupId
      programId
      lessonId
      mediaId
      createdAt
    }
  }
`;

export const getAnalyticEvents_ADMIN = gql`
  query($programId: ID, $lessonId: ID, $mediaId: ID, $type: String) {
    getAnalyticEvents_ADMIN(
      programId: $programId
      lessonId: $lessonId
      mediaId: $mediaId
      type: $type
    ) {
      analyticEvents {
        id
        type
        profileId
        groupId
        programId
        lessonId
        mediaId
        updatedAt
        createdAt
        views
        profile {
          id
          user {
            id
            firstName
            lastName
            email
            phone
          }
        }
      }
      userCount
    }
  }
`;

/** Tagging System Specific **/

export const groupTags = gql`
  query {
    groupTags {
      groupId
      id
      label
      parentId
      lessons
      sessions
      programs
      packages
      users
      media
    }
  }
`;

export const groupTags_Media = gql`
  query {
    groupTags_Media {
      id
      label
      parentId
    }
  }
`;

/** Package Specific **/

export const packageQuery = gql`
  query($groupId: ID!, $packageId: ID!) {
    packageQuery(groupId: $groupId, packageId: $packageId) {
      id
      label
      price
      features
      description
      coverPhoto
      purchaseLimit
      recurring
      interval
      intervalCount
      processingFee
      accessCode_CONFIG
      confirmationPage
      confirmationUrl
      accessCode_CONFIG
      accessCodes {
        id
        code
        limit
      }
      purchases {
        id
        createdAt
        updatedAt
        status
        stripeStatus
      }
    }
  }
`;

/** Program Specific **/

export const getPrivatePrograms = gql`
  query {
    getPrivatePrograms {
      id
      title
      pricing
      access
      series
      settings
      featuredImage
      activePackages {
        id
        packageItem {
          id
          label
          price
          description
          features            
          purchaseLimit
          recurring
          interval
          processingFee
          accessCode_CONFIG
          confirmationPage
          confirmationUrl
          intervalCount
          intervalQuantity
          coverPhoto
        }
        purchases {
          id
          stripeStatus
        }
      }
      checkpoints {
        id
        index
        series
        free
        label
        lesson {
          id
          label
          createdAt
          featuredImage
        }
        media {
          id
          url
          label
          createdAt
        }
      }
    }
  }
`;

export const searchPrograms = gql`
  query($tagId: ID, $limit: Int!, $page: Int!) {
    searchPrograms(tagId: $tagId, limit: $limit, page: $page) {
      total
      pages
      groupId
      programs {
        id
        title
        pricing
        access
        settings
        featuredImage
        assignedUsers {
          id
          profile {
            id
            user {
              id
              firstName
              lastName
              email
              avatar
            }
          }
        }
        activePackages {
          id
          packageItem {
            id
            label
            price
            processingFee
            accessCode_CONFIG
            confirmationPage
            confirmationUrl
            description
            features
            purchaseLimit
            recurring
            interval
            intervalCount
            intervalQuantity
            coverPhoto
          }
        }
        checkpoints {
          id
          index
          series
          free
          label
          lesson {
            id
            label
            createdAt
            featuredImage
            slides {
              id
              settings
              index
            }
          }
          media {
            id
            url
            label
            fileInfo
            createdAt
          }
        }
        tags {
          id
          tag {
            id
            parentId
            label
            lessons
            programs
            packages
            sessions
            users
            media
          }
        }
      }
    }
  }
`;

export const basicProgramQuery = gql`
  query($programId: ID!) {
    basicProgramQuery(programId: $programId) {
      id
      title
      pricing
      access
      series
      settings
      error
      featuredImage
      activePackages {
        id
        packageItem {
          id
          label
          price
          description
          features
          purchaseLimit
          processingFee
          accessCode_CONFIG
          confirmationPage
          confirmationUrl
          recurring
          interval
          intervalCount
          intervalQuantity
          coverPhoto
        }
        purchases {
          id
          stripeStatus
        }
      }
      checkpoints {
        id
        index
        series
        free
        label
        dripTime
        daysAfter
        featuredImage
        lesson {
          id
          label
          createdAt
          featuredImage
          slides {
            id
            settings
            index
          }
        }
        media {
          id
          url
          label
          fileInfo
          createdAt
        }
      }
    }
  }
`;

export const programQuery = gql`
  query($programId: ID!) {
    programQuery(programId: $programId) {
      id
      title
      pricing
      access
      series
      settings
      featuredImage
      assignedUsers {
        id
        profile {
          id
          user {
            id
            firstName
            lastName
            email
            avatar
          }
        }
      }
      activePackages {
        id
        packageItem {
          id
          label
          price
          description
          features
          purchaseLimit
          processingFee
          accessCode_CONFIG
          confirmationPage
          confirmationUrl
          recurring
          interval
          intervalCount
          intervalQuantity
          coverPhoto
        }
      }
      checkpoints {
        id
        index
        series
        free
        label
        dripTime
        daysAfter
        featuredImage
        lesson {
          id
          label
          createdAt
          featuredImage
          slides {
            id
            settings
            index
          }
        }
        media {
          id
          url
          label
          fileInfo
          createdAt
        }
      }
      tags {
        id
        tag {
          id
          parentId
          label
          lessons
          programs
          packages
          sessions
          users
          media
        }
      }
    }
  }
`;

/** Form Specific **/

export const formFieldTemplateChoices = gql`
  query($formBlockTemplateId: ID!) {
    formFieldTemplateChoices(formBlockTemplateId: $formBlockTemplateId) {
      id
      index
      label
    }
  }
`;

export const formFieldTemplates = gql`
  query {
    formFieldTemplates {
      id      
      type
      label
      title
      index
      required    
      choices {
        id
        index
        label
      }
    }
  }
`;


export const searchForms = gql`
  query($limit: Int!, $page: Int!) {
    searchForms(limit: $limit, page: $page) {
      total
      pages
      groupId
      forms {
        id
        label
        createdAt
        questionCount
      }
    }
  }
`;

export const formQuery = gql`
  query($formId: ID!) {
    formQuery(formId: $formId) {
      id
      label
      settings
      buttonText
      createdAt
      group {
        id
        code
        name
        tenant {
          id
          code
          name
          logo
          currency
        }
      }
    }
  }
`;

export const formHouseTemplatesQuery = gql`
  query {
    formHouseTemplatesQuery {
      id
      label
      formBlocks {
        id
        index
        type
        label
        media {
          id
          url
          label
        }
        choices {
          id
          index
          label
        }
      }      
    }
  }
`;

export const formBlockReponsesQuery_Admin_viaResponseId = gql`
  query($responseId: ID!) {
    formBlockReponsesQuery_Admin_viaResponseId(responseId: $responseId) {
      id
      index
      type
      label
      choices {
        id
        index
        label
      }
      response {
        id
        index
        textResponse
        mediaResponse {
          id
          label
          url
        }
        choiceResponses {
          id
          formBlockChoiceId
        }
      }
    }
  }
`;

export const formBlockReponsesQuery_Admin_Table = gql`
  query($showAllFields: Boolean, $formId: ID!, $limit: Int!, $page: Int!) {
    formBlockReponsesQuery_Admin_Table(showAllFields: $showAllFields, formId: $formId, limit: $limit, page: $page) {
      formBlockData {
        id
        index
        type
        label
        choices {
          id
          index
          label
        }
      }
      formResponses {
        id
        createdAt
        profile {
          id
          user {
            id
            email
            avatar
            firstName
            lastName            
          }
        }
        purchases {
          id
          createdAt
          updatedAt
          amount
          coupon
          stripeStatus
        }
        responses {
          id
          index
          formBlockId
          textResponse        
          mediaResponse {
            id
            label
            url
          }
          choiceResponses {
            id
            formBlockChoiceId
          }        
        }
      }      
    }
  }
`;

export const formBlockQuery_ADMIN = gql`
  query($formId: ID!) {
    formBlockQuery_ADMIN(formId: $formId) {
      id
      index
      type
      label
      required
      media {
        id
        url
        label
      }
      choices {
        id
        index
        label
      }
    }
  }
`;

export const formBlockQuery = gql`
  query($formId: ID!) {
    formBlockQuery(formId: $formId) {
      id
      index
      type
      label
      required
      media {
        id
        url
        label
      }
      choices {
        id
        index
        label
      }
      response {
        id
        index
        textResponse
        mediaResponse {
          id
          label
          url
        }
        choiceResponses {
          id
          formBlockChoiceId
        }
      }
    }
  }
`;

/** Lesson Specific **/

export const searchLessons = gql`
  query($tagId: ID, $limit: Int!, $page: Int!, $tags: [ID]) {
    searchLessons(tagId: $tagId, limit: $limit, page: $page, tags: $tags) {
      total
      pages
      groupId
      lessons {
        id
        label
        createdAt
        featuredImage
        slides {
          id
          settings
          index
        }
        tags {
          id
          tag {
            id
            parentId
            label
            lessons
            programs
            packages
            sessions
            users
            media
          }
        }
      }
    }
  }
`;

export const lessonQuery = gql`
  query($lessonId: ID!) {
    lessonQuery(lessonId: $lessonId) {
      id
      label
      createdAt
      featuredImage
      tags {
        id
        tag {
          id
          parentId
          label
          lessons
          programs
          sessions
          packages
          users
          media
        }
      }
    }
  }
`;

export const slideQuery = gql`
  query($lessonId: ID!) {
    slideQuery(lessonId: $lessonId) {
      id
      settings
      index
    }
  }
`;

export const blockQuery_ADMIN = gql`
  query($lessonId: ID!) {
    blockQuery_ADMIN(lessonId: $lessonId) {
      id
      slideId
      index
      type
      url
      answer
      settings
      choices
      contentLong
      contentShort
      contentMedium
      quizAnswers {
        id
        programId
        createdAt
        answer
        correct
        profileId
        profile {
          id
          user {
            id
            firstName
            lastName
            email
            phone
          }
        }
      }
      formResponses {
        id
        programId
        createdAt
        response
        fieldId
        profileId
        profile {
          id
          user {
            id
            firstName
            lastName
            email
            phone
          }
        }
      }
      sessions {
        id
        programId
        athleteId
        coachId
        voiceover {
          id
          videoUrl
          url
          updatedAt
        }
        coach {
          id
          user {
            id
            firstName
            lastName
            email
            phone
          }
        }
        athlete {
          id
          user {
            id
            firstName
            lastName
            email
            phone
          }
        }
      }
    }
  }
`;

export const blockQuery = gql`
  query($lessonId: ID!) {
    blockQuery(lessonId: $lessonId) {
      id
      slideId
      index
      type
      url
      answer
      settings
      choices
      contentLong
      contentShort
      contentMedium
      quizAnswer {
        id
        programId
        createdAt
        answer
        correct
      }
      formResponses {
        id
        programId
        createdAt
        response
        fieldId
      }
      session {
        id
        programId
        voiceover {
          id
          videoUrl
          url
        }
      }
    }
  }
`;

/** End of Lesson Specific **/

/** Media **/

export const getInstagramUrl = gql`
  query($url: String!){
    getInstagramUrl(url: $url)
  }
`;


/*** End of New Schema ***/

export const CurrentUserSchema_v2 = `{
  id
  role
}`;

export const CurrentUserSchema = `{
  id
  role
  status
  currentGroupId
  currentTenantId
  activeGroupPackagePurchased
  profileGroups {
    id
    status
    role
    group {
      id
      name
      code
      public
      homeUrl
      activePackageId
      menuItemStyle
      courseCoverPhoto
      tenantId
      tenant {
        id
        logo
        name
        code
        theme
      }
    }
  }
  currentGroup {
    id
    name
    code
    public
    tenantId
    homeUrl
    activePackageId
    menuItemStyle
    courseCoverPhoto
    profileGroups {
      id
      status
      role
    }
  }
  tenant {
    id
    name
    code
    domain
    logo
    primaryHex
    theme
    groups {
      id
      name
      code
      public
      activePackageId
      homeUrl
      menuItemStyle
      courseCoverPhoto
    }
  }
  user {
    id
    firstName
    lastName
    avatar
    email
    phone
    verified
  }
}`;

export const CurrentUserQuery = gql`
  query {
    profile ${CurrentUserSchema_v2}
  }
`;

export const adminUserQuery_Dignifiers = gql`
  query($userId: ID!) {
    adminUserQuery_Dignifiers(userId: $userId) {
      isCustomer
      isAdmin
      tags {
        id
        tag {
          id        
          label
          parentId
        }
      }
    }    
  }
`;


export const CurrentUserQuery_UserInfo = gql`
  query {
    user {
      id
      email
      phone
      avatar
      firstName
      lastName
    }
  }
`;

export const CurrentUserQuery_ProfileGroups = gql`
  query {
    profileGroups {
      id
      role
      group {
        id
        name
        code
        tenant {
          id
          name
          code
          logo
        }
      }
    }
  }
`;

export const CurrentUserQuery_ActiveGroupPurchased = gql`
  query {
    activeGroupPurchased
  }
`;

export const groupCustomFields = gql`
  query($profileId: ID) {
    groupCustomFields(profileId: $profileId) {
      id
      label
      customProfileFields {
        id
        value
      }
    }
  }
`;

export const userParentsOrChildren = gql`
  query($profileId: ID) {
    userParentsOrChildren(profileId: $profileId) {
      id
      user {
        id
        firstName
        lastName
        email
        phone
      }
    }
  }
`;

export const getVoiceoverTemplates = gql`
  query {
    getVoiceoverTemplates {
      id
      label
      voiceoverTemplateSlide {
        id
        index
        lines
        chronDigits
        textAssets
        mediaAssets
        additionalConfig
      }
    }
  }
`;

export const getSavedCards = gql`
  query {
    getSavedCards {
      id
      last4
      brand
    }
  }
`;

export const getStripeAccount = gql`
  query($tenantCode: String, $groupCode: String) {
    getStripeAccount(tenantCode: $tenantCode, groupCode: $groupCode) {
      id
      email
      error
      stripeAPIKey
    }
  }
`;

export const getMediaById =  gql`
  query($id: ID) {
    getMediaById(id: $id) {
      id
      label
      url
    }
  }
`;

export const getMediaId =  gql`
query($url: String) {
  getMediaId(url: $url)
}
`;

export const Gallery = gql`
  query($categoryId: ID, $limit: Int!, $page: Int!, $profileId: ID, $tags: [ID]) {
    searchMedia(
      categoryId: $categoryId
      limit: $limit
      page: $page
      tags: $tags
      profileId: $profileId
    ) {
      total
      pages
      groupId
      gallery {
        id
        url
        label
        fileInfo
        createdAt
        profileId
        groupId
        profile {
          id
          user {
            id
            firstName
            lastName
            email
            phone
          }
        }
        connectedGroups {
          id
          groupId
        }
        tags {
          id
          tag {
            id
            parentId
            label
            lessons
            programs
            packages
            sessions
            users
            media
          }
        }
      }
    }
  }
`;

export const getPublicInstance = gql`
  query($tenantCode: String, $groupCode: String, $hostName: String) {
    getPublicInstance(
      tenantCode: $tenantCode
      groupCode: $groupCode
      hostName: $hostName
    ) {
      activeGroup {
        id
        code
        name
        public
        homeUrl
        replyToEmail
        activePackageId
        menuItemStyle
        courseCoverPhoto
        pages {
          id
          type
          settings
        }
        tenant {
          id
          code
          logo          
          theme
          name
          currency
          primaryHex
          appleAppId
        }
        groupSiteConnection {
          id
          url
        }
      }
    }
  }
`;

export const searchPageSections = gql`
  query($pageId: ID!) {
    searchPageSections(pageId: $pageId) {
      id
      index
      title
      settings
    }
  }
`;

export const searchPageSectionItems = gql`
  query($pageId: ID!) {
    searchPageSectionItems(pageId: $pageId) {
      id
      index
      settings
      sectionId
      program {
        id
        title
        pricing
        access
        series
        settings
        featuredImage
        activePackages {
          id
          packageItem {
            id
            label
            price
            description
            features            
            purchaseLimit
            processingFee
            confirmationPage
            confirmationUrl
            recurring
            interval
            accessCode_CONFIG
            intervalCount
            intervalQuantity
            coverPhoto
          }
          purchases {
            id
            stripeStatus
          }
        }
        checkpoints {
          id
          index
          series
          free
          label
          lesson {
            id
            label
            createdAt
            featuredImage
          }
          media {
            id
            url
            label
            createdAt
          }
        }
      }
      packageItem {
        id
        label
        price
        recurring
        interval
        intervalCount
        processingFee
        confirmationPage
        confirmationUrl
        description
        coverPhoto
        accessCode_CONFIG
        purchaseLimit
        features
      }
    }
  }
`;

export const makePurchase = gql`
  mutation makePurchase($input: PurchaseInput!) {
    makePurchase(input: $input)
  }
`;

export const makeExternalPurchase = gql`
  mutation makeExternalPurchase($input: ExternalPurchaseInput!) {
    makeExternalPurchase(input: $input)
  }
`;

export const searchCoupons = gql`
  query searchCoupons($page: Int!, $limit: Int!) {
    searchCoupons(page: $page, limit: $limit) {
      coupons {
        id
        code
        active
        valueOff
        createdAt
        type
        duration
        durationMonths
        maxRedemptions
        redemptionCount
      }
      groupId
      pages
      total
    }
  }
`;

export const CheckMasterCoupon = gql`
  query CheckMasterCoupon($code: String!){
    CheckMasterCoupon(code: $code){
      coupon {
        id
        code
        active
        valueOff
        createdAt
        type
        duration
        durationMonths
        maxRedemptions
      }
      showReferralWarning
      referralMessage
    }
  }
`;

export const checkCoupon = gql`
  query checkCoupon($code: String!, $groupId: ID) {
    checkCoupon(code: $code, groupId: $groupId) {
      coupon {
        id
        code
        active
        valueOff
        createdAt
        type
        duration
        durationMonths
        maxRedemptions
      }
      showReferralWarning
      referralMessage
    }
  }
`;

export const UserReferral = gql`
  query {
    profile {
      id
      user {
        id
        referral {
          code
          shareText
          invites {
            email
            status
          }
        }
      }
    }
  }
`;

export const UserOnboardingQuery = gql`
  query {
    profile {
      id
      role
      status
      tenant {
        id
        name
        code
      }
      user {
        id
        phone
      }
    }
  }
`;

export const sessionQuery = gql`
  query($id: ID!) {
    session(id: $id) {
      id
      title
      createdAt
      status
      dueDate
      notes
      voiceover {
        id
        processedUrl
        jobStatus
        videoUrl
        url
      }
      coach {
        id
        role
        status
        user {
          id
          firstName
          lastName
          avatar
        }
      }
      courseBlock {
        id
        courseLesson {
          id
          free
          delayAccess
          dripEnabled
          prevLessonDependant
          prevCourseDependant
        }
        courseSlide {
          id
          settings
        }
        course {
          id
          title
          settings
        }
      }
      athlete {
        id
        role
        status
        user {
          id
          firstName
          lastName
          email
          avatar
        }
      }
    }
  }
`;

export const SessionQuery = gql`
  query($id: ID!) {
    session(id: $id) {
      id
      title
      status
      voiceover {
        id
        url
        videoUrl
        jobStatus
        processedUrl
      }
    }
  }
`;

export const Categories = gql`
  query {
    Categories {
      id
      label
      parentId
    }
  }
`;

export const Sessions = gql`
  query(
    $status: String
    $courseBlock: ID
    $profileId: ID
    $groupId: ID
    $page: Int!
    $limit: Int!
  ) {
    searchSessions(
      status: $status
      courseBlock: $courseBlock
      profileId: $profileId
      groupId: $groupId
      page: $page
      limit: $limit
    ) {
      sessions {
        id
        title
        notes
        dueDate
        status
        createdAt
        updatedAt
        completedDate
        athlete {
          id
          user {
            id
            firstName
            lastName
            email
            avatar
          }
        }
        coach {
          id
          user {
            id
            firstName
            lastName
            email
            avatar
          }
        }
        courseBlock {
          id
          courseLesson {
            id
            title
            free
            delayAccess
            dripEnabled
            prevLessonDependant
            prevCourseDependant
          }
          courseSlide {
            id
            settings
          }
          course {
            id
            title
            settings
          }
        }
        voiceover {
          id
          videoUrl
          url
          processedUrl
          jobStatus
        }
      }
      pages
      total
    }
  }
`;

export const SearchSessionsAdmin = gql`
  query($status: String, $page: Int!, $limit: Int!) {
    searchSessionsAdmin(status: $status, page: $page, limit: $limit) {
      sessions {
        id
        title
        notes
        dueDate
        status
        createdAt
        updatedAt
        completedDate
        athlete {
          id
          user {
            id
            firstName
            lastName
            email
            avatar
          }
        }
        coach {
          id
          user {
            id
            firstName
            lastName
            email
            avatar
          }
        }
        voiceover {
          id
          videoUrl
          url
          processedUrl
          jobStatus
        }
        tags {
          id
          tag {
            id
            parentId
            label
            lessons
            programs
            packages
            sessions
            users
            media
          }
        }
      }
      pages
      total
    }
  }
`;

export const SessionsOptimized = gql`
  query(
    $status: String
    $courseBlock: ID
    $profileId: ID
    $groupId: ID
    $page: Int!
    $limit: Int!
  ) {
    searchSessionsOptimized(
      status: $status
      courseBlock: $courseBlock
      profileId: $profileId
      groupId: $groupId
      page: $page
      limit: $limit
    ) {
      sessions {
        id
        title
        notes
        dueDate
        status
        createdAt
        updatedAt
        completedDate
        athlete {
          id
          user {
            id
            firstName
            lastName
            email
            avatar
          }
        }
        coach {
          id
          user {
            id
            firstName
            lastName
            email
            avatar
          }
        }
        courseBlock {
          id
          courseLesson {
            id
            title
            free
            delayAccess
            dripEnabled
            prevLessonDependant
            prevCourseDependant
          }
          courseSlide {
            id
            settings
          }
          course {
            id
            title
            settings
          }
        }
        voiceover {
          id
          videoUrl
          url
          processedUrl
          jobStatus
        }
      }
      pages
      total
    }
  }
`;

export const USER_STATS = gql`
  query {
    profile {
      id
      role
      user {
        id
        firstName
        lastName
        avatar
      }
    }
  }
`;

export const adminProfileSearch = gql`
  query(
    $sortByName: Boolean
    $simplified: Boolean
    $role: String
    $page: Int!
    $limit: Int!
    $s: String
    $groupId: ID!
  ) {
    adminProfileSearch(
      simplified: $simplified
      role: $role
      page: $page
      limit: $limit
      s: $s
      groupId: $groupId
      sortByName: $sortByName
    ){
      id
      role
      status
      createdAt
      nativeBeginning
      profile {
        id
        user {
          id
          firstName
          lastName
          phone
          email
          avatar
        }
      }
      lastPurchase {
        id
        createdAt
        updatedAt
        amount
        coupon
        status
        package {
          id
          label
          recurring
          interval
          intervalCount
          coverPhoto
        }
      }
      tags {
        id
        tag {
          id
          parentId
          label
          users
        }
      }
    }
  }
`;

export const profileSearch = gql`
  query(
    $mostRecent: Boolean
    $oneOnOneSpecific: Boolean
    $mediaSpecific: Boolean
    $sessionSpecific: Boolean
    $role: String
    $tag: String
    $page: Int!
    $limit: Int!
    $s: String
  ) {
    profileSearch(
      mostRecent: $mostRecent
      oneOnOneSpecific: $oneOnOneSpecific
      mediaSpecific: $mediaSpecific
      sessionSpecific: $sessionSpecific
      role: $role
      tag: $tag
      page: $page
      limit: $limit
      s: $s
    ) {
      total
      pages
      groupId
      profileGroups {
        id
        status
        role
        dateRef
        nativeBeginning
        profile {
          id
          role
          createdAt
          mediaCount
          sessionCount
          profileCustomFields {
            id
            value
            customField {
              id
              label
            }
          }
          user {
            id
            firstName
            lastName
            phone
            email
            avatar
          }
        }
        lastPurchase {
          id
          createdAt
          updatedAt
          amount
          coupon
          status
          package {
            id
            label
            recurring
            interval
            intervalCount
            coverPhoto
          }
        }
        lastNative {
          id
          type
          url
          settings
          contentShort
          contentMedium
          contentLong
          mediaId
          sessionId
          createdAt
          updatedAt
        }
        tags {
          id
          tag {
            id
            parentId
            label
            lessons
            programs
            packages
            sessions
            users
            media
          }
        }
      }
    }
  }
`;

export const stripeProductQuery = gql`
  query($starting_after: String, $limit: Int!) {
    stripeProductQuery(
      starting_after: $starting_after
      limit: $limit
    ) {
      starting_after
      products {
        id
        title
        price
        recurring
        packageId
        processingFee
        accessCode_CONFIG
        confirmationPage
        confirmationUrl
      }
    }
  }
`;

export const stripeInvoiceQuery = gql`
  query($starting_after: String, $limit: Int!) {
    stripeInvoiceQuery(
      starting_after: $starting_after
      limit: $limit
    ) {
      starting_after
      invoices {
        id
        title
        createdAt
        total
        recurring
        purchaseId
        stripeId
        purchaserName
        purchaseStatus
        stripePricingId
        stripePlanId
        stripeProdId
        profile {
          id
          user {
            id
            firstName
            lastName
            email
            phone
            avatar
          }
        }
        packageItem {
          id
          label
          price
          recurring
          interval
          intervalCount
          processingFee
          accessCode_CONFIG
          confirmationPage
          confirmationUrl
        }
      }
    }
  }
`;

export const stripeSubscriptionQuery = gql`
  query($starting_after: String, $limit: Int!) {
    stripeInvoiceQuery(
      starting_after: $starting_after
      limit: $limit
    ) {
      starting_after
      subscriptions {
        id
        title
        createdAt
        total
        recurring
        purchaseId
        purchaserName
        purchaseStatus
      }
    }
  }
`;

export const productSearch = gql`
  query(
    $page: Int!
    $limit: Int!
    $type: String
    $status: String
    $access: String
  ) {
    productSearch(
      page: $page
      limit: $limit
      status: $status
      access: $access
      type: $type
    ) {
      id
      label
      priceText
      descriptionText
      recurringText
    }
  }
`;

export const packageSearch = gql`
  query(
    $id: ID
    $status: String
    $access: String
    $type: String
    $tenantCode: String
    $groupCode: String
  ) {
    packageSearch(
      id: $id
      status: $status
      access: $access
      type: $type
      tenantCode: $tenantCode
      groupCode: $groupCode
    ) {
      groupId
      packages {
        id
        label
        description
        features
        currency
        price
        active
        recurring
        index
        interval
        intervalCount
        purchaseLimit
        processingFee
        confirmationPage
        confirmationUrl        
        stripeId
        unit
        public
        coverPhoto
        accessCode_CONFIG
        purchases {
          id
          createdAt
          updatedAt
          status
          stripeStatus
        }
        tags {
          id
          tag {
            id
            parentId
            label
            lessons
            programs
            packages
            sessions
            users
            media
          }
        }
      }
    }
  }
`;

export const getProfileQuery = gql`
  query($profileId: ID!) {
    getProfile(profileId: $profileId) {
      id
      role
      status
      profile {
        id
        user {
          id
          firstName
          lastName
          verified
          email
          phone
          avatar
        }
      }
      tags {
        id
        tag {
          id
          parentId
          label
          users
        }
      }
    }
  }
`;

export const searchSessions = gql`
  query($status: String, $userId: ID, $page: Int!, $limit: Int!) {
    searchSessions(
      status: $status
      userId: $userId
      page: $page
      limit: $limit
    ) {
      sessions {
        id
        title
        status
        createdAt
        completedDate
        dueDate
        coach {
          id
          user {
            id
            firstName
            lastName
            email
            avatar
          }
        }
        athlete {
          id
          user {
            id
            firstName
            lastName
            avatar
          }
        }
      }
      pages
      total
    }
  }
`;

export const searchBasicOrders = gql`
  query($page: Int!, $limit: Int!) {
    searchBasicOrders(page: $page, limit: $limit) {
      orders {
        id
        createdAt
        amount
        coupon
        status
        stripeStatus
        package {
          id
          label
          recurring
          coverPhoto
        }
        profile {
          id
          role
          user {
            id
            email
            firstName
            lastName
            avatar
          }
        }
      }
      total
      pages
    }
  }
`;

export const getSports = gql`
  query {
    getSports {
      id
      label
    }
  }
`;

export const searchOrders = gql`
  query($page: Int!, $limit: Int!, $profileId: ID) {
    searchOrders(page: $page, limit: $limit, profileId: $profileId) {
      orders {
        id
        createdAt
        amount
        coupon
        status
        stripeStatus
        package {
          id
          label
          price
          recurring
          interval
          description
          intervalCount
          processingFee
          accessCode_CONFIG
        }
        profile {
          id
          role
          user {
            id
            email
            firstName
            lastName
            avatar
          }
        }
      }
      groupId
      total
      pages
    }
  }
`;

export const searchCoursesBasicUser = gql`
  query($input: CourseSearchInput!) {
    searchCourses(input: $input) {
      coursePurchases {
        id
        createdAt
        updatedAt
        status
        stripeStatus
      }
      courses {
        id
        title
        featuredImage
        status
        access
        settings
        price
        packageId
        activePackage {
          id
          label
          price
          description
          coverPhoto
          features
          purchaseLimit
          recurring
          interval
          intervalCount
          purchases {
            id
            createdAt
            updatedAt
            status
            stripeStatus
          }
        }
        visits {
          id
          createdAt
        }
        recentVisits {
          id
          createdAt
        }
        categories {
          id
          index
          category {
            id
            index
            label
          }
        }
        lessons {
          id
          title
          featuredImage
          type
          index
          published
          courseId
          free
          delayAccess
          dripEnabled
          prevLessonDependant
          prevCourseDependant
        }
        slides {
          id
          title
          align
          settings
          index
          published
          courseId
          courseLessonId
        }
        blocks {
          id
          index
          type
          embedType
          rendered
          content
          url
          settings
          question
          answer
          choices
          explanation
          published
          courseId
          courseLessonId
          courseSlideId
          parentBlockId
          session {
            id
            title
            status
            voiceover {
              id
              videoUrl
              url
              processedUrl
              jobStatus
            }
          }
        }
        assignments {
          id
          profileId
          courseId
        }
        coursePurchases {
          id
          createdAt
          updatedAt
          status
          stripeStatus
        }
      }
    }
  }
`;

export const courseTenantAndGroup = gql`
  query($courseId: ID!) {
    courseTenantAndGroup(courseId: $courseId) {
      group {
        id
        name
        code
        public
        homeUrl
        activePackageId
        menuItemStyle
        courseCoverPhoto
      }
      tenant {
        id
        name
        code
        domain
        logo
        primaryHex
        theme
      }
    }
  }
`;

export const getApiToken = gql`
  query {
    getApiToken {
      id
      createdAt
    }
  }
`;