import React, { PureComponent } from 'react';
import apolloClient from '../../../../../apollo.client';
import { stripeConnect } from '../../../../../apollo.mutations';
import { ProfileContext } from '../../../../../context/profile.context';
import { parseSearchParams } from '../../../../../helper';
import './stripeConnect.scss';

class GeneralConfirmation_PurchaseSuccess extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      error: null
    };
  }

  initUpdate(profile){
    const search = window.location.search;
    let { code = false } =
      search && search !== '' ? parseSearchParams(search) : {};

    if (code && profile) {
      this.updateStripeConnection(code);
    } else {
      window.location = `/`;

      return <div>Redirecting...</div>;
    }
  }

  updateStripeConnection = async code => {
    try {
      const data =
        (
          (await apolloClient.mutate({
            mutation: stripeConnect,
            variables: { stripeCode: code }
          })) || {}
        ).data || {};

      if (data.stripeConnect) {
        if (data.stripeConnect && data.stripeConnect.error) {
          alert('There was an error with your request...');
          window.location = `/`;
        } else {
          localStorage.removeItem('stripe-temp-creation-id');
          this.setState({ stripe_response_result: true });
        }
      } else {
        alert('There was an error with your request...');

        window.location = `/`;
      }
    } catch (e) {
      alert('There was an error with your request...');

      window.location = `/`;
    }
  };

  render() {
    const { stripe_response_result } = this.state;

    return (
      <ProfileContext.Consumer>
        {profile => {
          if(!this.already_connected) {
            this.already_connected = true;
            this.initUpdate(profile)
          }

          return (
            <div className="success-connect-container">
              <div className="success-connect-white-container">
                {stripe_response_result ? (
                  <>
                    <img src="/images/process-complete.gif" />
                    <h1>Success!</h1>
                    <p>Stripe is now connected</p>
                    <button onClick={() => (window.location = `/`)}>Continue</button>
                  </>
                ) : (
                  <h1>Loading...</h1>
                )}
              </div>
            </div>
            );
        }}
      </ProfileContext.Consumer>
    );
  }
};

export default GeneralConfirmation_PurchaseSuccess;
