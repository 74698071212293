import React, { PureComponent } from 'react';
import ReactDOM from 'react-dom';
import AdminHeader from './Accessories/AdminHeader';
import AdminMenu_REDUX from './Accessories/AdminMenu_REDUX';
import { ProfileContext } from '../../../context/profile.context';
import { RequesterContext } from '../../../context/requester.context';
import './adminNav.scss';

class AuthenticatedNav extends PureComponent {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <RequesterContext.Consumer>
        {requester => (
          <ProfileContext.Consumer>
            {profile =>
              ReactDOM.createPortal(
                <>
                  <AdminMenu_REDUX profile={profile} requester={requester} {...this.props} />
                </>,
                document.getElementById('forMenuPortal')
              )
            }
          </ProfileContext.Consumer>
        )}
      </RequesterContext.Consumer>
    );
  }
};

export default AuthenticatedNav;