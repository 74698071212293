import React, { PureComponent } from 'react';
import OrdersAndMembershipsMain from '../../../AdminChildren/Sales/Orders/OrdersAndMembershipsMain';
import SavedCards from './Billing/SavedCards';

class Groups extends PureComponent {
  constructor(props) {
    super(props);
  }

  render() {
    const { profile, requester } = this.props;

    return (
      <div className="immedaite-settings-main-component orders-within-settings">
        <SavedCards profile={profile} requester={requester} />
        <OrdersAndMembershipsMain
          profile={profile}
          requester={requester}
          categories={[]}
        />
      </div>
    );
  }
};

export default Groups;
