import gql from 'graphql-tag';
import { Gallery, signedUrl_BASIC } from '../apollo.queries';
import apolloClient from '../apollo.client';
import ACTIONS from '../constants/actions';
import WEBSITE_ACTIONS from '../constants/website/actions';

/** V2 Start **/

export const signedUrlInit_BASIC = async fileName =>
  (
    (await apolloClient.mutate({
      mutation: signedUrl_BASIC,
      variables: { fileName }
    })) || {}
  ).data.signedUrl_BASIC;

/** V2 End **/

const addToGallery = gql`
  mutation($input: UpsertGalleryInput!) {
    addToGallery(input: $input) {
      id
      label
      url
      createdAt
    }
  }
`;

const uploadVoiceoverComplete = gql`
  mutation($voiceoverId: ID!, $isOriginal: Boolean!) {
    uploadVoiceover(voiceoverId: $voiceoverId, isOriginal: $isOriginal) {
      id
      status
      voiceover {
        id
        url
        videoUrl
        jobStatus
      }
    }
  }
`;

const uploadAnalysisFileComplete = gql`
  mutation($input: UploadAnalysisFileInput!) {
    uploadAnalysisFile(input: $input) {
      id
      index
      type
      embedType
      rendered
      content
      url
      settings
      question
      answer
      choices
      explanation
      published
      courseId
      courseLessonId
      courseSlideId
      session {
        id
        title
        status
        voiceover {
          id
          videoUrl
          url
          processedUrl
          jobStatus
        }
      }
    }
  }
`;

function futch(url, opts = {}, onProgress) {
  return new Promise((resolve, reject) => {
    const xhr = new XMLHttpRequest();
    xhr.open(opts.method || 'get', url);
    for (const k in opts.headers || {}) {
      xhr.setRequestHeader(k, opts.headers[k]);
    }

    xhr.onload = e => {
      resolve(e.target.responseText ? JSON.parse(e.target.responseText) : null);
    };

    xhr.onerror = reject;
    if (xhr.upload && onProgress) {
      // event.loaded / event.total * 100 ; //event.lengthComputable
      xhr.upload.onprogress = onProgress;
    }
    xhr.send(opts.body);
  });
}

export const upsertGallery = async (label, url, categoryId) => {
  const payload = {
    label,
    url,
    categoryId
  };

  return await apolloClient
    .mutate({
      mutation: addToGallery,
      variables: {
        input: payload
      }
    })
    .catch(e => console.log(e));
};

export const uploadCreationCenterFile = (
  file,
  postInfo,
  label,
  self
) => async dispatch => {
  // Build S3 form
  const data = new FormData();
  Object.keys(postInfo.fields).map(key =>
    data.append(key, postInfo.fields[key])
  );
  data.append('file', file);
  const options = {
    method: 'POST',
    body: data
  };

  // Start uploading form to S3
  const res = await futch(
    'https://athletic-outlook.s3-accelerate.amazonaws.com',
    options,
    e => self.setState({ progress: (e.loaded / e.total) * 100 })
  ).catch(e => console.log(e));

  const split_url = postInfo.fields.key.split('/');

  await upsertGallery(
    label || file.name.split('.')[0],
    postInfo.url + '/' + postInfo.fields.key
  );

  return split_url[split_url.length - 1];
};

export const uploadVoiceoverFile = ({
  file,
  canvasFile,
  audioFile,
  session,
  postInfo,
  canvasPostInfo,
  audioPostInfo,
  isOriginal
}) => async dispatch => {
  const videoLayers = [
    {
      pathPrefix: 'canvas',
      postInfo: canvasPostInfo,
      file: canvasFile
    },
    {
      pathPrefix: 'audio',
      postInfo: audioPostInfo,
      file: audioFile
    },
    {
      pathPrefix: 'video',
      postInfo,
      file
    }
  ];

  let upload_failed = false;
  for (let videoLayer of videoLayers) {
    // Build S3 form
    const data = new FormData();
    Object.keys(videoLayer.postInfo.fields).map(key =>
      data.append(key, videoLayer.postInfo.fields[key])
    );

    data.append('file', videoLayer.file);
    const options = {
      method: 'POST',
      body: data
    };

    dispatch({ type: ACTIONS.UPLOAD_IN_PROGRESS });

    // Start uploading form to S3
    const res = await futch(
      'https://athletic-outlook.s3-accelerate.amazonaws.com',
      options,
      e =>
        dispatch({
          type: ACTIONS.UPLOAD_PROGRESS,
          payload: (e.loaded / e.total) * 100
        })
    ).catch(e => e);

    if (res) {
      upload_failed = true;
    }
  }

  dispatch({
    type: ACTIONS.UPLOAD_PROGRESS,
    payload: {
      uploadType: false,
      progress: 100
    }
  });

  //const label = `Voiceover (${session ? session.athlete ? `${session.athlete.user.firstName}${session.athlete.user.lastName && ` ${session.athlete.user.lastName}`}` : session.title : `Custom: ${new Date().getTime()}`})`;

  //upsertGallery(label, videoLayers[2].postInfo.url + '/processed-voiceover/' + videoLayers[2].postInfo.fields.key);

  // Check Response
  if (upload_failed) {
    // Error. S3 Returns null on success. Only return is error
    return dispatch({
      type: ACTIONS.UPLOAD_ERROR,
      payload: 'Unable to upload'
    });
  } else {
    return apolloClient
      .mutate({
        mutation: uploadVoiceoverComplete,
        variables: {
          voiceoverId: session.voiceover.id,
          isOriginal: isOriginal
        }
      })
      .then(({ loading, data }) => {
        if (data.uploadVoiceover && data.uploadVoiceover.id) {
          dispatch({
            type: ACTIONS.UPLOAD_COMPLETED,
            payload: { isOriginal: isOriginal }
          });

          return data.uploadVoiceover.id;
        } else {
          window.location.reload();
        }
      })
      .catch(e => {
        window.location.reload();
      });
  }
};

export const uploadLogoFile = (file, postInfo, self) => async dispatch => {
  // Build S3 form
  const data = new FormData();
  Object.keys(postInfo.fields).map(key =>
    data.append(key, postInfo.fields[key])
  );
  data.append('file', file);
  const options = {
    method: 'POST',
    body: data
  };

  // Start uploading form to S3
  const res = await futch(
    'https://athletic-outlook.s3-accelerate.amazonaws.com',
    options,
    e => self.setState({ progress: (e.loaded / e.total) * 100 })
  ).catch(e => console.log(e));

  const split_file_name = file.name.split('.');
  const split_url = postInfo.fields.key.split('/');

  await upsertGallery(
    split_file_name[0],
    postInfo.url + '/' + postInfo.fields.key
  );

  return split_url[split_url.length - 1];
};

export const uploadWebsiteFile = (file, postInfo, dontAddToGallery) => async dispatch => {
  // Build S3 form
  const data = new FormData();
  Object.keys(postInfo.fields).map(key =>
    data.append(key, postInfo.fields[key])
  );
  data.append('file', file);
  const options = {
    method: 'POST',
    body: data
  };

  // Start uploading form to S3
  const res = await futch(
    'https://athletic-outlook.s3-accelerate.amazonaws.com',
    options,
    e =>
      dispatch({
        type: WEBSITE_ACTIONS.UPDATE_STATE,
        payload: {
          progress: (e.loaded / e.total) * 100
        }
      })
  ).catch(e => console.log(e));

  const split_file_name = file.name.split('.');
  const split_url = postInfo.fields.key.split('/');

  !dontAddToGallery && await upsertGallery(
    split_file_name[0],
    postInfo.url + '/' + postInfo.fields.key
  );

  return split_url[split_url.length - 1];
};

export const uploadAvatarFile = async (file, postInfo, self) => {
  // Build S3 form
  const data = new FormData();
  Object.keys(postInfo.fields).map(key =>
    data.append(key, postInfo.fields[key])
  );
  data.append('file', file);
  const options = {
    method: 'POST',
    body: data
  };

  // Start uploading form to S3
  await futch(
    'https://athletic-outlook.s3-accelerate.amazonaws.com',
    options,
    e => self.setState({ progress: (e.loaded / e.total) * 100 })
  ).catch(e => console.log(e));

  const split_file_name = file.name.split('.');
  const split_url = postInfo.fields.key.split('/');

  await upsertGallery(
    split_file_name[0],
    postInfo.url + '/' + postInfo.fields.key
  );

  return split_url[split_url.length - 1];
};

export const uploadMediaFile = async (file, postInfo, self, categoryId) => {
  // Build S3 form

  const data = new FormData();
  Object.keys(postInfo.fields).map(key =>
    data.append(key, postInfo.fields[key])
  );
  data.append('file', file);
  const options = {
    method: 'POST',
    body: data
  };

  // Start uploading form to S3
  const res = await futch(
    'https://athletic-outlook.s3-accelerate.amazonaws.com',
    options,
    e =>
      (self.state.progress || 0) < (e.loaded / e.total) * 100 &&
      self.setState({ progress: (e.loaded / e.total) * 100 })
  ).catch(e => e);

  const split_file_name = file.name.split('.');

  const galleryAddition = await upsertGallery(
    split_file_name[0],
    postInfo.url + '/' + postInfo.fields.key,
    categoryId
  );

  // Check Response
  if (res) {
    // Error. S3 Returns null on success. Only return is error
    return null;
  } else {
    return galleryAddition.data && galleryAddition.data.addToGallery
      ? galleryAddition.data.addToGallery
      : null;
  }
};

export const uploadCourseMediaFile = (
  file,
  block,
  label,
  postInfo,
  isOriginal,
  self
) => async dispatch => {
  // Build S3 form
  const data = new FormData();
  Object.keys(postInfo.fields).map(key =>
    data.append(key, postInfo.fields[key])
  );
  data.append('file', file);
  const options = {
    method: 'POST',
    body: data
  };

  // Start uploading form to S3
  const res = await futch(
    'https://athletic-outlook.s3-accelerate.amazonaws.com',
    options,
    e => self.setState({ progress: (e.loaded / e.total) * 100 })
  ).catch(e => e);

  const split_file_name = file.name.split('.');
  const file_name_to_pass =
    file.preview.split('/').slice(-1)[0] + '.' + split_file_name.slice(-1)[0];

  await upsertGallery(
    label || split_file_name[0],
    postInfo.url + '/' + postInfo.fields.key
  );

  // Check Response
  if (res) {
    // Error. S3 Returns null on success. Only return is error
    return 'error';
  } else {
    return { url: postInfo.url + '/' + postInfo.fields.key };
  }
};

export const uploadAnalysisFile = (
  file,
  courseBlockId,
  session,
  postInfo,
  self
) => async dispatch => {
  // Build S3 form
  const data = new FormData();
  Object.keys(postInfo.fields).map(key =>
    data.append(key, postInfo.fields[key])
  );
  data.append('file', file);
  const options = {
    method: 'POST',
    body: data
  };

  // Start uploading form to S3
  const res = await futch(
    'https://athletic-outlook.s3-accelerate.amazonaws.com',
    options,
    e => self.setState({ progress: (e.loaded / e.total) * 100 })
  ).catch(e => console.log(e));

  const split_url = postInfo.fields.key.split('/');

  const label = `Athlete Submission (${
    session && session.athlete
      ? `${session.athlete.firstName}${session.athlete.lastName &&
          ` ${session.athlete.lastName}`}`
      : file.name.split('.')[0]
  })`;

  await upsertGallery(label, postInfo.url + '/' + postInfo.fields.key);

  // Check Response
  if (res) {
    // Error. S3 Returns null on success. Only return is error
    return 'error';
  } else {
    return apolloClient
      .mutate({
        mutation: uploadAnalysisFileComplete,
        variables: {
          input: {
            sessionId: (session && session.id) || null,
            courseBlockId: courseBlockId || null,
            fileName: split_url[split_url.length - 1]
          }
        }
      })
      .catch(e => console.log(e));
  }
};

export const getMediaSignedUrl = (
  file,
  categoryId,
  self,
  updateVoiceoverState
) => {
  const file_to_pass = file && file.target ? file.target.files[0] : file;
  if (file_to_pass) {
    const file_mb_size = file_to_pass.size / 1024 / 1024;
    const split_file_name = file_to_pass.name.split('.');

    if (file_mb_size > 2000) {
      alert('File to large...');
    } else {
      self.setState({
        successAnimation: false,
        progress: 0.1,
        uploadingFile: true
      });

      apolloClient
        .query({
          query: gql`
            query($fileName: String!) {
              mediaSignedUrl(fileName: $fileName)
            }
          `,
          fetchPolicy: 'network-only',
          variables: {
            fileName: split_file_name[split_file_name.length - 1].toLowerCase()
          }
        })
        .then(async ({ data: { mediaSignedUrl } }) => {
          if (mediaSignedUrl && mediaSignedUrl.url) {
            const { currentPage, limit } = self.state;

            const res = await uploadMediaFile(
              file_to_pass,
              mediaSignedUrl,
              self,
              categoryId || null
            );

            if (updateVoiceoverState) {
              setTimeout(
                () =>
                  updateVoiceoverState({
                    reloadMediaGallery: categoryId || true
                  }),
                500
              );
            } else if (res) {
              let previousData = apolloClient.readQuery({
                query: Gallery,
                variables: {
                  ...(categoryId && { categoryId }),
                  page: currentPage,
                  limit
                }
              });

              if (
                previousData &&
                previousData.searchMedia &&
                previousData.searchMedia.gallery
              ) {
                previousData.searchMedia.gallery.unshift(res);

                apolloClient.writeQuery({
                  query: Gallery,
                  variables: {
                    ...(categoryId && { categoryId }),
                    page: currentPage,
                    limit
                  },
                  data: previousData
                });
              }
            }
          }

          self.setState({ successAnimation: true }, () =>
            setTimeout(() => self.setState({ uploadingFile: false }), 1500)
          );
        })
        .catch(e => {
          console.log(e);
          self.setState({ uploadingFile: false });
        });
    }
  }
};
