import React, { memo } from 'react';
import { Elements, StripeProvider } from 'react-stripe-elements';
import { loadStripe } from '@stripe/stripe-js';
import StripeForm from './StripeForm';

const stripePromise = loadStripe('pk_live_0uqRmD0g012sAQC3OmP20w1n00s9XddMKA');

const StripeFormMapper = ({ onRef, onError, onToken, lightTheme }) => <>
  <div className="custom-stripe-form">
    <div className="auth-input-and-label-container">
      <label>Card Number</label>
      <StripeProvider apiKey="pk_live_0uqRmD0g012sAQC3OmP20w1n00s9XddMKA">
        <Elements stripe={stripePromise}>
          <StripeForm
            onRef={onRef}
            onError={onError}
            onToken={onToken}
            showCardInput={true}
            whiteColor={!lightTheme}
          />
        </Elements>
      </StripeProvider>
    </div>
  </div>
</>;


export default memo(StripeFormMapper);
