import React, { memo, useContext, useMemo } from 'react';
import { useQuery } from 'react-apollo';
import GeneralTagsFeed from './GeneralTagsFeed';
import { RequesterContext } from '../../context/requester.context';
import { ProfileContext } from '../../context/profile.context';
import { groupTags } from '../../apollo.queries';

const GeneralTags = props => {
  const { origin = 'media', origins = [] } = props;

  const requester = useContext(RequesterContext);
  const profile = useContext(ProfileContext);

  const cachedData = useQuery(
    groupTags,
    { fetchPolicy: "cache-only" }
  );

  const newData = useQuery(
    groupTags,
    { fetchPolicy: "network-only" }
  );

  const tagData = useMemo(() => (newData.data || {}).groupTags || (cachedData.data || {}).groupTags || [], [(newData.data || {}).groupTags, (cachedData.data || {}).groupTags]);
  
  const db_categories = useMemo(() => origins.length > 0 ? tagData.filter(tag => origins.find(localOrigin => tag[localOrigin])) : tagData.filter(tag => tag[origin]), [origin, origins, tagData]);
  
  return (
    <GeneralTagsFeed
      {...props}
      refetch={newData.refetch}
      profile={profile}
      requester={requester}
      db_categories={db_categories}
    />
  );
};

export default memo(GeneralTags);
