import gql from 'graphql-tag';
import { useEffect, useCallback, useMemo } from 'react';
import { parsePhoneNumber } from 'react-phone-number-input';
import { useQuery, useLazyQuery } from 'react-apollo';
import ACTIONS from '../constants/dashboard/actions';
import {
  CurrentUserQuery,
  CurrentUserSchema,
  checkCoupon,
  groupCustomFields,
  oneOnOneQuery_AdminNative,
  oneOnOneQuery_AdminFeed,
  oneOnOneQuery_Native,
  oneOnOneQuery_Sessions,
  oneOnOneQuery_Drip,
  activityFeed_UserUploads_Admin,
  activityFeed_Orders_Admin,
  activityFeed_NewUsers_Admin,
  activityFeed_ProgramCompletions_Admin,
  activityFeed_ProgramResponses_Admin,
  activityFeed_Forms_Admin,
  oneOnOneQuery_NativeMultiple,
  oneOnOneQuery_Announcements,
  oneOnOneQuery_AnnouncementsMultiple,
  oneOnOneQuery_PrivatePrograms,
  oneOnOneQuery_SMS_AdminNative
} from '../apollo.queries';
import { uploadAvatarFile } from './upload.actions';
import {
  updateUser,
  removeProfileGroup,
  cancelMembership,
  upsertArchivedInbox,
  removeArchivedInbox,
  connectFacebook,
  adminAddUser
} from '../apollo.mutations';
import apolloClient from '../apollo.client';
/** New Actions **/

const getSignedUrl = gql`
  query($fileName: String!) {
    avatarSignedUrl(fileName: $fileName)
  }
`;

const updateUserAdminMutation = gql`
  mutation updateUserAdmin($input: UpdateUserInput!, $profileId: ID) {
    updateUserAdmin(input: $input, profileId: $profileId) {
      success
      error
    }
  }
`;

const createCustomFieldMutation = gql`
  mutation createCustomField($label: String!) {
    createCustomField(label: $label) {
      id
      label
    }
  }
`;

const removeCustomFieldMutation = gql`
  mutation removeCustomField($customFieldId: ID!) {
    removeCustomField(customFieldId: $customFieldId) {
      id
    }
  }
`;

const updateProfileCustomFieldMutation = gql`
  mutation updateProfileCustomField(
    $profileId: ID!
    $customFieldId: ID!
    $value: String!
  ) {
    updateProfileCustomField(
      profileId: $profileId
      customFieldId: $customFieldId
      value: $value
    ) {
      id
      label
      customProfileFields {
        id
        value
      }
    }
  }
`;

/****/

export const updateFacebookConnection = async token => {
  try {
    const data = (
      (await apolloClient.mutate({
        mutation: connectFacebook,
        variables: { token }
      })) || {}
    ).data || {};

    if (data.connectFacebook) {
      return true;
    } else {
      alert('There was an error with your request...');

      return false;
    }
  } catch (e) {
    alert('There was an error with your request...');
    
    return false;
  }
};

/****/

export const getLogoKey = (logo = '', tenantId) =>
  logo
    ? logo.split('/').length > 1
      ? logo
      : `undefined/logo/${tenantId}/${logo}`
    : '';

export const getAvatarKey = (avatar = '', userId) =>
  avatar
    ? avatar.split('/').length > 1
      ? avatar
      : `undefined/avatar/${userId}/${avatar}`
    : '';

export const cancelMembershipInit = ({ purchaseId }) => async dispatch => {
  dispatch({
    type: ACTIONS.UPDATE_STATE,
    payload: {
      loadingCancellation: true
    }
  });

  const cancelMembershipData = (
    (await apolloClient.mutate({
      mutation: cancelMembership,
      variables: { purchaseId }
    })) || {}
  ).data.cancelMembership;

  dispatch({
    type: ACTIONS.UPDATE_STATE,
    payload: {
      loadingCancellation: false,
      [`membership_cancelled_${purchaseId}`]: true
    }
  });

  return { cancelMembershipData };
};

export const updateUserInit = ({
  firstName,
  lastName,
  email,
  avatar,
  phone,
  password,
  phoneCode,
  dontReload
}) => async dispatch => {
  if (!(firstName && lastName)) {
    alert('Enter First & Last names to update.');

    return false;
  }

  dispatch({
    type: ACTIONS.UPDATE_STATE,
    payload: {
      upsertingUser: true
    }
  });

  const { error, success, profile, token } = (
    (await apolloClient.mutate({
      mutation: updateUser,
      variables: {
        input: {
          firstName,
          lastName,
          email,
          avatar,
          phone,
          password,
          phoneCode
        }
      }
    })) || {}
  ).data.updateUser;

  dispatch({
    type: ACTIONS.UPDATE_STATE,
    payload: {
      upsertingUser: false,
      userUpdated: error ? false : true
    }
  });

  if (error) {
    if(error === 'phone-verification-needed'){
      dispatch({
        type: ACTIONS.UPDATE_STATE,
        payload: {
          phoneVerificationNeeded: true, upsertingUser: false
        }
      });
    } else {
      alert(error);
    };

    return false;
  };

  !dontReload && window.location.reload();

  return { success, profile, token };
};

export const removeProfileGroupInit = id => async dispatch => {
  dispatch({
    type: ACTIONS.UPDATE_STATE,
    payload: {
      removingGroup: true,
      [`removed_group_${id}`]: true
    }
  });

  (
    (await apolloClient.mutate({
      mutation: removeProfileGroup,
      variables: {
        input: { id }
      }
    })) || {}
  ).data.removeProfileGroup;

  dispatch({
    type: ACTIONS.UPDATE_STATE,
    payload: {
      removingGroup: false
    }
  });

  return true;
};

/** Pre V2 **/

const updateUserAvatarMutation = gql`
  mutation updateUserAvatar($avatar: String!) {
    updateUserAvatar(avatar: $avatar)
  }
`;

const updateCurrentGroup = gql`
  mutation updateCurrentGroup(
    $id: ID
    $tenantCode: String
    $groupCode: String
  ) {
    updateCurrentGroup(
      id: $id
      tenantCode: $tenantCode
      groupCode: $groupCode
    ) ${CurrentUserSchema}
  }
`;

const exportUsersToJSON = gql`
  mutation {
    exportUsersToJSON {
      id
      status
      role
      profile {
        id
        role
        createdAt
        profileCustomFields {
          id
          value
          customField {
            id
            label
          }
        }
        profileTags {
          id
          tag {
            id
            label
          }
        }
        user {
          id
          firstName
          lastName
          email
          avatar
        }
      }
    }
  }
`;

export const tenantPrefixUrlDeterminant = (tenantCode, groupCode) => {
  const hostName = window.location.hostname.toLowerCase();

  return hostName.indexOf('coachiq.io') > -1 || hostName.indexOf('localhost') > -1 || hostName.indexOf('127.0.0.1') > -1
    ? `/${tenantCode}/${groupCode}`
    : '';
};

export const updateCurrentGroupInit = async (
  id_to_pass,
  profile,
  updateCurrentGroupInitSpecific,
  reloadSpecific
) =>
  await new Promise(resolve =>
    apolloClient
      .mutate({
        mutation: updateCurrentGroup,
        variables: {
          ...(updateCurrentGroupInitSpecific
            ? updateCurrentGroupInitSpecific
            : { id: id_to_pass })
        },
        update: async (cache, { data }) => {
          // Cache is Apollo cache, and data is server response
          if (data.updateCurrentGroup && data.updateCurrentGroup.id) {
            const updated_profile = data.updateCurrentGroup;

            cache.writeQuery({
              query: CurrentUserQuery,
              data: { profile: updated_profile }
            });

            if (reloadSpecific) {
              window.location.reload();
            }
          }
        }
      })
      .then(({ loading, data }) => {
        if (data.updateCurrentGroup && data.updateCurrentGroup.id) {
          resolve(data.updateCurrentGroup);
        } else {
          resolve(false);
        }
      })
      .catch(e => {
        resolve(false);
      })
  );

export const applyCoupon = ({
  couponCode,
  groupId,
  currencyPrefix,
  selectedPackage
}) => dispatch => {
  dispatch({
    type: ACTIONS.UPDATE_STATE,
    payload: {
      loadingSubmission: true
    }
  });

  apolloClient
    .query({
      query: checkCoupon,
      variables: {
        code: couponCode,
        groupId
      },
      fetchPolicy: 'network-only'
    })
    .then(({ loading, data }) => {
      if (data.checkCoupon && data.checkCoupon.coupon) {
        const coupon = data.checkCoupon.coupon;
        let amountToSubtract =
          data.checkCoupon.coupon.type.toLowerCase() === 'fixed'
            ? data.checkCoupon.coupon.valueOff
            : (
                selectedPackage.price *
                (data.checkCoupon.coupon.valueOff / 100)
              ).toFixed(2);

        let total = (selectedPackage.price - amountToSubtract).toFixed(2);
        if (total <= 0) {
          amountToSubtract = selectedPackage.price;
          total = (0).toFixed(2);
        }

        dispatch({
          type: ACTIONS.UPDATE_STATE,
          payload: {
            coupon,
            updatedTotal: total,
            loadingSubmission: false,
            promoSuccess: `You saved ${currencyPrefix}${amountToSubtract}!`,
            error: ''
          }
        });
      } else {
        dispatch({
          type: ACTIONS.UPDATE_STATE,
          payload: {
            error: 'Invalid or Expired Coupon Code',
            updatedTotal: null,
            coupon: null,
            loadingSubmission: false,
            promoSuccess: null
          }
        });
      }
    })
    .catch(e => {
      console.log(e);
      dispatch({
        type: ACTIONS.UPDATE_STATE,
        payload: {
          loadingSubmission: false,
          updatedTotal: null,
          promoSuccess: null,
          coupon: null,
          error: e.message.split(': ')[1]
        }
      });
    });
};

export const exportUsersToJSON_Init = (groupCustomFields, self) =>
  self.setState(
    {
      loadingCsv: true
    },
    () =>
      apolloClient
        .mutate({
          mutation: exportUsersToJSON
        })
        .then(({ data }) => {
          if (data && data.exportUsersToJSON) {
            const profileGroups = data.exportUsersToJSON;

            const CSV_HEADERS = [
              {
                label: 'Name',
                key: 'name'
              },
              {
                label: 'Email',
                key: 'email'
              },
              {
                label: 'Tags',
                key: 'tags'
              },
              ...(groupCustomFields &&
                groupCustomFields.map(field => ({
                  label: field.label,
                  key: field.id
                })))
            ];

            const CSV_DATA = profileGroups
              .filter(
                profileGroup =>
                  profileGroup.profile && profileGroup.profile.user
              )
              .map((profileGroup, profileGroupIndex) => ({
                name:
                  profileGroup.profile.user.firstName ||
                  profileGroup.profile.user.lastName
                    ? `${profileGroup.profile.user.firstName ||
                        '-'} ${profileGroup.profile.user.lastName || ''}`
                    : '-',
                email: profileGroup.profile.user.email,
                tags: profileGroup.profile.profileTags
                  ? profileGroup.profile.profileTags.map(
                      (profileTag, profileTagIndex) =>
                        profileTag.tag &&
                        `${profileTagIndex > 0 && ', '}${profileTag.tag.label}`
                    )
                  : '-',
                ...(groupCustomFields &&
                  groupCustomFields.reduce((obj, field) => {
                    const profileCustomField = profileGroup.profile.profileCustomFields.find(
                      profileField => profileField.customField.id === field.id
                    );

                    return {
                      ...obj,
                      [field.id]: profileCustomField
                        ? profileCustomField.value
                        : '-'
                    };
                  }, {}))
              }));

            self.setState({
              loadingCsv: false,
              CSV_HEADERS,
              CSV_DATA
            });
          } else {
            alert(
              'There was an error, please try again. Reach out if the problem continues.'
            );
            self.setState({
              loadingCsv: false
            });
          }
        })
        .catch(e => {
          console.log(e);

          self.setState(
            {
              loadingCsv: false
            },
            () =>
              alert(
                'There was an error, please try again. Reach out if the problem continues.'
              )
          );
        })
  );

export const updateDashboardState = updatedStates => dispatch =>
  dispatch({
    type: ACTIONS.UPDATE_STATE,
    payload: updatedStates
  });

export const initAccountDashboard = variables => dispatch => {
  const { profile } = variables;

  const parsedPhoneNumber =
    profile.user.phone &&
    (parsePhoneNumber(`+${profile.user.phone.replace('+', '')}`) || {
      nationalNumber: profile.user.phone
    });

  let initialStates = {
    firstName: profile.user.firstName || '',
    lastName: profile.user.lastName || '',
    phone: parsedPhoneNumber ? parsedPhoneNumber.nationalNumber : '',
    parsedPhoneNumber,
    fallbackCountryCode:
      parsedPhoneNumber && parsedPhoneNumber.countryCallingCode
        ? parsedPhoneNumber.countryCallingCode
        : '1',
    email: profile.user.email || '',
    avatar: profile.user.avatar || '',
    initialized: true
  };

  initialStates = {
    ...initialStates,
    fullName: `${initialStates.firstName}${initialStates.firstName &&
      initialStates.lastName &&
      ' '}${initialStates.lastName}`
  };

  initialStates = {
    ...initialStates,
    initializedRefStates: initialStates
  };

  dispatch({
    type: ACTIONS.UPDATE_STATE,
    payload: initialStates
  });
};

const Avatar_upload = async (
  file,
  avatarSignedUrl,
  updateDashboardStateLocal
) => {
  const res = await uploadAvatarFile(file, avatarSignedUrl, {
    setState: updateDashboardStateLocal
  });

  apolloClient.mutate({
    mutation: updateUserAvatarMutation,
    variables: { avatar: res },
    update: async (cache, { data }) => {
      if (data && data.updateUserAvatar && data.updateUserAvatar) {
        let { profile } = cache.readQuery({ query: CurrentUserQuery });

        profile.user.avatar = res;

        cache.writeQuery({
          query: CurrentUserQuery,
          data: { profile }
        });
      }
    }
  });

  updateDashboardStateLocal({
    avatar: res,
    tempFileUrl: null,
    tempFile: null,
    uploading: false
  });

  if (res) {
    updateDashboardStateLocal({ reupload: false });
  }
};

const Avatar_getSignedUrl = (file, updateDashboardStateLocal) => {
  const file_name_split = file.name.split('.');
  const file_name_to_pass =
    new Date().getTime() + '.' + file_name_split[file_name_split.length - 1];

  apolloClient
    .query({
      query: getSignedUrl,
      variables: {
        fileName: file_name_to_pass
      },
      fetchPolicy: 'network-only'
    })
    .then(({ data }) => {
      // if signed url, start uploading to s3. when that's done, mark voiceover as completed. ref uploadCourseMediaFile
      if (data.avatarSignedUrl && data.avatarSignedUrl.url) {
        Avatar_upload(file, data.avatarSignedUrl, updateDashboardStateLocal);
      }
    })
    .catch(e => alert(e.message.split(': ')[1]));
};

export const Avatar_onDrop = (
  files,
  tempFileUrl,
  updateDashboardStateLocal
) => {
  if (
    files[0] &&
    files[0].name
      .split('.')
      .slice(-1)[0]
      .toLowerCase() === 'svg'
  ) {
    alert('Try uploading an png or jpg file type.');
  } else if (tempFileUrl) {
    alert('Please let the previous upload finish first, thanks!');
  } else {
    if (files.length === 1) {
      updateDashboardStateLocal({
        tempFileUrl: URL.createObjectURL(files[0]),
        tempFile: files[0],
        uploading: true
      });

      Avatar_getSignedUrl(files[0], updateDashboardStateLocal);
    } else {
      alert('File type not accepted');
    }
  }
};

export const createCustomField = ({ profileId, newField }) => dispatch => {
  dispatch({
    type: ACTIONS.UPDATE_STATE,
    payload: { loading_update: true }
  });

  apolloClient
    .mutate({
      mutation: createCustomFieldMutation,
      variables: {
        label: newField
      },
      update: (cache, { data }) => {
        if (data && data.createCustomField && data.createCustomField.id) {
          const prevData = cache.readQuery({
            query: groupCustomFields,
            variables: { profileId }
          });

          cache.writeQuery({
            query: groupCustomFields,
            variables: { profileId },
            data: {
              groupCustomFields: [
                ...prevData.groupCustomFields,
                ...[
                  {
                    ...data.createCustomField,
                    customProfileFields: [
                      {
                        id: `new-profile-field-${new Date().getTime()}`,
                        value: '',
                        __typename: 'ProfileCustomField'
                      }
                    ]
                  }
                ]
              ]
            }
          });
        }
      }
    })
    .then(({ data }) => {
      if (data && data.createCustomField && data.createCustomField.id) {
        dispatch({
          type: ACTIONS.UPDATE_STATE,
          payload: {
            new_field: '',
            error_field_add: '',
            success_field_add: 'Field Added'
          }
        });

        setTimeout(
          () =>
            dispatch({
              type: ACTIONS.UPDATE_STATE,
              payload: { loading_update: false }
            }),
          2000
        );
      } else {
        dispatch({
          type: ACTIONS.UPDATE_STATE,
          payload: {
            loading_field_add: false,
            success_field_add: '',
            error_field_add: 'Request was unsuccessful...'
          }
        });
      }
    })
    .catch(e =>
      dispatch({
        type: ACTIONS.UPDATE_STATE,
        payload: {
          loading_field_add: false,
          success_field_add: '',
          error_field_add: e.message.split(': ')[1]
        }
      })
    );
};

export const removeCustomField = ({ profileId, customFieldId }) => dispatch => {
  dispatch({
    type: ACTIONS.UPDATE_STATE,
    payload: { loading_field_add: true }
  });

  apolloClient
    .mutate({
      mutation: removeCustomFieldMutation,
      variables: {
        customFieldId
      },
      update: (cache, { data }) => {
        if (data && data.removeCustomField && data.removeCustomField.id) {
          const prevData = cache.readQuery({
            query: groupCustomFields,
            variables: { profileId }
          });

          const groupCustomFieldsToPass = prevData.groupCustomFields.filter(
            field => field.id !== data.removeCustomField.id
          );

          cache.writeQuery({
            query: groupCustomFields,
            variables: { profileId },
            data: {
              groupCustomFields: groupCustomFieldsToPass
            }
          });
        }
      }
    })
    .then(({ data }) => {
      if (data && data.removeCustomField && data.removeCustomField.id) {
        dispatch({
          type: ACTIONS.UPDATE_STATE,
          payload: {
            success_field_add: 'Field Removed',
            loading_field_add: false
          }
        });
      } else {
        alert('Request was unsuccessful...');

        dispatch({
          type: ACTIONS.UPDATE_STATE,
          payload: {
            loading_field_add: false
          }
        });
      }
    })
    .catch(e => {
      alert(e.message.split(': ')[1]);

      dispatch({
        type: ACTIONS.UPDATE_STATE,
        payload: {
          loading_field_add: false
        }
      });
    });
};

export const updateProfileField = ({
  profileId,
  customFields,
  prevGroupCustomFields
}) => dispatch => {
  for (let fieldId of Object.keys(customFields)) {
    apolloClient
      .mutate({
        mutation: updateProfileCustomFieldMutation,
        variables: {
          profileId: profileId,
          customFieldId: fieldId,
          value: customFields[fieldId]
        }
      })
      .then(({ data }) => {
        if (
          data &&
          data.updateProfileCustomField &&
          data.updateProfileCustomField.id
        ) {
          dispatch({
            type: ACTIONS.UPDATE_STATE,
            payload: {
              error: '',
              success: 'User Updated',
              loading_update: false,
              deactivateButtons: true,
              updatesMade: false
            }
          });

          setTimeout(
            () =>
              dispatch({
                type: ACTIONS.UPDATE_STATE,
                payload: {
                  success: null,
                  deactivateButtons: false
                }
              }),
            2000
          );
        } else {
          dispatch({
            type: ACTIONS.UPDATE_STATE,
            payload: {
              error: 'Request was unsuccessful...',
              success: null,
              loading_update: false
            }
          });
        }
      })
      .catch(e =>
        dispatch({
          type: ACTIONS.UPDATE_STATE,
          payload: {
            error: e.message.split(': ')[1],
            success: null,
            loading_update: false
          }
        })
      );
  }
};

export const updateUserAdmin = ({
  currentProfileGroup,
  firstName,
  lastName,
  email,
  phone,
  avatar,
  customFields = [],
  prevGroupCustomFields
}) => dispatch => {
  if (!email || email === '') {
    alert('Add email');
  } else {
    dispatch({
      type: ACTIONS.UPDATE_STATE,
      payload: { loading_update: true, error: '', success: false }
    });

    let currentProfile = currentProfileGroup.profile;

    const payload = {
      firstName,
      lastName,
      email,
      phone,
      avatar
    };

    apolloClient
      .mutate({
        mutation: updateUserAdminMutation,
        variables: {
          profileId: currentProfile.id,
          input: payload
        }
      })
      .then(({ data = {} }) => {
        if (data.updateUserAdmin && data.updateUserAdmin.success) {
          if (Object.keys(customFields).length > 0) {
            dispatch(
              updateProfileField({
                profileId: currentProfile.id,
                customFields,
                prevGroupCustomFields
              })
            );
          } else {
            dispatch({
              type: ACTIONS.UPDATE_STATE,
              payload: {
                error: '',
                loading_update: false,
                success: 'User Updated',
                deactivateButtons: true,
                updatesMade: false
              }
            });

            setTimeout(
              () =>
                dispatch({
                  type: ACTIONS.UPDATE_STATE,
                  payload: {
                    success: null,
                    deactivateButtons: false
                  }
                }),
              2000
            );
          }
        } else {
          dispatch({
            type: ACTIONS.UPDATE_STATE,
            payload: {
              loading_update: false,
              success: false,
              error:
                (data.updateUserAdmin && data.updateUserAdmin.error) ||
                'Request was unsuccessful...'
            }
          });
        }
      })
      .catch(e =>
        dispatch({
          type: ACTIONS.UPDATE_STATE,
          payload: {
            loading_update: false,
            success: false,
            error: e.message.split(': ')[1]
          }
        })
      );
  }
};

export const Announcement_QueryConcat = () => {
  const limit = 50;
  
  const AnnouncementResults_CacheOnly = useQuery(
    oneOnOneQuery_Announcements,
    { variables: { page: 1, limit }, fetchPolicy: "cache-only" }
  );

  const AnnouncementMultipleResults_CacheOnly = useQuery(
    oneOnOneQuery_AnnouncementsMultiple,
    { variables: { page: 1, limit }, fetchPolicy: "cache-only" }
  );

  /****/
  
  const AnnouncementResults = useQuery(
    oneOnOneQuery_Announcements,
    { variables: { page: 1, limit }, fetchPolicy: "network-only" }
  );

  const AnnouncementMultipleResults = useQuery(
    oneOnOneQuery_AnnouncementsMultiple,
    { variables: { page: 1, limit }, fetchPolicy: "network-only" }
  );

  /****/

  const AnnouncementResults_MAPPED_ONE = useMemo(() => 
    (AnnouncementResults.data || {}).oneOnOneQuery_Announcements || (AnnouncementResults_CacheOnly.data || {}).oneOnOneQuery_Announcements || [], 
    [(AnnouncementResults.data || {}).oneOnOneQuery_Announcements]
  );

  const AnnouncementResults_MAPPED_TWO = useMemo(() => 
    (AnnouncementMultipleResults.data || {}).oneOnOneQuery_AnnouncementsMultiple || (AnnouncementMultipleResults_CacheOnly.data || {}).oneOnOneQuery_AnnouncementsMultiple || [], 
    [(AnnouncementMultipleResults.data || {}).oneOnOneQuery_AnnouncementsMultiple]
  );

  const AnnouncementResults_MAPPED_COMBINED = useMemo(() => 
    [...AnnouncementResults_MAPPED_ONE, ...AnnouncementResults_MAPPED_TWO], 
    [AnnouncementResults_MAPPED_ONE, AnnouncementResults_MAPPED_TWO]
  );

  const stillLoading = useMemo(() => (AnnouncementResults.loading || AnnouncementMultipleResults.loading) , [AnnouncementResults.loading || AnnouncementMultipleResults.loading]);
  
  return {
    AnnouncementResults: AnnouncementResults_MAPPED_COMBINED,
    stillLoading
  };
};

export const Inbox_QueryConcat = (props) => {
  const { greaterThan } = props || {};
  const limit = 50;
  
  const DripResults_CacheOnly = greaterThan ? {} : useQuery(
    oneOnOneQuery_Drip,
    { variables: { page: 1, limit }, fetchPolicy: "cache-only" }
  );

  const PrivateProgramsResults_CacheOnly = greaterThan ? {} : useQuery(
    oneOnOneQuery_PrivatePrograms,
    { variables: { page: 1, limit }, fetchPolicy: "cache-only" }
  );

  const NativeResults_CacheOnly = useQuery(
    oneOnOneQuery_Native,
    { variables: { greaterThan, page: 1, limit }, fetchPolicy: "cache-only" }
  );

  const NativeMultipleResults_CacheOnly = useQuery(
    oneOnOneQuery_NativeMultiple,
    { variables: { greaterThan, page: 1, limit }, fetchPolicy: "cache-only" }
  );

  const SessionResults_CacheOnly = greaterThan ? {} : useQuery(
    oneOnOneQuery_Sessions,
    { variables: { page: 1, limit }, fetchPolicy: "cache-only" }
  );

  /****/

  const DripResults = greaterThan ? {} : useQuery(
    oneOnOneQuery_Drip,
    { variables: { page: 1, limit }, fetchPolicy: "network-only" }
  );

  const PrivateProgramsResults = greaterThan ? {} : useQuery(
    oneOnOneQuery_PrivatePrograms,
    { variables: { page: 1, limit }, fetchPolicy: "network-only" }
  );

  const NativeResults = useQuery(
    oneOnOneQuery_Native,
    { variables: { greaterThan, page: 1, limit }, fetchPolicy: "network-only" }
  );

  const NativeMultipleResults = useQuery(
    oneOnOneQuery_NativeMultiple,
    { variables: { greaterThan, page: 1, limit }, fetchPolicy: "network-only" }
  );

  const SessionResults = greaterThan ? {} : useQuery(
    oneOnOneQuery_Sessions,
    { variables: { page: 1, limit }, fetchPolicy: "network-only" }
  );

  /****/
  
  const DripResults_MAPPED = useMemo(() => 
    (DripResults.data || {}).oneOnOneQuery_Drip || (DripResults_CacheOnly.data || {}).oneOnOneQuery_Drip || [], 
    [(DripResults.data || {}).oneOnOneQuery_Drip]
  );

  const PrivateProgramsResults_MAPPED = useMemo(() => 
    (PrivateProgramsResults.data || {}).oneOnOneQuery_PrivatePrograms || (PrivateProgramsResults_CacheOnly.data || {}).oneOnOneQuery_PrivatePrograms || [], 
    [(PrivateProgramsResults.data || {}).oneOnOneQuery_PrivatePrograms]
  );

  const NativeResults_MAPPED_ONE = useMemo(() => 
    (NativeResults.data || {}).oneOnOneQuery_Native || (NativeResults_CacheOnly.data || {}).oneOnOneQuery_Native || [], 
    [(NativeResults.data || {}).oneOnOneQuery_Native]
  );

  const NativeResults_MAPPED_TWO = useMemo(() => 
    (NativeMultipleResults.data || {}).oneOnOneQuery_NativeMultiple || (NativeMultipleResults_CacheOnly.data || {}).oneOnOneQuery_NativeMultiple || [], 
    [(NativeMultipleResults.data || {}).oneOnOneQuery_NativeMultiple]
  );

  const NativeResults_MAPPED_COMBINED = useMemo(() => 
    [...NativeResults_MAPPED_ONE, ...NativeResults_MAPPED_TWO], 
    [NativeResults_MAPPED_ONE, NativeResults_MAPPED_TWO]
  );

  const SessionResults_MAPPED = useMemo(() => 
    (SessionResults.data || {}).oneOnOneQuery_Sessions || (SessionResults_CacheOnly.data || {}).oneOnOneQuery_Sessions || [], 
    [SessionResults.data, SessionResults_CacheOnly.data]
  );
  
  const mostRecentRefetch = useCallback(() => { NativeResults.refetch(); NativeMultipleResults.refetch(); }, []);

  const oneIsStillLoading = useMemo(() => NativeResults.loading || SessionResults.loading || DripResults.loading || PrivateProgramsResults.loading || NativeMultipleResults.loading, [NativeResults.loading || SessionResults.loading || DripResults.loading || PrivateProgramsResults.loading || NativeMultipleResults.loading])

  return {
    mostRecentRefetch,
    DripResults: DripResults_MAPPED,
    PrivateProgramsResults: PrivateProgramsResults_MAPPED,
    NativeResults: NativeResults_MAPPED_COMBINED,
    SessionResults: SessionResults_MAPPED,
    oneIsStillLoading
  };
};

export const Admin_ActivityFeed_QueryConcat = ({ globalTimeFilter, activeChildFilter, activeFilter, profileId, groupId }) => {
  const limit = 50;

  const isAll = activeFilter === 'all';

  const userUploadsSpecific = isAll || (activeFilter === 'users' && (!activeChildFilter || activeChildFilter === 'user-uploads'));

  const ordersSpecific = isAll || activeFilter === 'orders';

  const programCompletionsSpecific = isAll || (activeFilter === 'programs' && (!activeChildFilter || activeChildFilter === 'program-completions'));

  const programResponsesSpecific = isAll || (activeFilter === 'programs' && (!activeChildFilter || activeChildFilter === 'program-responses'));

  const formsSpecific = isAll || activeFilter === 'forms';

  const newUsersSpecific = profileId === 'activity-feed' && (isAll || (activeFilter === 'users' && (!activeChildFilter || activeChildFilter === 'user-new')));

  const {
    userUploads_Results_CacheOnly = {},
    orders_Results_CacheOnly = {},
    programCompletions_Results_CacheOnly = {},
    programResponses_Results_CacheOnly = {},
    forms_Results_CacheOnly = {},
    newUsers_Results_CacheOnly = {}
  } = {
    userUploads_Results_CacheOnly: userUploadsSpecific ? apolloClient.readQuery({
      query: activityFeed_UserUploads_Admin,
      variables: { page: 1, limit, groupId, timeFilter: globalTimeFilter, ...profileId !== 'activity-feed' && { profileId } }
    }) : {},

    orders_Results_CacheOnly: ordersSpecific ? apolloClient.readQuery({
      query: activityFeed_Orders_Admin,
      variables: { page: 1, limit, groupId, timeFilter: globalTimeFilter, ...profileId !== 'activity-feed' && { profileId } }
    }) : {},

    programCompletions_Results_CacheOnly: programCompletionsSpecific ? apolloClient.readQuery({
      query: activityFeed_ProgramCompletions_Admin,
      variables: { page: 1, limit, groupId, timeFilter: globalTimeFilter, ...profileId !== 'activity-feed' && { profileId } }
    }) : {},

    programResponses_Results_CacheOnly: programResponsesSpecific ? apolloClient.readQuery({
      query: activityFeed_ProgramResponses_Admin,
      variables: { page: 1, limit, groupId, timeFilter: globalTimeFilter, ...profileId !== 'activity-feed' && { profileId } }
    }) : {},

    forms_Results_CacheOnly: formsSpecific ? apolloClient.readQuery({
      query: activityFeed_Forms_Admin,
      variables: { page: 1, limit, groupId, timeFilter: globalTimeFilter, ...profileId !== 'activity-feed' && { profileId } }
    }) : {},

    newUsers_Results_CacheOnly: newUsersSpecific ? apolloClient.readQuery({
      query: activityFeed_NewUsers_Admin,
      variables: { page: 1, limit, groupId, timeFilter: globalTimeFilter }
    }) : {}
  };

  /****/

  const [initUserUploads_networResults, userUploads_Results = {}] = useLazyQuery(
    activityFeed_UserUploads_Admin,
    { variables: { page: 1, limit, groupId, timeFilter: globalTimeFilter, ...profileId !== 'activity-feed' && { profileId } }, fetchPolicy: "network-only" }
  );

  const [initOrders_networResults, orders_Results = {}] = useLazyQuery(
    activityFeed_Orders_Admin,
    { variables: { page: 1, limit, groupId, timeFilter: globalTimeFilter, ...profileId !== 'activity-feed' && { profileId } }, fetchPolicy: "network-only" }
  );

  const [initProgramCompletions_networResults, programCompletions_Results = {}] = useLazyQuery(
    activityFeed_ProgramCompletions_Admin,
    { variables: { page: 1, limit, groupId, timeFilter: globalTimeFilter, ...profileId !== 'activity-feed' && { profileId } }, fetchPolicy: "network-only" }
  );

  const [initProgramResponses_networResults, programResponses_Results = {}] = useLazyQuery(
    activityFeed_ProgramResponses_Admin,
    { variables: { page: 1, limit, groupId, timeFilter: globalTimeFilter, ...profileId !== 'activity-feed' && { profileId } }, fetchPolicy: "network-only" }
  );

  const [initForms_networResults, forms_Results = {}] = useLazyQuery(
    activityFeed_Forms_Admin,
    { variables: { page: 1, limit, groupId, timeFilter: globalTimeFilter, ...profileId !== 'activity-feed' && { profileId } }, fetchPolicy: "network-only" }
  );
  
  const [initNewUsers_networResults, newUsers_Results = {}] = useLazyQuery(
    activityFeed_NewUsers_Admin,
    { variables: { page: 1, limit, groupId, timeFilter: globalTimeFilter }, fetchPolicy: "network-only" }
  );

  useEffect(() => {
    if(userUploadsSpecific) initUserUploads_networResults();

    if(ordersSpecific) initOrders_networResults();

    if(programCompletionsSpecific) initProgramCompletions_networResults();

    if(programResponsesSpecific) initProgramResponses_networResults();

    if(formsSpecific) initForms_networResults();

    if(newUsersSpecific) initNewUsers_networResults();
  }, [activeChildFilter, activeFilter]);

  /****/

  const userUploadsResults_MAPPED = useMemo(() => 
    userUploadsSpecific ? (userUploads_Results.data || {}).activityFeed_UserUploads_Admin || ((userUploads_Results_CacheOnly || {}).data || {}).activityFeed_UserUploads_Admin || [] : [], 
    [(userUploads_Results.data || {}).activityFeed_UserUploads_Admin || [], userUploadsSpecific]
  );

  const ordersResults_MAPPED = useMemo(() => 
    ordersSpecific ? (orders_Results.data || {}).activityFeed_Orders_Admin || ((orders_Results_CacheOnly || {}).data || {}).activityFeed_Orders_Admin || [] : [], 
    [(orders_Results.data || {}).activityFeed_Orders_Admin || [], ordersSpecific]
  );

  const formsResults_MAPPED = useMemo(() => 
    formsSpecific ? (forms_Results.data || {}).activityFeed_Forms_Admin || ((forms_Results_CacheOnly || {}).data || {}).activityFeed_Forms_Admin || [] : [], 
    [(forms_Results.data || {}).activityFeed_Forms_Admin || [], formsSpecific]
  );

  const programCompletionsResults_MAPPED = useMemo(() => 
    programCompletionsSpecific ? (programCompletions_Results.data || {}).activityFeed_ProgramCompletions_Admin || ((programCompletions_Results_CacheOnly || {}).data || {}).activityFeed_ProgramCompletions_Admin || [] : [], 
    [(programCompletions_Results.data || {}).activityFeed_ProgramCompletions_Admin || [], programCompletionsSpecific]
  );

  const programResponsesResults_MAPPED = useMemo(() => 
    programResponsesSpecific ? (programResponses_Results.data || {}).activityFeed_ProgramResponses_Admin || ((programResponses_Results_CacheOnly || {}).data || {}).activityFeed_ProgramResponses_Admin || [] : [], 
    [(programResponses_Results.data || {}).activityFeed_ProgramResponses_Admin || [], programResponsesSpecific]
  );

  const newUsersResults_MAPPED = useMemo(() => 
    newUsersSpecific ? (newUsers_Results.data || {}).activityFeed_NewUsers_Admin || ((newUsers_Results_CacheOnly || {}).data || {}).activityFeed_NewUsers_Admin || [] : [],
    [(newUsers_Results.data || {}).activityFeed_NewUsers_Admin || [], newUsersSpecific]
  );
  
  return {    
    /**Orders**/
    ordersResults: ordersResults_MAPPED,
    formsResults: formsResults_MAPPED,

    /**Users**/
    newUsersResults: newUsersResults_MAPPED,
    userUploadsResults: userUploadsResults_MAPPED,

    /**Programs**/
    programCompletionsResults: programCompletionsResults_MAPPED,
    programResponsesResults: programResponsesResults_MAPPED,

    /**General**/
    loading: programResponses_Results.loading || programCompletions_Results.loading || userUploads_Results.loading || orders_Results.loading || newUsers_Results.loading
  };
};

export const Admin_InboxSMS_QueryConcat = ({ phone, greaterThan }) => {
  const greaterThan_EXISTS = greaterThan || greaterThan === 0;

  const limit = 20;

  const NativeResults_CacheOnly = useQuery(
    oneOnOneQuery_SMS_AdminNative,
    { variables: { page: 1, limit, phone, greaterThan }, fetchPolicy: "cache-only" }
  );

  /****/

  const [getNetworkResults, NativeResults] = useLazyQuery(oneOnOneQuery_SMS_AdminNative, { variables: { page: 1, limit, phone, greaterThan }, fetchPolicy: "network-only" });
  
  useEffect(() => { 
    if(((NativeResults_CacheOnly.data || {}).oneOnOneQuery_SMS_AdminNative || []).length === 0 || greaterThan_EXISTS){
      getNetworkResults();
    };
  }, [greaterThan ? true : false]);

  /****/

  if(greaterThan_EXISTS){
    return {
      NativeResults: (NativeResults.data || {}).oneOnOneQuery_SMS_AdminNative || (NativeResults_CacheOnly.data || {}).oneOnOneQuery_SMS_AdminNative || [],
      oneIsStillLoading: NativeResults.loading
    };
  };

  return {
    NativeResults: (NativeResults_CacheOnly.data || {}).oneOnOneQuery_SMS_AdminNative || [],
    oneIsStillLoading: NativeResults.loading
  };
};

export const Admin_Inbox_QueryConcat = ({ profileId, greaterThan }) => {
  const greaterThan_EXISTS = greaterThan || greaterThan === 0;

  const limit = 20;

  const NativeResults_CacheOnly = useQuery(
    oneOnOneQuery_AdminNative,
    { variables: { page: 1, limit, greaterThan, ...profileId !== 'activity-feed' && { profileId } }, fetchPolicy: "cache-only" }
  );

  /****/

  const [getNetworkResults, NativeResults] = useLazyQuery(oneOnOneQuery_AdminNative, { variables: { page: 1, limit, greaterThan, ...profileId !== 'activity-feed' && { profileId } }, fetchPolicy: "network-only" });
  
  useEffect(() => { 
    if(((NativeResults_CacheOnly.data || {}).oneOnOneQuery_AdminNative || []).length === 0 || greaterThan_EXISTS){
      getNetworkResults();
    }; 
  }, []);

  /****/
  
  if(greaterThan_EXISTS){
    return {
      NativeResults: (NativeResults.data || {}).oneOnOneQuery_AdminNative || (NativeResults_CacheOnly.data || {}).oneOnOneQuery_AdminNative || [],
      oneIsStillLoading: NativeResults.loading
    };
  };

  return {
    NativeResults: (NativeResults_CacheOnly.data || {}).oneOnOneQuery_AdminNative || [],    
    oneIsStillLoading: NativeResults.loading
  };
};

export const Admin_Tags_QueryConcat = ({ mediaSpecific, oneOnOneSpecific, sessionSpecific }) => {
  const limit = (oneOnOneSpecific || sessionSpecific || mediaSpecific) ? 20 : 100;
  
  const Results_CacheOnly = useQuery(
    oneOnOneQuery_AdminFeed,
    { 
      variables: { 
        ...(oneOnOneSpecific && { inboxSpecific: true }),
        ...(mediaSpecific && { mediaSpecific: true }),
        ...(sessionSpecific && { sessionSpecific: true }),
        page: 1, 
        limit
      }, 
      fetchPolicy: "cache-only"
    }
  );
  /****/

  const Results = useQuery(
    oneOnOneQuery_AdminFeed,
    { 
      variables: { 
        ...(oneOnOneSpecific && { inboxSpecific: true }),
        ...(mediaSpecific && { mediaSpecific: true }),
        ...(sessionSpecific && { sessionSpecific: true }),
        page: 1, 
        limit
      }, 
      fetchPolicy: "network-only" 
    }
  );

  const mainData = useMemo(() => 
    (Results.data || {}).oneOnOneQuery_AdminFeed || (Results_CacheOnly.data || {}).oneOnOneQuery_AdminFeed  || [], 
    [(Results.data || {}).oneOnOneQuery_AdminFeed]
  );

  return {
    data: mainData,
    loading: Results.loading
  };
};

export const upsertArchivedInboxInit = async ({ originId, destinationId, groupId, type, auxId }) => (
  (await apolloClient.mutate({
    mutation: upsertArchivedInbox,
    variables: {
      input: { originId, groupId, ...['sms-twilio', 'announcement'].indexOf(type) > -1 ? { type, auxId } : { destinationId } }
    }
  })) || {}
).data.upsertArchivedInbox;

export const removeArchivedInboxInit = async ({ originId, destinationId, groupId, type, auxId }) => (
  (await apolloClient.mutate({
    mutation: removeArchivedInbox,
    variables: {
      input: { originId, groupId, ...['sms-twilio', 'announcement'].indexOf(type) > -1 ? { type, auxId } : { destinationId } }
    }
  })) || {}
).data.removeArchivedInbox;


export const adminAddUserInit = async (input) => {
  try{
    await apolloClient.mutate({
      mutation: adminAddUser,
      variables: { input }
    });
  } catch (e) {
    console.log(e);
    throw new Error(e);
  };

  return;
};