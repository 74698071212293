import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { parallel } from 'async-es';
import GalleryMainRenderParser from './GalleryMainRenderParser';
import apolloClient from '../../../../apollo.client';
import { Gallery, searchLessons } from '../../../../apollo.queries';
import { updateGeneralState } from '../../../../actions/general.actions';
import { getLogoKey } from '../../../../actions/user.actions';

class GalleryMainRender extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      additionalQueriedFiles: [],
      currentPage: 1,
      limit: 24
    };
  }

  componentDidUpdate(prevProps) {
    const { generalState } = this.props;
    const { updatingMedia, deletingMedia } = generalState;

    if (
      (updatingMedia && prevProps.generalState.updatingMedia !== updatingMedia) ||
      (deletingMedia && prevProps.generalState.deletingMedia !== deletingMedia)
    ) {
      this.setState({
        additionalQueriedFiles: [],
        currentPage: 1,
        limit: 24
      });
    }
  }

  handleScroll = async () => {
    const { loadingParent, updateGeneralState, profileId = null, lessonsExist, requester, activeGlobalAssetFilter, tags } = this.props;
    const { loadingAdditionalFiles, additionalQueriedFiles, currentPage, limit } = this.state;

    const orgLogo = requester.activeGroup.tenant.logo;
    const orgId = requester.activeGroup.tenant.id;

    let splitLogo = orgLogo && orgLogo.split('.');
    if(splitLogo){
      splitLogo.pop();
      splitLogo = splitLogo.join('.');
    };
    
    const fallbackImage = splitLogo ? `https://dgpv43ylujmyh.cloudfront.net/${getLogoKey(orgLogo, orgId)}` : '/images/android-chrome-512x512.png';

    if(
      !loadingParent && !loadingAdditionalFiles && !(
        this.Media_EndOfQuery && 
        this.Lessons_EndOfQuery
      )
    ){
      this.setState({ loadingAdditionalFiles: true }, async () => {
        const {
          MediaResults = {},
          LessonsResults = {}
        } = await new Promise(
          resolve => 
            parallel({
              MediaResults: callback =>
                setTimeout(
                  async () => 
                    callback(
                      null,
                      this.Media_EndOfQuery || ("lessons" === activeGlobalAssetFilter && lessonsExist)
                        ? []
                        : await apolloClient.query({
                            query: Gallery,
                            fetchPolicy: 'network-only',
                            variables: {
                              profileId,
                              tags,
                              page: currentPage + 1,
                              limit: limit
                            }
                          }) 
                    )                  
                , 1),      
              LessonsResults: callback =>
                setTimeout(
                  async () => 
                    callback(
                      null,
                      (this.Lessons_EndOfQuery || !lessonsExist || ["lessons", "all"].indexOf(activeGlobalAssetFilter) === -1)
                          ? []
                          : await apolloClient.query({
                              query: searchLessons,
                              fetchPolicy: 'network-only',
                              variables: {
                                tags,
                                page: currentPage + 1,
                                limit
                              }
                            })
                      )                  
                  , 2)

            }, (_, results) => resolve(results || {}))
        );        

        const MediaResults_Final = (((MediaResults.data || {}).searchMedia || {}).gallery || []).map(media => ({ ...media, timeStamp: new Date(media.createdAt).getTime() }));
        const LessonsResults_Final = (((LessonsResults.data || {}).searchLessons || {}).lessons || []).map(lesson => ({ ...lesson, isLesson: true, url: lesson.featuredImage || fallbackImage, timeStamp: new Date(lesson.createdAt).getTime() }));

        const newResults = [
          ...MediaResults_Final,
          ...LessonsResults_Final
        ];

        this.setState({
          additionalQueriedFiles: [
            ...additionalQueriedFiles,
            ...newResults
          ]
        }, () => {
          this.Media_EndOfQuery = MediaResults_Final.length === 0;
          this.Lessons_EndOfQuery = LessonsResults_Final.length === 0;

          if(this.changingViewMedia){
            this.changingViewMedia = false;
  
            if(this.Media_EndOfQuery){
              updateGeneralState({ changeViewMedia: 0 });
            };
          };

          setTimeout(
            () => 
              this.setState({
                loadingAdditionalFiles: false,
                currentPage: currentPage + 1
              })
            , 250
          );
        });
      });
    };
  };

  render() {
    const {
      profile,
      loadingParent,
      requester,
      onRemove,
      onSelect,
      parentData = [],
      simplified,
      imagesOnly,
      videosOnly,
      itemsSelected,
      generalState,
      onRadioInit,
      lessonsExist,
      hideNewFiles,
      onFileDropChange,
      updateGeneralState,
      profileId = null
    } = this.props;

    const {
      additionalQueriedFiles = [],
      loadingAdditionalFiles
    } = this.state;
    
    return (
      <GalleryMainRenderParser
        onSelect={onSelect}
        onRemove={onRemove}
        onFileDropChange={onFileDropChange}
        profileId={profileId}
        requester={requester}
        videosOnly={videosOnly}
        imagesOnly={imagesOnly}
        itemsSelected={itemsSelected}
        generalState={generalState}
        parentData={parentData}
        profile={profile}
        hideNewFiles={hideNewFiles}
        lessonsExist={lessonsExist}
        simplified={simplified}
        onRadioInit={onRadioInit}
        update_local_changingViewMedia={val => (this.changingViewMedia = val)}
        local_changingViewMedia={this.changingViewMedia}
        hasMore={!(this.Media_EndOfQuery && this.Lessons_EndOfQuery) || loadingParent || loadingAdditionalFiles}
        markAsDone={() => { this.Media_EndOfQuery = true; this.Lessons_EndOfQuery = true; }}
        onEndReached={this.handleScroll}
        loadingParent={loadingParent}
        updateGeneralState={updateGeneralState}
        additionalItems={additionalQueriedFiles}
        loadingAdditionalItems={loadingAdditionalFiles}
      />
    );
  }
};

const mapStateToProps = state => ({
  generalState: state.generalState
});

const mapDispatchToProps = dispatch => ({
  updateGeneralState: updatedStates =>
    dispatch(updateGeneralState(updatedStates))
});

export default connect(mapStateToProps, mapDispatchToProps)(GalleryMainRender);
