import gql from 'graphql-tag';
import Analytics from '@segment/analytics.js-core/build/analytics';
import SegmentIntegration from '@segment/analytics.js-integration-segmentio';
import apolloClient from '../apollo.client';
import ACTIONS from '../constants/program/actions';

import {
  upsertTag,
  upsertPackage,
  upsertTagConnection,
  removeTagConnection,
  deactivateTag,
  sendAnalyticEvent,
  upsertMediaGroup,
  removeMediaGroup
} from '../apollo.mutations';
import { packageQuery } from '../apollo.queries';
import { useCallback } from 'react';

// instantiate the library
const analytics = new Analytics();

// add Segment's own integration ( or any other device mode integration )
analytics.use(SegmentIntegration);

// define the integration settings object.
// Since we are using only Segment integration in this example, we only have
// "Segment.io" in the integrationSettings object
const integrationSettings = {
  'Segment.io': {
    apiKey: '1MExx1PoHPWRkiWhnobQkeHEmpL8u3tm',
    retryQueue: true,
    addBundledMetadata: true
  }
};

// Initialize the library
analytics.initialize(integrationSettings);

/** New Functions **/

let MatType = Float32Array;

const m4 = {
  translate: (m, tx, ty, tz, dst) => {
    // This is the optimized version of
    // return multiply(m, translation(tx, ty, tz), dst);
    dst = dst || new MatType(16);

    var m00 = m[0];
    var m01 = m[1];
    var m02 = m[2];
    var m03 = m[3];
    var m10 = m[1 * 4 + 0];
    var m11 = m[1 * 4 + 1];
    var m12 = m[1 * 4 + 2];
    var m13 = m[1 * 4 + 3];
    var m20 = m[2 * 4 + 0];
    var m21 = m[2 * 4 + 1];
    var m22 = m[2 * 4 + 2];
    var m23 = m[2 * 4 + 3];
    var m30 = m[3 * 4 + 0];
    var m31 = m[3 * 4 + 1];
    var m32 = m[3 * 4 + 2];
    var m33 = m[3 * 4 + 3];

    if (m !== dst) {
      dst[ 0] = m00;
      dst[ 1] = m01;
      dst[ 2] = m02;
      dst[ 3] = m03;
      dst[ 4] = m10;
      dst[ 5] = m11;
      dst[ 6] = m12;
      dst[ 7] = m13;
      dst[ 8] = m20;
      dst[ 9] = m21;
      dst[10] = m22;
      dst[11] = m23;
    }

    dst[12] = m00 * tx + m10 * ty + m20 * tz + m30;
    dst[13] = m01 * tx + m11 * ty + m21 * tz + m31;
    dst[14] = m02 * tx + m12 * ty + m22 * tz + m32;
    dst[15] = m03 * tx + m13 * ty + m23 * tz + m33;

    return dst;
  },
  scale: (m, sx, sy, sz, dst) => {
    // This is the optimized version of
    // return multiply(m, scaling(sx, sy, sz), dst);
    dst = dst || new MatType(16);

    dst[ 0] = sx * m[0 * 4 + 0];
    dst[ 1] = sx * m[0 * 4 + 1];
    dst[ 2] = sx * m[0 * 4 + 2];
    dst[ 3] = sx * m[0 * 4 + 3];
    dst[ 4] = sy * m[1 * 4 + 0];
    dst[ 5] = sy * m[1 * 4 + 1];
    dst[ 6] = sy * m[1 * 4 + 2];
    dst[ 7] = sy * m[1 * 4 + 3];
    dst[ 8] = sz * m[2 * 4 + 0];
    dst[ 9] = sz * m[2 * 4 + 1];
    dst[10] = sz * m[2 * 4 + 2];
    dst[11] = sz * m[2 * 4 + 3];

    if (m !== dst) {
      dst[12] = m[12];
      dst[13] = m[13];
      dst[14] = m[14];
      dst[15] = m[15];
    }

    return dst;
  },
  orthographic: (left, right, bottom, top, near, far, dst) => {
    dst = dst || new MatType(16);

    dst[ 0] = 2 / (right - left);
    dst[ 1] = 0;
    dst[ 2] = 0;
    dst[ 3] = 0;
    dst[ 4] = 0;
    dst[ 5] = 2 / (top - bottom);
    dst[ 6] = 0;
    dst[ 7] = 0;
    dst[ 8] = 0;
    dst[ 9] = 0;
    dst[10] = 2 / (near - far);
    dst[11] = 0;
    dst[12] = (left + right) / (left - right);
    dst[13] = (bottom + top) / (bottom - top);
    dst[14] = (near + far) / (near - far);
    dst[15] = 1;

    return dst;
  },
  scaling: (sx, sy) => {
    return [
      sx, 0, 0,
      0, sy, 0,
      0, 0, 1,
    ];
  },
  translation: (tx, ty) => {
    return [
      1, 0, 0,
      0, 1, 0,
      tx, ty, 1,
    ];
  }
};

const isPowerOf2 = (value) => {
  return (value && (value - 1)) == 0;
}

export const renderTextWithBreaks = (v) => {
  const split_a = (v && v.split('\n')) || []
  return split_a.map((l, i) => <>{l}{(i < (split_a.length - 1)) && <br />}</>)
};

export const drawWebglImage = ({
  gl, program, elementRef, texWidth, texHeight,
  dstX, dstY, dstWidth, dstHeight,
  srcX, srcY, srcWidth, srcHeight,
  matrixLocation, textureMatrixLocation, textureLocation, positionBuffer, positionLocation
}) => {
  const tex = gl.createTexture();

  gl.bindTexture(gl.TEXTURE_2D, tex);
 
  gl.texImage2D(gl.TEXTURE_2D, 0, gl.RGBA, 1, 1, 0, gl.RGBA, gl.UNSIGNED_BYTE, new Uint8Array([0, 0, 255, 255]));

  // let's assume all images are not a power of 2
  if (isPowerOf2(texWidth) && isPowerOf2(texHeight)) {
    // Yes, it's a power of 2. Generate mips.
    gl.generateMipmap(gl.TEXTURE_2D);
 } else {
    // No, it's not a power of 2. Turn off mips and set
    // wrapping to clamp to edge
    gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_WRAP_S, gl.CLAMP_TO_EDGE);
    gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_WRAP_T, gl.CLAMP_TO_EDGE);
    gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_MIN_FILTER, gl.LINEAR);
 };

  gl.bindTexture(gl.TEXTURE_2D, tex);

  gl.texImage2D(gl.TEXTURE_2D, srcWidth, srcHeight, 0, gl.RGBA, gl.RGBA, gl.UNSIGNED_BYTE, elementRef);

  gl.bindTexture(gl.TEXTURE_2D, tex);

  // Tell WebGL to use our shader program pair
  gl.useProgram(program);

  // Setup the attributes to pull data from our buffers
  gl.bindBuffer(gl.ARRAY_BUFFER, positionBuffer);
  gl.enableVertexAttribArray(positionLocation);
  gl.vertexAttribPointer(positionLocation, 2, gl.FLOAT, false, 0, 0);

  // this matrix will convert from pixels to clip space
  var matrix = m4.orthographic(0, gl.canvas.width, gl.canvas.height, 0, -1, 1);

  // this matrix will translate our quad to dstX, dstY
  matrix = m4.translate(matrix, dstX, dstY, 0);

  // this matrix will scale our 1 unit quad
  // from 1 unit to texWidth, texHeight units
  matrix = m4.scale(matrix, dstWidth, dstHeight, 1);

  // Set the matrix.
  gl.uniformMatrix4fv(matrixLocation, false, matrix);

  // just like a 2d projection matrix except in texture space (0 to 1)
  // instead of clip space. This matrix puts us in pixel space.
  var texMatrix = m4.scaling(1 / texWidth, 1 / texHeight, 1);

  // because were in pixel space
  // the scale and translation are now in pixels
  var texMatrix = m4.translate(texMatrix, srcX, srcY, 0);
  var texMatrix = m4.scale(texMatrix, srcWidth, srcHeight, 1);

  // Set the texture matrix.
  gl.uniformMatrix4fv(textureMatrixLocation, false, texMatrix);

  // Tell the shader to get the texture from texture unit 0
  gl.uniform1i(textureLocation, 0);

  // draw the quad (2 triangles, 6 vertices)
  gl.drawArrays(gl.TRIANGLES, 0, 6);
};

export const timeAgoShort = ms => {
  var ago = Math.floor(ms / 1000);
  var part = 0;

  if (ago < 60) {
    return ago + 's';
  }

  if (ago < 3600) {
    while (ago >= 60) {
      ago -= 60;
      part += 1;
    }
    return part + 'm';
  }

  if (ago < 86400) {
    while (ago >= 3600) {
      ago -= 3600;
      part += 1;
    }
    return part + 'h';
  }

  if (ago < 604800) {
    while (ago >= 172800) {
      ago -= 172800;
      part += 1;
    }
    return part + 'd';
  }

  if (ago < 31536000) {
    while (ago >= 604800) {
      ago -= 604800;
      part += 1;
    }
    return part + 'w';
  }

  if (ago < 1419120000) {
    while (ago >= 31104000) {
      ago -= 31104000;
      part += 1;
    }
    return part + 'y';
  }

  // TODO pass in Date.now() and ms to check for 0 as never
  return 'never';
};

export const timeUntil = ms => {
  var ago = Math.floor(ms / 1000);
  var part = 0;

  if (ago < 60) {
    return ago + ' seconds';
  }

  if (ago < 120) {
    return 'a minute';
  }
  if (ago < 3600) {
    while (ago >= 60) {
      ago -= 60;
      part += 1;
    }
    return part + ' minutes';
  }

  if (ago < 7200) {
    return 'an hour';
  }
  if (ago < 86400) {
    while (ago >= 3600) {
      ago -= 3600;
      part += 1;
    }
    return part + ' hours';
  }

  if (ago < 172800) {
    return 'a day';
  }
  if (ago < 604800) {
    while (ago >= 86400) {
      ago -= 86400;
      part += 1;
    }
    return part + ' days';
  }

  if (ago < 1209600) {
    return 'a week';
  }
  if (ago < 2592000) {
    while (ago >= 604800) {
      ago -= 604800;
      part += 1;
    }
    return part + ' weeks';
  }

  if (ago < 5184000) {
    return 'a month ago';
  }
  if (ago < 31536000) {
    while (ago >= 2592000) {
      ago -= 2592000;
      part += 1;
    }
    return part + ' months';
  }

  if (ago < 1419120000) {
    // 45 years, approximately the epoch
    return 'more than year';
  }

  // TODO pass in Date.now() and ms to check for 0 as never
  return 'never';
};

export const timeAgo = ms => {
  var ago = Math.floor(ms / 1000);
  var part = 0;

  if (ago < 2) {
    return 'a moment ago';
  }
  if (ago < 5) {
    return 'moments ago';
  }
  if (ago < 60) {
    return ago + ' seconds ago';
  }

  if (ago < 120) {
    return 'a minute ago';
  }
  if (ago < 3600) {
    while (ago >= 60) {
      ago -= 60;
      part += 1;
    }
    return part + ' minutes ago';
  }

  if (ago < 7200) {
    return 'an hour ago';
  }
  if (ago < 86400) {
    while (ago >= 3600) {
      ago -= 3600;
      part += 1;
    }
    return part + ' hours ago';
  }

  if (ago < 172800) {
    return 'a day ago';
  }
  if (ago < 604800) {
    while (ago >= 172800) {
      ago -= 172800;
      part += 1;
    }
    return part + ' days ago';
  }

  if (ago < 1209600) {
    return 'a week ago';
  }
  if (ago < 2592000) {
    while (ago >= 604800) {
      ago -= 604800;
      part += 1;
    }
    return part + ' weeks ago';
  }

  if (ago < 5184000) {
    return 'a month ago';
  }
  if (ago < 31536000) {
    while (ago >= 2592000) {
      ago -= 2592000;
      part += 1;
    }
    return part + ' months ago';
  }

  if (ago < 1419120000) {
    // 45 years, approximately the epoch
    return 'more than year ago';
  }

  // TODO pass in Date.now() and ms to check for 0 as never
  return 'never';
};

/**Start of Segment Analytics**/

export const segment_pageView = ({ groupId, userId, name, category }) =>
  analytics.page(category, name, {
    ...(document.getElementsByTagName('title')[0] && {
      title: document.getElementsByTagName('title')[0].innerHTML
    }),
    url: window.location.href
  });

export const segment_updateGroup = (groupId, variables) =>
  analytics.group(groupId, variables);

export const segmentUserInit = profile => {
  const {
    tenant,
    currentGroup,
    user: { id, firstName, lastName, email, phone }
  } = profile;

  analytics.identify(id, {
    ...(currentGroup && { groupId: currentGroup.id }),
    traits: {
      name: firstName || lastName ? `${firstName} ${lastName}` : `No name`,
      email,
      phone
    }
  });

  if (currentGroup && tenant) {
    const { id, name } = currentGroup;

    segment_updateGroup(id, {
      name,
      org_id: tenant.id,
      org_name: tenant.name
    });
  }
};

export const segment_siteUpdate = ({
  groupId,
  programs_connected,
  program_sections,
  packages_connected,
  package_sections,
  packages_enabled
}) =>
  segment_updateGroup(groupId, {
    programs_connected,
    program_sections,
    packages_connected,
    package_sections,
    packages_enabled
  });

/**End of Segment Analytics**/

export const sendAnalyticEventInit = async ({
  type,
  programId,
  lessonId,
  mediaId
}) => {
  const sendAnalyticEventData = (
    (await apolloClient.mutate({
      mutation: sendAnalyticEvent,
      variables: {
        input: {
          type,
          programId,
          lessonId,
          mediaId
        }
      }
    })) || {}
  ).data.sendAnalyticEvent.success;

  return { sendAnalyticEventData };
};

export const upsertPackageInit = ({
  activePackage: {
    id,
    features = { included: [] },
    price = '0',
    recurring = 0,
    label,
    description,
    processingFee,
    confirmationPage = 'default',
    confirmationUrl,
    interval = 'month',
    intervalCount = 1,
    accessCodes = [],
    accessCodes_toRemove = [],
    accessCode_CONFIG
  }
}) => async dispatch => {
  dispatch({
    type: ACTIONS.UPDATE_STATE,
    payload: {
      upsertingPackage: true
    }
  })

  const upsertedPackage = (
    (await apolloClient.mutate({
      mutation: upsertPackage,
      variables: {
        input: {
          id,
          features: JSON.stringify(features),
          price: `${price}`,
          recurring: recurring ? true : false,
          label,
          description,
          interval,
          confirmationUrl,
          confirmationPage,
          processingFee,
          intervalCount,
          accessCodes: accessCodes?.map(c => ({ ...c, ...c.limit && { limit: parseInt(c.limit) || 0 } })),
          accessCodes_toRemove,
          accessCode_CONFIG
        }
      }
    })) || {}
  ).data.upsertPackage.success

  dispatch({
    type: ACTIONS.UPDATE_STATE,
    payload: {
      upsertingPackage: false
    }
  })

  return { upsertedPackage };
};

export const filteredTags_MULTIPLE_ORIGIN = ({ activeTags, origins = [] }) =>
  activeTags.filter(
    ({ tag }) =>
      origins.find(origin => tag[origin] &&
        (
          !tag.parentId || activeTags.find(
            categoryLocalChild =>
              categoryLocalChild.tag[origin] &&
              categoryLocalChild.tag.id === tag.parentId
          )
        )
      )      
  );

export const filteredTags = ({ activeTags, origin }) =>
  activeTags.filter(
    ({ tag }) =>
      tag[origin] &&
      (!tag.parentId ||
        activeTags.find(
          categoryLocalChild =>
            categoryLocalChild.tag[origin] &&
            categoryLocalChild.tag.id === tag.parentId
        ))
  );

export const untaggedFilter_MULTIPLE_ORIGIN = ({ assetsToFilter, origins = [] }) => {
  try {
    return assetsToFilter.filter(
      ({ tags = [] }) =>
        (tags || []).length === 0 ||
        filteredTags_MULTIPLE_ORIGIN({ activeTags: tags, origins }).length === 0
    );
  } catch (e) {
    console.log(e)
  };

  return assetsToFilter;
}

export const untaggedFilter = ({ assetsToFilter, origin }) =>
  assetsToFilter.filter(
    ({ tags = [] }) =>
      (tags || []).length === 0 ||
      filteredTags({ activeTags: tags, origin }).length === 0
  );

export const filterAssets_MULTIPLE_ORIGIN = ({
  assetsToFilter,
  activeSidebarCategories = [],
  generalState,
  origins = [],
  activeUserBasedTags,
  activeRoleBasedTags
}) => {
  try {
    return assetsToFilter.filter(
      ({ tags = [], profileId, athlete = {}, role, lastPurchase }) =>
        (activeSidebarCategories.length === 0 ||
          filteredTags_MULTIPLE_ORIGIN({ activeTags: tags, origins }).find(({ tag }) =>
            tag.parentId
              ? (generalState[`${tag.parentId}_children`] || []).find(
                  category => category.id === tag.id
                ) && tags.find(category => category.tag.id === tag.parentId)
              : (generalState[`${tag.id}_children`] || []).length > 0
              ? false
              : activeSidebarCategories.find(category => category.id === tag.id)
          )) &&
        (activeUserBasedTags || activeRoleBasedTags
          ? activeUserBasedTags && Object.keys(activeUserBasedTags).length > 0
            ? activeUserBasedTags[profileId || (athlete && athlete.id)]
            : activeRoleBasedTags && Object.keys(activeRoleBasedTags).length > 0
            ? (['Admin', 'Master'].indexOf(role) > -1
                ? activeRoleBasedTags['admin']
                : activeRoleBasedTags['users']) || (activeRoleBasedTags['customer'] && lastPurchase && lastPurchase.id)
            : true
          : true)
    );
  } catch (e) {
    console.log(e)
  };

  return assetsToFilter;
};

export const filterAssets = ({
  assetsToFilter,
  activeSidebarCategories = [],
  generalState,
  origin,
  activeUserBasedTags,
  activeRoleBasedTags
}) =>
  assetsToFilter.filter(
    ({ tags = [], profileId, athlete = {}, role, lastPurchase }) =>
      (activeSidebarCategories.length === 0 ||
        filteredTags({ activeTags: tags, origin }).find(({ tag }) =>
          tag.parentId
            ? (generalState[`${tag.parentId}_children`] || []).find(
                category => category.id === tag.id
              ) && tags.find(category => category.tag.id === tag.parentId)
            : (generalState[`${tag.id}_children`] || []).length > 0
            ? false
            : activeSidebarCategories.find(category => category.id === tag.id)
        )) &&
      (activeUserBasedTags || activeRoleBasedTags
        ? activeUserBasedTags && Object.keys(activeUserBasedTags).length > 0
          ? activeUserBasedTags[profileId || (athlete && athlete.id)]
          : activeRoleBasedTags && Object.keys(activeRoleBasedTags).length > 0
          ? (['Admin', 'Master'].indexOf(role) > -1
              ? activeRoleBasedTags['admin']
              : activeRoleBasedTags['users']) || (activeRoleBasedTags['customer'] && lastPurchase && lastPurchase.id)
          : true
        : true)
  );

export const onUntagged = tagsToReset => async dispatch =>
  dispatch({
    type: ACTIONS.UPDATE_STATE,
    payload: {
      untaggedActive: true,
      activeSidebarCategories: [],
      ...tagsToReset
    }
  });

export const onTagSelect = ({
  activeSidebarCategories,
  parentChildren,
  toAdd
}) => async dispatch =>
  dispatch({
    type: ACTIONS.UPDATE_STATE,
    payload: {
      untaggedActive: false,
      activeSidebarCategories: [...(activeSidebarCategories || []), toAdd],
      ...(toAdd.parentId && {
        [`${toAdd.parentId}_children`]: [...(parentChildren || []), toAdd]
      })
    }
  });

export const onTagRemove = ({
  activeSidebarCategories,
  parentChildren,
  toRemove
}) => async dispatch => {
  const filteredCategories = (activeSidebarCategories || []).filter(
    category =>
      category.id !== toRemove.id &&
      (!category.parentId || category.parentId !== toRemove.id)
  );

  dispatch({
    type: ACTIONS.UPDATE_STATE,
    payload: {
      untaggedActive: false,
      ...(toRemove.parentId
        ? {
            [`${toRemove.parentId}_children`]:
              (parentChildren || []).length > 0
                ? parentChildren.filter(category => category.id !== toRemove.id)
                : undefined,
            activeSidebarCategories: filteredCategories
          }
        : {
            [`${toRemove.id}_children`]: undefined,
            activeSidebarCategories: filteredCategories
          })
    }
  });
};

export const upsertTagInit = ({
  newTag: { label, parentLabel },
  origin = 'media'
}) => async dispatch => {
  dispatch({
    type: ACTIONS.UPDATE_STATE,
    payload: {
      upsertingTag: true
    }
  });

  const upsertedTag = (
    (await apolloClient.mutate({
      mutation: upsertTag,
      variables: {
        input: {
          label,
          parentLabel,
          origin
        }
      }
    })) || {}
  ).data.upsertTag.success;

  dispatch({
    type: ACTIONS.UPDATE_STATE,
    payload: {
      resetTags: true
    }
  });

  setTimeout(
    () =>
      dispatch({
        type: ACTIONS.UPDATE_STATE,
        payload: {
          upsertingTag: false,
          resetTags: false
        }
      }),
    250
  );

  return { upsertedTag };
};

export const deactivateTagInit = ({
  label,
  parentLabel,
  origin = 'media'
}) => async dispatch => {
  dispatch({
    type: ACTIONS.UPDATE_STATE,
    payload: {
      deactivatingTag: true
    }
  });

  const deactivatedTag = (
    (await apolloClient.mutate({
      mutation: deactivateTag,
      variables: {
        input: {
          label,
          parentLabel,
          origin
        }
      }
    })) || {}
  ).data.deactivateTag.success;

  dispatch({
    type: ACTIONS.UPDATE_STATE,
    payload: {
      resetTags: true,
      updatingTags: true
    }
  });

  setTimeout(
    () =>
      dispatch({
        type: ACTIONS.UPDATE_STATE,
        payload: {
          resetTags: false,
          updatingTags: false
        }
      }),
    250
  );

  setTimeout(
    () =>
      dispatch({
        type: ACTIONS.UPDATE_STATE,
        payload: {
          deactivatingTag: false
        }
      }),
    500
  );

  return { deactivatedTag };
};

export const upsertTagConnectionInit = ({
  tagId,
  assetId,
  origin,
  originRef = 'mediaId',
  label,
  dontDispatch
}) => async dispatch => {
  if (!dontDispatch) {
    dispatch({
      type: ACTIONS.UPDATE_STATE,
      payload: {
        upsertingTagConnection: true
      }
    });
  }

  const upsertedTag = (
    (await apolloClient.mutate({
      mutation: upsertTagConnection,
      variables: {
        input: {
          tagId,
          assetId,
          label,
          origin,
          originRef
        }
      }
    })) || {}
  ).data.upsertTagConnection;

  if (!dontDispatch) {
    dispatch({
      type: ACTIONS.UPDATE_STATE,
      payload: {
        upsertingTagConnection: false
      }
    });
  }

  return upsertedTag;
};

export const removeTagConnectionInit = ({
  id,
  tagId,
  assetId,
  originRef,
  label,
  dontDispatch
}) => async dispatch => {
  if (!dontDispatch) {
    dispatch({
      type: ACTIONS.UPDATE_STATE,
      payload: {
        removingTagConnection: true
      }
    });
  }

  const removedTag = (
    (await apolloClient.mutate({
      mutation: removeTagConnection,
      variables: {
        input: { id, label, tagId, assetId, originRef }
      }
    })) || {}
  ).data.removeTagConnection.success;

  if (!dontDispatch) {
    dispatch({
      type: ACTIONS.UPDATE_STATE,
      payload: {
        removingTagConnection: false
      }
    });
  }

  return { removedTag };
};

export const upsertMediaGroupInit = ({
  groupId,
  mediaId,
  dontDispatch
}) => async dispatch => {
  if (!dontDispatch) {
    dispatch({
      type: ACTIONS.UPDATE_STATE,
      payload: {
        upsertingTagConnection: true
      }
    });
  }

  const upsertedTag = (
    (await apolloClient.mutate({
      mutation: upsertMediaGroup,
      variables: {
        input: {
          groupId,
          mediaId
        }
      }
    })) || {}
  ).data.upsertMediaGroup;

  if (!dontDispatch) {
    dispatch({
      type: ACTIONS.UPDATE_STATE,
      payload: {
        upsertingTagConnection: false
      }
    });
  };

  return upsertedTag;
};

export const removeMediaGroupInit = ({
  id,
  dontDispatch
}) => async dispatch => {
  if (!dontDispatch) {
    dispatch({
      type: ACTIONS.UPDATE_STATE,
      payload: {
        removingTagConnection: true
      }
    });
  };

  const removedTag = (
    (await apolloClient.mutate({
      mutation: removeMediaGroup,
      variables: {
        input: { id }
      }
    })) || {}
  ).data.removeMediaGroup.success;

  if (!dontDispatch) {
    dispatch({
      type: ACTIONS.UPDATE_STATE,
      payload: {
        removingTagConnection: false
      }
    });
  }

  return { removedTag };
};

/** Legacy Functions **/

const addToGallery = gql`
  mutation($input: UpsertGalleryInput!) {
    addToGallery(input: $input) {
      id
      label
      url
      createdAt
    }
  }
`;

const getSignedUrl = gql`
  query($fileName: String!) {
    mediaSignedUrl(fileName: $fileName)
  }
`;

function futch(url, opts = {}, onProgress) {
  return new Promise((resolve, reject) => {
    const xhr = new XMLHttpRequest();
    xhr.open(opts.method || 'get', url);
    for (const k in opts.headers || {}) {
      xhr.setRequestHeader(k, opts.headers[k]);
    }

    xhr.onload = e => {
      resolve(e.target.responseText ? JSON.parse(e.target.responseText) : null);
    };

    xhr.onerror = reject;
    if (xhr.upload && onProgress) {
      // event.loaded / event.total * 100 ; //event.lengthComputable
      xhr.upload.onprogress = onProgress;
    }
    xhr.send(opts.body);
  });
}

export const resetGeneralState = () => dispatch =>
  dispatch({
    type: ACTIONS.RESET_STATE
  });

export const updateGeneralState = updatedStates => dispatch =>
  dispatch({
    type: ACTIONS.UPDATE_STATE,
    payload: updatedStates
  });

export const NotificationSettingsRef = {
  Admin: [
    {
      title: 'Analysis Notifications',
      settings: [
        {
          id: 'admin_user_analysis_upload',
          label: 'Receive an email when a user uploads a film'
        }
      ]
    },
    // {
    //   title: 'Message Notifications',
    //   settings: [
    //     {
    //       id: 'admin_message_notification',
    //       label: 'Receive a text for a new message'
    //     }
    //   ]
    // },
    {
      title: 'Payment Notifications',
      settings: [
        {
          id: 'admin_course_purchase',
          label: 'Receive an email when a user purchases a course'
        },
        {
          id: 'admin_package_purchase',
          label: 'Receive an email when a user purchases a package'
        }
      ]
    },
    {
      title: 'User Notifications',
      settings: [
        {
          id: 'admin_new_user',
          label: 'Receive an email when a new user joins your organization.'
        }
      ]
    }
  ],
  Coach: [
    {
      title: 'Analysis Notifications',
      settings: [
        {
          id: 'coach_assigned_session',
          label: 'Receive an email when assigned an analysis session'
        }
      ]
    }
    // {
    //   title: 'Message Notifications',
    //   settings: [
    //     {
    //       id: 'coach_message_notification',
    //       label: 'Receive a text for a new message'
    //     }
    //   ]
    // }
  ],
  User: [
    {
      title: 'Purchase Notifications',
      settings: [
        {
          id: 'user_course_purchase',
          label: 'Receive an email when you purchase a course'
        },
        {
          id: 'user_package_purchase',
          label: 'Receive an email when you purchase a package'
        }
      ]
    },
    // {
    //   title: 'Message Notifications',
    //   settings: [
    //     {
    //       id: 'user_message_notification',
    //       label: 'Receive a text for a new message'
    //     },
    //     {
    //       id: 'user_text_group_invitation',
    //       label: 'Receive a text when you receive an invitation'
    //     },
    //     {
    //       id: 'user_email_group_invitation',
    //       label: 'Receive an email when you receive an invitation'
    //     }
    //   ]
    // },
    {
      title: 'Course Notifications',
      settings: [
        {
          id: 'course_lesson_unlocked',
          label: 'Receive a text when a lesson unlocks'
        }
      ]
    },
    {
      title: 'Analysis Notifications',
      settings: [
        {
          id: 'analysis_session_complete',
          label: 'Receive a text when a video analysis session is ready.'
        }
      ]
    }
  ]
};

/**Gallery Specific**/

export const fileTypeList = {
  bmp: 'image',
  gif: 'image',
  ico: 'image',
  jpeg: 'image',
  jpg: 'image',
  png: 'image',
  svg: 'image',
  tif: 'image',
  tiff: 'image',
  webp: 'image',
  webm: 'video',
  mpg: 'video',
  mp2: 'video',
  mpeg: 'video',
  mpe: 'video',
  mpv: 'video',
  ogg: 'video',
  mp4: 'video',
  m4p: 'video',
  m4v: 'video',
  avi: 'video',
  wmv: 'video',
  mov: 'video',
  qt: 'video',
  flv: 'video',
  swf: 'video',
  avchd: 'video',
  '3gp': 'video'
};

const acceptedFileTypes = {
  bmp: 'image',
  gif: 'image',
  ico: 'image',
  jpeg: 'image',
  jpg: 'image',
  png: 'image',
  svg: 'image',
  tif: 'image',
  tiff: 'image',
  webp: 'image',
  webm: 'video',
  mpg: 'video',
  mp2: 'video',
  mpeg: 'video',
  mpe: 'video',
  mpv: 'video',
  ogg: 'video',
  mp4: 'video',
  avi: 'video',
  wmv: 'video',
  mov: 'video',
  flv: 'video',
  swf: 'video',
  avchd: 'video',
  '3gp': 'video'
};

export const isVideoFull = url => {
  const splitSrc = url && url.split('.');
  
  return (
    splitSrc &&
    fileTypeList[splitSrc[splitSrc.length - 1].toLowerCase()] === 'video'
  );
};

const addToGalleryInit = async ({ label, url, categoryId }) => {
  const payload = {
    label,
    url,
    categoryId
  };
  
  try {
    return await apolloClient.mutate({
      mutation: addToGallery,
      variables: {
        input: payload
      }
    });
  } catch (e) {
    console.log(e);
    return '';
  };
};

export const fileUpload_onChange_INLINE = async ({ files, updateLoadingProgress }) => {
  try {
    let filesToReturn = {}
    for(let fileObj of files){
      const split_file_name = fileObj.file.name.split('.')
      const fileType = split_file_name[split_file_name.length - 1].toLowerCase()
  
      if(!acceptedFileTypes[fileType]){
        alert(`File type "${fileType}" not allowed. (${fileObj.file.name})`)
      } else {      
        const mediaResponse = await apolloClient.query({
          query: getSignedUrl,
          fetchPolicy: 'network-only',
          variables: {
            fileName: split_file_name[split_file_name.length - 1].toLowerCase()
          }
        })
    
        const mediaSignedUrl = mediaResponse.data.mediaSignedUrl
    
        const form_data = new FormData();
        Object.keys(mediaSignedUrl.fields).map(key =>
          form_data.append(key, mediaSignedUrl.fields[key])
        )
    
        form_data.append('file', fileObj.file)
        const options = {
          method: 'POST',
          body: form_data
        }
  
        const res = await futch(
          'https://athletic-outlook.s3-accelerate.amazonaws.com',
          options,
          e => updateLoadingProgress({ id: fileObj.id, progress: e.loaded / e.total })
        ).catch(e => e)
    
        let mutationResponse = {}
        try {
          mutationResponse = await addToGalleryInit({
            label: split_file_name[0],
            url: mediaSignedUrl.url + '/' + mediaSignedUrl.fields.key
          });
        } catch (e) {
          console.log(e);
        }
  
        const { data = { addToGallery: false } } = mutationResponse
    
        filesToReturn[fileObj.id] = {
          progress: 1,
          tempUrl: fileObj.tempUrl,
          serverResponse: data.addToGallery,
          ...(res ? { failed: true } : { complete: true })          
        }
      }
    }
  
    return filesToReturn
  } catch (e) {
    alert('There was an error, please try again')
    return false
  }
}

export const fileUpload_onChange = ({
  files,
  currentFiles,
  pendingSets
}) => async dispatch => {
  const allFiles = files.reduce(
    (accumulator, currentValue) => ({
      ...accumulator,
      [currentValue.id]: {
        ...currentValue,
        isTempVideo: isVideoFull(currentValue.file.name)
      }
    }),
    {}
  );

  dispatch({
    type: ACTIONS.UPDATE_STATE,
    payload: {
      ...allFiles,
      filesUploading: {        
        ...allFiles,
        ...currentFiles
      },
      pendingSets,
      fromUpload: true
    }
  });

  for (let fileObj of files) {
    const split_file_name = fileObj.file.name.split('.');
    const fileType = split_file_name[split_file_name.length - 1].toLowerCase();

    if(!acceptedFileTypes[fileType]){
      alert(`File type "${fileType}" not allowed. (${fileObj.file.name})`);

      dispatch({
        type: ACTIONS.UPDATE_STATE,
        payload: {
          [fileObj.id]: undefined,
          [`${pendingSets}_completedFileSet`]: true
        }
      });
    } else {      
      const mediaResponse = await apolloClient.query({
        query: getSignedUrl,
        fetchPolicy: 'network-only',
        variables: {
          fileName: split_file_name[split_file_name.length - 1].toLowerCase()
        }
      });
  
      const mediaSignedUrl = mediaResponse.data.mediaSignedUrl;
  
      const form_data = new FormData();
      Object.keys(mediaSignedUrl.fields).map(key =>
        form_data.append(key, mediaSignedUrl.fields[key])
      );
  
      form_data.append('file', fileObj.file);
      const options = {
        method: 'POST',
        body: form_data
      };
  
      // Start uploading form to S3
      const res = await futch(
        'https://athletic-outlook.s3-accelerate.amazonaws.com',
        options,
        e =>
          dispatch({
            type: ACTIONS.UPDATE_STATE,
            payload: {
              [fileObj.id]: {
                progress: e.loaded / e.total
              }
            }
          })
      ).catch(e => e);
  
      let mutationResponse = {};
      try {
        mutationResponse = await addToGalleryInit({
          label: split_file_name[0],
          url: mediaSignedUrl.url + '/' + mediaSignedUrl.fields.key
        });
      } catch (e) {
        console.log(e);
      };

      const { data = { addToGallery: false } } = mutationResponse;
  
      dispatch({
        type: ACTIONS.UPDATE_STATE,
        payload: {
          [fileObj.id]: {
            progress: 1,
            serverResponse: data.addToGallery,
            ...(res ? { failed: true } : { complete: true })          
          },
          [`${pendingSets}_completedFileSet`]: true
        }
      });
    };
  }
};

export const getInstagramFeed = async userName => {
  const proxyUrl = (url, max_id) => {
    return `https://images${~~(
      Math.random() * 3333
    )}-focus-opensocial.googleusercontent.com/gadgets/proxy?container=fb&url=${url}${
      max_id ? `&max_id=${max_id}` : ''
    }`;
  };

  const mapMedia = json => {
    try {
      const thumbnailIndex = node => {
        node.thumbnail_resources.forEach((item, index) => {
          if (item.config_width === 640) {
            return index;
          }
        });

        return 4; // MAGIC
      };

      const src = node => {
        return 'https://www.instagram.com/p/' + node.shortcode;
      };

      const url = node => {
        switch (node.__typename) {
          case 'GraphVideo':
            return node.thumbnail_src;
          case 'GraphSidecar':
          default:
            return node.thumbnail_resources[thumbnailIndex(node)].src;
        }
      };

      const alt = node => {
        if (
          node.edge_media_to_caption.edges[0] &&
          node.edge_media_to_caption.edges[0].node
        ) {
          return node.edge_media_to_caption.edges[0].node.text;
        } else if (node.accessibility_caption) {
          return node.accessibility_caption;
        } else {
          return '';
        }
      };

      const edges =
        json.entry_data.ProfilePage[0].graphql.user.edge_owner_to_timeline_media
          .edges;

      const dateNow = new Date().getTime();

      return edges.map((edge, index) => {
        return {
          id: `instagram-${dateNow}-${index}`,
          label: alt(edge.node),
          src: src(edge.node),
          url: url(edge.node)
        };
      });
    } catch (err) {
      throw Error('cannot map media array');
    }
  };

  const getJSON = body => {
    try {
      const data = body.split('window._sharedData = ')[1].split('</script>')[0];
      return JSON.parse(data.substr(0, data.length - 1));
    } catch (err) {
      throw Error('cannot parse response body');
    }
  };

  return await new Promise(resolve =>
    fetch(proxyUrl(`https://www.instagram.com/${userName}/?__a=1`))
      .then(resp => resp.text())
      .then(body => getJSON(body))
      .then(json => resolve(mapMedia(json)))
  );
};

export const initSinglePackage = ({ packageId, groupId }) => async dispatch => {
  const { data = {} } =
    (await apolloClient.query({
      query: packageQuery,
      fetchPolicy: 'network-only',
      variables: { packageId, groupId }
    })) || {};

  if (!data.packageQuery) {
    alert('Package not found.');
    window.location = '';
    return;
  }

  dispatch({
    type: ACTIONS.UPDATE_STATE,
    payload: {
      activePackage: {
        ...data.packageQuery,
        features:
          data.packageQuery.features && JSON.parse(data.packageQuery.features)
      }
    }
  });
};
