import ACTIONS from '../constants/payment/actions';

const initialState = {
  ShowCardInputOverwrite: true,
  guestStripeToken: null,
  alreadyForceablyRan: false,
  saveCard: true
};

export default (state = initialState, action) => {
  switch (action.type) {
    case ACTIONS.RESET_STATE:
      return {
        ...state
      };

    case ACTIONS.UPDATE_STATE:
      return {
        ...state,
        ...action.payload
      };

    default:
      return state;
  }
};
