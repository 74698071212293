import React, { memo, useMemo } from 'react';

const RolesTagRender = ({ origin, onMostRecentInit, onMainClick, activateRoles, activeRoleBasedTags, mostRecentActive }) => {

  const rolesIterateRender = useMemo(
    () => (
      rolesToIterate.map(({ id, label }, index) => (
        <button
          key={`role-gallery-tag-${index}`}
          className={`gallery-tag-immediate role-gallery-tag ${activeRoleBasedTags[id] && 'active-selection'}`}
          onClick={() => onMainClick(id)}
        >
          {label}
        </button>
      ))
    ), [activeRoleBasedTags]
  );
  
  const mostRecentClassName = useMemo(() => `gallery-tag-immediate role-gallery-tag ${mostRecentActive && 'active-selection'}`, [mostRecentActive]);

  return (
    activateRoles
      ? <>
          <div className="tag-container user-profile-specific">
            <div className={`for-relativity children-exists`} key={`user-parent-tag-gallery-tag`}>
              {rolesIterateRender}
              {
                origin !== 'users' && (
                  <button
                    key={`role-gallery-tag-most-recent-specific`}
                    className={mostRecentClassName}
                    onClick={onMostRecentInit}
                  >
                    Most Recent
                  </button>
                )
              }
            </div>
          </div>
        </> 
      : <></>
  );
};

const rolesToIterate = [
  {
    id: 'admin',
    label: 'Admin'
  },                                  
  {
    id: 'users',
    label: 'Users'
  },
  {
    id: 'customer',
    label: 'Customers'
  }
];

export default memo(RolesTagRender);
