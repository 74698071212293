import React from 'react';
import TEMPLATES from './templates';

const landscapeIcon = (
  <svg
    width="22"
    height="20"
    viewBox="0 0 22 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M18.28 17.7902L19.591 18.3702C20.251 18.6602 20.991 18.1802 20.991 17.4502V2.52021C20.991 1.79021 20.241 1.31021 19.581 1.60021L18.27 2.18021C14.27 3.95021 7.70398 3.95021 3.70398 2.18021L2.38998 1.59021C1.72898 1.29021 0.97998 1.78021 0.97998 2.50021V17.4302C0.97998 18.1502 1.71998 18.6302 2.37998 18.3402L3.69098 17.7502C7.69098 15.9702 14.257 15.9702 18.257 17.7502L18.28 17.7902Z"
      stroke="#666666"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

const noSplitIcon = (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M16 19.0098H4C2.343 19.0098 1 17.6668 1 16.0098V4.00977C1 2.35277 2.343 1.00977 4 1.00977H16C17.657 1.00977 19 2.35277 19 4.00977V16.0098C19 17.6668 17.657 19.0098 16 19.0098Z"
      stroke="#666666"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const INITIAL_RECORDING_SLIDES_STATE = TEMPLATES['defaultTemplate'];

export const INITIAL_STATE = {
  constantGlobalHeight: 720,
  constantScaleFactor: 1,
  activeMasterElement: { mediaSection: 0 },

  /****/

  activeSlide: 0,
  recordingSlides: [JSON.parse(JSON.stringify(INITIAL_RECORDING_SLIDES_STATE))],

  playbackRateRefSecondary: 1,
  playbackRateRef: 1,

  aspectRatioSelection: {
    label: 'Landscape',
    slug: 'landscape',
    description: 'Youtube, Vimeo',
    ratioDescriptor: '16:9',
    aspectRatio: 0.5625,
    icon: landscapeIcon
  },

  screenTemplateSelection: {
    label: 'Default',
    slug: 'default',
    icon: noSplitIcon
  },

  activeLineType: 'drawing',
  activeEditorType: 'addMedia',
  lineWidth: 4,
  linesToRedo: [],
  lines: [],
  brushColor: '#FAE24C',
  allowVoiceRecording: true,
  allowCamRecording: false,

  downloadedFiles: {},
  isRecording: false,
  recordedFile: null,
  previewUrl: '',
  timeElapsed: 0,
  status: null,
  progress: 0,
  downloadProgress: 0,
  isPaused: false,
  activeRecordingQueue: true
};
