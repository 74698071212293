import ReactGA from 'react-ga';
import { useQuery } from 'react-apollo';
import isEqual from 'react-fast-compare';
import { useMemo, useCallback } from 'react';
import { parallel, mapLimit } from 'async-es';

import { signedUrlInit_BASIC, upsertGallery } from '../actions/upload.actions';
import { isVideoFull } from './general.actions';
import ACTIONS from '../constants/course/actions';

import apolloClient from '../apollo.client';

import {
  upsertFormResponse,
  upsertQuizAnswer,
  uploadLessonAnalysisFile,
  upsertTag,
  upsertTagConnection,
  upsertLesson,
  removeLesson,
  upsertSlides,
  upsertBlocks,
  removeSlides,
  removeBlocks
} from '../apollo.mutations';

import {
  lessonQuery,
  slideQuery,
  blockQuery,
  blockQuery_ADMIN
} from '../apollo.queries';


/** Start of Constants **/

function futch(url, opts = {}, onProgress) {
  return new Promise((resolve, reject) => {
    const xhr = new XMLHttpRequest();
    xhr.open(opts.method || 'get', url);
    for (const k in opts.headers || {}) {
      xhr.setRequestHeader(k, opts.headers[k]);
    }

    xhr.onload = e => {
      resolve(e.target.responseText ? JSON.parse(e.target.responseText) : null);
    };

    xhr.onerror = reject;
    if (xhr.upload && onProgress) {
      // event.loaded / event.total * 100 ; //event.lengthComputable
      xhr.upload.onprogress = onProgress;
    }
    xhr.send(opts.body);
  });
}

const videoFileExts = ['mp4', 'wmv', '3gp', 'ogg', 'webm', 'flv', 'avi', 'mov'];

export const chunks = (array, size) => {
  if (!array.length) {
    return [];
  }
  const head = array.slice(0, size);
  const tail = array.slice(size);

  return [head, ...chunks(tail, size)];
};

export const baseS3Url = 'https://dgpv43ylujmyh.cloudfront.net';

export const defaultSettingWeighting = {
  font_size: 0.1,
  padding: 0.25
};

export const typeSpecificBlockSettings = {
  form: {
    bg_color: '#F9B016',
    color: '#FFFFFF'
  },
  other: {
    bg_color: '#000000',
    color: '#FFFFFF'
  }
};

export const defaultBlockSettings = blockType => ({
  light_theme: false,
  media_fit: 'cover',
  font_size: 0.25,
  inline: true,
  scale: 1,
  x: 0,
  y: 0,
  width: 1,
  autoPlay: true,
  loop: true,
  mute: false,
  controls: false,
  nextSlideUponfinish: false,
  ...(typeSpecificBlockSettings[blockType] ||
    typeSpecificBlockSettings['other'])
});

export const getS3Key = (url = '') => {
  const splitUrl = url.split(
    `${
      url.indexOf('processed-voiceover') > -1
        ? '/processed-voiceover/'
        : url.indexOf('dgpv43ylujmyh.cloudfront.net') > -1
        ? '/'
        : '/athletic-outlook/'
    }undefined/`
  );

  const oneOrTwo = splitUrl[1] || splitUrl[0] || '';

  return oneOrTwo.split('.')[0];
};

export const getBlockVideoSources = (url = '') =>
  url
    ? {
        originalUrl: url
          .replace('s3.us-west-2.amazonaws.com', 'dgpv43ylujmyh.cloudfront.net')
          .replace(
            'athletic-outlook.s3.amazonaws.com',
            'dgpv43ylujmyh.cloudfront.net'
          )
          .replace('/athletic-outlook', ''),
        videoUrl:
          url.indexOf('/processed-voiceover/') > -1
            ? url
                .replace(
                  's3.us-west-2.amazonaws.com',
                  'dgpv43ylujmyh.cloudfront.net'
                )
                .replace(
                  'athletic-outlook.s3.amazonaws.com',
                  'dgpv43ylujmyh.cloudfront.net'
                )
                .replace('/athletic-outlook', '')
            : url.indexOf('/evaluation/') > -1
              ? `${url
                  .replace(
                    's3.us-west-2.amazonaws.com',
                    'dgpv43ylujmyh.cloudfront.net/processed-voiceover'
                  )
                  .replace(
                    'athletic-outlook.s3.amazonaws.com',
                    'dgpv43ylujmyh.cloudfront.net/processed-voiceover'
                  )
                  .replace('video-desktop', 'processed-voiceover')
                  .replace('/athletic-outlook', '')}`
              : url.indexOf('oaidalleapiprodscus') > -1 ? url : `${baseS3Url}/video-desktop/undefined/${getS3Key(url)}.mp4`,
        thumbUrl: `${baseS3Url}/thumb/undefined/${
          url.split('undefined/')[1].split('.')[0]
        }.0000001.jpg`
      }
    : { originalUrl: '', videoUrl: '', thumbUrl: '' };

export const getBlockImageSource = url =>
  url.indexOf('://') > -1
    ? url.indexOf('oaidalleapiprodscus') > -1 ? url : `undefined/${
        url.split(
          `${
            url.indexOf('processed-voiceover') > -1
              ? '/processed-voiceover/'
              : url.indexOf('dgpv43ylujmyh.cloudfront.net') > -1
              ? '/'
              : '/athletic-outlook/'
          }undefined/`
        )[1]
      }`
    : `undefined/${url}`;

export const isVideo = extension =>
  videoFileExts.indexOf(extension.toLowerCase()) > -1;

export const playAllVideos = e => dispatch => {
  if (!e || ['TEXTAREA', 'INPUT', 'BUTTON'].indexOf(e.target.nodeName) === -1) {
    for (let video of document.querySelectorAll(
      '.swiper-slide-active .v2-general-block-render-container .custom-video-component-container video'
    ) || []) {
      video.paused && video.play();
    }

    dispatch({
      type: ACTIONS.UPDATE_STATE,
      payload: { videoPlaying: true }
    });
  }

  return;
};

export const pauseAllVideos = e => dispatch => {
  if (!e || ['TEXTAREA', 'INPUT', 'BUTTON'].indexOf(e.target.nodeName) === -1) {
    for (let video of document.querySelectorAll(
      '.v2-general-block-render-container .custom-video-component-container video'
    ) || []) {
      !video.paused && video.pause();
    }

    dispatch({
      type: ACTIONS.UPDATE_STATE,
      payload: { videoPlaying: false }
    });
  }

  return;
};

export const slideToLessonSlider = (index = 0) => {
  const paginationSwiper = (
    document.querySelector(
      '.dynamic-single-lesson-pagination .swiper'
    ) || {}
  ).swiper;
  const lessonSwiper = (
    document.querySelector(
      '.outer-public-v2-container-container > .swiper'
    ) || {}
  ).swiper;

  if (paginationSwiper) {
    paginationSwiper.slideTo(index);
  }

  if (lessonSwiper) {
    lessonSwiper.slideTo(index);
  }
};

export const slidePrevLessonSlider = () => {
  const paginationSwiper = (
    document.querySelector(
      '.dynamic-single-lesson-pagination .swiper'
    ) || {}
  ).swiper;
  const lessonSwiper = (
    document.querySelector(
      '.outer-public-v2-container-container > .swiper'
    ) || {}
  ).swiper;

  if (paginationSwiper) {
    paginationSwiper.slidePrev();
  }

  if (lessonSwiper) {
    lessonSwiper.slidePrev();
  }
};

export const slideNextLessonSlider = () => {
  const lessonSwiper = (document.querySelector('.outer-public-v2-container-container > .swiper') || {}).swiper;

  if(lessonSwiper){
    lessonSwiper.slideNext();
  };
};

export const slideToPagination = (index = 0) => {
  const paginationSwiper = (
    document.querySelector(
      '.dynamic-single-lesson-pagination .swiper'
    ) || {}
  ).swiper;
  const lessonSwiper = (
    document.querySelector(
      '.outer-public-v2-container-container > .swiper'
    ) || {}
  ).swiper;

  if (paginationSwiper) {
    paginationSwiper.slideTo(index);
  }

  if (lessonSwiper) {
    lessonSwiper.slideTo(index);
  }

  pauseAllVideos();
};

/** Start of Mutations **/

export const upsertQuizAnswerInit = ({
  answer,
  programId,
  blockId
}) => async dispatch => {
  dispatch({
    type: ACTIONS.UPDATE_STATE,
    payload: { loadingQuizAnswer: true }
  });

  await apolloClient.mutate({
    mutation: upsertQuizAnswer,
    variables: {
      input: { answer, programId, blockId }
    }
  });

  dispatch({
    type: ACTIONS.UPDATE_STATE,
    payload: { loadingQuizAnswer: false }
  });
};

export const upsertFormResponseInit = ({
  response,
  programId,
  blockId
}) => async dispatch => {
  dispatch({
    type: ACTIONS.UPDATE_STATE,
    payload: { loadingResponse: true }
  });

  await apolloClient.mutate({
    mutation: upsertFormResponse,
    variables: {
      input: { response, programId, blockId }
    }
  });

  dispatch({
    type: ACTIONS.UPDATE_STATE,
    payload: { loadingResponse: false }
  });
};

export const uploadAnalysisFile = ({
  file,
  programId,
  blockId,
  sessionId
}) => async dispatch => {
  dispatch({
    type: ACTIONS.UPDATE_STATE,
    payload: { uploadingFile: URL.createObjectURL(file), uploadProgress: 0 }
  });

  const fileNameSplit = file.name.split('.');
  const fileName =
    new Date().getTime() + '.' + fileNameSplit[fileNameSplit.length - 1];

  const signedUrl_BASIC_response = await signedUrlInit_BASIC(fileName);

  const data = new FormData();
  Object.keys(signedUrl_BASIC_response.fields).map(key =>
    data.append(key, signedUrl_BASIC_response.fields[key])
  );
  data.append('file', file);
  const options = {
    method: 'POST',
    body: data
  };

  // Start uploading form to S3
  await futch(
    'https://athletic-outlook.s3-accelerate.amazonaws.com',
    options,
    e =>
      dispatch({
        type: ACTIONS.UPDATE_STATE,
        payload: { uploadProgress: (e.loaded / e.total) * 100 }
      })
  ).catch(e => console.log(e));

  await upsertGallery(
    `user-upload-${new Date().getTime()}`,
    signedUrl_BASIC_response.url + '/' + signedUrl_BASIC_response.fields.key
  );

  dispatch({
    type: ACTIONS.UPDATE_STATE,
    payload: {
      [`${programId}_${blockId}_upload`]: {
        id: (
          (await apolloClient.mutate({
            mutation: uploadLessonAnalysisFile,
            variables: {
              input: {
                sessionId,
                programId,
                blockId,
                urlKey: signedUrl_BASIC_response.fields.key
              }
            }
          })) || {}
        ).data.uploadLessonAnalysisFile.success,
        programId,
        voiceover: {
          tempUrl: URL.createObjectURL(file),
          videoUrl: `${signedUrl_BASIC_response.url}/${signedUrl_BASIC_response.fields.key}`
        }
      },
      uploadingFile: false,
      uploadProgress: 0
    }
  });

  return true;
};

export const removeLessonInit = lessonId => async dispatch => {
  dispatch({
    type: ACTIONS.UPDATE_STATE,
    payload: {
      removingLesson: true
    }
  });

  const removeLessonData = (
    (await apolloClient.mutate({
      mutation: removeLesson,
      variables: {
        input: { id: lessonId }
      }
    })) || {}
  ).data.removeLesson.success;

  dispatch({
    type: ACTIONS.UPDATE_STATE,
    payload: {
      removingLesson: false,
      [`lesson_removed_${lessonId}`]: true
    }
  });

  return { removeLessonData };
};

export const createLessonInit = ({
  id,
  label,
  tagItems,
  featuredImage
}) => async dispatch => {
  dispatch({
    type: ACTIONS.UPDATE_STATE,
    payload: {
      creatingLesson: true
    }
  });

  const newLessonId = (
    (await apolloClient.mutate({
      mutation: upsertLesson,
      variables: {
        input: {
          id,
          label,
          featuredImage
        }
      }
    })) || {}
  ).data.upsertLesson.success;

  const upsertedTags = await new Promise(resolve =>
    mapLimit(
      tagItems,
      20,
      (tag, callback) =>
        setTimeout(
          async () => {
            const { id, label } = {
              ...tag,
              id: (
                (await apolloClient.mutate({
                  mutation: upsertTag,
                  variables: {
                    input: {
                      label: tag.label,
                      origin: 'lessons'
                    }
                  }
                })) || {}
              ).data.upsertTag
            };
    
            callback(
              null,
              {
                id: (
                  (await apolloClient.mutate({
                    mutation: upsertTagConnection,
                    variables: {
                      input: {
                        tagId: id,
                        assetId: newLessonId,
                        originRef: 'lessonId'
                      }
                    }
                  })) || {}
                ).data.upsertTagConnection,
                tag: { id, label }
              }
            );
          }            
        , 10),
      (err, results) => resolve(results || [])
    )
  );

  dispatch({
    type: ACTIONS.UPDATE_STATE,
    payload: {
      creatingLesson: false,
      currentDocumentHeight: parseFloat(document.body.clientHeight) - 20,
      activeSlide: 0,
      activeBlock: 0,
      lesson: {
        label,
        featuredImage,
        id: newLessonId,
        tags: upsertedTags,
        slides: [
          {
            id: `new-slide-${new Date().getTime()}`,
            index: 0,
            blocks: []
          }
        ]
      }
    }
  });
};

export const upsertLessonInit = ({
  lesson,
  blocksToRemove = [],
  slidesToRemove = []
}) => async dispatch => {
  dispatch({
    type: ACTIONS.UPDATE_STATE,
    payload: {
      updatingLesson: true
    }
  });

  const { id, label, featuredImage } = lesson;

  const newLesson =
    id.includes('new-lesson') &&
    (await apolloClient.mutate({
      mutation: upsertLesson,
      variables: {
        input: {
          id,
          label,
          featuredImage
        }
      }
    }));

  const lessonId = newLesson ? newLesson.data.upsertLesson.success : id;

  const blocks_flattened = lesson.slides
    .map(({ blocks = [], id }) =>
      blocks.map((block, index) => ({
        ...block,
        index,
        choices: block.choices ? JSON.stringify(block.choices) : null,
        settings: block.settings ? JSON.stringify(block.settings) : null,
        slideId: id,
        lessonId,
        __typename: undefined
      }))
    )
    .flat(1);
    
  const blocks_grouped = blocks_flattened.reduce(
    (obj, item) => {
      const refName = item.slideId.includes('new-slide') ? 'new' : 'old';

      return {
        ...obj,
        [refName]: [...obj[refName], item]
      };
    },
    { new: [], old: [] }
  );

  const {
    upsertedLesson,
    upsertedSlides,
    upsertedBlocks,
    slidesRemoved,
    blocksRemoved
  } = await new Promise(resolve =>
    parallel(
      {
        upsertedLesson: callback =>
          setTimeout(
            async () =>
              callback(
                null,
                newLesson
                  ? newLesson.data.upsertLesson.success
                  : (
                      await apolloClient.mutate({
                        mutation: upsertLesson,
                        variables: {
                          input: {
                            id,
                            label,
                            featuredImage
                          }
                        }
                      })
                    ).data.upsertLesson.success
              ),
            200
          ),
        upsertedSlides: callback =>
          setTimeout(
            async () =>
              callback(
                null,
                await new Promise(resolve =>
                  mapLimit(
                    chunks(
                      lesson.slides.map(({ id, index, settings }) => ({
                        id,
                        index,
                        settings,
                        lessonId
                      })),
                      10
                    ),
                    5,
                    (slides, callback) =>
                        setTimeout(
                          async () =>
                            callback(
                              null,
                              (
                                await apolloClient.mutate({
                                  mutation: upsertSlides,
                                  variables: {
                                    input: {
                                      slides
                                    }
                                  }
                                })
                              ).data.upsertSlides.success
                            )
                        , 10),
                    (err, results) => {
                      resolve((results || []).flat(1));
                    }
                  )
                )
              ),
            200
          ),
        upsertedBlocks: callback =>
          setTimeout(
            async () =>
              callback(
                null,
                await new Promise(resolve =>
                  mapLimit(
                    chunks(blocks_grouped.old, 10),
                    5,
                    (blocks, callback) =>
                        setTimeout(
                          async () =>
                            callback(
                              null,
                              (
                                await apolloClient.mutate({
                                  mutation: upsertBlocks,
                                  variables: {
                                    input: {
                                      blocks: (blocks || []).map(
                                        ({
                                          id,
                                          index,
                                          lessonId,
                                          slideId,
                                          settings,
                                          answer,
                                          url,
                                          type,
                                          choices,
                                          contentShort,
                                          contentMedium,
                                          contentLong
                                        }) => ({
                                          id,
                                          index,
                                          lessonId,
                                          slideId,
                                          settings,
                                          answer,
                                          url,
                                          type,
                                          choices,
                                          contentShort,
                                          contentMedium,
                                          contentLong
                                        })
                                      )
                                    }
                                  }
                                })
                              ).data.upsertBlocks.success
                            )
                        , 10),
                    (err, results) => resolve((results || []).flat(1))
                  )
                )
              ),
            100
          ),
        slidesRemoved: callback =>
          setTimeout(
            async () =>
              callback(
                null,
                await new Promise(resolve =>
                  mapLimit(
                    chunks(
                      (slidesToRemove || [])
                        .filter(slide => slide)
                        .map(slide => ({ id: slide.id })),
                      10
                    ),
                    5,
                    (slides, callback) =>
                        setTimeout(
                          async () =>
                            callback(
                              null,
                              (
                                await apolloClient.mutate({
                                  mutation: removeSlides,
                                  variables: {
                                    input: {
                                      slides
                                    }
                                  }
                                })
                              ).data.removeSlides.success
                            )
                        , 10),
                    (err, results) => resolve((results || []).flat(1))
                  )
                )
              ),
            100
          ),
        blocksRemoved: callback =>
          setTimeout(
            async () =>
              callback(
                null,
                await new Promise(resolve =>
                  mapLimit(
                    chunks(
                      (blocksToRemove || [])
                        .filter(block => block)
                        .map(block => ({ id: block.id })),
                      10
                    ),
                    5,
                    (blocks, callback) =>
                        setTimeout(
                          async () =>
                            callback(
                              null,
                              (
                                await apolloClient.mutate({
                                  mutation: removeBlocks,
                                  variables: {
                                    input: {
                                      blocks
                                    }
                                  }
                                })
                              ).data.removeBlocks.success
                            )
                        , 10),
                    (err, results) => resolve((results || []).flat(1))
                  )
                )
              ),
            100
          )
      },
      async (err, results) => {
        resolve({
          ...results,
          upsertedBlocks: [
            ...results.upsertedBlocks,
            await new Promise(resolve =>
              mapLimit(
                chunks(
                  blocks_grouped.new.map(block => ({
                    ...block,
                    slideId: (
                      results.upsertedSlides.find(
                        slide => slide && slide.prevId === block.slideId
                      ) || {}
                    ).id
                  })),
                  10
                ),
                5,
                (blocks, callback) =>
                  setTimeout(
                    async () =>
                      callback(
                        null,
                        (
                          await apolloClient.mutate({
                            mutation: upsertBlocks,
                            variables: {
                              input: {
                                blocks: (blocks || []).map(
                                  ({
                                    id,
                                    index,
                                    lessonId,
                                    slideId,
                                    settings,
                                    answer,
                                    url,
                                    type,
                                    choices,
                                    contentShort,
                                    contentMedium,
                                    contentLong
                                  }) => ({
                                    id,
                                    index,
                                    lessonId,
                                    slideId,
                                    settings,
                                    answer,
                                    url,
                                    type,
                                    choices,
                                    contentShort,
                                    contentMedium,
                                    contentLong
                                  })
                                )
                              }
                            }
                          })
                        ).data.upsertBlocks.success
                      )
                  , 10),
                (err, results) => resolve((results || []).flat())
              )
            )
          ].flat()
        })
      }
    )
  );

  dispatch({
    type: ACTIONS.UPDATE_STATE,
    payload: {
      updatingLesson: false
    }
  });

  return {
    slidesRemovedResponse: slidesRemoved,
    blocksRemovedResponse: blocksRemoved,
    upsertedLessonResponse: upsertedLesson,
    upsertedSlidesResponse: upsertedSlides,
    upsertedBlocksResponse: upsertedBlocks
  };
};

export const lessonFeed_PUBLIC = ({ lessonId }) => {
  const Slides_Results_CacheOnly = useQuery(
    slideQuery,
    { variables: { lessonId }, fetchPolicy: "cache-only" }
  );

  const Blocks_Results_CacheOnly = useQuery(
    blockQuery,
    { variables: { lessonId }, fetchPolicy: "cache-only" }
  );

  /****/

  const Slides_Results = useQuery(
    slideQuery,
    { variables: { lessonId }, fetchPolicy: "network-only" }
  );

  const Blocks_Results = useQuery(
    blockQuery,
    { variables: { lessonId }, fetchPolicy: "network-only" }
  );

  const slideQuery_FINAL = useMemo(() => (Slides_Results.data || {}).slideQuery || (Slides_Results_CacheOnly.data || {}).slideQuery || [], [(Slides_Results.data || {}).slideQuery]);
  const blockQuery_FINAL = useMemo(() => (Blocks_Results.data || {}).blockQuery || (Blocks_Results_CacheOnly.data || {}).blockQuery || [], [(Blocks_Results.data || {}).blockQuery]);

  const cacheToCompare = useMemo(() => (Blocks_Results_CacheOnly.data || {}).blockQuery, []);

  const reduceBlock = useCallback(
    (result, block) => ({
      ...result,
      [block.slideId]: [
        ...(result[block.slideId] || []),
        {
          ...block,
          choices: (block.choices && JSON.parse(block.choices)) || [],
          settings: {
            ...defaultBlockSettings(block.type),
            ...((block.settings && JSON.parse(block.settings)) || {})
          }
        }
      ]
    }), []
  );

  const reducedBlocks = useMemo(() => blockQuery_FINAL.reduce(reduceBlock, {}), [blockQuery_FINAL]);

  const getBlockImageSource_INIT = useCallback(getBlockImageSource, []);
  const getBlockVideoSources_INIT = useCallback(getBlockVideoSources, []);
  const isVideoFull_INIT = useCallback(isVideoFull, []);

  const doesVideoExist = useCallback((blocks) => blocks.find(block => block.type === 'media' && isVideoFull_INIT(block.url)) ? true : false, []);

  const isVideoSpecificInit = useCallback((firstMediaBLOCK) => firstMediaBLOCK && isVideoFull_INIT(firstMediaBLOCK.url), []);

  const getMediaInfo = useCallback((firstMediaBLOCK, isVideoSpecific) => (
    firstMediaBLOCK && (
      isVideoSpecific
        ? getBlockVideoSources_INIT(firstMediaBLOCK.url, firstMediaBLOCK.id.indexOf('course') > -1)
        : getBlockImageSource_INIT(firstMediaBLOCK.url)
    )
  ), []);

  const getFirstMediaBlock = useCallback(blocks => blocks.find(block => block.type === 'media' && block.url), []);

  const mapSlide = useCallback(slide => {
    const blocks = reducedBlocks[slide.id] || [];
    const firstMediaBLOCK = getFirstMediaBlock(blocks);
    const isVideoSpecific = isVideoSpecificInit(firstMediaBLOCK);
    const mediaInfo = getMediaInfo(firstMediaBLOCK, isVideoSpecific);
    const videoExistsWithin = doesVideoExist(blocks);

    return {
      ...slide,
      videoExistsWithin,
      firstMediaBLOCK,
      isVideoSpecific,
      mediaInfo,
      blocks
    };
  }, [reducedBlocks]);

  const slides = useMemo(() => slideQuery_FINAL.map(mapSlide), [slideQuery_FINAL, mapSlide]);

  const forceUpdate = useMemo(() => ((Blocks_Results.data || {}).blockQuery || []).length > 0 && !isEqual({ blockQuery: (Blocks_Results.data || {}).blockQuery }, { blockQuery: cacheToCompare }) ? true : false, [(Blocks_Results.data || {}).blockQuery]);

  return {
    slides,
    forceUpdate,
    loading: Slides_Results.loading || Blocks_Results.loading
  };
};

export const initLessonViewer = async ({ lesson = {}, lessonId }) =>
  await new Promise(resolve =>
    parallel(
      {
        lessonResponse: callback =>
          setTimeout(
            async () =>
              callback(
                null,
                (await apolloClient.query({
                  query: lessonQuery,
                  fetchPolicy: 'network-only',
                  variables: { lessonId: lesson.id || lessonId }
                })) || {}
              ),
            300
          ),
        slidesResponse: callback =>
          setTimeout(
            async () =>
              callback(
                null,
                (await apolloClient.query({
                  query: slideQuery,
                  fetchPolicy: 'network-only',
                  variables: { lessonId: lesson.id || lessonId }
                })) || []
              ),
            200
          ),
        blocksResponse: callback =>
          setTimeout(
            async () =>
              callback(
                null,
                (await apolloClient.query({
                  query: blockQuery,
                  fetchPolicy: 'network-only',
                  variables: { lessonId: lesson.id || lessonId }
                })) || []
              ),
            100
          )
      },
      (err, { lessonResponse, slidesResponse, blocksResponse }) => {
        const reducedBlocks = (blocksResponse.data.blockQuery || []).reduce(
          (result, block) => ({
            ...result,
            [block.slideId]: [
              ...(result[block.slideId] || []),
              {
                ...block,
                choices: (block.choices && JSON.parse(block.choices)) || [],
                settings: {
                  ...defaultBlockSettings(block.type),
                  ...((block.settings && JSON.parse(block.settings)) || {})
                }
              }
            ]
          }),
          {}
        );

        resolve({
          ...lessonResponse.data.lessonQuery,
          index: lesson.index || 0,
          slides: (slidesResponse.data.slideQuery || []).map(slide => {
            const activeBlocks_ARRAY = reducedBlocks[slide.id] || [];

            const firstMediaBLOCK = activeBlocks_ARRAY.find(
              block => block.type === 'media' && block.url
            );

            const isVideoSpecific =
              firstMediaBLOCK && isVideoFull(firstMediaBLOCK.url);
            const mediaInfo =
              firstMediaBLOCK &&
              (isVideoSpecific
                ? getBlockVideoSources(
                    firstMediaBLOCK.url,
                    firstMediaBLOCK.id.indexOf('course') > -1
                  )
                : getBlockImageSource(firstMediaBLOCK.url));

            const videoExistsWithin = activeBlocks_ARRAY.find(
              block => block.type === 'media' && isVideoFull(block.url)
            )
              ? true
              : false;

            return {
              ...slide,
              videoExistsWithin,
              firstMediaBLOCK,
              isVideoSpecific,
              mediaInfo,
              blocks: reducedBlocks[slide.id] || []
            };
          })
        });
      }
    )
  );

export const getLessonById = async (lessonId, builderSpecific) =>
  await new Promise(resolve =>
    parallel(
      {
        lessonResponse: callback =>
          setTimeout(
            async () =>
              callback(
                null,
                (await apolloClient.query({
                  query: lessonQuery,
                  fetchPolicy: 'network-only',
                  variables: { lessonId: lessonId }
                })) || {}
              ),
            300
          ),
        slidesResponse: callback =>
          setTimeout(
            async () =>
              callback(
                null,
                (await apolloClient.query({
                  query: slideQuery,
                  fetchPolicy: 'network-only',
                  variables: { lessonId: lessonId }
                })) || []
              ),
            200
          ),
        blocksResponse: callback =>
          setTimeout(
            async () =>
              callback(
                null,
                (await apolloClient.query({
                  query: builderSpecific ? blockQuery_ADMIN : blockQuery,
                  fetchPolicy: 'network-only',
                  variables: { lessonId: lessonId }
                })) || []
              ),
            100
          )
      },
      (err, { lessonResponse, slidesResponse, blocksResponse }) => {
        const reducedBlocks = (
          blocksResponse.data.blockQuery ||
          blocksResponse.data.blockQuery_ADMIN ||
          []
        ).reduce(
          (result, block) => ({
            ...result,
            [block.slideId]: [
              ...(result[block.slideId] || []),
              {
                ...block,
                choices: (block.choices && JSON.parse(block.choices)) || [],
                settings: {
                  ...defaultBlockSettings(block.type),
                  ...((block.settings && JSON.parse(block.settings)) || {})
                }
              }
            ]
          }),
          {}
        );

        resolve({
          ...lessonResponse.data.lessonQuery,
          slides: (slidesResponse.data.slideQuery || []).map(slide => ({
            ...slide,
            blocks: reducedBlocks[slide.id] || []
          }))
        });
      }
    )
  );

export const cloneLessonInit = lessonId => async dispatch => {
  dispatch({
    type: ACTIONS.UPDATE_STATE,
    payload: {
      creatingLesson: true
    }
  });

  const prevLesson = await getLessonById(lessonId);

  const newLessonId = (
    (await apolloClient.mutate({
      mutation: upsertLesson,
      variables: {
        input: {
          id: 'new-lesson-1',
          label: prevLesson.label || '',
          featuredImage: prevLesson.featuredImage
        }
      }
    })) || {}
  ).data.upsertLesson.success;

  const lesson = {
    id: newLessonId,
    label: `copy of ${prevLesson.label || 'No Title'}`,
    featuredImage: prevLesson.featuredImage,
    tags: [],
    slides: (prevLesson.slides || [])
      .sort((a, b) => a.index - b.index)
      .map((slide, slideIndex) => ({
        ...slide,
        id: `new-slide-from-clone-${slideIndex}`,
        index: slideIndex,
        lessonId: newLessonId,
        blocks: slide.blocks
          .sort((a, b) => a.index - b.index)
          .map((block, blockIndex) => ({
            ...block,
            id: `new-block-from-clone-${slideIndex}-${blockIndex}`,
            index: blockIndex,
            slideId: `new-slide-from-clone-${slideIndex}`,
            lessonId: newLessonId
          }))
      }))
  };

  dispatch({
    type: ACTIONS.UPDATE_STATE,
    payload: {
      creatingLesson: false,
      currentDocumentHeight: parseFloat(document.body.clientHeight) - 20,
      activeSlide: 0,
      activeBlock: 0,
      lesson,
      cloneSpecificUpdate: true
    }
  });
};

export const initLessonBuilder = ({
  lesson = {},
  lessonId
}) => async dispatch =>
  dispatch({
    type: ACTIONS.UPDATE_STATE,
    payload: {
      activeSlide: 0,
      activeBlock: 0,
      lesson: await getLessonById(lesson.id || lessonId, true)
    }
  });

/** End of Mutations **/

export const updateLessonState = updatedStates => dispatch =>
  dispatch({
    type: ACTIONS.UPDATE_STATE,
    payload: updatedStates
  });

export const addBlock = ({
  blockType,
  activeSlide,
  lesson,
  dimensionSettings
}) => dispatch => {
  let { slides } = lesson;
  let blocks = (slides[activeSlide] || {}).blocks || [];

  const newBlock = {
    id: `new-block-${new Date().getTime()}`,
    index: blocks.length,
    type: blockType,
    ...(blockType === 'quiz' || blockType === 'flash_card'
      ? {
          answer: undefined,
          contentShort: '',
          contentMedium: 'Flip Card',
          choices: ['', '']
        }
      : blockType === 'task'
      ? { contentMedium: '' }
      : blockType === 'button'
      ? {
          settings: { embedType: 'next_slide' },
          contentMedium: 'Next'
        }
      : blockType === 'embed'
      ? { embedType: null }
      : blockType === 'video_analysis' && { contentMedium: '' })
  };

  const activeSlideObject = slides[activeSlide] || {
    id: `new-slide-${new Date().getTime()}`,
    index: lesson.slides.length
  };

  slides[activeSlide] = {
    ...activeSlideObject,
    blocks: [
      ...blocks,
      {
        ...newBlock,
        slideId: activeSlideObject.id,
        settings: {
          ...(newBlock.settings || {}),
          ...defaultBlockSettings(blockType),
          ...dimensionSettings
        }
      }
    ]
  };

  dispatch({
    type: ACTIONS.UPDATE_STATE,
    payload: {
      lesson: {
        ...lesson,
        slides
      },
      activeBlock: newBlock.index,
      ...(blockType === 'media' && { showMediaSelectionPop: true })
    }
  });
};

export const parseFloatCustom = number => Math.round(number * 100) / 100;

export const cloneSlide = ({ lesson, slideToClone }) => dispatch => {
  let slides = lesson.slides;

  const dateTime = `${new Date().getTime()}-${slideToClone}`;

  slides.splice(slideToClone, 0, {
    ...JSON.parse(JSON.stringify(slides[slideToClone])),
    id: `new-slide-${dateTime}-${slideToClone}`,
    index: slideToClone,
    blocks: (slides[slideToClone].blocks || []).map((localBlock, index) => ({
      ...JSON.parse(JSON.stringify(localBlock)),
      slideId: `new-slide-${dateTime}-${slideToClone}`,
      id: `new-block-${dateTime}-${index}`,
      index
    }))
  });

  dispatch({
    type: ACTIONS.UPDATE_STATE,
    payload: {
      lesson: {
        ...lesson,
        slides: slides.map((slideLocal, index) => ({ ...slideLocal, index }))
      }
    }
  });
};

export const removeSlide = ({
  lesson,
  slidesToRemove,
  slideToRemove
}) => dispatch => {
  ReactGA.event({
    category: 'Course',
    action: 'course-slide-removed',
    label: 'Course Slide Removed'
  });

  let slides = lesson.slides;
  const slide_ref = lesson.slides[slideToRemove];

  slides.splice(slideToRemove, 1);

  dispatch({
    type: ACTIONS.UPDATE_STATE,
    payload: {
      slidesToRemove: [...slidesToRemove, slide_ref],
      lesson: {
        ...lesson,
        slides
      },
      activeSlide: 0
    }
  });
};

export const cloneBlock = ({
  lesson,
  activeSlide,
  blockToClone
}) => dispatch => {
  let blocks = lesson.slides[activeSlide].blocks;
  let lessonToManip = lesson;

  const dateTime = new Date().getTime();

  blocks.splice(
    blockToClone + 1,
    0,
    JSON.parse(
      JSON.stringify({
        ...blocks[blockToClone],
        id: `new-block-${dateTime}-${blockToClone + 1}`
      })
    )
  );

  lessonToManip.slides[
    activeSlide
  ].blocks = blocks.map((blockLocal, index) => ({ ...blockLocal, index }));

  dispatch({
    type: ACTIONS.UPDATE_STATE,
    payload: { lesson: lessonToManip }
  });
};

export const removeBlock = ({
  lesson,
  activeSlide,
  blockToRemove,
  blocksToRemove
}) => dispatch => {
  ReactGA.event({
    category: 'Course',
    action: 'course-slide-removed',
    label: 'Course Slide Removed'
  });

  let blocks = lesson.slides[activeSlide].blocks;
  const block_ref = lesson.slides[activeSlide].blocks[blockToRemove];

  blocks.splice(blockToRemove, 1);

  lesson.slides[activeSlide].blocks = blocks.map((blockLocal, index) => ({
    ...blockLocal,
    index
  }));

  dispatch({
    type: ACTIONS.UPDATE_STATE,
    payload: { lesson, blocksToRemove: [...blocksToRemove, block_ref] }
  });
};
