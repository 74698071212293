import React, { useContext, useEffect, useMemo } from 'react';
import { Routes, Route } from 'react-router-dom';
import { useQuery } from 'react-apollo';
import Loadable from 'react-loadable';
import LogoLoader from '../components/Shared/LogoLoader';
import OriginGuestRoutes from '../routes/origin/guestRoutes';
import ExternalGuestRoutes from '../routes/external/guestRoutes';
import FallbackWildcardHelper from './HelperRoutes/FallbackWildcardHelper';
import { fallbackPage } from '../actions/website.actions';
import { ProfileContext } from '../context/profile.context';
import { RequesterContext } from '../context/requester.context';
import { CurrentUserQuery } from '../apollo.queries';

const hostName = window.location.hostname.toLowerCase();
const originHost = hostName.indexOf('coachiq.io') > -1 || hostName.indexOf('localhost') > -1 || hostName.indexOf('127.0.0.1') > -1;

const generalLoader = (
  <section className="appLoading">
    <svg
      className="simple-line-loader-for-app-loading css-primary-hex-stroke"
      viewBox="0 0 50 50"
      style={{ stroke: 'rgba(20, 20, 20,.15)' }}
    >
      <circle
        className="path css-primary-hex-stroke"
        cx="25"
        cy="25"
        r="20"
        fill="none"
        strokeWidth="5"
        style={{ stroke: 'rgba(20, 20, 20,.15)' }}
      ></circle>
    </svg>
  </section>
);

const GeneralConfirmation_PurchaseSuccess = Loadable({
  loader: () => import('../containers/Admin/Sales/Packages/StripeSuccess/GeneralConfirmation_PurchaseSuccess'),
  loading: () => <LogoLoader />
});

const OrgCreation = Loadable({
  loader: () => import('../containers/Public/OrgCreation'),
  loading: () => <LogoLoader />
});

const FallbackRouter = () => {
  const requesterOrg = useContext(RequesterContext),
  { loading, data } = useQuery(CurrentUserQuery, { fetchPolicy: "network-only" }),

  activeSlug = useMemo(() => window.location.pathname.split('/').pop().replace('/', '').toLowerCase(), [window.location.pathname]),
  activePage = useMemo(() => ((requesterOrg && requesterOrg.activeGroup.pages) || []).find(({ type }) => type === activeSlug) || fallbackPage(activeSlug), [activeSlug, requesterOrg]),
  activePage_Prepped = useMemo(() => activePage ? {
    ...activePage,
    settings: (activePage.settings && JSON.parse(activePage.settings)) || {}
  } : {}, [activePage]),
  { theme = 'light' } = activePage_Prepped.settings || {},

  currentProfile = data?.profile;

  useEffect(() => { localStorage.setItem('active_theme', theme) }, [theme])

  if(loading && !data?.profile){
    return (
      <div className={`${theme} general-furthest-out-loader-container`}>
        {requesterOrg ? <LogoLoader tenant={requesterOrg.activeGroup.tenant} /> : generalLoader}
      </div>
    )
  }

  if(currentProfile){
    return (
      <ProfileContext.Provider value={currentProfile}>
        <Routes>
          <Route exact path={`/stripe-connected`} element={<GeneralConfirmation_PurchaseSuccess profile={currentProfile} />} />
          <Route exact path={`/create`} element={<OrgCreation profile={currentProfile} />} />

          <Route
            exact
            path={`/*`}
            element={
              <FallbackWildcardHelper 
                currentProfile={currentProfile} 
                fallbackSlugToUse={""}
                requesterOrg={requesterOrg}
              />
            }
          />
        </Routes>
      </ProfileContext.Provider>                  
    )
  } else {
    return originHost ? <OriginGuestRoutes /> : <ExternalGuestRoutes />;             
  }
}

export default FallbackRouter;
