import gql from 'graphql-tag';
import { CurrentUserSchema } from './apollo.queries';

/** Form Mutations **/

export const twilio_10dlc_init = gql`
  mutation twilio_10dlc_init($input: twilio_10dlc_init_input!) {
    twilio_10dlc_init(input: $input)
  }
`;

export const twilio_10dlc_createCampaign = gql`
  mutation twilio_10dlc_createCampaign {
    twilio_10dlc_createCampaign
  }
`;

export const twilio_10dlc_createBrandRegistration = gql`
  mutation twilio_10dlc_createBrandRegistration {
    twilio_10dlc_createBrandRegistration
  }
`;

export const twilio_10dlc_confirmTrustBundleSubmission = gql`
  mutation twilio_10dlc_confirmTrustBundleSubmission {
    twilio_10dlc_confirmTrustBundleSubmission
  }
`;

export const twilio_10dlc_createTrustBundle = gql`
  mutation twilio_10dlc_createTrustBundle($input: twilio_10dlc_createTrustBundle_input!) {
    twilio_10dlc_createTrustBundle(input: $input)
  }
`;

export const twilio_10dlc_confirmCustomerProfileSubmission = gql`
  mutation twilio_10dlc_confirmCustomerProfileSubmission {
    twilio_10dlc_confirmCustomerProfileSubmission
  }
`;

export const twilio_10dlc_addCustomerProfileAddress = gql`
  mutation twilio_10dlc_addCustomerProfileAddress($input: twilio_10dlc_addCustomerProfileAddress_input!) {
    twilio_10dlc_addCustomerProfileAddress(input: $input)
  }
`;

export const twilio_10dlc_addAuthorizedRepresentative = gql`
  mutation twilio_10dlc_addAuthorizedRepresentative($input: twilio_10dlc_addAuthorizedRepresentative_input!) {
    twilio_10dlc_addAuthorizedRepresentative(input: $input)
  }
`;

export const twilio_10dlc_createBusinessProfile = gql`
  mutation twilio_10dlc_createBusinessProfile($input: twilio_10dlc_createBusinessProfile_input!) {
    twilio_10dlc_createBusinessProfile(input: $input)
  }
`;

export const twilio_10dlc_soleProp_createAddress = gql`
  mutation twilio_10dlc_soleProp_createAddress($input: twilio_10dlc_soleProp_createAddress_input!) {
    twilio_10dlc_soleProp_createAddress(input: $input)
  }
`;

export const twilio_10dlc_soleProp_createEndUser = gql`
  mutation twilio_10dlc_soleProp_createEndUser($input: twilio_10dlc_soleProp_createEndUser_input!) {
    twilio_10dlc_soleProp_createEndUser(input: $input)
  }
`;

export const twilio_10dlc_soleProp_finishRegistration = gql`
  mutation twilio_10dlc_soleProp_finishRegistration($input: twilio_10dlc_soleProp_finishRegistration_input!) {
    twilio_10dlc_soleProp_finishRegistration(input: $input)
  }
`;

export const MasterRemoveCardSource = gql`
  mutation MasterRemoveCardSource($input: OrgRemoveCardSourceInput!) {
    MasterRemoveCardSource(input: $input)
  }
`;

export const MasterSetDefaultCardSource = gql`
  mutation MasterSetDefaultCardSource($input: OrgSetDefaultCardSourceInput!) {
    MasterSetDefaultCardSource(input: $input)
  }
`;

export const MasterAddCardSource = gql`
  mutation MasterAddCardSource($input: OrgAddCardSourceInput!) {
    MasterAddCardSource(input: $input)
  }
`;

export const MasterPurchase = gql`
  mutation MasterPurchase($input: OrgPurchaseInput!) {
    MasterPurchase(input: $input)
  }
`;

export const upsertFormBlockResponses = gql`
  mutation upsertFormBlockResponses($input: UpsertFormBlockResponseInput!) {
    upsertFormBlockResponses(input: $input)
  }
`;

export const upsertFormBlocks = gql`
  mutation upsertFormBlocks($input: UpsertFormBlocksInput!) {
    upsertFormBlocks(input: $input) {
      success {
        id
        prevId
        upsertedChoices {
          id
          prevId
        }
      }
      error
    }
  }
`;


export const removeForm = gql`
  mutation removeForm($input: RemoveFormInput!) {
    removeForm(input: $input) {
      success
      error
    }
  }
`;

export const removeFormBlocks = gql`
  mutation removeFormBlocks($input: RemoveFormBlocksInput!) {
    removeFormBlocks(input: $input) {
      success {
        id
      }
      error
    }
  }
`;

export const manualWebsiteRevalidate = gql`
  mutation manualWebsiteRevalidate {
    manualWebsiteRevalidate
  }
`;

export const customDomainRequest = gql`
  mutation customDomainRequest($customDomain: String!){
    customDomainRequest(customDomain: $customDomain)
  }
`;

export const removeFormTemplateBlock = gql`
  mutation removeFormTemplateBlock($id: ID!) {
    removeFormTemplateBlock(id: $id)
  }
`;

export const removeFormBlockChoices = gql`
  mutation removeFormBlockChoices($input: RemoveFormBlockChoicesInput!) {
    removeFormBlockChoices(input: $input) {
      success {
        id
      }
      error
    }
  }
`;

export const upsertForm = gql`
  mutation upsertForm($input: UpsertFormInput!) {
    upsertForm(input: $input) {
      success
      error
    }
  }
`;

export const upsertFormFieldTemplate = gql`
  mutation upsertFormFieldTemplate($input: UpsertFormFieldTemplateInput!) {
    upsertFormFieldTemplate(input: $input)
  }
`;

/** Facebook Based Mutations **/

export const connectFacebook = gql`
  mutation connectFacebook($token: String!) {
    connectFacebook(token: $token)
  }
`;

export const disconnectFacebook = gql`
  mutation disconnectFacebook {
    disconnectFacebook
  }
`;

/** Instagram Based Mutations **/

export const connectInstagram = gql`
  mutation connectInstagram($code: String!) {
    connectInstagram(code: $code)
  }
`;

export const disconnectInstagram = gql`
  mutation disconnectInstagram {
    disconnectInstagram
  }
`;


/** inbox Based Auths **/

export const generateOrgSmsNumber = gql`
  mutation generateOrgSmsNumber($country: String!, $areaCode: String) {
    generateOrgSmsNumber(country: $country, areaCode: $areaCode){
      success
      error
    }
  }
`;

export const updateCallForwardingNumber = gql`
  mutation updateCallForwardingNumber($phone: String!) {
    updateCallForwardingNumber(phone: $phone){
      success
      error
    }
  }
`;

export const removeAnnouncement = gql`
  mutation removeAnnouncement($announcementId: ID!) {
    removeAnnouncement(announcementId: $announcementId)
  }
`;

export const sendOneOnOneMessage = gql`
  mutation sendOneOnOneMessage($input: SendOneOnOneMessageInput!) {
    sendOneOnOneMessage(input: $input)
  }
`;

export const toggleMessageReaction = gql`
  mutation toggleMessageReaction($input: ToggleMessageReactionInput!) {
    toggleMessageReaction(input: $input)
  }
`;

export const addMessageDestination = gql`
  mutation addMessageDestination($input: AddMessageDestinationInput!) {
    addMessageDestination(input: $input)
  }
`;

export const groupedMessageDestinations = gql`
  mutation groupedMessageDestinations($input: GroupedMessageDestinationsInput!) {
    groupedMessageDestinations(input: $input){
      id
      firstName
      lastName
      email
      phone
    }
  }
`;

/** Analytic Events Based */

export const sendAnalyticEvent = gql`
  mutation sendAnalyticEvent($input: UpsertAnalyticEventInput!) {
    sendAnalyticEvent(input: $input) {
      error
      success
    }
  }
`;

/** User Based **/

export const addUserParentsOrChildren = gql`
  mutation addUserParentsOrChildren($originProfileId: ID!, $profileIds: [ID]!) {
    addUserParentsOrChildren(originProfileId: $originProfileId, profileIds: $profileIds)
  }
`;

export const removeUserParentsOrChildren = gql`
  mutation removeUserParentsOrChildren($originProfileId: ID!, $profileIds: [ID]!) {
    removeUserParentsOrChildren(originProfileId: $originProfileId, profileIds: $profileIds)
  }
`;

export const sendEmailInvite = gql`
  mutation sendEmailInvite($emails: [String]!) {
    sendEmailInvite(emails: $emails)
  }
`;

export const updateUser = gql`
  mutation updateUser($input: UpdateUserInput!) {
    updateUser(input: $input) {
      success
      error
      profile ${CurrentUserSchema}
      token
    }
  }
`;

export const changeRole = gql`
  mutation changeRole($profileGroupId: ID!, $role: String!) {
    changeRole(profileGroupId: $profileGroupId, role: $role)
  }
`;

export const joinGroup = gql`
  mutation joinGroup($groupId: ID!) {
    joinGroup(groupId: $groupId)
  }
`;

export const removeProfileGroup = gql`
  mutation removeProfileGroup($input: RemoveProfileGroupInput!) {
    removeProfileGroup(input: $input)
  }
`;

/** Auth Based **/

export const emailLogin = gql`
  mutation emailLogin($input: EmailAuth!) {
    emailLogin(input: $input) {
      success
      error
      profile {
        id
        role
        status
        currentGroupId
        currentTenantId
        activeGroupPackagePurchased
        profileGroups {
          id
          status
          role
          group {
            id
            name
            code
            public
            homeUrl
            activePackageId
            menuItemStyle
            courseCoverPhoto
            tenantId
            tenant {
              id
              logo
              name
              code
              theme
            }
          }
        }
        user {
          id
          firstName
          lastName
          avatar
          email
          phone
          verified
        }
      }
      token
    }
  }
`;

export const verifyPhoneCode = gql`
  mutation verifyPhoneCode($input: PhoneAuth!) {
    verifyPhoneCode(input: $input) {
      success
      error
      profile ${CurrentUserSchema}
      token
    }
  }
`;

/** Package Based **/

export const upsertPackage = gql`
  mutation upsertPackage($input: UpsertPackageInput!) {
    upsertPackage(input: $input)
  }
`;

export const deletePackage = gql`
  mutation($id: String!) {
    deletePackage(id: $id)
  }
`;

export const packageCreation_StripeBased = gql`
  mutation packageCreation_StripeBased($stripeId: String!) {
    packageCreation_StripeBased(stripeId: $stripeId)
  }
`;

export const invoiceConnection_StripeBased = gql`
  mutation invoiceConnection_StripeBased($input: UpsertStripeInvoiceInput!) {
    invoiceConnection_StripeBased(input: $input)
  }
`;

/** Tag Based **/

export const deactivateTag = gql`
  mutation deactivateTag($input: DeactivateTagInput!) {
    deactivateTag(input: $input)
  }
`;

export const upsertTag = gql`
  mutation upsertTag($input: UpsertTagInput!) {
    upsertTag(input: $input)
  }
`;

export const upsertTagConnection = gql`
  mutation upsertTagConnection($input: UpsertTagConnectionInput!) {
    upsertTagConnection(input: $input)
  }
`;

export const removeTagConnection = gql`
  mutation removeTagConnection($input: RemoveTagConnectionInput!) {
    removeTagConnection(input: $input)
  }
`;

export const upsertArchivedInbox = gql`
  mutation upsertArchivedInbox($input: UpsertArchivedInboxInput!) {
    upsertArchivedInbox(input: $input)
  }
`;

export const removeArchivedInbox = gql`
  mutation removeArchivedInbox($input: RemoveArchivedInboxInput!) {
    removeArchivedInbox(input: $input)
  }
`;

export const upsertMediaGroup = gql`
  mutation upsertMediaGroup($input: UpsertMediaGroupInput!) {
    upsertMediaGroup(input: $input)
  }
`;

export const removeMediaGroup = gql`
  mutation removeMediaGroup($input: RemoveMediaGroupInput!) {
    removeMediaGroup(input: $input)
  }
`;

/** Website Based **/

export const adminAddUser = gql`
  mutation adminAddUser($input: UpdateUserInput!) {
    adminAddUser(input: $input)
  }
`;

export const deleteUser = gql`
  mutation deleteUser($id: String!) {
    deleteUser(id: $id) {
      id
    }
  }
`;

export const stripeConnect = gql`
  mutation stripeConnect($stripeCode: String!) {
    stripeConnect(stripeCode: $stripeCode)
  }
`;

export const updateOrg = gql`
  mutation updateOrg($input: UpdateOrgInput!) {
    updateOrg(input: $input) {
      success
      error
    }
  }
`;

export const createOrg_ADMIN = gql`
  mutation createOrg_ADMIN($input: CreateOrg_ADMINInput!) {
    createOrg_ADMIN(input: $input) {
      success
      error
      profile ${CurrentUserSchema}
    }
  }
`;

export const updateGroupAccess = gql`
  mutation updateGroupAccess($input: UpdateGroupAccessInput!) {
    updateGroupAccess(input: $input) {
      success
      error
    }
  }
`;

/**V1 Website**/

export const upsertV1Website = gql`
  mutation upsertV1Website($input: UpsertV1WebsiteInput!) {
    upsertV1Website(input: $input)
  }
`

export const upsertV1WebsiteFooter = gql`
  mutation upsertV1WebsiteFooter($input: UpsertV1WebsiteFooterInput!) {
    upsertV1WebsiteFooter(input: $input)
  }
`

export const upsertV1WebsiteHeader = gql`
  mutation upsertV1WebsiteHeader($input: UpsertV1WebsiteHeaderInput!) {
    upsertV1WebsiteHeader(input: $input)
  }
`

export const upsertV1WebsitePage = gql`
  mutation upsertV1WebsitePage($input: UpsertV1WebsitePageInput!) {
    upsertV1WebsitePage(input: $input){
      id
      legacy
    }
  }
`

/**End of V1 Website**/

export const upsertPage = gql`
  mutation upsertPage($input: UpsertPageInput!) {
    upsertPage(input: $input) {
      success
      error
    }
  }
`;

export const upsertPageSections = gql`
  mutation upsertPageSections($input: UpsertPageSectionsInput!) {
    upsertPageSections(input: $input) {
      success {
        id
        prevId
      }
      error
    }
  }
`;

export const upsertPageSectionItems = gql`
  mutation upsertPageSectionItems($input: UpsertPageSectionItemsInput!) {
    upsertPageSectionItems(input: $input) {
      success {
        id
        prevId
      }
      error
    }
  }
`;

export const removePageSections = gql`
  mutation removePageSections($input: RemovePageSectionsInput!) {
    removePageSections(input: $input) {
      success {
        id
      }
      error
    }
  }
`;

export const removePageSectionItems = gql`
  mutation removePageSectionItems($input: RemovePageSectionItemsInput!) {
    removePageSectionItems(input: $input) {
      success {
        id
      }
      error
    }
  }
`;

/** End of Website Based **/

/** Media Based **/

export const upsertMedia = gql`
  mutation upsertMedia($input: UpsertMediaInput!) {
    upsertMedia(input: $input) {
      success
      error
    }
  }
`;

export const removeMedia = gql`
  mutation removeMedia($input: RemoveMediaInput!) {
    removeMedia(input: $input) {
      success
      error
    }
  }
`;

/** End of Media Based **/

/** Program Based **/

export const upsertProgram = gql`
  mutation upsertProgram($input: UpsertProgramInput!) {
    upsertProgram(input: $input) {
      success {
        id
        checkpoints {
          id
          prevId
        }
      }
      error
    }
  }
`;

export const removeProgram = gql`
  mutation removeProgram($input: RemoveProgramInput!) {
    removeProgram(input: $input) {
      success
      error
    }
  }
`;

export const removeCheckpoints = gql`
  mutation removeCheckpoints($input: RemoveCheckpointsInput!) {
    removeCheckpoints(input: $input) {
      success {
        id
      }
      error
    }
  }
`;

export const addPackagePurchase_ADMIN = gql`
  mutation addPackagePurchase_ADMIN($packageId: ID!, $profileId: ID!) {
    addPackagePurchase_ADMIN(packageId: $packageId, profileId: $profileId)
  }
`;

export const removePackagePurchase_ADMIN = gql`
  mutation removePackagePurchase_ADMIN($packageId: ID!, $profileId: ID!) {
    removePackagePurchase_ADMIN(packageId: $packageId, profileId: $profileId)
  }
`;

export const removePackages = gql`
  mutation removePackages($input: RemovePackagesInput!) {
    removePackages(input: $input) {
      success {
        id
      }
      error
    }
  }
`;

export const removeAssignedUsers = gql`
  mutation removeAssignedUsers($input: RemoveAssignedUsersInput!) {
    removeAssignedUsers(input: $input) {
      success {
        id
      }
      error
    }
  }
`;

/** End of Program Based **/

/** Lesson Based **/

export const upsertQuizAnswer = gql`
  mutation($input: UpsertQuizAnswerInput!) {
    upsertQuizAnswer(input: $input)
  }
`;

export const upsertFormResponse = gql`
  mutation($input: UpsertFormResponseInput!) {
    upsertFormResponse(input: $input)
  }
`;

export const uploadLessonAnalysisFile = gql`
  mutation uploadLessonAnalysisFile($input: UploadAnalysisFileInput!) {
    uploadLessonAnalysisFile(input: $input) {
      success
      error
    }
  }
`;

export const upsertLesson = gql`
  mutation upsertLesson($input: UpsertLessonInput!) {
    upsertLesson(input: $input) {
      success
      error
    }
  }
`;

export const removeLesson = gql`
  mutation removeLesson($input: RemoveLessonInput!) {
    removeLesson(input: $input) {
      success
      error
    }
  }
`;

export const upsertSlides = gql`
  mutation upsertSlides($input: UpsertSlidesInput!) {
    upsertSlides(input: $input) {
      success {
        id
        prevId
      }
      error
    }
  }
`;

export const upsertBlocks = gql`
  mutation upsertBlocks($input: UpsertBlocksInput!) {
    upsertBlocks(input: $input) {
      success {
        id
        prevId
      }
      error
    }
  }
`;

export const removeSlides = gql`
  mutation removeSlides($input: RemoveSlidesInput!) {
    removeSlides(input: $input) {
      success {
        id
      }
      error
    }
  }
`;

export const removeBlocks = gql`
  mutation removeBlocks($input: RemoveBlocksInput!) {
    removeBlocks(input: $input) {
      success {
        id
      }
      error
    }
  }
`;

/** End of Lesson Based **/

export const updateWebsitePage = gql`
  mutation updateWebsitePage(
    $pageId: ID!
    $slug: String
    $coverPhoto: String
    $headerBGColor: String
    $headerOpacity: Int
    $headerTitle: String
    $headerSubTitle: String
    $headerTitleStyle: String
    $headerSubTitleStyle: String
    $enabled: Boolean
  ) {
    updateWebsitePage(
      pageId: $pageId
      slug: $slug
      coverPhoto: $coverPhoto
      headerBGColor: $headerBGColor
      headerOpacity: $headerOpacity
      headerTitle: $headerTitle
      headerSubTitle: $headerSubTitle
      headerTitleStyle: $headerTitleStyle
      headerSubTitleStyle: $headerSubTitleStyle
      enabled: $enabled
    ) {
      id
      type
      slug
      headerTitle
      headerSubTitle
      headerTitleStyle
      headerSubTitleStyle
      coverPhoto
      headerOpacity
      headerBGColor
      enabled
    }
  }
`;

export const updateCoupon = gql`
  mutation updateCoupon($input: CouponInput!) {
    updateCoupon(input: $input) {
      id
      code
      active
      valueOff
      createdAt
      type
      duration
      durationMonths
      maxRedemptions
    }
  }
`;

export const removeCoupon = gql`
  mutation removeCoupon($id: ID!) {
    removeCoupon(id: $id)
  }
`;

export const cancelMembership = gql`
  mutation cancelMembership($purchaseId: ID!) {
    cancelMembership(purchaseId: $purchaseId)
  }
`;

export const addSavedCard = gql`
  mutation addSavedCard($token: String!) {
    addSavedCard(token: $token)
  }
`;

export const removeSavedCard = gql`
  mutation removeSavedCard($token: String!) {
    removeSavedCard(token: $token)
  }
`;
