import React, { memo, useMemo } from 'react';
import UserBasedTagsRender from './UserBasedTagsRender';

const UserBasedTagsMapper = ({ profile, requester, onMainClick, assignSpecific, showUserOptionsInit, showUserOptions, activateUsers }) => {
  const firstClassName = useMemo(() => `${showUserOptions && 'is-active'} parent-arrow-dignfier`, [showUserOptions]);

  const secondClassName = useMemo(() => `gallery-tag-immediate ${showUserOptions && 'active-selection'}`, [showUserOptions])

  const originText = useMemo(() => origin === 'sessions' ? 'By User' : 'User Uploads', [origin]);

  const assignBasedRender = useMemo(() => (
    assignSpecific && (
      <span className="check-mark-to-right">
        <svg
          width="10"
          height="8"
          viewBox="0 0 10 8"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M9 1.33325L3.5 6.66659L1 4.24234"
            stroke="#0292FF"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      </span>
    )
  ), [assignSpecific]);

  return (
    activateUsers
      ? <>
          <div className="tag-container user-profile-specific">
            <div className={`for-relativity children-exists`}>
              <div className={firstClassName}>
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M8 10L12 14L16 10"
                    stroke="#666666"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  ></path>
                </svg>
              </div>
              <button
                className={secondClassName}
                onClick={showUserOptionsInit}
              >
                {originText}
                {assignBasedRender}
              </button>                            
            </div>

            <UserBasedTagsRender              
              origin={origin}
              profile={profile}
              requester={requester}
              onMainClick={onMainClick}
              showUserOptions={showUserOptions}
            />
          </div>
        </> 
      : <></>
  );
};

export default memo(UserBasedTagsMapper);
