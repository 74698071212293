import React, { useEffect } from 'react';
import { useQuery } from 'react-apollo';
import { useParams } from 'react-router';
import FormViewer from '../../components/AdminChildren/Coaching/Forms/MainFrame/FormViewer';
import GeneralLoader from '../../components/Shared/GeneralLoader';
import { Form_QueryConcat } from '../../actions/form.actions';
import { CurrentUserQuery } from '../../apollo.queries';

// import './formEmbed.scss';

const DirectFormView = (props = {}) => {
  const { id } = useParams();
  const { formEmbed } = props || {};

  const currentProfile_cache = useQuery(CurrentUserQuery, { fetchPolicy: "cache-only" });
  const currentProfile = useQuery(CurrentUserQuery, { fetchPolicy: "network-only" });
  
  const {
    FormResult = {},
    stillLoading
  } = Form_QueryConcat(id);
  
  return (
    <section id="complete_form_frame" className={`complete-program-main-frame ${formEmbed && 'form-embed-iframe-specific'} live form-specific`} style={{ backgroundColor: (FormResult.settings || {}).theme === 'dark' ? "#000" : "#fff" }}>
      {(stillLoading || currentProfile.loading) ? <GeneralLoader /> : <FormViewer profile={(currentProfile.data || {}).profile || (currentProfile_cache.data || {}).profile} form={FormResult} />}
    </section>
  );
};

export default DirectFormView;