import React, { memo, useMemo } from 'react';
import OrganizationRouterMapper from './OrganizationRouterMapper';

const OrganizationRouterRender = ({ getPublicInstance_RESULT }) => {  
  const activeSlug = useMemo(() => {
    const activeSlug_raw = window.location.pathname.split('/').pop().replace('/', '').toLowerCase()
    
    return activeSlug_raw === 'join' ? 'sign_up' : activeSlug_raw
  }, [window.location.pathname])

  const getPublicInstance_JSON = useMemo(() => {
    const pagesObject = (getPublicInstance_RESULT?.activeGroup?.pages || []).reduce((results, page) => ({
      ...results,
      [page.type]: {
        ...page,
        settings: (page.settings && JSON.parse(page.settings)) || {}
      }
    }), {});

    return {
      ...getPublicInstance_RESULT,
      activeGroup: {
        ...getPublicInstance_RESULT?.activeGroup,
        pagesObject
      }
    };
  }, [getPublicInstance_RESULT?.activeGroup]);

  return <OrganizationRouterMapper getPublicInstance_JSON={getPublicInstance_JSON} activeSlug={activeSlug} />;
};

export default memo(OrganizationRouterRender);
