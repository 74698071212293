import React, { memo, useCallback, useContext, useMemo, useState } from 'react';
import GeneralImageRender from './GeneralImageRender';
import { RequesterContext } from '../../context/requester.context';
import { checkAndRerunImageOptInit } from '../../actions/media.actions';
import { getOptimizedFromKey } from '../../helper';

const S3_Prefix = 'https://dgpv43ylujmyh.cloudfront.net/';

const GeneralImage = props => {
  const {
    onRef,
    style = {},
    className,
    originalKey,
    overwriteUrl,
    onLoad = () => null,
    dontRunOptFallback = true,
    imageResolution = '700x'
  } = props;

  const requester = useContext(RequesterContext)

  const [onErrorImage, updateErrorImage] = useState()

  const optKey = useMemo(() => originalKey && getOptimizedFromKey(imageResolution, originalKey), [imageResolution, originalKey])

  const onError = useCallback((e) => {
    if(!originalKey){
      e.target.style.visibility = 'hidden';
      e.target.onerror = null;
    } else {
      if (!dontRunOptFallback) {
        checkAndRerunImageOptInit(originalKey)
      };

      updateErrorImage(originalKey)
    }
  }, [originalKey, requester])

  const src = useMemo(() => onErrorImage ? `${S3_Prefix}${onErrorImage}` : overwriteUrl || `${S3_Prefix}${optKey}`, [onErrorImage, overwriteUrl, optKey]);

  return <>
    {src && (
      <GeneralImageRender
        {...onRef && { ref: onRef }}
        {...className && { className: className }}
        style={{ ...style }}                
        onLoad={onLoad}
        onError={onError}
        src={src}                
      />
    )}
  </>
};

export default memo(GeneralImage);
