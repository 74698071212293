import React, { memo, useCallback, useMemo } from 'react';
import AdminOptions from './ActionCenter/AdminOptions';

const ActionCenter = props => {
  const { passedPaywall, independantMedia, prevCheckpoint, nextCheckpoint, mediaToUse, showSingleLesson = {}, parentGoBack, goBack, updateProgramState } = props;

  const onGoBack = useCallback(() => {
    if(showSingleLesson.storiesBased) parentGoBack();
    
    goBack();              
  }, [showSingleLesson.storiesBased]);

  const prevCheckpoint_auth = useMemo(() => (!independantMedia && prevCheckpoint && (prevCheckpoint.series !== 'drip' || prevCheckpoint.dripAuth > 0)) ? true : false, [independantMedia ? true : false, prevCheckpoint]);
  const nextCheckpoint_auth = useMemo(() => (!independantMedia && nextCheckpoint && (nextCheckpoint.series !== 'drip' || nextCheckpoint.dripAuth > 0)) ? true : false, [independantMedia ? true : false, nextCheckpoint]);

  const prevShowSingleLesson = useMemo(() => (
    prevCheckpoint_auth && {
      preview: showSingleLesson.preview,
      complete: prevCheckpoint.complete,
      ...prevCheckpoint.lesson
        ? {
            ...prevCheckpoint,
            ...prevCheckpoint.lesson,
            index: prevCheckpoint.index
          }
        : prevCheckpoint,
      storiesBased: showSingleLesson.storiesBased
    }
  ), [prevCheckpoint_auth, showSingleLesson, prevCheckpoint]);

  const nextShowSingleLesson = useMemo(() => (
    nextCheckpoint_auth && {
      preview: showSingleLesson.preview,
      complete: nextCheckpoint.complete,
      ...nextCheckpoint.lesson
      ? {
          ...nextCheckpoint,
          ...nextCheckpoint.lesson,
          index: nextCheckpoint.index
        }
      : nextCheckpoint,
      storiesBased: showSingleLesson.storiesBased
    }
  ), [nextCheckpoint_auth, showSingleLesson, nextCheckpoint]);

  const onPrev = useCallback(() => (
    (prevCheckpoint && (passedPaywall || prevCheckpoint.free))
      ? updateProgramState({
          singleLesson: undefined,
          showSingleLesson: prevShowSingleLesson
        })
      : updateProgramState({
          singleLesson: undefined,
          showSingleLesson: undefined,
          showProgramPurchase: {
            selectedPackage: false,
            checkpoint: {
              showSingleLesson: prevShowSingleLesson
            }
          }
        })
  ), [(prevCheckpoint && (passedPaywall || prevCheckpoint.free)) ? true : false, prevShowSingleLesson]);

  const onNext = useCallback(() => (
    (nextCheckpoint && (passedPaywall || nextCheckpoint.free))
      ? updateProgramState({
          singleLesson: undefined,
          showSingleLesson: nextShowSingleLesson
        })
      : updateProgramState({
          singleLesson: undefined,
          showSingleLesson: undefined,
          showProgramPurchase: {
            selectedPackage: false,
            checkpoint: {
              showSingleLesson: nextShowSingleLesson
            }
          }
        })
  ), [(nextCheckpoint && (passedPaywall || nextCheckpoint.free)) ? true : false, nextShowSingleLesson]);

  const mainTitle = useMemo(() => (showSingleLesson && showSingleLesson.label) || mediaToUse.label || 'Untitled', [showSingleLesson, mediaToUse.label]);
          
  return <>
    <div className="single-lesson-title-and-back-top-left">
      <button className="back-specific" onClick={onGoBack}>
        <svg
          width="8"
          height="7"
          viewBox="0 0 8 7"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M1.33337 0.833252L6.66671 6.16659"
            stroke="#666666"
            strokeLinecap="round"
            strokeLinejoin="round"
          ></path>
          <path
            d="M6.66671 0.833252L1.33337 6.16659"
            stroke="#666666"
            strokeLinecap="round"
            strokeLinejoin="round"
          ></path>
        </svg>
      </button>
      <h1 className="hide-when-mobile-enacted">{mainTitle}</h1>
    </div>
    
    <AdminOptions assetOfFocus={showSingleLesson.id ? showSingleLesson : mediaToUse} />

    <div className="powered-by-to-bottom-right hide-when-mobile-enacted">
      <p>Powered by</p>
      <img src="/images/horiz_logo_small.png" />
    </div>

    <div className="container-of-checkpoint-single-pagination hide-when-mobile-enacted">
      {prevCheckpoint_auth && (
        <button onClick={onPrev} className="prev-lesson-button">
          Previous
        </button>
      )}
      
      {nextCheckpoint_auth && (
        <button onClick={onNext} className="next-lesson-button">
          Next
        </button>
      )}
    </div>
  </>;
};

export default memo(ActionCenter);
