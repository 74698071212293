import React, { memo, useMemo } from 'react';
import { useQuery } from 'react-apollo';
import OrganizationRouterRender from './OrganizationRouterRender';
import FallbackRouter from './FallbackRouter';
import { getPublicInstance } from '../apollo.queries';

const OrganizationRouter = ({ orgVariables }) => {  
  const cachedResult = useQuery(getPublicInstance, {
    variables: orgVariables,
    fetchPolicy: "cache-only"
  }),

  { data = {}, loading } = useQuery(getPublicInstance, {
    variables: orgVariables,
    fetchPolicy: "network-only"
  }),

  getPublicInstance_RESULT = data?.getPublicInstance || cachedResult?.data?.getPublicInstance || {},

  activeCurrency = getPublicInstance_RESULT?.activeGroup?.tenant?.currency,

  RESULT_toPass = useMemo(() => getPublicInstance_RESULT?.activeGroup?.code && ({ 
    ...getPublicInstance_RESULT, 
    currencyPrefix: (activeCurrency && (
      activeCurrency === 'usd' || activeCurrency === 'cad'
        ? '$'
        : activeCurrency === 'gbp'
          ? '£'
          : activeCurrency === 'eur' && '€'
    )) || '$'
  }), [getPublicInstance_RESULT, activeCurrency]),

  themeToUse = useMemo(() => localStorage.getItem('active_theme') || 'light', [])

  return (
    (loading && !getPublicInstance_RESULT.activeGroup) 
      ? <div className={`${themeToUse} general-furthest-out-loader-container`}>
          {generalLoader}
        </div>
      : RESULT_toPass
        ? <OrganizationRouterRender getPublicInstance_RESULT={RESULT_toPass} />
        : <div className={`org-router-based`}><FallbackRouter /></div>
  )
},

generalLoader = (
  <section className="appLoading">
    <svg
      className="simple-line-loader-for-app-loading css-primary-hex-stroke"
      viewBox="0 0 50 50"
      style={{ stroke: 'rgba(20, 20, 20,.15)' }}
    >
      <circle
        className="path css-primary-hex-stroke"
        cx="25"
        cy="25"
        r="20"
        fill="none"
        strokeWidth="5"
        style={{ stroke: 'rgba(20, 20, 20,.15)' }}
      ></circle>
    </svg>
  </section>
)

export default memo(OrganizationRouter)