import React, { memo, useCallback } from 'react';
import { useQuery } from 'react-apollo';
import GeneralImage from '../../../Shared/GeneralImage';
import { getLogoKey, tenantPrefixUrlDeterminant } from '../../../../actions/user.actions';
import { CurrentUserQuery_ProfileGroups } from '../../../../apollo.queries';

const GroupFeed = props => {
  const { activeGroupId } = props;

  const cachedData = useQuery(CurrentUserQuery_ProfileGroups, { fetchPolicy: "cache-only" });
  const { data = {}, loading } = useQuery(CurrentUserQuery_ProfileGroups, { fetchPolicy: "network-only" });

  const profileGroups = data.profileGroups || (cachedData.data || {}).profileGroups || [];

  const mapItem = useCallback(({ role, group, id }) => (
    (group.tenant && group.id !== activeGroupId)
      ? <button 
          key={`profile-group-${id}-floater`}
          onClick={() => (
            window.location = `${tenantPrefixUrlDeterminant(
              group.tenant.code,
              group.code
            )}${
              ['Admin'].indexOf(role) > -1
                ? '/admin/dashboard'
                : '/programs'
            }`
          )}
        >
          {group.tenant.logo ? (
            <GeneralImage
              imageResolution={'150x'}
              originalKey={getLogoKey(
                group.tenant.logo,
                group.tenant.id
              )}
            />
          ) : (
            <img
              className="fallback-logo"
              src="/images/android-chrome-192x192.png"
            />
          )}
          <span>{group.name}</span>
          <span>{group.tenant.name}</span>
        </button>
      : <></>
  ), []);

  if(loading && profileGroups.length === 0){
    return (
      <div className="container-of-group-iterate">
        <div className="loading-iterate" style={{ padding: 10 }}>Loading</div>
      </div>
    );
  };

  return profileGroups.length > 0 ? (
    <div className="container-of-group-iterate">
      {profileGroups.map(mapItem)}
    </div>
  ) : <></>;
};

export default memo(GroupFeed);