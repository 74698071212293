import React, { memo, useMemo } from 'react';
import GalleryMainRender from './GalleryMainRender';
import { ProfileContext } from '../../../../context/profile.context';
import { RequesterContext } from '../../../../context/requester.context';

const GalleryMain = props => {
  const { itemsSelected = [], parentData = [], loadingParent, editMassMediaInit, onSingleSelect, onRemove, onSelect, onRadioInit } = props;

  const loader = useMemo(() => (
    (loadingParent && parentData.length === 0) && (
      <>
        <div
          key={`media-item-placeholder-1`}
          className={`complete-container-of-media-item preloader-specific`}
        ></div>
        <div
          key={`media-item-placeholder-2`}
          className={`complete-container-of-media-item preloader-specific`}
        ></div>
      </>
    )
  ), [loadingParent, parentData.length === 0]);

  return <>
    <RequesterContext.Consumer>
      {requester =>
        <ProfileContext.Consumer>
          {profile =>
            <GalleryMainRender
              {...props}
              profile={profile}
              requester={requester}
              onSelect={onSelect}
              onRemove={onRemove}
              onRadioInit={onRadioInit}
              itemsSelected={itemsSelected}
              {...!editMassMediaInit && {
                onRemove: undefined,
                onSelect: onSingleSelect,
                itemsSelected: undefined
              }}
            />
          }
        </ProfileContext.Consumer>
      }
    </RequesterContext.Consumer>
    {loader}  
  </>;
};

export default memo(GalleryMain);