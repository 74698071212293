import React, { memo, useMemo } from 'react';
import { useQuery } from 'react-apollo';
import ParentMapper from './GalleryChoice/ParentMapper';
import { Gallery } from '../../../../../apollo.queries';

const GalleryChoice = props => {
  const { profileId = null } = props;

  const cachedData = useQuery(
    Gallery,
    { 
      fetchPolicy: "cache-only",
      variables: {
        profileId,
        page: 1,
        limit: 24
      }
    }
  );

  const newData = useQuery(
    Gallery,
    {
      fetchPolicy: "network-only",
      variables: {
        profileId,
        page: 1,
        limit: 24
      }
    }
  );

  const parentData = useMemo(() => ((newData.data || {}).searchMedia || (cachedData.data || {}).searchMedia || {}).gallery || [], [newData.data, cachedData.data]);

  return <>
    <ParentMapper
      {...props}
      loadingParent={newData.loading}
      parentData={parentData}
    />
  </>;
};

export default memo(GalleryChoice);