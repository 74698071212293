import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { updateFormState } from '../../../../../../../../actions/form.actions';
import CountryPicker from '../../../../../../../Shared/CountryPicker';

class BlockFormCountryInput extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {};
  }

  render() {
    const { block, formState, updateFormState } = this.props;
    const { id, label, title, required = 1 } = block;
    const { formResponses = {}, reducedRequired = {} } = formState;
    const currentValue = (formResponses[id] || {}).country;

    return <>
      <div  className={`general-form-block-render-container country-type`}>
        <label className="question-name">{label || title || `Country`}{required ? '*' : ''}</label>

        <div className="top-section-with-icon-to-right">
          {reducedRequired[id] ? <div className="invalid-currently-dignifier">Required*</div> : <></>}

          <div className="top-section-with-icon-to-right">  
            <CountryPicker
              value={currentValue} 
              onChange={
                e => updateFormState({
                  ...reducedRequired[block.id] && { reducedRequired: { ...reducedRequired, [block.id]: undefined } },
                  formResponses: {
                    ...formResponses,
                    [id]: { country: e }
                  }              
                })
              }
            />
          </div>          
        </div>
      </div>
    </>;
  }
};

const mapStateToProps = state => ({
  formState: state.formState
});

const mapDispatchToProps = dispatch => ({
  updateFormState: updatedStates => dispatch(updateFormState(updatedStates))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(BlockFormCountryInput);