import ACTIONS from '../constants/program/actions';
import apolloClient from '../apollo.client';
import { parallel, mapLimit, parallelLimit } from 'async-es';
import CoachingMaterials from '../constants/program/coachingMaterials';
import { programQuery } from '../apollo.queries';
import {
  removeProgram,
  upsertProgram,
  removeCheckpoints,
  removePackages,
  removeAssignedUsers
} from '../apollo.mutations';
import { getLessonById } from './lesson.actions';

const chunks = (array, size) => {
  if (!array.length) {
    return [];
  }
  const head = array.slice(0, size);
  const tail = array.slice(size);

  return [head, ...chunks(tail, size)];
};

/** Start of Constants **/

export const checkpointTypesObject = CoachingMaterials.reduce(
  (result, currentValue) => ({
    ...result,
    [currentValue.type]: currentValue
  }),
  {}
);

/** Start of Mutations **/

export const removeProgramInit = programId => async dispatch => {
  dispatch({
    type: ACTIONS.UPDATE_STATE,
    payload: {
      removingProgram: true
    }
  });

  const removeProgramData = (
    (await apolloClient.mutate({
      mutation: removeProgram,
      variables: {
        input: { id: programId }
      }
    })) || {}
  ).data.removeProgram.success;

  dispatch({
    type: ACTIONS.UPDATE_STATE,
    payload: {
      removingProgram: false,
      [`program_removed_${programId}`]: true
    }
  });

  return { removeProgramData };
};

export const upsertProgramInit = ({
  program,
  checkpointsToRemove = [],
  packageItemsToRemove = [],
  assignmentsToRemove = []
}) => async dispatch => {  
  const {
    id,
    title,
    pricing,
    access,
    series,
    settings,
    featuredImage,
    assignedUsers = [],
    activePackages = [],
    checkpoints = []
  } = program;

  dispatch({
    type: ACTIONS.UPDATE_STATE,
    payload: {
      updatingProgram: true
    }
  });

  const newProgram = id.includes('new-program');

  const {
    upsertedProgram,
    checkpointsRemoved,
    packagesRemoved
  } = await new Promise(resolve =>
    parallel(
      {
        upsertedProgram: callback =>
          setTimeout(
            async () =>
              callback(
                null,
                (
                  await apolloClient.mutate({
                    mutation: upsertProgram,
                    variables: {
                      input: {
                        id,
                        title,
                        pricing,
                        access,
                        series,
                        settings,
                        featuredImage,
                        assignedUsers: assignedUsers.map(({ profile }) => ({
                          id: profile.id
                        })),
                        activePackages: activePackages.map(
                          ({ packageItem }) => ({ id: packageItem.id })
                        ),
                        checkpoints: checkpoints.map(
                          (
                            { id, type, series, lesson, media, free, label, daysAfter, featuredImage, dripTime, dripDate, dripType = 'days_after' },
                            index
                          ) => ({
                            id,
                            type,
                            series,
                            free,
                            label,
                            childId: lesson ? lesson.id : media.id,
                            index,
                            featuredImage,
                            ...series === 'drip' && (
                              dripType === 'days_after'
                                ? { daysAfter }
                                : {  
                                    dripTime: `${new Date(`${
                                      (dripDate ? new Date(dripDate) : new Date()).toLocaleDateString('en-US')
                                    } ${
                                      ((dripTime || dripDate) ? new Date(dripTime || dripDate) : new Date()).toLocaleTimeString('en-US', {
                                        hour12: true,
                                        hour: '2-digit',
                                        minute: '2-digit'
                                      })
                                    }`).getTime()}`
                                  }
                            )                           
                          })
                        )
                      }
                    }
                  })
                ).data.upsertProgram.success
              ),
            10
          ),
        checkpointsRemoved: callback =>
          setTimeout(
            async () =>
              callback(
                null,
                await new Promise(resolve =>
                  mapLimit(
                    chunks(
                      checkpointsToRemove.map(checkpoint => ({
                        id: checkpoint.id
                      })),
                      10
                    ),
                    3,
                    (checkpoints, callback) =>
                        setTimeout(
                          async () =>
                            callback(
                              null,
                              newProgram
                                ? []
                                : (
                                    await apolloClient.mutate({
                                      mutation: removeCheckpoints,
                                      variables: {
                                        input: {
                                          checkpoints
                                        }
                                      }
                                    })
                                  ).data.removeCheckpoints.success
                            )
                        , 10),
                    (err, results) => resolve((results || []).flat(1))
                  )
                )
              ),
            20
          ),
        packagesRemoved: callback =>
          setTimeout(
            async () =>
              callback(
                null,
                await new Promise(resolve =>
                  mapLimit(
                    chunks(packageItemsToRemove, 10),
                    3,
                    (packages, callback) =>
                        setTimeout(
                          async () =>
                            callback(
                              null,
                              newProgram
                                ? []
                                : (
                                    await apolloClient.mutate({
                                      mutation: removePackages,
                                      variables: {
                                        input: {
                                          packages,
                                          programId: id
                                        }
                                      }
                                    })
                                  ).data.removePackages.success
                            )
                        , 10),
                    (err, results) => resolve((results || []).flat(1))
                  )
                )
              ),
            30
          ),
        assignedUsersRemoved: callback =>
          setTimeout(
            async () =>
              callback(
                null,
                await new Promise(resolve =>
                  mapLimit(
                    chunks(assignmentsToRemove, 10),
                    3,
                    (profiles, callback) =>
                        setTimeout(
                          async () =>
                            callback(
                              null,
                              newProgram
                                ? []
                                : (
                                    await apolloClient.mutate({
                                      mutation: removeAssignedUsers,
                                      variables: {
                                        input: {
                                          profiles,
                                          programId: id
                                        }
                                      }
                                    })
                                  ).data.removeAssignedUsers.success
                            )
                        , 10),
                    (err, results) => resolve((results || []).flat(1))
                  )
                )
              ),
            40
          )
      },
      async (err, results) => resolve(results || {})
    )
  );

  dispatch({
    type: ACTIONS.UPDATE_STATE,
    payload: {
      updatingProgram: false
    }
  });

  return {
    upsertedProgram,
    checkpointsRemoved,
    packagesRemoved
  };
};

/** End of Mutations **/

export const updateProgramState = updatedStates => dispatch =>
  dispatch({
    type: ACTIONS.UPDATE_STATE,
    payload: updatedStates
  });

export const addMaterial = ({ program, coachingMaterialToAdd }) => dispatch => {
  let checkpointsToAdd = program.checkpoints || [];
  
  if((checkpointsToAdd || []).length > 50){
    alert('Only 50 checkpoints are allowed per program.');

    return;
  };

  checkpointsToAdd.splice(
    checkpointsToAdd.length >= coachingMaterialToAdd.index
      ? coachingMaterialToAdd.index
      : checkpointsToAdd.length,
    coachingMaterialToAdd.replace ? 1 : 0,
    coachingMaterialToAdd
  );  

  dispatch({
    type: ACTIONS.UPDATE_STATE,
    payload: {
      materialToAdd: coachingMaterialToAdd,
      program: {
        ...program,
        programUpdated: true,
        checkpoints: checkpointsToAdd.map((coachingMaterial, index) => ({
          ...coachingMaterial,
          index
        }))
      }
    }
  });
};

export const removeMaterial = ({
  program,
  coachingMaterialToRemove
}) => dispatch => {
  let checkpointsToAdd = program.checkpoints || [];
  checkpointsToAdd.splice(coachingMaterialToRemove.index, 1);

  dispatch({
    type: ACTIONS.UPDATE_STATE,
    payload: {
      activeTreeElement: -1,
      materialToAdd: null,
      program: {
        ...program,
        programUpdated: true,
        checkpoints: checkpointsToAdd.map((coachingMaterial, index) => ({
          ...coachingMaterial,
          index
        }))
      }
    }
  });
};

export const initProgramBuilder = ({
  programId,
  program,
  cloneSpecific
}) => async dispatch => {
  const { data } = program
    ? { data: { programQuery: program } }
    : (await apolloClient.query({
        query: programQuery,
        fetchPolicy: 'network-only',
        variables: { programId }
      })) || {};

  try {
    const checkpointsToPass = await new Promise(resolve =>
      parallelLimit(
        [
          ...(data.programQuery.checkpoints || []).map(
            (checkpoint, localIndex) => callback =>
              setTimeout(async () => {
                callback(null, {
                  ...checkpoint,
                  ...checkpoint.dripTime ? {
                    dripType: 'date',
                    dripTime: parseInt(checkpoint.dripTime),
                    dripDate: parseInt(checkpoint.dripTime)
                  } : { dripType: 'days_after' },
                  ...(cloneSpecific && {
                    id: `new-checkpoint-clone-specific-${localIndex}`
                  }),
                  ...(checkpoint.lesson
                    ? {
                        ...checkpointTypesObject['lesson'],
                        lesson: await getLessonById(checkpoint.lesson.id, true)
                      }
                    : {
                        ...checkpointTypesObject['media'],
                        media: checkpoint.media
                      })
                });
              }, localIndex)
          )
        ],
        10,
        (err, results) => resolve(results || [])
      )
    );

    dispatch({
      type: ACTIONS.UPDATE_STATE,
      payload: {
        activeSlide: 0,
        activeBlock: 0,
        program: {
          ...data.programQuery,
          ...cloneSpecific && { title: `copy of ${data.programQuery.title || 'No Title'}` },
          checkpoints: checkpointsToPass || []
        }
      }
    });
  } catch (e) {
    console.log(e);
  }

  return true;
};
