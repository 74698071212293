import React, { useMemo } from 'react';
import { useQuery } from 'react-apollo';
import { Helmet } from 'react-helmet';
import { useLocation, useParams } from 'react-router';
import FormViewer from '../../components/AdminChildren/Coaching/Forms/MainFrame/FormViewer';
import GeneralLoader from '../../components/Shared/GeneralLoader';
import { Form_QueryConcat } from '../../actions/form.actions';
import { CurrentUserQuery } from '../../apollo.queries';
import { getOptimizedFromKey } from '../../helper';
import { getLogoKey } from '../../actions/user.actions';

const DirectFormView = (props = {}) => {
  const queryP = new URLSearchParams(useLocation().search)

  const responseId_forcePayment = queryP.get('responseId')

  const { id } = useParams()
  const { formEmbed } = props || {}

  const currentProfile_cache = useQuery(CurrentUserQuery, { fetchPolicy: "cache-only" })
  const currentProfile = useQuery(CurrentUserQuery, { fetchPolicy: "network-only" })
  
  const {
    FormResult = {},
    stillLoading
  } = Form_QueryConcat(id)

  const tenantViaGroup = FormResult?.group?.tenant
  const activeGroupName = FormResult?.group?.name
  
  const logoUrl = useMemo(() => {
    const originalKey = originHost && tenantViaGroup?.logo && getLogoKey(tenantViaGroup?.logo, tenantViaGroup?.id)

    return originHost && originalKey && `https://s3.us-west-2.amazonaws.com/athletic-outlook/${getOptimizedFromKey( '150x', originalKey)}`
  }, [tenantViaGroup?.logo, originHost])

  return (
    <section id="complete_form_frame" className={`complete-program-main-frame ${formEmbed && 'form-embed-iframe-specific'} live form-specific`} style={{ backgroundColor: (FormResult.settings || {}).theme === 'dark' ? "#000" : "#fff" }}>
       {tenantViaGroup && <>
        <Helmet>
          <title>{`${activeGroupName} | ${tenantViaGroup.name}`}</title>
          <meta content={tenantViaGroup.name} itemprop="name" />
          <meta content={`${tenantViaGroup.name} Learning Management.`} name="description" />
          <meta content={(FormResult.settings || {}).theme === 'dark' ? "#000" : "#fff"} name="msapplication-TileColor" />
          <meta content={tenantViaGroup.name} property="og:title" />
          <meta content={tenantViaGroup.name} property="og:site_name" />
          <meta content={`${tenantViaGroup.name} Learning Management.`} property="og:description" />
          <meta content="summary_large_image" name="twitter:card" />
          <meta content={tenantViaGroup.name} name="twitter:title" />
          <meta content={`${tenantViaGroup.name} Learning Management.`} name="twitter:description" />                       
        </Helmet>

        {logoUrl ? (
          <Helmet>
            <link
              href={logoUrl}
              rel="icon"
              type="image/png"
            />
            <meta
              content={logoUrl}
              property="og:image"
            />
            <meta
              content={logoUrl}
              itemprop="image"
            />
            <meta
              content={logoUrl}
              name="twitter:image"
            />
          </Helmet>
        ) : <></>}
       </>}

      {(stillLoading || currentProfile.loading) ? <GeneralLoader /> : <FormViewer profile={(currentProfile.data || {}).profile || (currentProfile_cache.data || {}).profile} form={FormResult} responseId_forcePayment={responseId_forcePayment} />}
    </section>
  );
};

const hostName = window.location.hostname.toLowerCase();
const originHost = hostName.indexOf('coachiq.io') > -1 || hostName.indexOf('localhost') > -1 || hostName.indexOf('127.0.0.1') > -1;

export default DirectFormView;