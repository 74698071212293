import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import SelectInputV2 from '../../../../../../../Shared/SelectInputV2';
import { updateFormState } from '../../../../../../../../actions/form.actions';

class BlockFormSelectInput extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {};
  }

  render() {
    const { block, formState, updateFormState } = this.props;
    const { id, label, title, choices = [], required = 1 } = block;
    const { formResponses = {}, reducedRequired = {} } = formState;

    const choiceIterate = (choices || []).map((choice, index) => ({ id: choice.id, value: choice.id, label: choice.label || `Option ${index + 1}` }));
    const currentValue = (formResponses[block.id] || {}).choiceId && choiceIterate.find((choice) => choice.id === (formResponses[block.id] || {}).choiceId);

    return <>
      <div  className={`general-form-block-render-container select-type`}>
        <label className="question-name">{label || title || `Question Name`}{required ? '*' : ''}</label>

        {reducedRequired[block.id] ? <div className="invalid-currently-dignifier">Required*</div> : <></>}

        <div className="top-section-with-icon-to-right">          
          <SelectInputV2
            options={choiceIterate}
            value={currentValue}
            onChange={value => updateFormState({
              ...reducedRequired[block.id] && { reducedRequired: { ...reducedRequired, [block.id]: undefined } },
              formResponses: {
                ...formResponses,
                [id]: { choiceId: value.id }
              }              
            })}
          />  
        </div>        
      </div>
    </>;
  }
};

const mapStateToProps = state => ({
  formState: state.formState
});

const mapDispatchToProps = dispatch => ({
  updateFormState: updatedStates => dispatch(updateFormState(updatedStates))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(BlockFormSelectInput);