import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { updateGeneralState } from '../../../../../actions/general.actions';
import { cancelMembershipInit } from '../../../../../actions/user.actions';

class OrdersAndMembershipsMainTable extends Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  render() {
    const { showActionMenu } = this.state;
    const {
      orders,
      generalState,
      loadingAdditionalOrders,
      isMembership,
      currencyPrefix,
      cancelMembershipInit
    } = this.props;

    const { loadingCancellation } = generalState;

    return (
      <>
        <div className="immediate-order-table-body">
          {orders.map((order, index) => (
            <div key={order.id} className="order-table-row">
              <div className="product-specific-width td">
                {' '}
                {(order.package && order.package.label) || 'N/A'}
              </div>
              <div className="date-specific-width td">
                {new Date(order.createdAt).toLocaleString().split(',')[0]}
              </div>
              {isMembership ? (
                <div
                  className={`status-specific-width td ${
                    generalState[`membership_cancelled_${order.id}`]
                      ? 'cancelled'
                      : order.stripeStatus || 'cancelled'
                  }-status-ref`}
                >
                  {generalState[`membership_cancelled_${order.id}`]
                    ? 'cancelled'
                    : order.stripeStatus || 'cancelled'}
                </div>
              ) : (
                <div className="amount-specific-width td">
                  {`${currencyPrefix}${order.amount}` || 'N/A'}{' '}
                  {order.coupon && `(${order.coupon})`}
                </div>
              )}

              {!loadingCancellation && (
                <div className="display-specific-width align-right td">
                  {parseFloat(`${order.amount}`) > 0 && !generalState[`membership_cancelled_${order.id}`] && order.stripeStatus === 'active' && (
                      <>
                        <button
                          onClick={() =>
                            this.setState({
                              showActionMenu:
                                showActionMenu === order.id ? false : order.id
                            })
                          }
                        >
                          <svg
                            width="20"
                            height="4"
                            viewBox="0 0 20 4"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M18.6666 1.99997C18.6666 2.35997 18.3599 2.66664 17.9999 2.66664C17.6266 2.66664 17.3333 2.35997 17.3333 1.99997C17.3333 1.62664 17.6266 1.3333 17.9999 1.3333H17.9866C18.3466 1.31997 18.6533 1.62664 18.6533 1.98664"
                              stroke="#323232"
                              strokeWidth="2.5"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                            <path
                              d="M10.6666 1.99997C10.6666 2.35997 10.3599 2.66664 9.99992 2.66664C9.62659 2.66664 9.33325 2.35997 9.33325 1.99997C9.33325 1.62664 9.62659 1.3333 9.99992 1.3333H9.98658C10.3466 1.31997 10.6533 1.62664 10.6533 1.98664"
                              stroke="#323232"
                              strokeWidth="2.5"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                            <path
                              d="M2.66659 1.99997C2.66659 2.35997 2.35992 2.66664 1.99992 2.66664C1.62659 2.66664 1.33325 2.35997 1.33325 1.99997C1.33325 1.62664 1.62659 1.3333 1.99992 1.3333H1.98659C2.34659 1.31997 2.65325 1.62664 2.65325 1.98664"
                              stroke="#323232"
                              strokeWidth="2.5"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                          </svg>
                        </button>

                        {showActionMenu === order.id &&
                          !generalState[`membership_cancelled_${order.id}`] &&
                            order.stripeStatus === 'active' && (
                              <div className="menu-container">
                                <button
                                  className="membership-cancellation"
                                  onClick={() =>
                                    this.setState(
                                      { showActionMenu: false },
                                      () =>
                                        cancelMembershipInit({
                                          purchaseId: order.id
                                        })
                                    )
                                  }
                                >
                                  Cancel Membership
                                </button>
                              </div>
                            )}
                      </>
                    )}
                </div>
              )}
            </div>
          ))}
          {loadingCancellation && (
            <div className="general-tag-loading-specific">
              <div className="appLoading">
                <svg
                  className="simple-line-loader-for-app-loading css-primary-hex-stroke"
                  viewBox="0 0 50 50"
                >
                  <circle
                    className="path css-primary-hex-stroke"
                    cx="25"
                    cy="25"
                    r="20"
                    fill="none"
                    strokeWidth="5"
                  ></circle>
                </svg>
              </div>
            </div>
          )}
          {loadingAdditionalOrders && (
            <>
              <div
                key={`order-table-row-${isMembership}-1`}
                className="order-table-row"
              >
                <div className="product-specific-width td">...</div>
                <div className="date-specific-width td">...</div>
              </div>

              <div
                key={`order-table-row-${isMembership}-2`}
                className="order-table-row"
              >
                <div className="product-specific-width td">...</div>
                <div className="date-specific-width td">...</div>
              </div>
            </>
          )}
        </div>
      </>
    );
  }
}

OrdersAndMembershipsMainTable.propTypes = {
  loadingAdditionalOrders: PropTypes.bool,
  orders: PropTypes.array.isRequired,
  onMainClick: PropTypes.func.isRequired
};

const mapStateToProps = state => ({
  generalState: state.generalState
});

const mapDispatchToProps = dispatch => ({
  updateGeneralState: variables => dispatch(updateGeneralState(variables)),
  cancelMembershipInit: variables => dispatch(cancelMembershipInit(variables))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(OrdersAndMembershipsMainTable);
