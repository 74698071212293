import React from 'react';
import PropTypes from 'prop-types';

import './input.scss';

const TextInput = props => {
  const {
    id,
    label,
    value,
    onChange,
    className,
    required,
    type,
    min,
    max,
    maxLength,
    disabled,
    error,
    errorText,
    placeholder,
    helpText,
    onBlur,
    onFocus,
    onClick,
    style,
    size
  } = props;
  return (
    <div className={`ao-input ${className} ${error ? 'error' : ''}`}>
      <label htmlFor={id}>
        {label} {required && <span>*</span>}
      </label>
      <input
        id={id}
        disabled={disabled}
        type={type}
        placeholder={placeholder}
        required={required}
        min={min}
        max={max}
        maxLength={maxLength}
        value={value}
        style={style}
        size={
          size || parseInt(size) === 0
            ? size > 0
              ? size + 1
              : placeholder && placeholder.length + 1
            : null
        }
        onChange={e => onChange(e.target.value)}
        onBlur={e => onBlur(e)}
        onFocus={e => onFocus(e)}
        onClick={e => onClick(e)}
      />
      {type === 'Number' && (
        <div className="up-and-down-button-for-number-type">
          <button
            className="up-button-specific"
            onClick={e => onChange(parseInt(value) + 1)}
          >
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M8 10L12 14L16 10"
                stroke="#666666"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              ></path>
            </svg>
          </button>
          <button
            className="down-button-specific"
            onClick={e => onChange(parseInt(value) - 1)}
          >
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M8 10L12 14L16 10"
                stroke="#666666"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              ></path>
            </svg>
          </button>
        </div>
      )}
      {helpText && <p className="help">{helpText}</p>}
      {error && errorText && <p className="error">{errorText}</p>}
    </div>
  );
};

TextInput.propTypes = {
  id: PropTypes.string,
  label: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  onBlur: PropTypes.func,
  onFocus: PropTypes.func,
  onClick: PropTypes.func,
  style: PropTypes.object,
  className: PropTypes.string,
  required: PropTypes.bool,
  type: PropTypes.string,
  maxLength: PropTypes.number,
  max: PropTypes.number,
  min: PropTypes.number,
  disabled: PropTypes.bool,
  placeholder: PropTypes.string,
  helpText: PropTypes.string,
  size: PropTypes.number
};

TextInput.defaultProps = {
  className: '',
  required: false,
  type: 'text',
  maxLength: 100,
  disabled: false,
  placeholder: '',
  helpText: '',
  onFocus: () => {},
  onBlur: () => {},
  onClick: () => {}
};

export default TextInput;
