import React, { memo, useCallback, useEffect, useMemo } from 'react';
import Cohere from 'cohere-js';
import GeneralImage from '../../../../Shared/GeneralImage';
import { getAvatarKey, getLogoKey } from '../../../../../actions/user.actions';
import { RequesterContext } from '../../../../../context/requester.context';

const ProfileHeaderButton = props => {
  const { user = {}, profile, isActive } = props;
  const { avatar, email, firstName, lastName, id } = user;

  const displayName = useMemo(() => firstName || lastName ? `${firstName || ''}${ lastName && lastName.length > 0 ? ` ${lastName}` : '' }` : email, [email, firstName, lastName]);

  useEffect(() => {
    if (process.env.NODE_ENV === 'production') {
      Cohere.identify(
        profile.id,
        { displayName, email }
      );
    };
  }, []);

  const getAvatarKey_init = useCallback(getAvatarKey, []);
  const getLogoKey_init = useCallback(getLogoKey, []);

  const avatarRender = useCallback(
    (tenant) => (
      avatar ? (
        <GeneralImage
          className="avatar-specific"
          imageResolution={'350x'}
          originalKey={getAvatarKey_init(avatar, id)}
        />
      ) : tenant.logo ? (
        <GeneralImage
          className="avatar-specific fallback-logo"
          originalKey={getLogoKey_init(tenant.logo, tenant.id)}
          imageResolution={'350x'}
        />
      ) : (
        <img
          className="avatar-specific fallback-logo"
          src="https://app.coachiq.io/images/android-chrome-512x512.png"
        />
      )
    ), [avatar]
  );

  const activeStyle = useMemo(() => ({ ...isActive && { opacity: 1 } }), [isActive]);

  return <>      
    <RequesterContext.Consumer>
      {({ activeGroup }) => <>
        {avatarRender(activeGroup.tenant)}
        <h2 style={activeStyle}>{displayName}</h2>
        <svg
          style={activeStyle}
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M8 10L12 14L16 10"
            stroke="#666666"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      </>}
    </RequesterContext.Consumer>      
  </>;
};

export default memo(ProfileHeaderButton);
