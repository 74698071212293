import React, { memo, useCallback } from 'react';
import { FileIcon, defaultStyles } from 'react-file-icon';
import GeneralImage from '../../../../components/Shared/GeneralImage';

const ArchiveMediaItem = props => {
    const {
        file_type,
        image_src,
        thumb_url,
        fallbackImage2,
        fallbackImage,
        file_extension
    } = props;

    const getFileIconRender = useCallback(
        () => (
            <FileIcon
                extension={file_extension}
                {...defaultStyles[file_extension]}
            />
        ), [file_extension]
    );

    const getImageRender = useCallback(
        () => (
            <GeneralImage
                imageResolution={'350x'}
                originalKey={image_src}
            />
        ), [image_src]
    );

    const getThumbRender = useCallback(
        () => (
            <img
                src={thumb_url}
                onError={e => {
                    if (
                        e.target.className.indexOf(
                            'image-doesnt-exist'
                        ) > -1
                    ) {
                        e.target.onerror = null;
                        e.target.src = fallbackImage2;
                    } else {
                        e.target.className += 'fallback-logo image-doesnt-exist';
                        e.target.src = fallbackImage;
                    }
                }}
            />
        ), [thumb_url]
    );

    return (
        file_type === 'other'
            ? getFileIconRender()
            : file_type === 'image' 
                ? getImageRender()
                : getThumbRender()
    );
};

export default memo(ArchiveMediaItem);