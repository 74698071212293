import React, { memo, useState } from 'react';

const ActionLoader = ({ deactivatingTag, assignSpecific, upsertingTagConnection, removingTagConnection }) => (
  (deactivatingTag || (assignSpecific && (upsertingTagConnection || removingTagConnection))) && (
    <div className="general-tag-loading-specific">
      <div className="appLoading">
        <svg
          className="simple-line-loader-for-app-loading css-primary-hex-stroke"
          viewBox="0 0 50 50"
        >
          <circle
            className="path css-primary-hex-stroke"
            cx="25"
            cy="25"
            r="20"
            fill="none"
            strokeWidth="5"
          ></circle>
        </svg>
      </div>
    </div>
  )
);

export default memo(ActionLoader);
