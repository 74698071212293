import { ApolloClient, InMemoryCache, split, HttpLink, ApolloLink } from '@apollo/client';
import { getMainDefinition } from '@apollo/client/utilities';
import { WebSocketLink } from '@apollo/client/link/ws';

import replaceall from 'replaceall';

const hostName = window.location.hostname.toLowerCase();
const hostNameSlugToUse = replaceall('.', '-', hostName);
const originHost = hostName.indexOf('coachiq.io') > -1 || hostName.indexOf('localhost') > -1 || hostName.indexOf('127.0.0.1') > -1;

const httpLink = new HttpLink({
  uri: `${process.env.REACT_APP_OMNI_URL}/graphql`
});

const wsLink = new WebSocketLink({
  uri: process.env.REACT_APP_WS_URL,
  options: {
    reconnect: true,
    connectionParams: () => ( 
      new Promise(async (resolve) => {
        const pathnameLowercase = window.location.pathname.toLowerCase();
        const split_pathName = pathnameLowercase.split('/');

        resolve({
          groupidbrowserbased: originHost ? localStorage.getItem(`${hostNameSlugToUse}-${split_pathName[0]}-${split_pathName[1]}-active-group-id-v5`) || (pathnameLowercase.indexOf('/stripe-connected') > -1 && localStorage.getItem('stripe-temp-creation-id')) || null : localStorage.getItem(`${hostNameSlugToUse}-active-group-id-v5`),
          authToken: `JWT ${localStorage.getItem(
            `${hostNameSlugToUse}-auth-token-v3-12-13-21`
          ) || null}`
        })
      })
    )
  }
});

const authMiddleware = new ApolloLink((operation, forward) => {  
  const pathnameLowercase = window.location.pathname.toLowerCase();
  const split_pathName = pathnameLowercase.toLowerCase().split('/');

  operation.setContext({
    headers: {
      groupidbrowserbased: originHost ? localStorage.getItem(`${hostNameSlugToUse}-${split_pathName[0]}-${split_pathName[1]}-active-group-id-v5`) || (pathnameLowercase.indexOf('/stripe-connected') > -1 && localStorage.getItem('stripe-temp-creation-id')) || null : localStorage.getItem(`${hostNameSlugToUse}-active-group-id-v5`),
      authorization: `JWT ${localStorage.getItem(
        `${hostNameSlugToUse}-auth-token-v3-12-13-21`
      ) || null}`
    }
  });

  return forward(operation);
});

const httpLinkFinal = authMiddleware.concat(httpLink);

const splitLink = split(
  ({ query }) => {
    const definition = getMainDefinition(query);
    return (
      definition.kind === 'OperationDefinition' &&
      definition.operation === 'subscription'
    );
  },
  wsLink,
  httpLinkFinal
);

const defaultOptions = {
  watchQuery: {
    fetchPolicy: 'cache-and-network',
    errorPolicy: 'ignore'
  }
};

const client = new ApolloClient({
  link: splitLink,
  cache: new InMemoryCache({ addTypename: false }),
  connectToDevTools: true,
  defaultOptions
});

export default client;
