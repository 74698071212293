import React, { memo, useState } from 'react';
import { useNavigate } from 'react-router';
import MobileMenu from './MobileMenu';
import GroupFeed from './GroupFeed';
import IntercomUpdate from './IntercomUpdate';
import LogoutButton from '../../../Public/Header/ProfileSection/LoggedInMapper/LogoutButton';

const hostName = window.location.hostname.toLowerCase();
const isLocalHost = hostName.includes('localhost');

const BottomSection = props => {
  const {
    requester,
    activeSlug,
    onMenuItemClick
  } = props;

  const [showMenu, updateShowMenu] = useState();

  const navigate = useNavigate();

  const groupCode = requester.activeGroup.code;
  const groupName = requester.activeGroup.name;
  const tenantCode = requester.activeGroup.tenant.code;
  const tenantName = requester.activeGroup.tenant.name;

  return (
    <div className={`bottom-section-help-center-specific mobile-full`}>
      <IntercomUpdate updateIntercom={true} />
      <div className="immediate-bottom-section-links-container">
        <div className="top">
          <a
            href={`http${isLocalHost ? '' : 's'}://${hostName}${isLocalHost ? ':3000' : ''}/${tenantCode}/${groupCode}/programs`}
            target="_blank"
          >
            Athlete View
            <svg width="20" height="16" viewBox="0 0 20 16" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M1.11799 8.467C0.957986 8.167 0.957986 7.817 1.11799 7.527C3.00999 4.027 6.49799 0.994995 9.99799 0.994995C13.488 0.994995 16.988 4.02499 18.878 7.52499C19.028 7.81499 19.028 8.165 18.878 8.455C16.978 11.945 13.488 14.985 9.98799 14.985C6.48799 14.985 2.99799 11.945 1.09799 8.44499L1.11799 8.467Z" stroke="white" strokeLinecap="round" strokeLinejoin="round"/>
              <path d="M12.12 5.87001C13.29 7.04001 13.29 8.94001 12.12 10.11C10.94 11.28 9.03999 11.28 7.86999 10.11C6.68999 8.93001 6.68999 7.03001 7.86999 5.86001C9.03999 4.68001 10.94 4.68001 12.11 5.86001" stroke="white" strokeLinecap="round" strokeLinejoin="round"/>
            </svg>
          </a>
          <a
            href={"https://help.coachiq.io"}
            target="_blank"
          >
            Help Center
            <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M8.5 2H5.8C4.11984 2 3.27976 2 2.63803 2.32698C2.07354 2.6146 1.6146 3.07354 1.32698 3.63803C1 4.27976 1 5.11984 1 6.8V12.2C1 13.8802 1 14.7202 1.32698 15.362C1.6146 15.9265 2.07354 16.3854 2.63803 16.673C3.27976 17 4.11984 17 5.8 17H11.2C12.8802 17 13.7202 17 14.362 16.673C14.9265 16.3854 15.3854 15.9265 15.673 15.362C16 14.7202 16 13.8802 16 12.2V9.5M17 1L9 9M17 1V5.5M17 1H12.5" stroke="white" strokeLinecap="round" strokeLinejoin="round"/>
            </svg>
          </a>
        </div>
        <div className="bottom">
          <div className="container-to-init-within-on-hover">
            <div className="static" onMouseEnter={() => !showMenu && updateShowMenu(true)}>
              <div className="tenant-and-group-name">
                <p className="group-name">{groupName}</p>
                <p className="tenant-name">{tenantName}</p>
              </div>
              <svg width="9" height="13" viewBox="0 0 9 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M1.375 0.5L7.625 6.5L1.375 12.5" stroke="white" strokeLinecap="round" strokeLinejoin="round"/>
              </svg>
            </div>
            {showMenu && (
              <div className="floating-connections-list">         
                <GroupFeed activeGroupId={requester.activeGroup.id} />
                <div className="static-within">
                  <button onClick={() => navigate(`/${tenantCode}/${groupCode}/admin/settings`)}>
                    Settings
                    <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M8.27275 5.72716C8.97569 6.43011 8.97569 7.5698 8.27275 8.27275C7.5698 8.97569 6.43011 8.97569 5.72716 8.27275C5.02421 7.5698 5.02421 6.43011 5.72716 5.72716C6.43011 5.02421 7.5698 5.02421 8.27275 5.72716" stroke="black" strokeLinecap="round" strokeLinejoin="round"></path><path d="M2.49999 7C2.49999 7.198 2.51799 7.396 2.54199 7.588L1.48333 8.416C1.24866 8.6 1.18466 8.92867 1.33399 9.18667L2.27533 10.8153C2.42399 11.0733 2.73999 11.182 3.01666 11.0713L3.96466 10.6907C4.15199 10.6153 4.36066 10.6453 4.52866 10.7567C4.67533 10.854 4.82733 10.9433 4.98466 11.0233C5.16466 11.1147 5.29533 11.278 5.32399 11.478L5.46866 12.4867C5.51066 12.7813 5.76333 13 6.06066 13H7.93866C8.23599 13 8.48866 12.7813 8.53066 12.4867L8.67533 11.4787C8.70399 11.2787 8.83599 11.114 9.01666 11.0233C9.17333 10.9447 9.32466 10.856 9.47066 10.7593C9.63999 10.6473 9.84933 10.6153 10.0373 10.6913L10.9833 11.0713C11.2593 11.182 11.5753 11.0733 11.7247 10.8153L12.666 9.18667C12.8153 8.92867 12.7513 8.59933 12.5167 8.416L11.458 7.588C11.482 7.396 11.5 7.198 11.5 7C11.5 6.802 11.482 6.604 11.458 6.412L12.5167 5.584C12.7513 5.4 12.8153 5.07133 12.666 4.81333L11.7247 3.18467C11.576 2.92667 11.26 2.818 10.9833 2.92867L10.0373 3.30867C9.84933 3.384 9.63999 3.35267 9.47066 3.24067C9.32466 3.144 9.17333 3.05533 9.01666 2.97667C8.83599 2.886 8.70399 2.72133 8.67533 2.52133L8.53133 1.51333C8.48933 1.21867 8.23666 1 7.93933 1H6.06133C5.76399 1 5.51133 1.21867 5.46933 1.51333L5.32399 2.52267C5.29533 2.722 5.16399 2.886 4.98466 2.97733C4.82733 3.05733 4.67533 3.14733 4.52866 3.244C4.35999 3.35467 4.15133 3.38467 3.96399 3.30933L3.01666 2.92867C2.73999 2.818 2.42399 2.92667 2.27533 3.18467L1.33399 4.81333C1.18466 5.07133 1.24866 5.40067 1.48333 5.584L2.54199 6.412C2.51799 6.604 2.49999 6.802 2.49999 7V7Z" stroke="black" strokeLinecap="round" strokeLinejoin="round"></path></svg>
                  </button>
                  <LogoutButton />
                </div>              
              </div>
            )}            
          </div>
        </div>     
      </div>
      <MobileMenu
        activeSlug={activeSlug}
        groupCode={groupCode}
        tenantCode={tenantCode}
        onMenuItemClick={onMenuItemClick}
      />
    </div>
  );
};

export default memo(BottomSection);