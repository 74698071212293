import React, { memo, useCallback, useState } from 'react';
import { ApolloConsumer } from 'react-apollo';
import replaceall from 'replaceall';
import GeneralConfirmation from '../../../../Shared/GeneralConfirmation';

const LogoutButton = () => {
  const [showConfirmation, updateShowConfirmation] = useState();
  const onLogout = useCallback(client => {
    localStorage.removeItem(tokenName);
    client.resetStore();
    window.location = '/login';

    updateShowConfirmation(false);
  }, []);

  return <>
    <ApolloConsumer>
      {client => <>
        <button
          className="log-out-specific"
          onClick={() => updateShowConfirmation(true)}
        >
          <svg
            width="24"
            height="31"
            viewBox="0 0 24 31"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M6 12.5V8C6 4.685 8.685 2 12 2C15.3 2 18 4.685 18 8V12.5"
              stroke="black"
              strokeWidth="3"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M12 18.5V23"
              stroke="black"
              strokeWidth="3"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M19.5 29H4.5C2.835 29 1.5 27.65 1.5 26V15.5C1.5 13.835 2.835 12.5 4.5 12.5H19.5C21.15 12.5 22.5 13.835 22.5 15.5V26C22.5 27.65 21.15 29 19.5 29Z"
              stroke="black"
              strokeWidth="3"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
          Log Out
        </button>
        {showConfirmation && (
          <GeneralConfirmation
            question={`Are you sure?`}
            onConfirm={() => onLogout(client)}
            onCancel={() => updateShowConfirmation(false)}
            confirmText={'Yes, log out'}
            cancelText={'No'}
          />
        )}
      </>}
    </ApolloConsumer>          
  </>;
};

const hostName = window.location.hostname.toLowerCase();
const hostNameSlugToUse = replaceall('.', '-', hostName);
const tokenName = `${hostNameSlugToUse}-auth-token-v3-12-13-21`;

export default memo(LogoutButton);
