import React, { Component } from 'react';
import { connect } from 'react-redux';
import GeneralTagsFeedRender from './GeneralTagsFeedRender';
import { updateGeneralState } from '../../actions/general.actions';

class GeneralTagsFeed extends Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  componentDidMount(){
    const {
      generalState,
      assignSpecific,
      updateGeneralState
    } = this.props;

    const { activeSidebarCategories } = generalState;

    !assignSpecific && updateGeneralState({
      untaggedActive: false,
      activeSidebarCategories: [],
      ...(activeSidebarCategories || []).reduce(
        (result, currentValue) => ({
          ...(result || {}),
          ...(generalState[
            `${currentValue.id}_children`
          ] && {
            [`${currentValue.id}_children`]: undefined
          })
        }),
        {}
      )
    });
  }

  render() {
    const {
      origin = 'media',
      generalState,
      untaggedActive,
      assignSpecific,
      onSelect,
      onRemove,
      onUntagged,
      itemsSelected,
      activateUsers,
      activateRoles,
      db_categories,
      refetch,
      profile,
      requester
    } = this.props;

    return(
      <GeneralTagsFeedRender
        origin={origin}
        profile={profile}
        refetch={refetch}
        requester={requester}
        onSelect={onSelect}
        onRemove={onRemove}
        onUntagged={onUntagged}
        assignSpecific={assignSpecific}
        itemsSelected={itemsSelected}
        untaggedActive={untaggedActive}
        activateUsers={activateUsers}
        activateRoles={activateRoles}
        db_categories={db_categories}
        generalState={generalState}
      />
    );
  }
};

const mapStateToProps = state => ({
  generalState: state.generalState
});

const mapDispatchToProps = dispatch => ({
  updateGeneralState: variables => dispatch(updateGeneralState(variables))
});

export default connect(mapStateToProps, mapDispatchToProps)(GeneralTagsFeed);
