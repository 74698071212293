import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import ReactDOM from 'react-dom';
import MainMapper from './ParentStripeForm/MainMapper';
import PurchaseSuccessRouter from './PurchaseSuccessRouter';
import { updateGeneralState } from '../../../../../actions/general.actions';
import { applyCoupon} from '../../../../../actions/user.actions';
import { makeExternalPurchase } from '../../../../../apollo.queries';
import apolloClient from '../../../../../apollo.client';

class ParentStripeForm extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {};
  }

  runPurchase = () => {
    const {
      updateGeneralState,
      activeItem,
      generalState
    } = this.props;
    
    const { price } = activeItem;
    const { updatedTotal } = generalState;

    updateGeneralState({
      loadingPurchaseInit: true,
      error: ''
    });

    if(!(
      parseFloat(price) > 0 &&
      (!updatedTotal || parseFloat(updatedTotal) > 0)
    )){
      this.purchaseFinalize(null);
    } else if(this.stripeRef){
      this.stripeRef._submit();
    } else {
      alert('There was an error, please try again...');

      updateGeneralState({
        loadingPurchaseInit: false
      });
    }
  };

  purchaseFinalize = (stripeToken) => {
    const { generalState, secondPageSuccess, updateGeneralState } = this.props;
    const { coupon } = generalState;

    const input = {
      type: 'form',
      formResponseId: secondPageSuccess,
      ...coupon && { coupon: coupon.code },
      ...stripeToken && { stripeToken }
    };
    
    apolloClient
      .mutate({
        mutation: makeExternalPurchase,
        variables: { input }
      })
      .then(({ data = {} }) =>
        data.makeExternalPurchase && this.setState({
          successAnimation: true
        })
      )
      .catch(e => {
        const split_message = e && (e.message || e.e || e)
            .toString()
            .toLowerCase()
            .split('error: ');

        if(split_message && split_message[1]){
          if (
            split_message[1].indexOf('You must be logged in to do this') > -1
          ) {
            alert('There was an error, please try again.');
            updateGeneralState({
              loadingPurchaseInit: false,
              checkEmailResponse: false,
              error: split_message[1]
            });
          } else {
            alert(split_message[1]);
            updateGeneralState({
              loadingPurchaseInit: false,
              checkEmailResponse: false,
              error: split_message[1]
            });
          }
        } else {
          alert('There was an error, please try again.');
          updateGeneralState({
            loadingPurchaseInit: false,
            checkEmailResponse: false,
            error: 'There was an error, please try again later.'
          });
        }
      });

    return;
  };

  render() {
    const {
      successAnimation,
      hideSavedCards,
      couponCode,
      cardIdToUse
    } = this.state;

    const {
      destinationUrl,
      destination,
      requester,
      activeItem,
      applyCoupon,
      generalState,
      updateGeneralState,
      purchaseConfirmed,
      buttonTextColor,
      buttonColor,
      lightTheme
    } = this.props;

    const {
      loadingPurchaseInit,      
      promoSuccess,
      updatedTotal,
      error
    } = generalState;

    const { price } = activeItem;

    return <>
      <div className="complete-share-settings-section">
        <MainMapper 
          onRef={stripeRef =>
            (this.stripeRef = stripeRef)
          }
          onError={e =>
            updateGeneralState({
              error: e,
              loadingPurchaseInit: false
            })
          }
          onToken={stripeToken => { if(stripeToken){ this.purchaseFinalize(stripeToken.id) } }}
          onApplyCoupon={() => applyCoupon({
            couponCode,
            currencyPrefix: requester.currencyPrefix,
            groupId: requester.activeGroup.id,
            selectedPackage: activeItem
          })}
          onPromoCodeChange={value => this.setState({ couponCode: value })}        
          hideSavedCards={hideSavedCards}
          updatedTotal={updatedTotal}
          cardIdToUse={cardIdToUse}
          couponCode={couponCode}
          saveCard={false}
          price={price}
          lightTheme={lightTheme}
        />                  
      </div>

      <div className="status-update-section">
        {promoSuccess && <p className="success">{promoSuccess}</p>}
        {error && <p className="error">{error}</p>}
      </div>

      <button
        style={{ border: "none", backgroundColor: buttonColor, color: buttonTextColor }}
        className="button-manip-at-bottom" 
        onClick={() => this.runPurchase()}
      >
        Complete Payment
      </button>
      
      {loadingPurchaseInit &&
        ReactDOM.createPortal(
          <PurchaseSuccessRouter
            requester={requester}
            packageItem={activeItem}
            destination={destination}
            destinationUrl={destinationUrl}
            isSuccess={successAnimation} 
            purchaseConfirmed={purchaseConfirmed} 
          />,
          document.getElementById('outsideModalPortal-z2')
        )}
    </>;
  }
};

const mapStateToProps = state => ({
  generalState: state.generalState
});

const mapDispatchToProps = dispatch => ({
  updateGeneralState: variables => dispatch(updateGeneralState(variables)),
  applyCoupon: variables => dispatch(applyCoupon(variables))
});

export default connect(mapStateToProps, mapDispatchToProps)(ParentStripeForm);
