import BlockCustomTypes from './blockCustomTypes';
import BlockChoiceTypes from './blockChoiceTypes';
import BlockGeneralTypes from './blockGeneralTypes';
import BlockContactInfoTypes from './blockContactInfoTypes';

const BlockTypes = [
  BlockContactInfoTypes,
  BlockGeneralTypes,
  BlockChoiceTypes,
  BlockCustomTypes,
];

export default BlockTypes;
