import React, { memo } from 'react';
import GeneralImage from './GeneralImage';
import { RequesterContext } from '../../context/requester.context';

import { getLogoKey } from '../../actions/user.actions';

import './logoLoader.scss';

const LogoLoader = ({
  inline,
  tenant,
  theme,
  fullWidth,
  breathe,
  blackSpecific
}) => (
  <RequesterContext.Consumer>
    {requester => (
      <div
        className={
          (theme || localStorage.getItem('active_theme')) &&
          `${theme ||
            localStorage.getItem(
              'active_theme'
            )} general-furthest-out-loader-container`
        }
      >
        <section
          className={`in-and-out-logo-transition ${inline &&
            'inline-logo-loader'} ${fullWidth &&
            'full-width'} ${blackSpecific && 'black-specific'} ${breathe &&
            'breathe-specific'}`}
        >
          {(tenant || (requester && requester.activeGroup.tenant) || {})
            .logo ? (
            <GeneralImage
              imageResolution={'350x'}
              originalKey={
                tenant
                  ? getLogoKey(tenant.logo, tenant.id)
                  : getLogoKey(
                      requester.activeGroup.tenant.logo,
                      requester.activeGroup.tenant.id
                    )
              }
            />
          ) : (
            <img src={`/images/android-chrome-512x512.png`} />
          )}
        </section>
      </div>
    )}
  </RequesterContext.Consumer>
);

export default memo(LogoLoader);
