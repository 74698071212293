import React, { memo, useCallback, useMemo } from 'react';
import { Helmet } from 'react-helmet';
import { getOptimizedFromKey } from '../helper';
import { getLogoKey } from '../actions/user.actions';

const OrganizationRouterMapperHelmet = ({ primaryHexDefault, activeSettings = {}, activeGroupName, tenantViaGroup }) => {
  const tenantStylingInit = useCallback(tenantStyling, [])
  const { theme = 'light', primaryHex = primaryHexDefault } = activeSettings

  const background_color = theme === 'dark' ? '#141414' : '#ffffff'
  const theme_color_to_pass = primaryHex

  const gtag_id_to_pass = useMemo(() => (tenantViaGroup.id === '0bbc797c-ba27-4f4e-8e03-4fd6e1bba10c' ? 'GTM-WV4F3XV' : tenantViaGroup.id === '3313f7a5-55e1-4fa2-abd6-5fcd88d916a4' && 'UA-82856860-1'), [tenantViaGroup.id])
  const facebook_id_to_pass = useMemo(() => (tenantViaGroup.id === "be2b31c6-3fb7-4e52-9f2e-4fd8163d15f5" ? '720174432900497' : tenantViaGroup.id === '3313f7a5-55e1-4fa2-abd6-5fcd88d916a4' && '1284208128687426'), [tenantViaGroup.id])

  const logoUrl = useMemo(() => {
    const originalKey = originHost && tenantViaGroup?.logo && getLogoKey(tenantViaGroup?.logo, tenantViaGroup?.id)

    return originHost && originalKey && `https://s3.us-west-2.amazonaws.com/athletic-outlook/${getOptimizedFromKey( '150x', originalKey)}`
  }, [tenantViaGroup?.logo, originHost])

  return <>
    <Helmet>
      <title>{`${activeGroupName} | ${tenantViaGroup.name}`}</title>
      <meta content={tenantViaGroup.name} itemprop="name" />
      <meta content={`${tenantViaGroup.name} Learning Management.`} name="description" />
      <meta content={background_color} name="msapplication-TileColor" />
      <meta content={theme_color_to_pass} name="theme-color" />
      <meta content={tenantViaGroup.name} property="og:title" />
      <meta content={tenantViaGroup.name} property="og:site_name" />
      <meta content={`${tenantViaGroup.name} Learning Management.`} property="og:description" />
      <meta content="summary_large_image" name="twitter:card" />
      <meta content={tenantViaGroup.name} name="twitter:title" />
      <meta content={`${tenantViaGroup.name} Learning Management.`} name="twitter:description" />                       
    </Helmet>

    {logoUrl ? (
      <Helmet>
        <link
          href={logoUrl}
          rel="icon"
          type="image/png"
        />
        <meta
          content={logoUrl}
          property="og:image"
        />
        <meta
          content={logoUrl}
          itemprop="image"
        />
        <meta
          content={logoUrl}
          name="twitter:image"
        />
      </Helmet>
    ) : <></>}

    {
      gtag_id_to_pass ? <>
        <Helmet>
          <script async src={`https://www.googletagmanager.com/gtag/js?id=${gtag_id_to_pass}`}></script>
          <script>
            {`
              window.dataLayer = window.dataLayer || [];
              function gtag(){dataLayer.push(arguments);}
              gtag('js', new Date());
              gtag('config', "${gtag_id_to_pass}");
            `}
          </script>
        </Helmet>
      </> : <></>
    }
    {
      facebook_id_to_pass ? (
        <Helmet>
          <script>
            {`
              <!-- Facebook Pixel Code -->
              !function(f,b,e,v,n,t,s)
              {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
              n.callMethod.apply(n,arguments):n.queue.push(arguments)};
              if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
              n.queue=[];t=b.createElement(e);t.async=!0;
              t.src=v;s=b.getElementsByTagName(e)[0];
              s.parentNode.insertBefore(t,s)}(window,document,'script',
              'https://connect.facebook.net/en_US/fbevents.js');
              fbq('init', '${facebook_id_to_pass}'); 
              fbq('track', 'PageView');
            `}
          </script>
        </Helmet>                
      ) : <></>
    }

    {tenantStylingInit(theme_color_to_pass)}
  </>;
};

const tenantStyling = primaryHex => (
  <style
    dangerouslySetInnerHTML={{
      __html: `
    .css-primary-hex-fill {
      fill: ${primaryHex || '#d9b256'} !important;
    }

    .css-primary-hex-stroke {
      stroke: ${primaryHex || '#d9b256'} !important;
    }

    .css-primary-hex-bg, .css-primary-hex-bg-after:after {
      background-color: ${primaryHex || '#d9b256'} !important;
    }

    .css-primary-hex-bg-on-hover:hover, button.css-primary-hex-bg-on-hover:hover {
      background-color: ${primaryHex || '#d9b256'} !important;
      color: #fff !important;
    }
    
    .css-primary-hex-color-on-hover:hover {
      color: ${primaryHex || '#d9b256'} !important;
    }

    .css-primary-hex-color-on-hover-h3:hover h3 {
      color: ${primaryHex || '#d9b256'} !important;
    }

    .css-primary-hex-color-on-hover-h2:hover h2 {
      color: ${primaryHex || '#d9b256'} !important;
    }

    .css-primary-hex-border-color-on-hover:hover {
      border-color: ${primaryHex || '#d9b256'} !important;
    }           

    .css-primary-hex-color {
      color: ${primaryHex || '#d9b256'} !important;
    }

    .css-primary-hex-color-i-onhover:hover i {
      color: ${primaryHex || '#d9b256'} !important;
    }

    .css-primary-hex-color-slick-i .slick-arrow i, .css-primary-hex-color-slick-i i, .bottom-nav-specifc-for-i-them-specific .md-text--theme-primary i {
      color: ${primaryHex || '#d9b256'} !important;
    }

    .css-primary-hex-border-color-left {
      border-left-color: ${primaryHex || '#d9b256'} !important;
    }           

    .css-primary-hex-border-color {
      border-color: ${primaryHex || '#d9b256'} !important;
    }

    #swiper-pagination-in-header .swiper-pagination-bullet.swiper-pagination-bullet-active-main.swiper-pagination-bullet-active {
      background-color: #141414 !important; /*${primaryHex || '#d9b256'} !important;*/
    }
  `
    }}
  ></style>
);

const hostName = window.location.hostname.toLowerCase(),
      originHost = hostName.indexOf('coachiq.io') > -1 || hostName.indexOf('localhost') > -1 || hostName.indexOf('127.0.0.1') > -1

export default memo(OrganizationRouterMapperHelmet);
