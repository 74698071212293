import { mapLimit, parallel } from 'async-es';
import { useQuery } from 'react-apollo';
import gql from 'graphql-tag';
import ACTIONS from '../constants/course/actions';
import apolloClient from '../apollo.client';
import { chunks } from './lesson.actions';
import BlockIconRef from '../components/AdminChildren/Coaching/Forms/MainFrame/Builder/Constants/blockIconRef';
import { formBlockQuery, formBlockQuery_ADMIN, formBlockReponsesQuery_Admin_Table, formFieldTemplateChoices, formHouseTemplatesQuery, formQuery } from '../apollo.queries';
import { removeForm, removeFormBlockChoices, removeFormBlocks, removeFormTemplateBlock, upsertForm, upsertFormBlocks, upsertFormFieldTemplate } from '../apollo.mutations';

function futch(url, opts = {}, onProgress) {
  return new Promise((resolve, reject) => {
    const xhr = new XMLHttpRequest();
    xhr.open(opts.method || 'get', url);
    for (const k in opts.headers || {}) {
      xhr.setRequestHeader(k, opts.headers[k]);
    }

    xhr.onload = e => {
      resolve(e.target.responseText ? JSON.parse(e.target.responseText) : null);
    };

    xhr.onerror = reject;
    if (xhr.upload && onProgress) {
      // event.loaded / event.total * 100 ; //event.lengthComputable
      xhr.upload.onprogress = onProgress;
    }
    xhr.send(opts.body);
  });
};

export const updateFormState = updatedStates => dispatch =>
  dispatch({
    type: ACTIONS.UPDATE_STATE,
    payload: updatedStates
  });


const addToGallery_viaForm = gql`
  mutation($input: UpsertGalleryInput_viaForm!) {
    addToGallery_viaForm(input: $input) {
      id
    }
  }
`;

export const uploadFormFile = async (file, postInfo, formBlockId, updateProgress) => {
  const data = new FormData();
  Object.keys(postInfo.fields).map(key =>
    data.append(key, postInfo.fields[key])
  );

  data.append('file', file);
  const options = {
    method: 'POST',
    body: data
  };

  // Start uploading form to S3
  const res = await futch(
    'https://athletic-outlook.s3-accelerate.amazonaws.com',
    options,
    e => updateProgress((e.loaded / e.total) * 100 )
  ).catch(e => e);

  const split_file_name = file.name.split('.');
   
  const galleryAddition = await apolloClient.mutate({
    mutation: addToGallery_viaForm,
    variables: {
      input: {
        label: split_file_name[0],
        url: postInfo.url + '/' + postInfo.fields.key,
        formBlockId
      }
    }
  });

  // Check Response
  if (res) {
    // Error. S3 Returns null on success. Only return is error
    return null;
  } else {
    return galleryAddition.data && galleryAddition.data.addToGallery_viaForm
      ? galleryAddition.data.addToGallery_viaForm
      : null;
  }
};

export const uploadFormFiles = (mediaFiles = []) => async dispatch => {
  let count = 0, filesToPass = [];

  try {
    dispatch({
      type: ACTIONS.UPDATE_STATE,
      payload: {
        uploadingFiles: true,
        fileUpload_Total: mediaFiles.length,
        fileUpload_Count: 0,
        fileUpload_Progress: 0.1
      }
    });
    
    for(const file of mediaFiles){
      const file_to_pass = file.formBlockResponseMedia;
  
      const file_mb_size = file_to_pass.size / 1024 / 1024;
      const split_file_name = file_to_pass.name.split('.');
  
      if (file_mb_size > 2000) {
        alert('File to large...');
      } else {
        count++;
  
        dispatch({
          type: ACTIONS.UPDATE_STATE,
          payload: {
            fileUpload_Count: count,
            fileUpload_Progress: 0.1
          }
        });
  
        const { data: { formMediaSignedUrl } } = await apolloClient.query({
          query: gql`
            query($fileExtension: String!, $formBlockId: ID!) {
              formMediaSignedUrl(fileExtension: $fileExtension, formBlockId: $formBlockId)
            }
          `,
          fetchPolicy: 'network-only',
          variables: { 
            fileExtension: split_file_name[split_file_name.length - 1].toLowerCase(),
            formBlockId: file.formBlockId
          }
        });
        
        if(formMediaSignedUrl && formMediaSignedUrl.url){
          const res = await uploadFormFile(
            file_to_pass,
            formMediaSignedUrl,
            file.formBlockId,
            progress =>
              dispatch({
                type: ACTIONS.UPDATE_STATE,
                payload: { fileUpload_Progress: progress }
              })
          );
  
          res && filesToPass.push({ formBlockId: file.formBlockId, formBlockResponseMediaId: res.id });
        };

        dispatch({
          type: ACTIONS.UPDATE_STATE,
          payload: {
            fileUpload_Count: count,
            fileUpload_Progress: 1
          }
        });
      }
    };    
  } catch (e) {
    console.log(e);
  };  

  dispatch({
    type: ACTIONS.UPDATE_STATE,
    payload: {
      uploadingFiles: false,
      fileUpload_Total: mediaFiles.length,
      fileUpload_Count: mediaFiles.length,
      fileUpload_Progress: 1
    }
  });

  return filesToPass;
};

export const removeFormTemplateBlockInit = async (id) => (
    (await apolloClient.mutate({
      mutation: removeFormTemplateBlock,
      variables: { id }
    })) || {}
  ).data.adminAddUser;

export const cloneBlock = ({
  form,
  blockToClone
}) => dispatch => {
  let blocks = form.blocks;
  let formToManip = form;

  const dateTime = new Date().getTime();

  blocks.splice(
    blockToClone + 1,
    0,
    JSON.parse(
      JSON.stringify({
        ...blocks[blockToClone],
        id: `new-block-${dateTime}-${blockToClone + 1}`
      })
    )
  );

  formToManip.blocks = blocks.map((blockLocal, index) => ({ ...blockLocal, index }));

  dispatch({
    type: ACTIONS.UPDATE_STATE,
    payload: { form: formToManip }
  });
};

export const removeBlock = ({
  form,
  blockToRemove,
  blocksToRemove
}) => dispatch => {
  let blocks = form.blocks;
  const block_ref = form.blocks[blockToRemove];

  blocks.splice(blockToRemove, 1);

  form.blocks = blocks.map((blockLocal, index) => ({
    ...blockLocal,
    index
  }));

  dispatch({
    type: ACTIONS.UPDATE_STATE,
    payload: { form, blocksToRemove: [...blocksToRemove, block_ref] }
  });
};

export const addBlock = ({
  newBlock = {},
  newBlocks = [],
  form
}) => dispatch => {
  const currentTime = new Date().getTime();

  let blocks = form.blocks || [];
  
  let indexToUse = 0;
  if(newBlocks.length > 0){
    for(let newBlockLocal of newBlocks){
      let compiledBlock = {
        index: blocks.length,
        ...newBlockLocal,
        id: `new-block-${currentTime}-${blocks.length}`,
        ...newBlockLocal.choices && { choices: (newBlockLocal.choices || []).map((choice, choiceIndex) => ({ ...choice, id: `new-block-${currentTime}-${choiceIndex}-${blocks.length}` })) },
        ...newBlockLocal.type === 'number_heading_default' && { label: `#${blocks.filter(({ type }) => !['heading', 'description'].find(value => type.includes(value))).length + 1}` }
      };
    
      blocks = [
        ...blocks,
        compiledBlock
      ];

      indexToUse = compiledBlock.index;
    };
  } else {    
    let compiledBlock = {
      index: blocks.length,
      ...newBlock,
      id: `new-block-${currentTime}`,
      ...newBlock.choices && { choices: (newBlock.choices || []).map((choice, choiceIndex) => ({ ...choice, id: `new-block-${currentTime}-${choiceIndex}-${blocks.length}` })) },
      ...newBlock.type === 'number_heading_default' && { label: `#${blocks.filter(({ type }) => !['heading', 'description'].find(value => type.includes(value))).length + 1}` }
    };
  
    blocks = [
      ...blocks,
      compiledBlock
    ];

    indexToUse = compiledBlock.index;
  };

  dispatch({
    type: ACTIONS.UPDATE_STATE,
    payload: {
      form: {
        ...form,
        blocks
      },
      activeBlock: indexToUse, 
      formUpdatedRef: true
    }
  });
};
  

export const removeFormInit = formId => async dispatch => {
  dispatch({
    type: ACTIONS.UPDATE_STATE,
    payload: {
      removingForm: true
    }
  });

  const removeFormData = (
    (await apolloClient.mutate({
      mutation: removeForm,
      variables: {
        input: { id: formId }
      }
    })) || {}
  ).data.removeForm.success;

  dispatch({
    type: ACTIONS.UPDATE_STATE,
    payload: {
      removingForm: false,
      [`form_removed_${formId}`]: true
    }
  });

  return { removeFormData };
};

export const cloneFormInit = formId => async dispatch => {
};

export const Admin_FormBlockReponses_QueryConcat = (formId) => {
  const limit = 20;
  const FormBlockReponsesResults_CacheOnly = useQuery(
    formBlockReponsesQuery_Admin_Table,
    { variables: { formId, page: 1, limit }, fetchPolicy: "cache-only" }
  );

  /****/

  const FormBlockReponsesResultBlocks = useQuery(
    formBlockReponsesQuery_Admin_Table,
    { variables: { formId, page: 1, limit }, fetchPolicy: "network-only" }
  );

  /****/

  const { 
    formBlockData = [],
    formResponses = []
  } = (FormBlockReponsesResultBlocks.data || {}).formBlockReponsesQuery_Admin_Table || (FormBlockReponsesResults_CacheOnly.data || {}).formBlockReponsesQuery_Admin_Table || {};

  return {
    headerData: formBlockData.map(
      (currentValue) => ({
        ...BlockIconRef[currentValue.type] || {},
        ...currentValue,
        choices: (currentValue.choices || []).reduce(
          (results, currentValue) => ({
            ...results,
            [currentValue.id]: currentValue
          }), {}
        )
      }), {}
    ),
    iterateData: formResponses,
    loading: FormBlockReponsesResultBlocks.loading
  };
};


export const Form_QueryConcat = (formId) => {
  const FormResults_CacheOnly = useQuery(
    formQuery,
    { variables: { formId }, fetchPolicy: "cache-only" }
  );

  const FormResultBlocks_CacheOnly = useQuery(
    formBlockQuery,
    { variables: { formId }, fetchPolicy: "cache-only" }
  );

  /****/
  
  const FormResults = useQuery(
    formQuery,
    { variables: { formId}, fetchPolicy: "network-only" }
  );

  const FormResultBlocks = useQuery(
    formBlockQuery,
    { variables: { formId }, fetchPolicy: "network-only" }
  );

  /****/

  let finalizedSettings = null;
  const settings = ((FormResults.data || {}).formQuery || (FormResults_CacheOnly.data || {}).formQuery || {}).settings;
  try {
    if(settings) finalizedSettings = JSON.parse(settings);
  } catch(e) {
    console.log(e);
  };         

  return {
    FormResult: {
      ...((FormResults.data || {}).formQuery || (FormResults_CacheOnly.data || {}).formQuery || {}),
      settings: finalizedSettings,
      blocks: (FormResultBlocks.data || {}).formBlockQuery || (FormResultBlocks_CacheOnly.data || {}).formBlockQuery || []
    },
    stillLoading: (FormResults.loading || FormResultBlocks.loading) 
  };
};

export const getFormById = async (formId) =>
  await new Promise(resolve =>
    parallel(
      {
        formResponse: callback =>
          setTimeout(
            async () =>
              callback(
                null,
                (await apolloClient.query({
                  query: formQuery,
                  fetchPolicy: 'network-only',
                  variables: { formId }
                })) || {}
              ),
            300
          ),
        blocksResponse: callback =>
          setTimeout(
            async () =>
              callback(
                null,
                (await apolloClient.query({
                  query: formBlockQuery_ADMIN,
                  fetchPolicy: 'network-only',
                  variables: { formId }
                })) || []
              ),
            100
          )
      },
      (err, { formResponse, blocksResponse }) => {
        let finalizedSettings = null;
        const settings = formResponse.data.formQuery.settings;
        try {
          if(settings) finalizedSettings = JSON.parse(settings);
        } catch(e) {
          console.log(e);
        };

        resolve({
          ...formResponse.data.formQuery,
          settings: finalizedSettings,
          blocks: JSON.parse(JSON.stringify(
            blocksResponse.data.formBlockQuery ||
            blocksResponse.data.formBlockQuery_ADMIN ||
            []
          ))
        });
      }
    )
  );

export const initFormBuilder = ({
  form = {},
  formId
}) => async dispatch =>
  dispatch({
    type: ACTIONS.UPDATE_STATE,
    payload: {
      activeBlock: 0,
      form: await getFormById(form.id || formId, true)
    }
  });
  

export const getFormFieldTemplateChoices = async (formBlockTemplateId) => {
  const choices = (
    await apolloClient.query({
      fetchPolicy: 'network-only',
      query: formFieldTemplateChoices,
      variables: { formBlockTemplateId }
    })
  ).data.formFieldTemplateChoices || [];

  return choices;
};
  

export const upsertFormFieldTemplateInit = async ({
  id,
  required = 1,
  type,
  label,
  title,
  choices = []
}) => {
  const templateId = (
    await apolloClient.mutate({
      mutation: upsertFormFieldTemplate,
      variables: {
        input: {
          id,
          required: required ? true : false,
          type,
          label,
          title,
          choices: choices.map(({ id, label }, index) => ({ id, index, label }))
        }
      }
    })
  ).data.upsertFormFieldTemplate;

  return templateId;
};

export const upsertFormInit = ({
  form,
  blocksToRemove = [],
  blockChoicesToRemove = []
}) => async dispatch => {  
  dispatch({
    type: ACTIONS.UPDATE_STATE,
    payload: {
      updatingForm: true
    }
  });

  const { id, label, settings, buttonText, featuredImage } = form;

  const newForm = id.includes('new-form') && (
    await apolloClient.mutate({
      mutation: upsertForm,
      variables: {
        input: {
          id,
          label,          
          buttonText,
          featuredImage,
          settings: settings ? JSON.stringify(settings) : null
        }
      }
    })
  );

  const formId = newForm ? newForm.data.upsertForm.success : id;

  const blocks_flattened = (form.blocks || []).map((block, index) => ({
    ...block,
    index,
    formId,
    __typename: undefined
  }));

  const {
    upsertedForm,
    upsertedBlocks,
    blocksRemoved
  } = await new Promise(resolve =>
    parallel(
      {
        upsertedForm: callback =>
          setTimeout(
            async () =>
              callback(
                null,
                newForm
                  ? newForm.data.upsertForm.success
                  : (
                      await apolloClient.mutate({
                        mutation: upsertForm,
                        variables: {
                          input: {
                            id,
                            label,                            
                            buttonText,
                            featuredImage,
                            settings: settings ? JSON.stringify(settings) : null
                          }
                        }
                      })
                    ).data.upsertForm.success
              ),
            200
          ),
        upsertedBlocks: callback =>
          setTimeout(
            async () =>
              callback(
                null,
                await new Promise(resolve =>
                  mapLimit(
                    chunks(blocks_flattened, 10),
                    5,
                    (blocks, callback) =>
                        setTimeout(
                          async () =>
                            callback(
                              null,
                              (
                                await apolloClient.mutate({
                                  mutation: upsertFormBlocks,
                                  variables: {
                                    input: {
                                      formBlocks: (blocks || []).map(
                                        ({
                                          id,
                                          index,
                                          required = 1,
                                          formId,
                                          type,
                                          label,
                                          media,
                                          choices = []
                                        }) => ({
                                          id,
                                          index,
                                          required: required ? true : false,
                                          formId,
                                          type,
                                          label,
                                          ...media && { mediaId: media.id },
                                          choices: (choices || []).map(({ id, label }, index) => ({ id, index, label }))
                                        })
                                      )
                                    }
                                  }
                                })
                              ).data.upsertFormBlocks.success
                            )
                        , 10),
                    (err, results) => resolve((results || []).flat(1))
                  )
                )
              ),
            100
          ),
        blockChoicesRemoved: callback =>
          setTimeout(
            async () =>
              callback(
                null,
                await new Promise(resolve =>
                  mapLimit(
                    chunks(
                      (blockChoicesToRemove || [])
                        .filter(block => block)
                        .map(block => ({ id: block.id })),
                      10
                    ),
                    5,
                    (blocks, callback) =>
                        setTimeout(
                          async () =>
                            callback(
                              null,
                              (
                                await apolloClient.mutate({
                                  mutation: removeFormBlockChoices,
                                  variables: {
                                    input: {
                                      formBlockChoices: blocks
                                    }
                                  }
                                })
                              ).data.removeFormBlockChoices.success
                            )
                        , 10),
                    (err, results) => resolve((results || []).flat(1))
                  )
                )
              ),
            100
          ),
        blocksRemoved: callback =>
          setTimeout(
            async () =>
              callback(
                null,
                await new Promise(resolve =>
                  mapLimit(
                    chunks(
                      (blocksToRemove || [])
                        .filter(block => block)
                        .map(block => ({ id: block.id })),
                      10
                    ),
                    5,
                    (blocks, callback) =>
                        setTimeout(
                          async () =>
                            callback(
                              null,
                              (
                                await apolloClient.mutate({
                                  mutation: removeFormBlocks,
                                  variables: {
                                    input: {
                                      formBlocks: blocks
                                    }
                                  }
                                })
                              ).data.removeFormBlocks.success
                            )
                        , 10),
                    (err, results) => resolve((results || []).flat(1))
                  )
                )
              ),
            100
          )
      },
      async (err, results) => {
        resolve(results)
      }
    )
  );

  dispatch({
    type: ACTIONS.UPDATE_STATE,
    payload: {
      updatingForm: false
    }
  });

  return {
    blocksRemovedResponse: blocksRemoved,
    upsertedFormResponse: upsertedForm,
    upsertedBlocksResponse: upsertedBlocks
  };
};