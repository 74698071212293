import React, { memo } from 'react';

const OrdersTable = props => {
  const { orders, currencyPrefix } = props;

  return (
    <div className="immediate-orders-table-container">
      <div className="immediate-order-table-header">
        <div className="number-specific-width th">#</div>
        <div className="name-specific-width th">Name</div>
        <div className="date-specific-width th">Date</div>
        <div className="label-specific-width th">Product</div>
        <div className="amount-specific-width th">Amount</div>
      </div>
      <div className="immediate-order-table-body">
        {orders.map((order, index) => (
          <div key={order.id} className="order-table-row">
            <div className="number-specific-width td">{index + 1}</div>
            <div className="name-specific-width td">
              {order.profile && order.profile.user
                ? order.profile.user.firstName || order.profile.user.lastName
                  ? `${order.profile.user.firstName} ${order.profile.user.lastName}`
                  : order.profile.user.email
                : 'User No Longer Exist'}
            </div>
            <div className="date-specific-width td">
              {new Date(order.createdAt).toLocaleString()}
            </div>
            <div className="label-specific-width td">
              {(order.package && order.package.label) || 'N/A'}
            </div>
            <div className="amount-specific-width td">
              {`${currencyPrefix}${order.amount}` || 'N/A'}{' '}
              {order.coupon && `(${order.coupon})`}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default memo(OrdersTable);
