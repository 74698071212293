import React, { memo, useCallback } from 'react';
import { CircularProgress } from '@material-ui/core';
import ArchiveMediaItem from './ArchiveMediaItem';

const GalleryMainMediaMapper_NEW = ({ updateGeneralState, onRadioInit, profile, onRemove, onSelect, simplified, itemsSelected, fallbackImage1, fallbackImage2, generalState, filteredFilesUploading_Finalized = []}) => {
  const tempFileRender = useCallback(
    (fileObj, fileIndex) => (
      <div
        key={`media-item-${fileIndex}-${fileObj.id}`}
        className={`complete-container-of-media-item ${'new'}-file-type`}
      >
        <div className="main-media-section-of-item">
          {fileObj.tempUrl && (
            <img
              src={
                fileObj.isTempVideo
                  ? fallbackImage1
                  : fileObj.tempUrl
              }
            />
          )}
          <div className="white-overlay">
            <div className="file-progress-container">
              <span className="text">{parseInt(generalState[fileObj.id].progress * 100)}%</span>
              <CircularProgress
                variant="determinate"
                value={parseInt(generalState[fileObj.id].progress * 100)}
              />
            </div>
          </div>
        </div>
        <div className="bottom-media-section-of-item">
          <p className="label">
            <span className="new-specific">NEW</span>
            {fileObj.file.name.split('.')[0] || 'Untitled'}
          </p>
        </div>
      </div>
    ),
  );

  const keyExtractor = useCallback(
    (fileObjId, fileIndex) => `media-item-${fileIndex}-${fileObjId}`
  )

  const getMappedItem = useCallback(
    (fileObjId) => ({
      ...generalState[fileObjId].serverResponse,
      tags: generalState[`${generalState[fileObjId].serverResponse.id}_tagsSelected`] || []
    }), [generalState]
  );

  const renderItem = useCallback(
    (fileObj, fileIndex) => (
      generalState[fileObj.id] && (
        generalState[fileObj.id].serverResponse
          ? <ArchiveMediaItem
              key={keyExtractor(fileObj.id, fileIndex)}
              media={getMappedItem(fileObj.id)}
              profile={profile}
              newUpload={true}                            
              onRemove={onRemove}
              onSelect={onSelect}
              showRadioInit={true}
              onRadioInit={onRadioInit}
              simplified={simplified}
              itemsSelected={itemsSelected}
              fallbackImage={fallbackImage1}
              fallbackImage2={fallbackImage2}
              updateGeneralState={updateGeneralState}
            />
          : tempFileRender(fileObj, fileIndex)
    )), [generalState]
  );

  return filteredFilesUploading_Finalized.map(renderItem);
};

export default memo(GalleryMainMediaMapper_NEW);
