import React, { useCallback, useMemo } from 'react';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import AdminMenu from './AdminMenu';
import MobileMenu from './MobileMenu';
import { updateGeneralState } from '../../../../actions/general.actions';
import BottomSection from './BottomSection';

const AdminMenu_REDUX = props => {
  const {
    requester,
    generalState,
    updateGeneralState
  } = props;

  const navigate = useNavigate();    

  const groupCode = requester.activeGroup.code;
  const tenantCode = requester.activeGroup.tenant.code;

  const { activeSidebarCategories } = generalState;

  const activeSlug = useMemo(() => {
    const activeSlugSplit = window.location.pathname.toLowerCase().split('/');
    const activeSlug = activeSlugSplit[activeSlugSplit.length - 1];

    return (
      activeSlug === 'builder'
        ? activeSlugSplit[activeSlugSplit.length - 2]
        : activeSlug
    );
  }, [window.location.pathname.toLowerCase()]);

  const onMenuItemClick = useCallback(toPath => {
    updateGeneralState({
      untaggedActive: false,
      activeSidebarCategories: [],
      ...(activeSidebarCategories || []).reduce(
        (result, currentValue) => ({
          ...(result || {}),
          ...(!generalState[`${currentValue.id}_children`] && {
            [`${currentValue.id}_children`]: undefined
          })
        }),
        {}
      )
    });
    
    navigate(toPath);
  }, [activeSidebarCategories, generalState]);

  return (
    <section id="left-locked-admin-menu">
      <img
        id="logo-to-the-left"
        src="/images/dark_horiz_logo_small.png"
        alt="CoachIQ Logo"
      />
      
      <div className="top-admin-menu-section desktop-specific">
        <AdminMenu 
          activeSlug={activeSlug}
          groupCode={groupCode}
          tenantCode={tenantCode}
          onMenuItemClick={onMenuItemClick}
        />
      </div>

      <BottomSection 
        requester={requester}
        activeSlug={activeSlug}
        onMenuItemClick={onMenuItemClick}        
      />
    </section>
  );
};

const mapStateToProps = state => ({
  generalState: state.generalState
});

const mapDispatchToProps = dispatch => ({
  updateGeneralState: updatedStates => dispatch(updateGeneralState(updatedStates))
});

export default connect(mapStateToProps, mapDispatchToProps)(AdminMenu_REDUX);
