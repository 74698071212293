import React, { PureComponent } from 'react';
import ReactDOM from 'react-dom';
import { connect } from 'react-redux';
import { isMobile } from 'react-device-detect';
import moment from 'moment';
import GeneralImage from '../../../Shared/GeneralImage';
import stringSimilarity from 'string-similarity';
import TextInput from '../../../Shared/TextInput';
import { updateGeneralState } from '../../../../actions/general.actions';
import { oneOnOneQuery_AdminFeed, oneOnOneQuery_AdminFeed_Inbox } from '../../../../apollo.queries';
import apolloClient from '../../../../apollo.client';
import { getAvatarKey } from '../../../../actions/user.actions';
import NoItems from '../../../Shared/NoItems';

const checkStrings = (string1, string2) =>
  string1
    .toLowerCase()
    .trim()
    .startsWith(string2.toLowerCase()) ||
  stringSimilarity.compareTwoStrings(string1.toLowerCase().trim(), string2) >
    0.75;

class UsersMainTagSpecificRender extends PureComponent {
  constructor(props) {
    super(props);

    const { mediaSpecific, oneOnOneSpecific, sessionSpecific } = props;

    this.state = {
      additionalQueriedProfileGroups: [],
      currentPage: 1,
      limit: (oneOnOneSpecific || sessionSpecific || mediaSpecific) ? 30 : 100
    };
  }

  componentDidMount(){
    if(!isMobile){
      const { oneOnOneSpecific, parentData = [], generalState, updateGeneralState } = this.props;
      const { showAnnouncements } = generalState;

      const activeKeys = Object.keys(
        generalState[oneOnOneSpecific
          ? 'activeUserBasedTags_oneOnOne'
          : 'activeUserBasedTags'] || {}
      ).length > 0; 
      const immediateProfile = ((parentData || []).sort((a, b) => b.toSortBy - a.toSortBy)[0] || {}).profile;

      if(!showAnnouncements && !activeKeys && oneOnOneSpecific && immediateProfile){
        updateGeneralState({
          showAnnouncements: false,
          mobileActivityFeedInit: false,
          [oneOnOneSpecific
            ? 'activeUserBasedTags_oneOnOne'
            : 'activeUserBasedTags']: { [immediateProfile.id]: immediateProfile }
        });
      };
    };

    return true;    
  }

  handleScroll = async (e, force) => {
    if (!this.endOfQuery && !this.queryingAdditionalItems) {
      const { mediaSpecific, oneOnOneSpecific, sessionSpecific, showArchive } = this.props;
      const { additionalQueriedProfileGroups, currentPage, limit } = this.state;

      const bottom = e && e.target.scrollHeight - e.target.scrollTop - e.target.clientHeight < 30;
      if (!this.queryingAdditionalItems && (bottom || force)) {
        this.queryingAdditionalItems = true;
        this.setState({ loadingAdditionalProfileGroups: true });

        const { data } = await apolloClient.query({
          query: oneOnOneSpecific ? oneOnOneQuery_AdminFeed_Inbox : oneOnOneQuery_AdminFeed,
          fetchPolicy: 'network-only',
          variables: {
            ...oneOnOneSpecific
              ? { archiveSpecific: showArchive }
              : {
                  ...(mediaSpecific && { mediaSpecific: true }),
                  ...(sessionSpecific && { sessionSpecific: true }),
                },            
            page: currentPage + 1,
            limit: limit
          }
        });

        const endResults = data && (oneOnOneSpecific ? data.oneOnOneQuery_AdminFeed_Inbox : data.oneOnOneQuery_AdminFeed);

        if (!endResults || endResults.length === 0) {
          this.endOfQuery = true;
        };

        this.queryingAdditionalItems = false;

        this.setState({
          additionalQueriedProfileGroups: [
            ...additionalQueriedProfileGroups,
            ...(this.endOfQuery ? [] : endResults).map(
              (assetItem) => ({
                ...assetItem,
                toSortBy: new Date(assetItem.createdAt).getTime(),
                processedCreatedAt: moment(assetItem.createdAt).fromNow().replace('in ', '')
              })
            )
          ],
          currentPage: currentPage + 1,
          loadingAdditionalProfileGroups: false
        });
      }
    } else {
      return true;
    }
  };

  render() {
    const {
      limit,
      searchInput = '',
      additionalQueriedProfileGroups,
      loadingAdditionalProfileGroups
    } = this.state;

    const {
      twoLevel,
      parentData = [],
      profile,
      showArchive,
      avatarOnly,
      onMainClick,
      generalState,
      hideUnlessSearch,
      mediaSpecific,
      sessionSpecific,
      oneOnOneSpecific,
      updateGeneralState,
      toggleArchive,
      dontShowSelected,
      searchLabel = 'Search',
      hideArchiveButton,
      archiveCount = 0,
      lastAnnouncement
    } = this.props;

    const { activeUserBasedTags_oneOnOne = {} } = generalState;

    const activeUserBasedTags = oneOnOneSpecific
      ? activeUserBasedTags_oneOnOne
      : generalState.activeUserBasedTags;

    const profileGroupsMapped = [
      ...parentData,
      ...additionalQueriedProfileGroups
    ];
    
    const tempProfileGroups = searchInput && searchInput.length > 0
        ? profileGroupsMapped.filter(
            ({
              profile: {
                id,
                user: { firstName, lastName, email }
              }
            }) => (!dontShowSelected || !activeUserBasedTags[id]) && (((firstName || lastName) && checkStrings(`${firstName} ${lastName}`, searchInput)) || checkStrings(email || '-', searchInput))
          )
        : profileGroupsMapped;

    const profileGroups = (
      oneOnOneSpecific
        ? tempProfileGroups.filter(
            ({ profile: { id } }) => id !== profile.id
          )
        : mediaSpecific
          ? tempProfileGroups.filter(
              ({ profile: { mediaCount = 0 } }) => mediaCount > 0
            )
          : sessionSpecific
            ? tempProfileGroups.filter(
                ({ profile: { sessionCount = 0 } }) => sessionCount > 0
              )
            : tempProfileGroups
    ).sort((a, b) => b.toSortBy - a.toSortBy);

    if(
      !(oneOnOneSpecific || mediaSpecific) &&
      (parentData.length >= limit || (!this.endOfQuery && !this.queryingAdditionalItems)) && profileGroups.length < limit
    ){
      this.handleScroll(false, true);
    };

    const containerForPortal = isMobile ? document.querySelector('#admin-content-inner-sidebar.one-on-one-specific-sidebar') : document.querySelector('#admin-content-inner-content-with-sidebar.one-on-one-specific-main.is-activity-feed');

    return (
      <>
        {(!hideUnlessSearch || (searchInput.length > 0 && profileGroups.length > 0) || loadingAdditionalProfileGroups) && (          
          !hideArchiveButton && archiveCount > 0 && <>
            <div className="user-feed-action-items-above-search">                            
              {
                showArchive
                  ? <button
                      key={`user-gallery-tag-archive`}
                      onClick={toggleArchive}
                      className={`gallery-tag-immediate vew-all-specific user-gallery-tag`}
                    >
                      Back to Inbox
                      <span className="count-to-right-with-indicator">
                        <svg width="6" height="10" viewBox="0 0 6 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M1 9L5 5L1 1" stroke="#38383E" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                        </svg>
                      </span>   
                    </button>
                  : <button
                      key={`user-gallery-tag-archive`}
                      onClick={toggleArchive}
                      className={`gallery-tag-immediate vew-all-specific user-gallery-tag`}
                    >
                      <span className="for-relativity">
                        Archived
                        <span className="count-specific"><span>{archiveCount}</span></span>
                      </span>                      
                      <span className="count-to-right-with-indicator">                        
                        <svg width="6" height="10" viewBox="0 0 6 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M1 9L5 5L1 1" stroke="#38383E" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                        </svg>
                      </span>    
                    </button>
              }
            </div>              
          </>                    
        )}
        {avatarOnly && (          
          <div className="search-input" style={{ ...searchInput.length === 0 && profileGroups.length === 0 && { opacity: .5, pointerEvents: "none" } }}>
            <div className="icon-to-left">
              <svg
                width="18"
                height="18"
                viewBox="0 0 18 18"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M8.05012 1.00023C6.17902 1.00023 4.38455 1.74353 3.06148 3.0666C1.73841 4.38966 0.995117 6.18413 0.995117 8.05523C0.995117 9.92634 1.73841 11.7208 3.06148 13.0439C4.38455 14.3669 6.17902 15.1102 8.05012 15.1102C9.92255 15.1102 11.7183 14.3664 13.0423 13.0424C14.3663 11.7184 15.1101 9.92266 15.1101 8.05023C15.1101 6.17781 14.3663 4.38207 13.0423 3.05806C11.7183 1.73405 9.92255 0.990234 8.05012 0.990234V1.00023Z"
                  stroke="#666666"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M17 17.0003L13.05 13.0503"
                  stroke="#666666"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </div>
            <TextInput
              value={searchInput}
              placeholder={searchLabel}
              onChange={value => this.setState({ searchInput: value })}
            />
            {hideUnlessSearch && searchInput.length > 0 && (
              <button
                onClick={() => this.setState({ searchInput: '' })}
                className="immediate-reset-for-input"
              >
                <svg
                  width="14"
                  height="14"
                  viewBox="0 0 14 14"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M1 1L13 13"
                    stroke="#666666"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  ></path>
                  <path
                    d="M13 1L1 13"
                    stroke="#666666"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  ></path>
                </svg>
              </button>
            )}
          </div>
        )}
        {(!hideUnlessSearch || (searchInput.length > 0 && profileGroups.length > 0) || loadingAdditionalProfileGroups) && (
          <section
            key={`user-main-tag-iterate-${showArchive}`}
            className={`for-scroll-ref ${archiveCount > 0 && 'archive-count-exists'}`}
            onScroll={this.handleScroll}
          >                   
            {(!hideUnlessSearch && !showArchive && avatarOnly && searchInput.length === 0) && (
              <>           
                {
                  lastAnnouncement && !showArchive && (
                    <button
                      key={`user-gallery-tag-announcement`}
                      onClick={() =>
                        updateGeneralState({
                          showAnnouncements: true,
                          mobileActivityFeedInit: true,
                          [oneOnOneSpecific
                            ? 'activeUserBasedTags_oneOnOne'
                            : 'activeUserBasedTags']: {}
                        })
                      }
                      className={`gallery-tag-immediate vew-all-specific user-gallery-tag ${generalState.showAnnouncements && 'active-selection'}`}
                    >
                      Announcements
                      <span>{lastAnnouncement.contentShort || "No subject..."}</span> 
                      <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M15 18.9993H9.99999L9.98999 18.9983C5.00999 18.9883 0.98999 14.9683 0.98999 9.98828C0.98999 5.00828 5.00999 0.988281 9.98999 0.988281C14.96 0.988281 18.99 5.00828 18.98 9.98828V11.4883C18.98 12.8683 17.86 13.9883 16.48 13.9883C15.09 13.9883 13.98 12.8683 13.98 11.4883V9.97828C13.98 7.76828 12.18 5.97828 9.97999 5.97828H9.96999C7.75999 5.97828 5.96999 7.76828 5.96999 9.97828C5.96999 12.1783 7.75999 13.9783 9.96999 13.9683C12.17 13.9583 13.97 12.1683 13.96 9.95828" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                      </svg>
                      <span className="date-dictator">{moment(lastAnnouncement.updatedAt).fromNow().replace('in ', '')}</span>
                    </button>   
                  )
                }                
              </>
            )}

            {
              containerForPortal && (!isMobile || !lastAnnouncement) && !hideUnlessSearch && !showArchive && searchInput.length === 0 && profileGroups.length === 0 && (
                ReactDOM.createPortal(
                  <NoItems 
                    className={`centered ${isMobile && 'inbox-specific-mobile'}`}
                    icon={<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M15 19H11C9.89 19 9 18.1 9 17V11.5C9 10.39 9.89 9.5 11 9.5H19C20.1 9.5 21 10.39 21 11.5V17C21 18.1 20.1 19 19 19H18V21L15 19Z" stroke="#666666" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path><path d="M17.609 9.49999V5.42999C17.609 4.07999 16.519 2.98999 15.169 2.98999H5.41998C4.06998 2.98999 2.97998 4.07999 2.97998 5.41999V12.116C2.97998 13.456 4.06998 14.546 5.40998 14.546H6.61998V16.976L8.96998 15.4" stroke="#666666" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path></svg>}
                    title={"Your inbox empty"}
                    message={"Create a new message to get started"}
                  />, containerForPortal
                )
              )
            }
             
            {
              profileGroups.map((
                {
                  profile: { id, mediaCount = 0, sessionCount = 0, user },
                  createdAt,
                  processedCreatedAt,
                  type
                },
                index
              ) => (
                <button
                  key={`user-${id}-${showArchive}-gallery-tag-${index}`}
                  onClick={() =>
                    onMainClick(
                      avatarOnly
                        ? { id, mediaCount, sessionCount, user, createdAt, type }
                        : id
                    ) &&
                    hideUnlessSearch &&
                    this.setState({ searchInput: '' })
                  }
                  className={`gallery-tag-immediate user-gallery-tag ${activeUserBasedTags[id] && 'active-selection'}`}
                >
                  {avatarOnly &&
                    (user.avatar ? (
                      <GeneralImage
                        className="session-avatar-to-position-left"
                        imageResolution={'150x'}
                        originalKey={getAvatarKey(user.avatar, user.id)}
                      />
                    ) : (
                      <span className="inner-circle-for-no-bg">{(user.firstName || user.lastName || user.email) ? `${(user.firstName || user.email || '').charAt(0).toUpperCase()}${(user.lastName || '').charAt(0).toUpperCase()}` : '-'}</span>
                    ))}
                  <div>
                    {user.firstName || user.lastName
                      ? `${user.firstName} ${user.lastName}`
                      : twoLevel ? "No Name" : (user.email || user.phone)}
                  </div>
                  {twoLevel && (
                    <div>{user.email || user.phone}</div>
                  )}
                  {mediaSpecific && (
                    <span className="count-to-right">{mediaCount}</span>
                  )}
                  {avatarOnly && oneOnOneSpecific && (
                    <>
                      <span className={'last-action'}>
                        {type === "from-current" ? "Message sent..." : type === "media-message" ? "New media..." : type === "purchase" ? "New purchase..."  : "New text..."}
                      </span>

                      <span className="date-dictator">
                        {processedCreatedAt}
                      </span>
                    </>
                  )}
                </button>
              )
            )}
            {loadingAdditionalProfileGroups && (
              <>
                <button
                  key="all-gallery-tag-1"
                  className="gallery-tag-immediate one placeholder-specific"
                ></button>
                <button
                  key="all-gallery-tag-2"
                  className="gallery-tag-immediate two placeholder-specific"
                ></button>
                <button
                  key="all-gallery-tag-3"
                  className="gallery-tag-immediate three placeholder-specific"
                ></button>
              </>
            )}
          </section>
        )}
      </>
    );
  }
};

const mapStateToProps = state => ({
  generalState: state.generalState
});

const mapDispatchToProps = dispatch => ({
  updateGeneralState: variables => dispatch(updateGeneralState(variables))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(UsersMainTagSpecificRender);
