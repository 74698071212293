import React, { memo, useEffect, useMemo } from 'react';
import MediaAsset from './SingleMediaMapper/MediaAsset';
import ActionCenter from './SingleMediaMapper/ActionCenter';
import MobileActionCenter from './SingleMediaMapper/MobileActionCenter';
import { sendAnalyticEventInit } from '../../../actions/general.actions';

const SingleMediaMapper = props => {
  const {
    goBack,
    requester,
    updateProgramState,
    independantMedia,
    showMobileProgramView,
    passedPaywall,
    prevCheckpoint,
    nextCheckpoint,
    parentGoBack,
    showSingleLesson = {},
    mappedProgram = {},
    viewsSent = {},
    profile
  } = props;

  const firstCondition = useMemo(() => (mappedProgram && !mappedProgram.loading) ? true : false, [mappedProgram]);
  const mediaToUse = useMemo(() => independantMedia || showSingleLesson.media || {}, [independantMedia || showSingleLesson.media]);
  const secondCondition = useMemo(() => ((profile && profile.id) && !showSingleLesson.preview && !viewsSent[`${mappedProgram.id}_media_${mediaToUse.id}`]) ? true : false, [(profile && profile.id) ? true : false, showSingleLesson.preview ? true : false, viewsSent[`${mappedProgram.id}_media_${mediaToUse.id}`]  ? true : false]);

  useEffect(() => {
    try {      
      if(firstCondition){
        if(secondCondition){
          updateProgramState({
            viewsSent: {
              ...viewsSent,
              [`${mappedProgram.id}_media_${mediaToUse.id}`]: new Date().getTime()
            }
          });
          
          sendAnalyticEventInit({
            type: 'view',
            programId: mappedProgram.id,
            mediaId: mediaToUse.id
          });
        };     
      };
    } catch (e) {
      console.log(e);
    };
  }, [firstCondition, mediaToUse, secondCondition]);

  const mainClassName = useMemo(() => `${showMobileProgramView && 'enact-mobile-specific-view'}`, [showMobileProgramView ? true : false]);

  return (
    <div className={mainClassName}>
      <ActionCenter 
        passedPaywall={passedPaywall}
        independantMedia={independantMedia}
        prevCheckpoint={prevCheckpoint}
        nextCheckpoint={nextCheckpoint}
        mediaToUse={mediaToUse}
        showSingleLesson={showSingleLesson}
        parentGoBack={parentGoBack}
        goBack={goBack}
        updateProgramState={updateProgramState}
      />

      <div className="single-media-immediate-container">
        <MediaAsset
          requester={requester}
          independantMedia={independantMedia}
          showSingleLesson={showSingleLesson}
        />
      </div>

      {showMobileProgramView && (
        <MobileActionCenter
          requester={requester}
          updateProgramState={updateProgramState}
          independantMedia={independantMedia}
          passedPaywall={passedPaywall}
          nextCheckpoint={nextCheckpoint}
          showSingleLesson={showSingleLesson}
        />
      )}
    </div>
  );
};

export default memo(SingleMediaMapper);
