import React from 'react';
import Cohere from 'cohere-js';
import { createRoot } from 'react-dom/client';
import WebFontLoader from 'webfontloader';
import { confirmAlert } from 'react-confirm-alert';
import AppMapper from './AppMapper';
import * as serviceWorker from './service.worker';

import './styles/config.scss';

WebFontLoader.load({
  google: {
    families: ['Material Icons', 'Lato']
  }
})

if(process.env.NODE_ENV === 'production'){
  Cohere.init('CHr0MIAtj3YJCiI7gGk0v6GI')
}

const container = document.getElementById('app')
const root = createRoot(container)

root.render(<AppMapper />)

if('serviceWorker' in navigator){
  navigator.serviceWorker.addEventListener('controllerchange', function(){
    if(window.swRefreshing) return
    window.swRefreshing = true
    window.location.reload()
  })
}

serviceWorker.register({
  onUpdate: registration =>  
    confirmAlert({
      title: 'New Update Available',
      message: 'Your page will be refreshed upon update.',
      overlayClassName: 'update-specific',
      buttons: [
        {
          label: 'Update & Refresh',
          onClick: () => registration.waiting.postMessage({ type: 'SKIP_WAITING' })
        }
      ]
    }),
  onWaiting: registration =>
    confirmAlert({
      title: 'New Update Available',
      message: 'Your page will be refreshed upon update.',
      overlayClassName: 'update-specific',
      buttons: [
        {
          label: 'Update & Refresh',
          onClick: () => registration.waiting.postMessage({ type: 'SKIP_WAITING' })
        }
      ]
    })
})