import React from 'react';
import { connect } from 'react-redux';
import SingleMediaMapper from './SingleMediaMapper';
import { updateProgramState } from '../../../actions/program.actions';

const SingleMedia = props => {
  const { programState, mappedProgram = {}, independantMedia, showSingleLesson } = props;
  const { viewsSent = {} } = programState;

  const mediaToUse = independantMedia || showSingleLesson.media || {};

  return (
    <SingleMediaMapper 
      {...props}
      key={`single-media-${mappedProgram.id}-mapper-${mediaToUse.id}`} 
      viewsSent={viewsSent} programState={undefined} 
    />
  );
};

const mapStateToProps = state => ({
  programState: state.programState
});

const mapDispatchToProps = dispatch => ({
  updateProgramState: variables => dispatch(updateProgramState(variables))
});

export default connect(mapStateToProps, mapDispatchToProps)(SingleMedia);
