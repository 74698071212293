import React, { PureComponent } from 'react';

import PhoneInput, { parsePhoneNumber } from 'react-phone-number-input';

import TextInput from '../../../../Shared/TextInput';
import GeneralLoader from '../../../../Shared/GeneralLoader';
import { validEmail } from '../../../../../helper';
import { adminAddUserInit } from '../../../../../actions/user.actions';

import 'react-phone-number-input/style.css';

let country = 'US';
class NewUser extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {};
  }

  createNewUser = async () => {
    const { newUserCreated = () => null } = this.props;
    const {  firstName = '', lastName = '', email = '', tags = '', phone = '' } = this.state;

    if(validEmail(email)){
      this.setState({ addingUser: true });

      try {
        const parsedPhonenumber = phone && parsePhoneNumber(`+${phone.replace('+', '')}`);
        
        await adminAddUserInit({ firstName, lastName, email, tags, ...parsedPhonenumber && { phone: `${parsedPhonenumber.number}`.replace('+', '') } });

        newUserCreated();

        return true;
      } catch (e) {
        console.log(e);
      };      

      this.setState({ addingUser: false });
    } else {
      alert('A valid email must be sent.');
    };
  }

  render() {
    const { goBack } = this.props;
    const {  firstName = '', lastName = '', email = '', addingUser, tags = '', phone = '+1' } = this.state;

    return <>
      <div className="program-builder-white-popup new-user-specific">
        <div id="header-of-inner-admin-content">
          <h2 className="page-title">New User</h2>

          <div className="page-action-items-to-the-right">
            <button className="x-to-top-right" onClick={goBack}>
              <svg
                width="8"
                height="7"
                viewBox="0 0 8 7"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M1.33337 0.833252L6.66671 6.16659"
                  stroke="#666666"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M6.66671 0.833252L1.33337 6.16659"
                  stroke="#666666"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </button>
          </div>
        </div>

        <div id="complete-inner-main-admin-content" style={{ ...addingUser && { pointerEvents: "none", opacity: .5 } }}>
          <div id="admin-content-inner-content-with-sidebar" className="full-width-specific">
            <TextInput
              id="newUser-first-name"
              type="text"
              label="First Name"
              placeholder="First"
              className="name-specific-input"
              value={firstName}
              onChange={firstName => this.setState({ firstName })}
            />

            <TextInput
              id="newUser-last-name"
              type="text"
              label="Last Name"
              placeholder="Last"
              className="name-specific-input"
              value={lastName}
              onChange={lastName => this.setState({ lastName })}
            />

            <TextInput
              id="newUser-email"
              type="text"
              label="Email"
              placeholder="Email"
              className="name-specific-input"
              value={email}
              onChange={email => this.setState({ email })}
            />
            <div className="ao-input name-specific-input">
              <label>Phone Number</label>
              <PhoneInput
                value={phone}
                country={country}
                defaultCountry={country}
                id="phone_input"
                placeholder="(000) - 000 - 0000"
                onChange={phone => this.setState({ phone })}
              />
            </div>

            <TextInput
              id="newUser-tags"
              type="text"
              label="Tags (comma separated)"
              placeholder="Tags"
              className="name-specific-input"
              value={tags}
              onChange={tags => this.setState({ tags })}
            />
          </div>
        </div>

        <div className="program-builder-selection-footer" style={{ ...addingUser && { pointerEvents: "none", opacity: .5 } }}>
          <div className="action-items-to-the-right">
            <button
              style={{ ...!validEmail(email) && { opacity: .5 } }}
              onClick={this.createNewUser}
              className={`add-program-buider-materials`}
            >
              <svg
                width="18"
                height="18"
                viewBox="0 0 18 18"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M15 17H3C1.89 17 1 16.1 1 15V3C1 1.89 1.89 1 3 1H15C16.1 1 17 1.89 17 3V15C17 16.1 16.1 17 15 17Z"
                  stroke="white"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M9 5V13"
                  stroke="white"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M13 9H5"
                  stroke="white"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
              Create New User
            </button>
          </div>
        </div>        
      </div>

      {addingUser && <GeneralLoader />}
    </>;
  }
};

export default NewUser;