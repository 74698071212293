import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Query } from 'react-apollo';
import OrdersTable from './Accessories/OrdersTable';
import { updateGeneralState } from '../../../../actions/general.actions';
import { searchBasicOrders } from '../../../../apollo.queries';
import apolloClient from '../../../../apollo.client';
import OrdersAndMembershipsMainTable from './Accessories/OrdersAndMembershipsMainTable';

class OrdersAndMembershipsMain extends Component {
  constructor(props) {
    super(props);

    this.state = {
      additionalQueriedOrders: [],
      currentPage: 1,
      limit: 50
    };
  }

  handleScroll = async (e, force) => {
    if (!this.endOfQuery && !this.queryingAdditionalItems) {
      const { additionalQueriedOrders, currentPage, limit } = this.state;
      const { generalState, profileGroupToUse } = this.props;

      const bottom =
        e &&
        e.target.scrollHeight - e.target.scrollTop - e.target.clientHeight < 30;
      if (bottom || force) {
        this.queryingAdditionalItems = true;
        this.setState({ loadingAdditionalOrders: true });

        const { data } = await apolloClient.query({
          query: searchBasicOrders,
          fetchPolicy: 'network-only',
          variables: {
            page: currentPage + 1,
            limit: limit,
            ...(profileGroupToUse &&
              profileGroupToUse.profile && {
                profileId: profileGroupToUse.profile.id
              })
          }
        });

        if (
          !(data && data.searchBasicOrders && data.searchBasicOrders.orders) ||
          data.searchBasicOrders.orders.length === 0
        ) {
          this.endOfQuery = true;
        }

        this.queryingAdditionalItems = false;

        this.setState({
          additionalQueriedOrders: [
            ...additionalQueriedOrders,
            ...(this.endOfQuery ? [] : data.searchBasicOrders.orders)
          ],
          currentPage: currentPage + 1,
          loadingAdditionalOrders: false
        });
      }
    } else {
      return true;
    }
  };

  render() {
    const { requester } = this.props;
    const { loadingAdditionalOrders, additionalQueriedOrders } = this.state;

    return (
      <Query
        query={searchBasicOrders}
        fetchPolicy="cache-and-network"
        variables={{
          page: 1,
          limit: 50
        }}
      >
        {({ loading, data }) => {
          if (loading && !(data && data.searchBasicOrders)) {
            return (
              <>
                <h2>Loading...</h2>
                <section className="for-scroll-ref">
                  <div className="immediate-orders-table-container placeholder-specific">
                    <div className="immediate-order-table-header">
                      <div className="product-specific-width th">Product</div>
                      <div className="date-specific-width th">Date</div>
                    </div>
                    <div className="immediate-order-table-body">
                      <div
                        key={'order-table-row-1'}
                        className="order-table-row"
                      >
                        <div className="product-specific-width td">...</div>
                        <div className="date-specific-width td">...</div>
                      </div>
                    </div>
                  </div>
                </section>
              </>
            );
          }

          if (data && data.searchBasicOrders) {
            const orders = [
              ...((data.searchBasicOrders || {}).orders || []),
              ...additionalQueriedOrders
            ];

            if (
              ((data.searchBasicOrders || {}).orders || []).length >= 50 &&
              orders.length < 50
            ) {
              this.handleScroll(false, true);
            }

            const reducedOrders = orders.reduce(
              (results, currentValue) => ({
                ...results,
                [currentValue.package && currentValue.package.recurring
                  ? 'membership'
                  : 'single']: [
                  ...(results[
                    currentValue.package && currentValue.package.recurring
                      ? 'membership'
                      : 'single'
                  ] || []),
                  currentValue
                ]
              }),
              {}
            );

            return (
              <>
                <h2>Memberships</h2>
                <div className="window-separation">
                  <div className="immediate-orders-table-container">
                    <div className="immediate-order-table-header">
                      <div className="product-specific-width th">Product</div>
                      <div className="date-specific-width th">Date</div>
                      <div className="status-specific-width th">Status</div>
                    </div>

                    <section
                      className="for-scroll-ref"
                      onScroll={this.handleScroll}
                    >
                      <OrdersAndMembershipsMainTable
                        isMembership={true}
                        currencyPrefix={requester.currencyPrefix}
                        orders={reducedOrders['membership'] || []}
                        onMainClick={() => {}}
                        loadingAdditionalOrders={loadingAdditionalOrders}
                      />
                    </section>
                  </div>
                </div>

                <h2>Orders</h2>
                <div className="window-separation">
                  <div className="immediate-orders-table-container">
                    <div className="immediate-order-table-header">
                      <div className="product-specific-width th">Product</div>
                      <div className="date-specific-width th">Date</div>
                      <div className="amount-specific-width th">Amount</div>
                    </div>

                    <section
                      className="for-scroll-ref"
                      onScroll={this.handleScroll}
                    >
                      <OrdersAndMembershipsMainTable
                        currencyPrefix={requester.currencyPrefix}
                        orders={reducedOrders['single'] || []}
                        onMainClick={() => {}}
                        loadingAdditionalOrders={loadingAdditionalOrders}
                      />
                    </section>
                  </div>
                </div>
              </>
            );
          } else {
            return <>Not Found</>;
          }
        }}
      </Query>
    );
  }
}

OrdersAndMembershipsMain.propTypes = {
  tags: PropTypes.array,
  requester: PropTypes.object.isRequired,
  profileGroupToUse: PropTypes.object.isRequired,
  simplified: PropTypes.bool
};

const mapStateToProps = state => ({
  generalState: state.generalState
});

const mapDispatchToProps = dispatch => ({
  updateGeneralState: variables => dispatch(updateGeneralState(variables))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(OrdersAndMembershipsMain);
