const emailRegex = new RegExp(
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
);
// const passwordRegex = new RegExp('^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{7,})');
const passwordRegex = new RegExp('^(?=.*[0-9])(?=.{7,})');
const phoneRegex = new RegExp(
  /^[+]?[(]?[0-9]{3}[)]?[-\s.]?[0-9]{3}[-\s.]?[0-9]{4,6}$/
);
const tenantCodeRegex = new RegExp(/^[a-zA-Z0-9_-]{2,40}$/);

const monthNames = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December'
];

export const sToMMSS = s => {
  return (s - (s %= 60)) / 60 + (9 < s ? ':' : ':0') + s;
};

export const getProgressClass = progress => {
  if (progress >= 100) {
    return 'ao-progress--100';
  }
  if (progress >= 80) {
    return 'ao-progress--80';
  }
  if (progress >= 60) {
    return 'ao-progress--60';
  }
  if (progress >= 40) {
    return 'ao-progress--40';
  }
  return 'ao-progress--20';
};

export const parseSearchParams = search => {
  try {
    return JSON.parse(
      '{"' +
        decodeURI(search)
          .replace('?', '')
          .replace(/"/g, '\\"')
          .replace(/&/g, '","')
          .replace(/=/g, '":"') +
        '"}'
    );
  } catch {
    return {};
  }
};

export const validTenantCode = code => {
  if (code) return tenantCodeRegex.test(code);
  return true;
};

export const validEmail = email => {
  if (email) return emailRegex.test(email);
  return true;
};

export const validPassword = password => {
  if (password) return passwordRegex.test(password);
  return true;
};

export const validPhone = phone => {
  if (phone) return phoneRegex.test(phone);
  return true;
};

export const phoneInputFormat = current => {
  if (!current) {
    return '';
  }

  let output = '(';
  current.replace(
    /^\D*(\d{0,3})\D*(\d{0,3})\D*(\d{0,4})/,
    (match, g1, g2, g3) => {
      if (g1.length) {
        output += g1;
        if (g1.length === 3) {
          output += ')';
          if (g2.length) {
            output += ' ' + g2;
            if (g2.length === 3) {
              output += ' - ';
              if (g3.length) {
                output += g3;
              }
            }
          }
        }
      }
    }
  );

  return output;
};

export const getRemainingTime = requested => {
  let date = new Date(requested.replace(/\s/, 'T'));
  date.setDate(date.getDate() + 7);
  const duration = date - new Date();
  return _getHoursMins(duration);
};

export const _getHoursMins = duration => {
  // let milliseconds = parseInt((duration%1000)/100, 10);
  // let seconds = parseInt((duration/1000)%60, 10);
  let minutes = Math.abs(parseInt((duration / (1000 * 60)) % 60, 10));
  let hours = Math.abs(parseInt((duration / (1000 * 60 * 60)) % 24, 10));
  let days = Math.abs(parseInt(duration / (1000 * 60 * 60 * 24), 10));

  hours = hours < 10 && hours >= 0 ? '0' + hours : hours;
  minutes = minutes < 10 ? '0' + minutes : minutes;
  const overdue = duration < 0 ? 'overdue' : 'remaining';
  if (days > 0) {
    hours = days + 'd ' + hours;
  }
  return hours + 'h ' + minutes + 'm ' + overdue;
};

// export const payloadBuilder = (type, body) => {
//   const options = {
//     method: type,
//     headers: {
//       Accept: 'application/json',
//       'Content-Type': 'application/json',
//       Authorization:
//         'JWT ' + localStorage.getItem(process.env.REACT_APP_AUTH_TOKEN_NAME)
//     }
//   };
//   return body
//     ? Object.assign({}, options, { body: JSON.stringify(body) })
//     : options;
// };

export const _inchesToFt = input => {
  let res = (input / 12).toString().split('.');
  if (res.length === 2) {
    const first = res[0];
    const second = Math.round(12 * parseFloat('0.' + res[1]));
    return `${first}'${second}"`;
  }
  if (res.length === 1) {
    return res[0] + "'0";
  }
  return '';
};

export const _ftToInches = height => {
  let res = height.split("'");
  return parseInt(res[0], 10) * 12 + parseInt(res[1], 10);
};

export const millisToMinSec = millis => {
  var minutes = Math.floor(millis / 60000);
  var seconds = ((millis % 60000) / 1000).toFixed(0);
  return minutes + ':' + (seconds < 10 ? '0' : '') + seconds;
};

export const getMonthName = month => monthNames[month];

export const groupBy = (array, key) =>
  array.reduce((result, currentValue) => {
    (result[currentValue[key]] = result[currentValue[key]] || []).push(
      currentValue
    );

    return result;
  }, {});

export const getOptimizedFromKey = (resolution, key) => {
  let splitOriginalTopLevel = key.replace('undefined/', '').split('.')
  
  splitOriginalTopLevel.pop()
  splitOriginalTopLevel = splitOriginalTopLevel.join('.')

  return `optimized-images/${resolution}/${splitOriginalTopLevel}.jpg`
};
