import React, { memo } from 'react';
import { useQuery } from 'react-apollo';
import ParentMapper from '../routes/ParentMapper';
import LogoLoader from '../components/Shared/LogoLoader';
import { ProfileContext } from '../context/profile.context';
import { CurrentUserQuery_ActiveGroupPurchased } from '../apollo.queries';

const LoggedInRouter = ({ requesterOrg, profile, theme }) => {
  const { data = {}, loading } = useQuery(CurrentUserQuery_ActiveGroupPurchased, { fetchPolicy: "network-only" })

  if(loading){
    return (
      <div className={`${theme} general-furthest-out-loader-container`}>
        <LogoLoader tenant={requesterOrg.activeGroup.tenant} />
      </div>
    )
  }

  return (
    <div className={`logged-in-section-wrapper-container`}>
      <ProfileContext.Provider value={{ ...profile, activeGroupPackagePurchased: data.activeGroupPurchased ? true : false }}>
        <ParentMapper />
      </ProfileContext.Provider>
    </div>
  )
}

export default memo(LoggedInRouter)
