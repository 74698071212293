import React, { Component } from 'react';
import { injectStripe, CardElement } from 'react-stripe-elements';

class StripeForm extends Component {
  constructor(props) {
    super(props);
  }
  
  componentDidMount() {
    this.props.onRef(this);
  }

  componentWillUnmount() {
    this.props.onRef(undefined);
  }

  _submit() {
    try {
      this.props.stripe.createToken().then(({ token, error }) => {
        if (error) {
          this.props.onError(error.message);
        }

        if (token) {
          this.props.onToken(token);
        }
      });
    } catch (e) {
      console.log(e);
    }
  }

  render() {
    const { whiteColor } = this.props;

    return (
      <CardElement {...(whiteColor && { style: { base: { color: '#fff' } } })} />
    );
  }
};

export default injectStripe(StripeForm);
