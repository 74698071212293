import React, { memo, useMemo } from 'react';

const AllAndUntaggedInit = ({ noDbCats, onUntagged, untaggedActive, assignSpecific, itemsSelectedConditional, onMainClick }) => {
  const allTagsClassName = useMemo(() => `gallery-tag-immediate all-specific ${!untaggedActive && itemsSelectedConditional && 'active-selection'}`, [untaggedActive, itemsSelectedConditional]);

  const untaggedClassName = useMemo(() => `gallery-tag-immediate ${(untaggedActive || noDbCats) && 'active-selection'}`, [untaggedActive, noDbCats]);

  return (
    !assignSpecific && !noDbCats 
      ? <>
          <button
            onClick={onMainClick}
            key="all-gallery-tag"
            className={allTagsClassName}
          >
            All tags
          </button>

          <div
            className="tag-container untagged-specific"
            key={`untagged-tag-container`}
          >
            <div
              className={`for-relativity`}
              key={`untagged-gallery-tag`}
            >
              <button
                className={untaggedClassName}
                onClick={() => onUntagged()}
              >
                {'Untagged'}
              </button>
            </div>
          </div>
        </> 
      : <></>
  );
};

export default memo(AllAndUntaggedInit);
