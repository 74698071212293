import React, { PureComponent } from 'react';

class BlockFormHeading extends PureComponent {
  constructor(props) {
    super(props);
  }

  render() {
    const { block } = this.props;
    const { label, title } = block;

    return <>
      <div  className={`general-form-block-render-container heading-type`}>
        <h3>{label || title || "Heading"}</h3>
      </div>
    </>;
  }
};

export default BlockFormHeading;
