import React, { memo } from 'react';

const MobileInitButton = ({ toggleMobileInit, mobileInitialized }) => (
  <button
    className="gallery-tag-immediate for-relativity  mobile-show-tags-init-specific"
    onClick={toggleMobileInit}
  >
    {mobileInitialized ? 'Hide' : 'Show'} Tags
    <div className={`parent-arrow-dignfier`}>
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M8 10L12 14L16 10"
          stroke="#666666"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        ></path>
      </svg>
    </div>
  </button>
);

export default memo(MobileInitButton);
