import React, { memo } from 'react'
import { Helmet } from 'react-helmet'
import replaceall from 'replaceall'
import './addToHomeScreen.scss'

const hostName = window.location.hostname.toLowerCase()
const hostNameSlugToUse = replaceall('.', '-', hostName)

const prefixUrl = `http${hostNameSlugToUse === 'localhost' ? '' : 's'}://${hostName}${hostNameSlugToUse === 'localhost' ? ':3000' : ''}`

const AppHelmet = () => (
  <Helmet>
    <link
      href={`${prefixUrl}/manifests/${hostNameSlugToUse}/manifest.json`}
      rel="manifest"
    />
    <link
      href={`${prefixUrl}/images/header-images/${hostNameSlugToUse}/apple-touch-icon.png`}
      rel="apple-touch-icon"
      sizes="180x180"
    />
    <link
      href={`${prefixUrl}/images/header-images/${hostNameSlugToUse}/favicon-32x32.png`}
      rel="icon"
      type="image/png"
      sizes="32x32"
    />
    <link
      href={`${prefixUrl}/images/header-images/${hostNameSlugToUse}/favicon-16x16.png`}
      rel="icon"
      type="image/png"
      sizes="16x16"
    />
    <link
      href={`${prefixUrl}/images/header-images/${hostNameSlugToUse}/safari-pinned-tab.svg`}
      rel="mask-icon"
      color="#5bbad5"
    />
    <meta content={`${prefixUrl}/`} property="og:url" />
    <meta
      content={`${prefixUrl}/images/header-images/${hostNameSlugToUse}/apple-touch-icon.png`}
      property="og:image"
    />
    <meta
      content={`${prefixUrl}/images/header-images/${hostNameSlugToUse}/apple-touch-icon.png`}
      itemprop="image"
    />
    <meta
      content={`${prefixUrl}/images/header-images/${hostNameSlugToUse}/apple-touch-icon.png`}
      name="twitter:image"
    />
  </Helmet>
)

export default memo(AppHelmet)