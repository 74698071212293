import React, { memo, useState, useMemo, useContext } from 'react';
import MenuItems from './MenuItems';
import { isMobile } from 'react-device-detect';
import { tenantPrefixUrlDeterminant } from '../../../../../actions/user.actions';
import { RequesterContext } from '../../../../../context/requester.context';
import { ProfileContext } from '../../../../../context/profile.context';

const Dashboard = props => {
  const { assetOfFocus } = props;

  const requester = useContext(RequesterContext);
  const profile = useContext(ProfileContext);

  const [showQuickActionsMenu, updateShowQuickActionsMenu] = useState();

  const menuPrefix = useMemo(
    () => `${tenantPrefixUrlDeterminant(
      requester.activeGroup.tenant.code,
      requester.activeGroup.code
    )}/admin`, [requester.activeGroup]
  );

  return <>
    {
      !isMobile && (profile || {}).role === 'Admin' && <div className={`quick-actions-menu-container ${showQuickActionsMenu && 'menu-is-active'}`}>
        <button className="init-quick-actions-menu" onClick={() => updateShowQuickActionsMenu(!showQuickActionsMenu)}>
          <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M13 25C6.36 25 1 19.628 1 13C1 6.36 6.372 1 13 1C19.628 1 25 6.372 25 13C25 19.628 19.628 25 13 25Z" fill="#F9B016" stroke="#F9B016" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M13 7.6665V18.3332" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M18.3334 13H7.66675" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
          </svg>
        </button>

        <MenuItems 
          isSMSVideo={assetOfFocus.sms && (assetOfFocus.label || '').includes('video')}
          urlToUse={assetOfFocus.url}
          updateShowQuickActionsMenu={updateShowQuickActionsMenu} 
          showQuickActionsMenu={showQuickActionsMenu} 
          menuPrefix={menuPrefix} 
          /> 
      </div>
    }
  </>;
};

export default memo(Dashboard);
