import React, { PureComponent } from 'react';
import ReactDOM from 'react-dom';
import { connect } from 'react-redux';
import AddMedia from '../../../../Coaching/Gallery/PopUps/AddMedia';
import ParentMapperChild from './ParentMapperChild';
import { ProfileContext } from '../../../../../../context/profile.context';
import { RequesterContext } from '../../../../../../context/requester.context';
import { updateGeneralState, onTagSelect, onUntagged, onTagRemove, fileUpload_onChange } from '../../../../../../actions/general.actions';

const defaultProp = e => {
  e.preventDefault();
  e.stopPropagation();

  return true;
};

class ParentMapper extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {    
    const { pendingSets = 0, generalState, fileUpload_onChange } = this.props;
    const { addMediaPop, filesUploading } = generalState;

    const { fileDragging } = this.state;

    return (
      <div
        {
          ...!addMediaPop && {
            onDragOver: e =>
              defaultProp(e) &&
              !fileDragging &&
              this.setState({ fileDragging: true }),
            onDragLeave: e =>
              defaultProp(e) &&
              fileDragging &&
              e.target.id === 'dignifying-leaving' &&
              this.setState({ fileDragging: false }),                    
            onDragEnd: e =>
              defaultProp(e) &&
              fileDragging &&
              this.setState({ fileDragging: false })                    
          }
        }                
        className="program-builder-white-popup gallery-specific"
      >
        <RequesterContext.Consumer>
          {requester => (
            <ProfileContext.Consumer>
              {profile => (
                <ParentMapperChild {...this.props} profile={profile} requester={requester} />
              )}
            </ProfileContext.Consumer>
          )}
        </RequesterContext.Consumer>

        {
          addMediaPop 
            ? ReactDOM.createPortal(
                <AddMedia />,
                document.getElementById('outsideModalPortal-z2')
              )
            : fileDragging && (
              <div className="file-is-dragging-container">
                <div className="file-is-dragging-inner-container">
                  <p>Drop files to upload them</p>
                </div>
                <input
                  id="dignifying-leaving"
                  type="file"
                  multiple
                  onChange={e => {
                    const currentTimestamp = new Date().getTime();

                    this.setState({ fileDragging: false }, () =>
                      fileUpload_onChange({
                        pendingSets: pendingSets + 1,
                        currentFiles: filesUploading,
                        files: [...e.target.files].map((file, index) => ({
                          file,
                          tempUrl: URL.createObjectURL(file),
                          progress: 0,
                          id: `${currentTimestamp}-${index}`
                        }))
                      })
                    );
                  }}
                />
              </div>
            )
        }
      </div>
    );
  }
};

const mapStateToProps = state => ({
  generalState: state.generalState
});

const mapDispatchToProps = dispatch => ({
  onUntagged: variables => dispatch(onUntagged(variables)),
  onTagSelect: variables => dispatch(onTagSelect(variables)),
  onTagRemove: variables => dispatch(onTagRemove(variables)),
  updateGeneralState: variables => dispatch(updateGeneralState(variables)),
  fileUpload_onChange: files => dispatch(fileUpload_onChange(files))
});

export default connect(mapStateToProps, mapDispatchToProps)(ParentMapper);
