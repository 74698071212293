import React, { memo, useCallback, useMemo } from 'react';

const ChildTagRender = ({ origin, categoryLocalChild, onChildDeleteInit, onChildTagClick, itemsSelected, categoryLocal, assignSpecific, untaggedAndDbConditional }) => {
  const isActive = useCallback(
    categoryLocalChildId => itemsSelected && itemsSelected.find(item => item.id === categoryLocalChildId), 
    [itemsSelected]
  );

  const immediateTagClassName = useMemo(
    () => `gallery-tag-immediate ${(
      categoryLocalChild.id
        ? isActive(categoryLocalChild.id)
        : untaggedAndDbConditional
    ) && 'active-selection'}`, [categoryLocalChild.id, untaggedAndDbConditional, itemsSelected]
  );

  const deleteInitRender = useMemo(
    () => !assignSpecific && categoryLocalChild.id && (
      <button
        onClick={() =>
          onChildDeleteInit({
            origin,
            label: categoryLocalChild.label,
            parentLabel: categoryLocal.label
          })
        }
        className="tag-delete"
      >
        {deleteIcon}
      </button>
    ), [assignSpecific, categoryLocalChild, categoryLocal]
  );

  return <>
    <div className="for-relativity">
      <button
        className={immediateTagClassName}
        onClick={() => onChildTagClick(categoryLocalChild)}
      >
        {categoryLocalChild.label || 'Untagged'}
        {(assignSpecific && categoryLocalChild.label) && (
          <span className="check-mark-to-right">
            <svg
              width="10"
              height="8"
              viewBox="0 0 10 8"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M9 1.33325L3.5 6.66659L1 4.24234"
                stroke="#0292FF"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </span>
        )}
      </button>
      {deleteInitRender}
    </div>
  </>;
};

const deleteIcon = (
  <svg
    width="12"
    height="14"
    viewBox="0 0 12 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M8.35991 12.9999H3.63324C2.84657 12.9932 2.19324 12.3932 2.13324 11.6132L1.48657 3.24658H10.4866L9.83991 11.6066V11.5999C9.77324 12.3799 9.12657 12.9799 8.33991 12.9799L8.35991 12.9999Z"
      stroke="#EC4C4C"
      strokeLinecap="round"
      strokeLinejoin="round"
    ></path>
    <path
      d="M11.3333 3.25342H0.666626"
      stroke="#EC4C4C"
      strokeLinecap="round"
      strokeLinejoin="round"
    ></path>
    <path
      d="M4.11999 1.00015H7.86999H7.86332C8.27665 0.993483 8.60999 1.33348 8.60999 1.74682V3.24682H3.35999V1.74682V1.74015C3.35999 1.32015 3.69332 0.986816 4.10665 0.986816L4.11999 1.00015Z"
      stroke="#EC4C4C"
      strokeLinecap="round"
      strokeLinejoin="round"
    ></path>
  </svg>
);

export default memo(ChildTagRender);
