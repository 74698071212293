import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { updateFormState } from '../../../../../../../../actions/form.actions';

class BlockFormCheckboxInput extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {};
  }

  render() {
    const { block, formState, updateFormState } = this.props;
    const { label, title, choices = [], required = 1 } = block;
    const { formResponses = {}, reducedRequired = {} } = formState;
    const currentValue = (formResponses[block.id] || {}).choiceIds || {};

    return <>
      <div  className={`general-form-block-render-container terms-specific checkbox-type`}>
        <p className="question-name">{label || title || `Question Name`}{required ? '*' : ''}</p>

        {reducedRequired[block.id] ? <div className="invalid-currently-dignifier">Required*</div> : <></>}

        <div className="container-of-form-block-checkbox">
          {(choices || []).map(({ id, label }, index) => (
            <div                        
              key={`block_choice_actual_render_${id}`}        
              className={`re-order-form-specific choice-specific general-form-block-render-container-for-dragging choice-block-type`}                          
            >
              <div 
                className={`form-block-checkbox-choice ${currentValue[id] && 'active'}`}
                onClick={() => 
                  updateFormState({
                    ...reducedRequired[block.id] && { reducedRequired: { ...reducedRequired, [block.id]: undefined } },
                    formResponses: {
                      ...formResponses,
                      [block.id]: {
                        choiceIds: {
                          ...currentValue,
                          [id]: !currentValue[id]
                        }
                      }
                    }              
                  })
                } 
                key={`form-block-choice-${index}`}
              >
                <span className="tangible-box">
                  <span>
                    <svg width="19" height="14" viewBox="0 0 19 14" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M17.25 1.75049L6.75 12.25L1.5 7.00049" stroke="#0D0D0D" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"></path></svg>
                  </span>
                </span>
                
                <p>{label || `Option ${index + 1}`}</p>
              </div>
            </div>  
          ))}
        </div>
      </div>
    </>;
  }
};

const mapStateToProps = state => ({
  formState: state.formState
});

const mapDispatchToProps = dispatch => ({
  updateFormState: updatedStates => dispatch(updateFormState(updatedStates))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(BlockFormCheckboxInput);