import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import Main from './Main';
import { updateGeneralState } from '../../../../actions/general.actions';

const Main_REDUX = props => {
  const { activeItem, requester, generalState, updateGeneralState } = props;
  const { updatedTotal } = generalState;

  useEffect(() => { return () => updateGeneralState({ coupon: undefined, updatedTotal: undefined, loadingSubmission: undefined, promoSuccess: undefined }); }, []);
  
  return <Main requester={requester} updatedTotal={updatedTotal} activeItem={activeItem} />;
};

const mapStateToProps = state => ({
  generalState: state.generalState
});

const mapFuncToProps = dispatch => ({
  updateGeneralState: variables => dispatch(updateGeneralState(variables))
});

export default connect(mapStateToProps, mapFuncToProps)(Main_REDUX);
