import React, { useCallback, useState } from 'react';
import { connect } from 'react-redux';
import GeneralConfirmation from '../../Shared/GeneralConfirmation';
import { updateGeneralState } from '../../../actions/general.actions';

const Sidebar = ({ requester = {}, generalState, updateGeneralState, logOut }) => {
  const [showConfirmation, update_showConfirmation] = useState()
  
  const { activeSettingSection = 'groups' } = generalState
  const { activeGroup } = requester || {}

  const onConfirm = useCallback(() => { logOut(); update_showConfirmation(false) }, [])

  return (
    <div className="settings-section-iterate">
      {[
        {
          label: 'Groups',
          slug: 'groups'
        },
        {
          label: 'Account',
          slug: 'account'
        },
        ...activeGroup
          ? [
              {
                label: 'Billing',
                slug: 'billing'
              }
            ]
          : [],
        {
          label: 'Log out',
          slug: 'log-out'
        }
      ].map(menuItem => (
        <button
          className={`${activeSettingSection === menuItem.slug && 'active-menu-item'}`}
          onClick={() =>
            menuItem.slug === 'log-out'
              ? update_showConfirmation(true)
              : updateGeneralState({ activeSettingSection: menuItem.slug })
          }
          key={`setting-menu-item-${menuItem.slug}`}
        >
          {menuItem.label}
        </button>
      ))}

      {showConfirmation && (
        <GeneralConfirmation
          cancelText={'No'}
          onConfirm={onConfirm}
          question={`Are you sure?`}
          confirmText={'Yes, log out'}
          onCancel={() => update_showConfirmation(false)}
        />
      )}
    </div>
  )
}

const mapStateToProps = state => ({
  generalState: state.generalState
});

const mapDispatchToProps = dispatch => ({
  updateGeneralState: variables => dispatch(updateGeneralState(variables))
});

export default connect(mapStateToProps, mapDispatchToProps)(Sidebar);
