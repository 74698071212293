import React, { PureComponent } from 'react';
import BlockFormTextInput from '../Viewer/BlockFormTextInput';
import BlockFormTextArea from '../Viewer/BlockFormTextArea';
import BlockFormPhoneInput from '../Viewer/BlockFormPhoneInput';
import BlockFormSelectInput from '../Viewer/BlockFormSelectInput';
import BlockFormHeading from '../Viewer/BlockFormHeading';
import BlockFormMedia from '../Viewer/BlockFormMedia';
import BlockFormCheckboxInput from '../Viewer/BlockFormCheckboxInput';
import BlockFormCheckboxTermsInput from '../Viewer/BlockFormCheckboxTermsInput';
import BlockFormDescription from '../Viewer/BlockFormDescription';
import BlockFormEmailInput from '../Viewer/BlockFormEmailInput';
import BlockFormRadioInput from '../Viewer/BlockFormRadioInput';
import BlockFormCountryInput from '../Viewer/BlockFormCountryInput';
import BlockFormUploadInput from '../Viewer/BlockFormUploadInput';

class BlockRoutingChild extends PureComponent {
  constructor(props) {
    super(props);
  }

  render() {
    const { block, isActive } = this.props;
    const { type = '' } = block;

    return ['athlete_first_name_default', 'athlete_last_name_default', 'athlete_age_default', 'parent_first_name_default', 'parent_last_name_default', 'zip_default', 'address_default', 'instagram_default', 'twitter_default', 'text_input'].indexOf(type) > -1 ? (
      <BlockFormTextInput isActive={isActive} block={block} />
    ) : ['athlete_email_default', 'parent_email_default'].indexOf(type) > -1 ? (
      <BlockFormEmailInput isActive={isActive} block={block} />
    ) : ['athlete_tel_default', 'parent_tel_default', 'phone_input'].indexOf(type) > -1 ? (
      <BlockFormPhoneInput isActive={isActive} block={block} />
    ) : ['text_area'].indexOf(type) > -1 ? (
      <BlockFormTextArea isActive={isActive} block={block} />
    ) : ['select_input'].indexOf(type) > -1 ? (
      <BlockFormSelectInput isActive={isActive} block={block} />
    ) : ['checkbox_default', 'checkbox_input'].indexOf(type) > -1 ? (
      <BlockFormCheckboxInput isActive={isActive} block={block} />
    ) : ['terms_default'].indexOf(type) > -1 ? (
      <BlockFormCheckboxTermsInput isActive={isActive} block={block} />
    )  : ['radio_default', 'radio_input'].indexOf(type) > -1 ? (
      <BlockFormRadioInput isActive={isActive} block={block} />
    ) : ['heading_default', 'number_heading_default'].indexOf(type) > -1 ? (
      <BlockFormHeading isActive={isActive} block={block} />
    ) : ['description_default'].indexOf(type) > -1 ? (
      <BlockFormDescription isActive={isActive} block={block} />
    ) : ['country_input'].indexOf(type) > -1 ? (
      <BlockFormCountryInput isActive={isActive} block={block} />
    ) : ['file_upload_default'].indexOf(type) > -1 ? (
      <BlockFormUploadInput isActive={isActive} block={block} />
    ) : ['media_default'].indexOf(type) > -1 ? (
      <BlockFormMedia isActive={isActive} block={block} />
    ) : <></>;
  }
};

export default BlockRoutingChild;
