import React from 'react';

const ACTIONS = [
  {
    color: '#F37070',
    text: 'Lesson',
    type: 'lesson',
    icon: <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M4.3335 12.9998L5.5735 9.6665" stroke="white" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M9.66676 12.9998L8.42676 9.6665" stroke="white" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M4 6.99984V5.6665" stroke="white" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M6 6.99984V3.6665" stroke="white" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M8 7V5" stroke="white" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M10 7.00013V3.9668" stroke="white" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M11.6667 1H2.33333C1.59695 1 1 1.59695 1 2.33333V8.33333C1 9.06971 1.59695 9.66667 2.33333 9.66667H11.6667C12.403 9.66667 13 9.06971 13 8.33333V2.33333C13 1.59695 12.403 1 11.6667 1Z" stroke="white" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>,
    borderRadius: '100%'
  },
  {
    color: '#2E86AB',
    text: 'Media',
    type: 'media',
    icon: <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M7.33496 11.5L11.665 9L7.33496 6.5V11.5Z" stroke="#fff" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path><path d="M5.24991 1.50007H12.7499C14.8166 1.50007 16.4916 3.17756 16.4916 5.24673V12.7467C16.4916 14.8134 14.8082 16.4884 12.7416 16.4884H5.23324C3.15824 16.4884 1.48657 14.8051 1.48657 12.7384V5.2334C1.48657 3.1584 3.16157 1.4834 5.23657 1.4834L5.24991 1.50007Z" stroke="#fff" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path></svg>,
    borderRadius: '100%'
  }
];

export default ACTIONS;
