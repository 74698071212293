import React from 'react';
import ReactDOM from 'react-dom';
import { useQuery } from 'react-apollo';
import { useParams } from 'react-router';
import { FileIcon, defaultStyles } from 'react-file-icon';
import CustomVideoRender from '../../components/Shared/CustomVideoRender';
import GeneralImage from '../../components/Shared/GeneralImage';
import { getBlockVideoSources } from '../../actions/lesson.actions';
import { getMediaById } from '../../apollo.queries';

const file_type_list = {
  bmp: 'image',
  gif: 'image',
  ico: 'image',
  jpeg: 'image',
  jpg: 'image',
  png: 'image',
  svg: 'image',
  tif: 'image',
  tiff: 'image',
  webp: 'image',
  webm: 'video',
  mpg: 'video',
  mp2: 'video',
  mpeg: 'video',
  mpe: 'video',
  mpv: 'video',
  ogg: 'video',
  mp4: 'video',
  m4p: 'video',
  m4v: 'video',
  avi: 'video',
  wmv: 'video',
  mov: 'video',
  qt: 'video',
  flv: 'video',
  swf: 'video',
  avchd: 'video',
'3gp': 'video'
};

const DirectMediaView = () => {
  const { id } = useParams();
  
  const Results_CacheOnly = id ? useQuery(
    getMediaById,
    { 
      variables: { id }, 
      fetchPolicy: "cache-only"
    }
  ) : {};

  const Results = id ? useQuery(
    getMediaById,
    { 
      variables: { id }, 
      fetchPolicy: "network-only" 
    }
  ) : {};

  const { url, label = '' } = (Results.data || {}).getMediaById || (Results_CacheOnly.data || {}).getMediaById  || {};
  const loading = Results.loading;

  if(loading && !url){
    return <div className="general-tag-loading-specific">
      <div className="appLoading">
        <svg
          className="simple-line-loader-for-app-loading css-primary-hex-stroke"
          viewBox="0 0 50 50"
        >
          <circle
            className="path css-primary-hex-stroke"
            cx="25"
            cy="25"
            r="20"
            fill="none"
            strokeWidth="5"
          ></circle>
        </svg>
      </div>
    </div>;
  };

  const base_split_url = url.split('.');
  const file_extension = base_split_url[
    base_split_url.length - 1
  ].toLowerCase();
  const file_type = file_type_list[file_extension] || 'other';

  const url_to_check = url.toLowerCase();
  const split_url = url_to_check.split(
    url_to_check.indexOf('processed-voiceover') > -1
      ? '/processed-voiceover/undefined/'
      : url_to_check.indexOf('dgpv43ylujmyh.cloudfront.net') > -1
      ? '/undefined/'
      : '/athletic-outlook/undefined/'
  );
  const base_key = `${split_url[split_url.length - 1]}`;
  const image_src = `undefined/${base_key}`;

  return ReactDOM.createPortal(
    <div className="outer-public-v2-container-container preview-specific single-lesson-specific">
      {(label.length > 0 || !url) && (
        <div className="single-lesson-title-and-back-top-left">
          <h1 className="hide-when-mobile-enacted" style={{ paddingLeft: "0px" }}>{!url ? "No file exists..." : label}</h1>
        </div>
      )}
      
      <div className="powered-by-to-bottom-right hide-when-mobile-enacted">
        <p>Powered by</p>
        <img src="/images/horiz_logo_small.png" />
      </div>

      <div className="single-media-immediate-container">
        {url && (file_type === 'other' ? (
          <FileIcon
            extension={file_extension}
            {...defaultStyles[file_extension]}
          />
        ) : file_type === 'image' ? (
          <GeneralImage imageResolution={'1200x'} originalKey={image_src} />
        ) : file_type === 'video' && (
          <CustomVideoRender
            blockSettings={{ controls: true }}
            {...getBlockVideoSources(url)}
          />
        ))}
      </div>
    </div>, document.getElementById('outsideModalPortal')
  );
};

export default DirectMediaView;
