import { combineReducers } from 'redux';

import websiteState from './website.reducer';
import paymentState from './payment.reducer';
import dashboardState from './dashboard.reducer';
import sessionsReducer from './sessions.reducer';
import formState from './form.reducer';
import lessonState from './lesson.reducer';
import voiceoverState from './voiceover.reducer';
import galleryState from './gallery.reducer';
import programState from './program.reducer';
import generalState from './general.reducer';
import oneOnOneState from './inbox.reducer';
import canvasState from './canvasManip.reducer';

const rootReducer = combineReducers({
  oneOnOneState,
  generalState,
  sessionsReducer,
  voiceoverState,
  websiteState,
  formState,
  lessonState,
  paymentState,
  dashboardState,
  programState,
  galleryState,
  canvasState
});

export default rootReducer;
