import React, { memo, useMemo, useCallback, useState } from 'react';
import { connect } from 'react-redux';
import CreateTagMapper from './GeneralTagsFeed/CreateTagMapper';
import ActionLoader from './GeneralTagsFeed/ActionLoader';
import DeleteConfirmation from './GeneralTagsFeed/DeleteConfirmation';
import MobileInitButton from './GeneralTagsFeed/MobileInitButton';
import AllAndUntaggedInit from './GeneralTagsFeed/AllAndUntaggedInit';
import UserBasedTagsMapper from './GeneralTagsFeed/UserBasedTagsMapper';
import ParentTagsMapper from './GeneralTagsFeed/ParentTagsMapper';
import RolesTagsRender from './GeneralTagsFeed/RolesTagsRender';
import { deactivateTagInit, updateGeneralState } from '../../actions/general.actions';

const GeneralTagsFeedRender = props => {
  const { refetch, db_categories = [], origin, assignSpecific, onSelect, onRemove, onUntagged, itemsSelected, deactivateTagInit, updateGeneralState, untaggedActive, activateRoles, activateUsers, profile, generalState, requester } = props;

  const {
    activeSidebarCategories,
    deactivatingTag,
    showUserOptions,
    mostRecentActive,
    upsertingTagConnection,
    removingTagConnection
  } = generalState;

  const { activeUserBasedTags = {}, activeRoleBasedTags = {} } = generalState;
  
  const [mobileInitialized, updateMobileInitialized] = useState();
  const [showDeleteConfirmation, updateShowDeleteConfirmation] = useState();
  
  const onDeleteConfirm = useCallback(
    () => {
      const deactivateTagInit_local = async () => {
        await deactivateTagInit(showDeleteConfirmation);

        updateShowDeleteConfirmation(false);

        refetch();
      };      
      
      deactivateTagInit_local();
    }, [showDeleteConfirmation]
  );
  

  const containerClassName = useMemo(() => `immediate-gallery-tags-container ${mobileInitialized && 'mobile-initialized'} ${assignSpecific && 'is-assign-specific'}`, [mobileInitialized, assignSpecific]);

  const onAllAndUntaggedInit = useCallback(
    () => updateGeneralState({
      untaggedActive: false,
      activeSidebarCategories: [],
      ...(activeSidebarCategories || []).reduce(
        (result, currentValue) => ({
          ...(result || {}),
          ...(generalState[`${currentValue.id}_children`] && {
            [`${currentValue.id}_children`]: undefined
          })
        }), {}
      )
    }), [generalState, activeSidebarCategories]
  );

  const onMainUserBasedClick = useCallback(
    profileId => {
      let prev_activeUserBasedTags = activeUserBasedTags;
      if(prev_activeUserBasedTags[profileId]){
        delete prev_activeUserBasedTags[profileId];
      } else {
        prev_activeUserBasedTags[profileId] = true;
      };

      updateGeneralState({ activeUserBasedTags:prev_activeUserBasedTags });
    }, [activeUserBasedTags]
  );

  const showUserOptionsInit = useCallback(
    () => updateGeneralState({
      activeUserBasedTags: {},
      showUserOptions: !showUserOptions
    }), [showUserOptions]
  );

  const onMostRecentInit = useCallback(
    () => updateGeneralState({ mostRecentActive: !mostRecentActive }),
    [mostRecentActive]
  );

  const onMainRolesClick = useCallback(
    id => {
      let prev_activeRoleBasedTags = activeRoleBasedTags;
      if (prev_activeRoleBasedTags[id]) {
        delete prev_activeRoleBasedTags[id];
      } else {
        prev_activeRoleBasedTags[id] = true;
      };

      updateGeneralState({ activeRoleBasedTags: prev_activeRoleBasedTags });
    }, [activeRoleBasedTags]
  );

  const onTagDeleteClick = useCallback(
    (categoryLocal, isParent) =>
      updateShowDeleteConfirmation({
        origin,
        label: categoryLocal.label,
        isParent
      })
    , [origin]
  );

  const isChildActive = useCallback(
    categoryLocalChildId => itemsSelected.find(item => item.id === categoryLocalChildId) ? true : false, 
    [itemsSelected]
  );


  const onParentTagClick = (categoryLocal, is_active) => (
    categoryLocal.id
      ? itemsSelected && (is_active ? onRemove(categoryLocal) : onSelect(categoryLocal))
      : onUntagged()
  );

  const onChildTagClick = categoryLocalChild => (
    categoryLocalChild.id
      ? itemsSelected && (
          isChildActive(categoryLocalChild.id) 
            ? onRemove(categoryLocalChild) 
            : onSelect(categoryLocalChild)
        )
      : onUntagged()
  );

  return (
    <div className={containerClassName}>
      <MobileInitButton
        toggleMobileInit={() => updateMobileInitialized(!mobileInitialized)}
        mobileInitialized={mobileInitialized}
      />
      
      <div className="tag-horizontal-separator mobile-specific"></div>
      <div className="to-hide-on-mobile-dynamically">
        <AllAndUntaggedInit
          noDbCats={db_categories.length === 0}
          assignSpecific={assignSpecific}
          onUntagged={onUntagged}
          untaggedActive={untaggedActive}
          itemsSelectedConditional={!itemsSelected || itemsSelected.length === 0}
          onMainClick={onAllAndUntaggedInit}
        />

        <UserBasedTagsMapper
          onMainClick={onMainUserBasedClick}
          showUserOptionsInit={showUserOptionsInit}
          profile={profile}
          requester={requester}
          assignSpecific={assignSpecific}
          showUserOptions={showUserOptions}
          activateUsers={activateUsers}
        />
        
        <RolesTagsRender
          key={`roles-tags-render-${Object.keys(activeRoleBasedTags).join('-')}`}
          origin={origin}
          activateRoles={activateRoles}
          mostRecentActive={mostRecentActive}
          activeRoleBasedTags={activeRoleBasedTags}
          onMostRecentInit={onMostRecentInit}
          onMainClick={onMainRolesClick}
        />

        <div className="tag-horizontal-separator"></div>

        <ParentTagsMapper
          origin={origin}
          itemsSelected={itemsSelected}
          untaggedActive={untaggedActive}
          assignSpecific={assignSpecific}
          categories_to_iterate={db_categories}
          onParentTagClick={onParentTagClick}
          onTagDeleteClick={onTagDeleteClick}
          onChildTagClick={onChildTagClick}
          onChildDeleteInit={updateShowDeleteConfirmation}
        />
      </div>
      
      <CreateTagMapper refetch={refetch} origin={origin} assignSpecific={assignSpecific} />

      <ActionLoader deactivatingTag={deactivatingTag} assignSpecific={assignSpecific} upsertingTagConnection={upsertingTagConnection} removingTagConnection={removingTagConnection} />

      <DeleteConfirmation deactivatingTag={deactivatingTag} showDeleteConfirmation={showDeleteConfirmation} onDeleteConfirm={onDeleteConfirm} updateShowDeleteConfirmation={updateShowDeleteConfirmation} />
    </div>
  );
};

const mapDispatchToProps = dispatch => ({
  deactivateTagInit: variables => dispatch(deactivateTagInit(variables)),
  updateGeneralState: variables => dispatch(updateGeneralState(variables))
});

export default connect(null, mapDispatchToProps)(memo(GeneralTagsFeedRender));