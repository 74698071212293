import ACTIONS from '../constants/actions';

export default (state = {}, action) => {
  switch (action.type) {
    case ACTIONS.RESET_STATE:
      return {
        ...state
      };

    case ACTIONS.UPDATE_STATE:
      return {
        ...state,
        ...action.payload
      };

    default:
      return state;
  }
};
