import React, { memo, useState, useMemo } from 'react';
import ReactDOM from 'react-dom';
import NewUser from './ActionCenter/NewUser';
import NavigateTo from '../../../../components/Shared/NavigateTo';
import MenuItems from './ActionCenter/MenuItems';
import { tenantPrefixUrlDeterminant } from '../../../../actions/user.actions';

const Dashboard = props => {
  const { requester } = props;
  const [newUserPop, updateNewUserPop] = useState();
  const [showQuickActionsMenu, updateShowQuickActionsMenu] = useState();
  const [newUserCreated, updateNewUserCreated] = useState();

  const menuPrefix = useMemo(
    () => `${tenantPrefixUrlDeterminant(
      requester.activeGroup.tenant.code,
      requester.activeGroup.code
    )}/admin`, [requester.activeGroup]
  );

  return (
    <div className={`quick-actions-menu-container ${showQuickActionsMenu && 'menu-is-active'}`}>
      <button className="init-quick-actions-menu" onClick={() => updateShowQuickActionsMenu(!showQuickActionsMenu)}>
        <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M13 25C6.36 25 1 19.628 1 13C1 6.36 6.372 1 13 1C19.628 1 25 6.372 25 13C25 19.628 19.628 25 13 25Z" fill="#F9B016" stroke="#F9B016" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
          <path d="M13 7.6665V18.3332" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
          <path d="M18.3334 13H7.66675" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>
      </button>

      <MenuItems initNewUserPop={() => updateNewUserPop(true)} updateShowQuickActionsMenu={updateShowQuickActionsMenu} showQuickActionsMenu={showQuickActionsMenu} menuPrefix={menuPrefix} />            

      {
        newUserPop && ReactDOM.createPortal(
          <div className="outer-container-of-program-addition-pop-up new-user-specific">
            <NewUser
              goBack={() => updateNewUserPop(false)}              
              newUserCreated={() => updateNewUserCreated(true)}
            />
            {newUserCreated && <NavigateTo toPath={`${menuPrefix}/people`} />}
          </div>,
          document.getElementById('outsideModalPortal-z2')
        )
      }   
    </div>
  );
};

export default memo(Dashboard);
