import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { isPossiblePhoneNumber } from 'react-phone-number-input';
import BlockViewerRouting from './Builder/Blocks/Routing/BlockViewerRouting';
import BlockIconRef from './Builder/Constants/blockIconRef';
import FormProductRender from './Builder/Accessories/FormProductRender';
import FormSubmit from './FormSubmit';
import GeneralLoader from '../../../../Shared/GeneralLoader';
import { validEmail } from '../../../../../helper';
import apolloClient from '../../../../../apollo.client';
import { updateFormState } from '../../../../../actions/form.actions';
import { upsertFormBlockResponses } from '../../../../../apollo.mutations';

class FormViewer extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {};

    this.formBlockGuestId = `${new Date().getTime().toString(36) + Math.random().toString(36).slice(5)}`;
  }

  componentDidMount(){
    const { form, formState, updateFormState } = this.props;
    const { blocks = [] } = form;
    const { formResponses = {} } = formState;

    if((blocks || []).length > 0){
      updateFormState({
        formResponses: {
          ...formResponses,
          ...(blocks || []).reduce(
            (results, block) => {
              const { response = {}, type } = block;
              const { mediaResponse, textResponse = '', choiceResponses = [] } = response || {};

              return {
                ...results,
                ...!(this.props.formState.formResponses || {})[block.id] && {
                  [block.id]: {
                    ...mediaResponse
                      ? { media: mediaResponse }
                      : ['checkbox_default', 'checkbox_input'].indexOf(type) > -1 
                        ? {
                            choiceIds: choiceResponses.reduce(
                              (results, { formBlockChoiceId }) => ({
                                ...results,
                                [formBlockChoiceId]: true
                              }), {}
                            )
                          }
                        : ['country_input', 'radio_default', 'radio_input', 'select_input'].indexOf(type) > -1    
                          ? { choiceId: (choiceResponses[0] || {}).formBlockChoiceId || null }
                          : { text: textResponse || '' }
                  }
                }
              };
            }, {}
          )          
        }              
      });
    }
  }

  firstPageConditional = async () => {
    const { form = {}, formState, previewSpecific, updateFormState } = this.props;
    const { formResponses = {} } = formState;
    const { settings = {} } = form;
    const { atp = [] } = settings || {};

    const atpToBlocks = atp.map(atpType => ({ id: `forced-atp-${atpType}`, required: 1, ...atpType_REF[atpType] }));

    const reducedRequired = atpToBlocks.reduce(
      (results, { id, required, type }) => {
        const {
          media,
          country = {},
          text = '',          
          choiceId = '',
          choiceIds = {}
        } = formResponses[id] || {};

        const filteredChoices = Object.values(choiceIds).filter(answer => answer);
        let responseStillNeeded = required && ['media_default','heading_default','description_default','number_heading_default'].indexOf(type) === -1 && !country.label && !((media || {}).id || (media || {}).size) && (text || '').length === 0 && (choiceId || '').length === 0 && filteredChoices.length === 0;

        if(!responseStillNeeded && ['athlete_email_default', 'parent_email_default'].indexOf(type) > -1 && !validEmail(text)){
          responseStillNeeded = true;
        };

        if(!responseStillNeeded && ['athlete_tel_default', 'parent_tel_default'].indexOf(type) > -1 && !isPossiblePhoneNumber(text)){
          responseStillNeeded = true;
        };

        return {
          ...results,
          ...responseStillNeeded && { [id]: true }
        };
      }, {}
    );

    if(Object.keys(reducedRequired).length > 0){
      this.setState({ reducedRequired, formSubmitting: false });

      return;
    };

    if(previewSpecific){
      this.setState({ firstPageSuccess: true });
    } else {
      const response = (
        await apolloClient.mutate({
          mutation: upsertFormBlockResponses,
          variables: { 
            input: { 
              formBlocks: (atpToBlocks || []).map(({ id }) => {
                const { text = '' } = formResponses[id] || {};

                return { 
                  formBlockId: id,
                  formBlockResponseText: text
                };
              }), 
              formBlockGuestId: this.formBlockGuestId, 
              atpSpecific: form.id 
            } 
          }
        })
      ).data.upsertFormBlockResponses;

      if(response === 'error'){
        this.setState({ errorLocal: "The phone number used is already connected to an active user with a different email. Please use the email associated with that account, or use another number to continue." });

        return;
      };

      let tempRef = formResponses || {};

      delete tempRef['forced-atp-f'];
      delete tempRef['forced-atp-l'];
      delete tempRef['forced-atp-e'];
      delete tempRef['forced-atp-p'];

      updateFormState({ formResponses: tempRef });

      this.setState({ formSubmitting: false, firstPageSuccess: response });
    };
  }

  render() {
    const { form = {}, previewSpecific, profile, hideTitle, responseId_forcePayment } = this.props;
    const { firstPageSuccess, secondPageSuccess, reducedRequired = {}, errorLocal, formSubmitting } = this.state;
    const { label = 'Form Name', settings = {}, blocks = [] } = form;
    const { buttonColor = "#000", buttonTextColor = '#fff', theme = 'light', atp, pid, url = '', destination = 'thank_you' } = settings || {};

    const requiredCount = Object.values(reducedRequired).filter(exists => exists).length > 0;

    return <>
      <div className={`form-viewer ${theme || 'light'}`}>
        {!hideTitle && !responseId_forcePayment && ((atp && !firstPageSuccess && !profile) || (!secondPageSuccess || !pid)) && <h2 className="form-title">{label || "Form Name"}</h2>}
        {(!responseId_forcePayment && atp && !firstPageSuccess && !profile)
          ? <div className="auth-page-containment-viewer">
              <div className="immediate-form-items-iterate">
                {atp.sort((a, b) => atpType_REF[a].relativeIndex - atpType_REF[b].relativeIndex).map((atpType) =>
                  <BlockViewerRouting 
                    key={`atp-fixed-input-${atpType}`}
                    block={{ id: `forced-atp-${atpType}`, required: 1, ...atpType_REF[atpType] }} 
                  /> 
                )}
              </div>
              {errorLocal ? <p className="required-needed">{errorLocal}</p> : requiredCount && <p className="required-needed">Some fields require a response before a submission is allowed.</p>}      
              <div 
                className="button-manip-at-bottom" 
                onClick={this.firstPageConditional}
                style={{ backgroundColor: buttonColor, color: buttonTextColor }}
              >Next</div> 
              {formSubmitting && <GeneralLoader />}
            </div>
          : (!responseId_forcePayment && (!secondPageSuccess || !pid)) ? (
            <div className="main-form-page-containment-viewer">
              {
                <div className="immediate-form-items-iterate">
                  {blocks.map((localBlock) =>             
                    <BlockViewerRouting
                      key={`block_general_ref_${localBlock.id}`}                      
                      block={{
                        ...BlockIconRef[localBlock.type] || {},
                        ...localBlock
                      }}
                      updatePage={() => null}
                    />   
                  )}
                </div>
              }               
              <FormSubmit 
                form={form} 
                previewSpecific={previewSpecific} 
                firstPageSuccess={firstPageSuccess} 
                formBlockGuestId={this.formBlockGuestId} 
                onSecondPageSuccess={v => this.setState({ secondPageSuccess: v })} 
              />     
            </div>
          ) : (
            <div className="main-form-page-containment-viewer">
              <div className="immediate-form-items-iterate">
                <FormProductRender
                  id={pid} 
                  formId={form.id}
                  activeGroup={form.group}
                  destination={destination}
                  destinationUrl={url}
                  buttonColor={buttonColor} 
                  buttonTextColor={buttonTextColor} 
                  firstPageSuccess={firstPageSuccess}
                  secondPageSuccess={responseId_forcePayment || secondPageSuccess}
                  formBlockGuestId={this.formBlockGuestId}
                  lightTheme={(theme || 'light') === 'light'}
                />            
              </div>
            </div>
          )   
        }           
      </div>
    </>;
  }
};

const atpType_REF = {
  e: {
    relativeIndex: 3,
    label: "Email",
    type: "athlete_email_default",
  },
  f: {
    relativeIndex: 1,
    label: "First",
    type: "athlete_first_name_default"
  },
  l: {
    relativeIndex: 2,
    label: "Last",
    type: "athlete_last_name_default"
  },
  p: {
    relativeIndex: 4,
    label: "Phone",
    type: "athlete_tel_default"
  }
};

const mapStateToProps = state => ({
  formState: state.formState
});

const mapDispatchToProps = dispatch => ({
  updateFormState: updatedStates => dispatch(updateFormState(updatedStates))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(FormViewer);
