import React, { memo, useEffect, useMemo } from 'react';
import { useQuery } from 'react-apollo';
import { useIntercom } from 'react-use-intercom';
import { CurrentUserQuery_UserInfo } from '../../../../apollo.queries';

const IntercomUpdate = () => {
    const { update } = useIntercom();

    const result = useQuery(CurrentUserQuery_UserInfo, { fetchPolicy: "network-only" });
    const cachedResult = useQuery(CurrentUserQuery_UserInfo, { fetchPolicy: "cache-only" });

    const { user, loading } = useMemo(() => ({ user: (result.data || {}).user || (cachedResult.data || {}).user, loading: result.loading }), [result.data, cachedResult.data, result.loading]);

    useEffect(() => {
        if(user){
            const { firstName, lastName, email, phone } = user;

            update({
                name: firstName || lastName ? `${firstName} ${lastName}` : `No name`,
                email,
                phone
            });
        };
    }, [user]);

    if(!user && loading) return <></>;

    return <></>;
};

export default memo(IntercomUpdate);
