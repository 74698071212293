import React, { memo } from 'react';
import TextInput from '../../../../../../Shared/TextInput';

const SearchArea = props => {
  const { searchInput, updateGeneralState } = props;

  return (
    <div className="search-input">
      <div className="icon-to-left">
        <svg
          width="18"
          height="18"
          viewBox="0 0 18 18"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M8.05012 1.00023C6.17902 1.00023 4.38455 1.74353 3.06148 3.0666C1.73841 4.38966 0.995117 6.18413 0.995117 8.05523C0.995117 9.92634 1.73841 11.7208 3.06148 13.0439C4.38455 14.3669 6.17902 15.1102 8.05012 15.1102C9.92255 15.1102 11.7183 14.3664 13.0423 13.0424C14.3663 11.7184 15.1101 9.92266 15.1101 8.05023C15.1101 6.17781 14.3663 4.38207 13.0423 3.05806C11.7183 1.73405 9.92255 0.990234 8.05012 0.990234V1.00023Z"
            stroke="#666666"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M17 17.0003L13.05 13.0503"
            stroke="#666666"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      </div>
      <TextInput
        value={searchInput}
        placeholder={'Search'}
        onChange={value => updateGeneralState({ searchInput: value })}
      />
    </div>
  );
};

export default memo(SearchArea);
