import React, { PureComponent} from 'react';
import { ApolloConsumer } from 'react-apollo';
import replaceall from 'replaceall';
import { ProfileContext } from '../../context/profile.context';
import { RequesterContext } from '../../context/requester.context';
import Main from '../../components/Public/Settings/Main';
import Sidebar from '../../components/Public/Settings/Sidebar';

const hostName = window.location.hostname.toLowerCase();
const hostNameSlugToUse = replaceall('.', '-', hostName);
const tokenName = `${hostNameSlugToUse}-auth-token-v3-12-13-21`;

class Settings extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {};
  }

  render() {
    const { mobileActivated = true } = this.state;

    return (
      <RequesterContext.Consumer>
        {requester => (
          <ProfileContext.Consumer>
            {profile => (
              <section className="settings-page-specific">
                <div
                  id="complete-inner-main-admin-content"
                  className={`settings-specific  ${mobileActivated &&
                    'mobile-is-activate'}`}
                >
                  <div id="admin-content-inner-sidebar">
                    <h1
                      className="page-title"
                      onClick={() =>
                        this.setState({ mobileActivated: !mobileActivated })
                      }
                    >
                      Settings
                      <svg
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M8 10L12 14L16 10"
                          stroke="#666666"
                          strokeWidth="1.5"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        ></path>
                      </svg>
                    </h1>
                    <ApolloConsumer>
                      {client => (
                        <Sidebar
                          logOut={() => {
                            localStorage.removeItem(tokenName);
                            client.resetStore();
                            window.location = '/login';
                          }}
                          profile={profile}
                          requester={requester}
                        />
                      )}
                    </ApolloConsumer>
                  </div>

                  <div id="admin-content-inner-content-with-sidebar">
                    <Main profile={profile} requester={requester} />
                  </div>
                </div>
              </section>
            )}
          </ProfileContext.Consumer>
        )}
      </RequesterContext.Consumer>
    );
  }
};

export default Settings;
