import React, { memo } from 'react';
import Loadable from 'react-loadable';
import { Route, Routes, Navigate } from 'react-router-dom';
import LogoLoader from '../../components/Shared/LogoLoader';

const Terms = Loadable({
  loader: () => import('../../containers/Legal/Terms'),
  loading: () => <LogoLoader />
});

const Privacy = Loadable({
  loader: () => import('../../containers/Legal/Privacy'),
  loading: () => <LogoLoader />
});

const PublicAuth = Loadable({
  loader: () => import('../../containers/Public/Auth'),
  loading: () => <LogoLoader />
});

const PublicParentMapper = Loadable({
  loader: () => import('../../containers/Public/ParentMapper'),
  loading: () => <LogoLoader />
});

const PublicProgramViewer = Loadable({
  loader: () => import('../../containers/Public/Programs/Single'),
  loading: () => <LogoLoader />
});

const PublicAccessCodeViewer = Loadable({
  loader: () => import('../../containers/Public/Packages/AccessCode'),
  loading: () => <LogoLoader />
});

const PublicPackageViewer = Loadable({
  loader: () => import('../../containers/Public/Packages/Single'),
  loading: () => <LogoLoader />
});

const guestRoutes = () => (
  <Routes>
    <Route exact path="terms" element={<Terms />} />
    <Route exact path="privacy" element={<Privacy />} />
    <Route exact path={`login/forgot/:code`} element={<PublicAuth />} />
    <Route exact path={`forgot/:code`} element={<PublicAuth />} />
    
    <Route
      exact
      path={`access-code`}
      element={<PublicAccessCodeViewer />}
    />

    <Route
      exact
      path={`programs/:programId`}
      element={<PublicProgramViewer />}
    />

    <Route
      exact
      path={`products/:packageId`}
      element={<PublicPackageViewer />}
    />

    <Route
      exact
      path={`packages/:packageId`}
      element={<PublicPackageViewer />}
    />

    <Route
      exact
      path={`join/:packageId`}
      element={<PublicPackageViewer />}
    />

    <Route exact path={`*`} element={<PublicParentMapper />} />

    <Route
      path="*"
      element={<Navigate replace={true} to={`programs`} />}
    />
  </Routes>
);

export default memo(guestRoutes);