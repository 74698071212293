import ACTIONS from '../constants/program/actions';

const initialState = {};

export default (state = initialState, action) => {
  switch (action.type) {
    case ACTIONS.RESET_STATE:
      return initialState;

    case ACTIONS.UPDATE_STATE:
      return {
        ...state,
        ...action.payload
      };

    case ACTIONS.UNDO_STATE: {
      let prevStates = state.previousStates;
      const newPresent = prevStates[prevStates.length - 2];
      prevStates.splice(prevStates.length - 2, 1);
      return {
        ...state,
        ...newPresent,
        previousStates: prevStates,
        futureStates: [Object.assign({}, state, {}), ...state.futureStates]
      };
    }

    case ACTIONS.REDO_STATE:
      return {
        ...state,
        ...state.futureStates[0],
        previousStates: [...state.previousStates, state],
        futureStates: state.futureStates.slice(1)
      };

    default:
      return state;
  }
};
