import React, { memo, useCallback, useEffect, useMemo } from 'react';
import { useQuery } from 'react-apollo';
import replaceall from 'replaceall';
import LogoLoader from '../components/Shared/LogoLoader';
import LoggedInRouter from './LoggedInRouter';
import NotLoggedInRouter from './NotLoggedInRouter';
import { fallbackPage } from '../actions/website.actions';
import { ProfileContext } from '../context/profile.context';
import { CurrentUserQuery } from '../apollo.queries';

const hostName = window.location.hostname.toLowerCase();
const hostNameSlugToUse = replaceall('.', '-', hostName);

let hidden = null;
let visibilityChange = null;
if (typeof document.hidden !== 'undefined') { // Opera 12.10 and Firefox 18 and later support 
  hidden = 'hidden';
  visibilityChange = 'visibilitychange';
} else if (typeof document.msHidden !== 'undefined') {
  hidden = 'msHidden';
  visibilityChange = 'msvisibilitychange';
} else if (typeof document.webkitHidden !== 'undefined') {
  hidden = 'webkitHidden';
  visibilityChange = 'webkitvisibilitychange';
};

const AuthRouter = ({ requesterOrg }) => {
  const cache_results = useQuery(CurrentUserQuery, { fetchPolicy: "cache-only" }),
        network_results = useQuery(CurrentUserQuery, { fetchPolicy: "network-only" }),
        profile = network_results?.data?.profile || cache_results?.data?.profile || {},
        loading = network_results?.loading,

        activeSlug = useMemo(() => {
          let split_pathName = window.location.pathname.toLowerCase().split('/');
            
          return split_pathName.pop().replace('/', '');
        }, [window.location.pathname]),

        { theme = 'light' } = useMemo(() => {
          const activePage = (requesterOrg.activeGroup.pages || []).find(
            ({ type }) => type === activeSlug
          ) || fallbackPage(activeSlug);

          const activePage_Prepped = activePage
            ? {
                ...activePage,
                settings: (activePage.settings && JSON.parse(activePage.settings)) || {}
              }
            : {};

          return activePage_Prepped.settings || {};
        }, [requesterOrg.activeGroup.pages]),

  handleVisibilityChange = useCallback(() => {
    if(!document[hidden]){
      localStorage.setItem(`${hostNameSlugToUse}-last-active-group-prefix-v5`, `/${requesterOrg.activeGroup.tenant.code}/${requesterOrg.activeGroup.code}`)
  
      localStorage.setItem('active_theme', theme)
    }
  }, [hostNameSlugToUse, theme, requesterOrg.activeGroup.code, requesterOrg.activeGroup.tenant.code])

  useEffect(() => {
    localStorage.setItem('active_theme', theme);    

    document.addEventListener(visibilityChange, handleVisibilityChange, false);

    return () => {
      document.removeEventListener(visibilityChange, handleVisibilityChange, false);
    }
  }, [])

  return (
    loading
      ? <div className={`${theme} general-furthest-out-loader-container`}>
          <LogoLoader tenant={requesterOrg.activeGroup.tenant} />
        </div>
      : profile?.id ? (
        <React.Fragment><LoggedInRouter key={`logged-in-router${requesterOrg.activeGroup.id}=${profile.id}`} theme={theme} profile={profile} requesterOrg={requesterOrg} /></React.Fragment>
      ) : (
        <ProfileContext.Provider value={false}>
          <React.Fragment><NotLoggedInRouter key={`not-logged-in-router${requesterOrg.activeGroup.id}`} requesterOrg={requesterOrg} /></React.Fragment>
        </ProfileContext.Provider>
      )
  )
}

export default memo(AuthRouter);