import React, { memo, useCallback, useMemo } from 'react';
import { connect } from 'react-redux';
import { useQuery } from 'react-apollo';
import NavigationContainer from '../../../../../components/Shared/NavigationContainer';
import { updateGeneralState } from '../../../../../actions/general.actions';
import { updateWebsiteState } from '../../../../../actions/website.actions';
import { getStripeAccount } from '../../../../../apollo.queries';
import { isMobile } from 'react-device-detect';

const MenuItems = props => {
  const { initNewUserPop, menuPrefix, updateWebsiteState, showQuickActionsMenu, updateShowQuickActionsMenu, updateGeneralState } = props;

  const { data = {} } = useQuery(getStripeAccount, { fetchPolicy: "network-only" });

  const stripeAccountExists = data.getStripeAccount && data.getStripeAccount.email;

  const menuArray = useMemo(
    () => [
      {
        label: "New user",
        icon: <svg width="18" height="20" viewBox="0 0 18 20" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M9 10.0002C6.53 10.0002 4.51 7.98023 4.51 5.51023C4.51 3.04223 6.526 0.990234 8.99 0.990234C11.45 0.990234 13.47 3.00023 13.47 5.47023C13.47 7.93823 11.454 9.98023 8.98 9.98023L9 10.0002ZM16 19.0002H2C1.45 19.0002 1 18.5502 1 18.0002V17.0002C1 14.8002 2.8 13.0002 5 13.0002H13C15.2 13.0002 17 14.8002 17 17.0002V18.0002C17 18.5502 16.55 19.0002 16 19.0002Z" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/></svg>,                                    
        toPath: null,
        afterNavigate: () => {
          initNewUserPop();
          updateShowQuickActionsMenu(false);
        }
      },
      {
        label: "Upload media",
        icon: <svg width="16" height="18" viewBox="0 0 16 18" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M12.0417 6.21096C13.6632 7.12305 14.4739 7.57909 14.744 8.17834C14.9794 8.70082 14.9794 9.29918 14.744 9.82166C14.4739 10.4209 13.6632 10.877 12.0417 11.789L5.76883 15.3175C4.2012 16.1993 3.41738 16.6402 2.77534 16.5676C2.21522 16.5043 1.70782 16.2076 1.37802 15.7504C0.999999 15.2264 0.999999 14.3271 0.999999 12.5285V5.47151C0.999999 3.67288 0.999999 2.77357 1.37802 2.24957C1.70782 1.79242 2.21522 1.49567 2.77534 1.43235C3.41738 1.35977 4.2012 1.80067 5.76884 2.68247L12.0417 6.21096Z" stroke="#131A29" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/></svg>,
        toPath: `${menuPrefix}/media`,
        afterNavigate: () => {
          updateGeneralState({ addMediaPop: true });
          updateShowQuickActionsMenu(false);
        }
      },
      ...isMobile ? [
        {
          label: "Settings",
          icon: <svg width="20" height="19" viewBox="0 0 20 19" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M18.0886 1.62121C17.2986 0.831209 16.0486 0.791209 15.2086 1.52121L7.16758 8.55321C6.28758 9.31321 6.24758 10.6532 7.06758 11.4632L8.20758 12.6032C9.01758 13.4132 10.3576 13.3732 11.1176 12.5032L18.1496 4.46321C18.8696 3.62321 18.8296 2.37321 18.0496 1.59321L18.0886 1.62121Z" stroke="black" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/><path d="M8.73755 7.19922L12.5175 10.9792" stroke="black" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/><path d="M1.45765 17.6523H5.15765C8.13765 17.6523 9.63765 14.0423 7.51765 11.9323C5.95765 10.3723 3.32965 10.9423 2.55965 13.0023L1.02965 17.0523C0.919651 17.3323 1.12965 17.6323 1.42965 17.6323L1.45765 17.6523Z" stroke="black" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/></svg>,
          toPath: `${menuPrefix}/settings`
        }
      ] : [
        {
          label: "Edit website",
          icon: <svg width="20" height="19" viewBox="0 0 20 19" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M18.0886 1.62121C17.2986 0.831209 16.0486 0.791209 15.2086 1.52121L7.16758 8.55321C6.28758 9.31321 6.24758 10.6532 7.06758 11.4632L8.20758 12.6032C9.01758 13.4132 10.3576 13.3732 11.1176 12.5032L18.1496 4.46321C18.8696 3.62321 18.8296 2.37321 18.0496 1.59321L18.0886 1.62121Z" stroke="black" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/><path d="M8.73755 7.19922L12.5175 10.9792" stroke="black" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/><path d="M1.45765 17.6523H5.15765C8.13765 17.6523 9.63765 14.0423 7.51765 11.9323C5.95765 10.3723 3.32965 10.9423 2.55965 13.0023L1.02965 17.0523C0.919651 17.3323 1.12965 17.6323 1.42965 17.6323L1.45765 17.6523Z" stroke="black" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/></svg>,
          toPath: `${menuPrefix}/website`,
          afterNavigate: () => {
            updateWebsiteState({ showWebsiteEdit: true });
            updateShowQuickActionsMenu(false);
          }
        },
        {
          label: "Create a program",
          icon: <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M14.5001 12.4004H13.3001C12.6374 12.4004 12.1001 12.9376 12.1001 13.6004V14.8004C12.1001 15.4631 12.6374 16.0004 13.3001 16.0004H14.5001C15.1628 16.0004 15.7001 15.4631 15.7001 14.8004V13.6004C15.7001 12.9376 15.1628 12.4004 14.5001 12.4004Z" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
          <path d="M6.70005 12.4004H5.50005C4.83731 12.4004 4.30005 12.9376 4.30005 13.6004V14.8004C4.30005 15.4631 4.83731 16.0004 5.50005 16.0004H6.70005C7.36279 16.0004 7.90005 15.4631 7.90005 14.8004V13.6004C7.90005 12.9376 7.36279 12.4004 6.70005 12.4004Z" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
          <path d="M13.9001 12.4V11.2C13.9001 10.53 13.3601 10 12.7001 10H7.30009H7.29009C6.62009 10 6.09009 10.53 6.09009 11.2V12.4" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
          <path d="M15 1H5C2.79086 1 1 2.79086 1 5V15C1 17.2091 2.79086 19 5 19H15C17.2091 19 19 17.2091 19 15V5C19 2.79086 17.2091 1 15 1Z" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
          <path d="M10 9.99961V7.59961" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
          <path d="M10.6 4H9.39995C8.73721 4 8.19995 4.53726 8.19995 5.2V6.4C8.19995 7.06274 8.73721 7.6 9.39995 7.6H10.6C11.2627 7.6 11.8 7.06274 11.8 6.4V5.2C11.8 4.53726 11.2627 4 10.6 4Z" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
          </svg>,
          toPath: `${menuPrefix}/programs/builder/new`,
          afterNavigate: () => updateShowQuickActionsMenu(false)
        },
        {
          label: "Create a video",
          icon: <svg width="20" height="14" viewBox="0 0 20 14" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M14 5L16.5768 3.45392C17.3699 2.97803 17.7665 2.74009 18.0928 2.77051C18.3773 2.79703 18.6369 2.944 18.806 3.17433C19 3.43848 19 3.90095 19 4.8259V9.1741C19 10.099 19 10.5615 18.806 10.8257C18.6369 11.056 18.3773 11.203 18.0928 11.2295C17.7665 11.2599 17.3699 11.022 16.5768 10.5461L14 9M4.2 13H10.8C11.9201 13 12.4802 13 12.908 12.782C13.2843 12.5903 13.5903 12.2843 13.782 11.908C14 11.4802 14 10.9201 14 9.8V3.4C14 2.55992 14 2.13988 13.8365 1.81901C13.6927 1.53677 13.4632 1.3073 13.181 1.16349C12.8601 1 12.4401 1 11.6 1H3.4C2.55992 1 2.13988 1 1.81901 1.16349C1.53677 1.3073 1.3073 1.53677 1.16349 1.81901C1 2.13988 1 2.55992 1 3.4V9.8C1 10.9201 1 11.4802 1.21799 11.908C1.40973 12.2843 1.71569 12.5903 2.09202 12.782C2.51984 13 3.07989 13 4.2 13Z" stroke="#131A29" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/></svg>,
          toPath: `${menuPrefix}/video/create`,
          afterNavigate: () => updateShowQuickActionsMenu(false)
        },
        ...stripeAccountExists
          ? [
              {
                label: "Create a product",
                icon: <svg width="20" height="16" viewBox="0 0 20 16" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M10.001 10.625V11.5" stroke="#323232" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/><path d="M8.48291 9.99984C8.70291 10.3698 9.09291 10.6198 9.56291 10.6198H9.99291H10.5129C11.1829 10.6198 11.7329 10.0698 11.7329 9.38984C11.7329 8.81984 11.3429 8.32984 10.8029 8.18984L9.16291 7.76984C8.61291 7.62984 8.23291 7.13984 8.23291 6.56984C8.23291 5.88984 8.78291 5.33984 9.45291 5.33984H9.97291H10.4029C10.8629 5.33984 11.2529 5.58984 11.4829 5.96484" stroke="#323232" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/><path d="M17.8 14.2128C15.29 13.7028 12.711 13.7728 10.233 14.3928L9.99298 14.4528C7.35298 15.1028 4.61198 15.1728 1.94998 14.6428L1.78998 14.6028C1.31998 14.5028 0.97998 14.0928 0.97998 13.6228V2.77984C0.97998 2.13984 1.55698 1.66984 2.16998 1.78984C4.67498 2.28984 7.25898 2.21984 9.72998 1.60984L10.2 1.48984C12.67 0.869841 15.26 0.808841 17.76 1.30984L18.15 1.37984C18.61 1.46984 18.95 1.87984 18.95 2.35984V13.1898C18.95 13.8198 18.37 14.2898 17.75 14.1698L17.8 14.2128Z" stroke="#323232" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/></svg>,
                toPath: `${menuPrefix}/products/builder/new`,
                beforeNavigate: () => updateWebsiteState({
                  activePackage: {
                    title: '',
                    stripeAccountExists: true,
                    features: { included: [] }
                  }
                }),
                afterNavigate: () => updateShowQuickActionsMenu(false)
              }
            ]
          : [],
        {
          label: "Create a form",
          icon: <svg width="19" height="18" viewBox="0 0 19 18" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M7.7501 14H3.16677L3.15843 13.9992C2.23343 13.9908 1.49177 13.2492 1.49177 12.3242V3.1575C1.48343 2.2325 2.23343 1.4825 3.1501 1.4825H12.3168H12.3084C13.2251 1.47417 13.9751 2.22417 13.9751 3.14083V7.72417" stroke="#666666" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path><path d="M7.75 1.5V14" stroke="#666666" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path><path d="M14.2442 16.2558L17.0859 13.4058H17.0776C17.4026 13.0725 17.4026 12.5475 17.0776 12.2225L15.7526 10.8975C15.4192 10.5642 14.8942 10.5642 14.5692 10.8892L11.7192 13.7308V13.7225C11.5609 13.8725 11.4692 14.0892 11.4692 14.3058V16.4558H13.6192V16.455C13.8359 16.4467 14.0442 16.3633 14.2026 16.205L14.2442 16.2558Z" stroke="#666666" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path></svg>,
          toPath: `${menuPrefix}/forms/builder/new`,
          afterNavigate: () => updateShowQuickActionsMenu(false)
        },
        {
          label: "Create a coupon",
          icon: <svg width="20" height="14" viewBox="0 0 20 14" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M3 7.00019C3 5.69397 2.16519 4.58273 1 4.1709V2.6C1 2.03995 1 1.75992 1.10899 1.54601C1.20487 1.35785 1.35785 1.20487 1.54601 1.10899C1.75992 1 2.03995 1 2.6 1H17.4C17.9601 1 18.2401 1 18.454 1.10899C18.6422 1.20487 18.7951 1.35785 18.891 1.54601C19 1.75992 19 2.03995 19 2.6V4.17071C17.8348 4.58254 17 5.69397 17 7.00019C17 8.30641 17.8348 9.41746 19 9.82929V11.4C19 11.9601 19 12.2401 18.891 12.454C18.7951 12.6422 18.6422 12.7951 18.454 12.891C18.2401 13 17.9601 13 17.4 13H2.6C2.03995 13 1.75992 13 1.54601 12.891C1.35785 12.7951 1.20487 12.6422 1.10899 12.454C1 12.2401 1 11.9601 1 11.4V9.82949C2.16519 9.41765 3 8.30641 3 7.00019Z" stroke="#131A29" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/></svg>,
          toPath: `${menuPrefix}/coupons`,
          afterNavigate: () => {
            updateGeneralState({ activeCoupon: { code: '', type: 'Free' } });
            updateShowQuickActionsMenu(false);
          }
        }
      ]          
    ], [stripeAccountExists, menuPrefix]
  );
  
  const renderMenuItem = useCallback(
    ({ icon, label, toPath, afterNavigate, beforeNavigate }, index) => (
      <NavigationContainer 
        key={`master-navigation-item-${index}`}
        toPath={toPath} 
        beforeNavigate={beforeNavigate}
        afterNavigate={afterNavigate} 
        className="menu-items" 
        child={<>{icon}{label}</>} 
      />
    ), []
  );

  const iterateRender = useMemo(() => menuArray.map(renderMenuItem), [menuArray]);

  return (
    <>
      {showQuickActionsMenu && (
        <div className="quick-actions-menu">
          {iterateRender}
        </div>
      )}   
    </>
  );
};

const mapDispatchToProps = dispatch => ({
  updateGeneralState: variables => dispatch(updateGeneralState(variables)),
  updateWebsiteState: variables => dispatch(updateWebsiteState(variables))
});

export default connect(null, mapDispatchToProps)(memo(MenuItems));