import React, { PureComponent } from 'react';
import CurrencyFormat from 'react-currency-format';
import { connect } from 'react-redux';
import { resetGeneralState } from '../../../../../actions/general.actions';
import { tenantPrefixUrlDeterminant } from '../../../../../actions/user.actions';

class PurchaseSuccessRouter extends PureComponent {
  constructor(props){
    super(props);

    this.state = {};
  }

  render(){
    const { requester, isSuccess, packageItem = {}, generalState, destination, destinationUrl } = this.props;
    const { updatedTotal } = generalState;
    const { recurring, processingFee, price = '0', label = '', intervalCount = 1, interval = 'month' } = packageItem;
    const { tenant, code } = requester.activeGroup;

    const finalPrice = updatedTotal || price;
    
    return isSuccess ? (
      <div className="outer-public-v2-container-container purchase-success-specific">
        <div className="dark-bg-for-exit-behind"></div>
        <div className="for-scroll-ref">
          <div className="purchase-success-immediate">
            <a
              className="back-specific"
              onClick={() => window.location = `/${tenant.code}/${code}/programs`}
            >
              <svg
                width="7"
                height="14"
                viewBox="0 0 7 14"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M6 1L0 7L6 13"
                  stroke="#0091FF"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
              <span className="text">Back</span>
            </a>

            <div className="actual-thank-content">
              <div className="pricing-receipt">   
                <div className="icon-container">
                  <svg width="50" height="48" viewBox="0 0 50 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M29.1335 20.3333L23.9335 25.5067L20.8135 22.3867" stroke="#73CDAD" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                    <path d="M17 33.6667H33" stroke="#73CDAD" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                    <path d="M46.44 47V3.56C46.44 2.81333 45.8267 2.22666 45.1067 2.22666C44.84 2.22666 44.6267 2.28 44.4133 2.41333L40.6267 4.65333V4.62666C39.7733 5.13333 38.7067 5.13333 37.88 4.62666L33.4533 1.96C32.6 1.42666 31.5333 1.42666 30.7067 1.93333L26.28 4.57333V4.54666C25.4267 5.05333 24.36 5.05333 23.5333 4.54666L19.1067 1.88C18.2533 1.34666 17.1867 1.34667 16.36 1.85333L11.9333 4.49333V4.46667C11.08 4.97333 10.0133 4.97333 9.18668 4.46667L5.40001 2.2C4.76001 1.8 3.93335 2.01333 3.56001 2.65333C3.42668 2.84 3.34668 3.08 3.34668 3.32V46.7333" stroke="#73CDAD" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                    <path d="M49 47H1" stroke="#73CDAD" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                  </svg>
                </div>           
                <CurrencyFormat
                  value={processingFee  ? (parseFloat(finalPrice) + parseFloat(finalPrice) * .029).toFixed(2) : finalPrice}
                  displayType={'text'}
                  thousandSeparator={true}
                  prefix={requester.currencyPrefix}
                  renderText={value => (
                    <p>
                      {value}
                      {recurring ? (
                        <span>/ {intervalCount > 1
                            ? `${intervalCount} ${interval}s`
                            : interval}</span>
                      ) : (
                        ''
                      )}
                    </p>
                  )}
                />
              </div>
              <div className="immediate-content">
                  <p className="label-specific descriptor">You just bought:</p>
                  <p className="label-specific">{label || "Untitled"}</p>
                  <p className="bottom-contact">Contact your Coach with any further questions</p>
                  {destination !== 'thank_you' && (
                    <p
                      ref={() => {
                        if(!this.hasntRanYet){
                          this.hasntRanYet = true;                            

                          setTimeout(() => window.location = destination === 'to_url' ? `${(destinationUrl || '').indexOf('://') > -1 ? destinationUrl : `http://${destinationUrl}`}` : `${tenantPrefixUrlDeterminant((tenant || {}).code, code)}/join`, 2000);
                        }
                      }}  
                      className="bottom-contact"
                    >You will be redirected shortly.</p>
                  )}
              </div>
            </div>
          </div>
        </div>        
      </div>
    ) : (
      <div className="outer-public-v2-container-container purchase-success-specific">
        <div className="dark-bg-for-exit-behind"></div>
        <div className="appLoading">
          <svg
            className="simple-line-loader-for-app-loading css-primary-hex-stroke"
            viewBox="0 0 50 50"
          >
            <circle
              className="path css-primary-hex-stroke"
              cx="25"
              cy="25"
              r="20"
              fill="none"
              strokeWidth="5"
            ></circle>
          </svg>
        </div>
      </div>                      
    );
  }
};

const mapStateToProps = states => ({
  generalState: states.generalState
});

const mapDispatchToProps = dispatch => ({
  resetGeneralState: variables => dispatch(resetGeneralState(variables))
});

export default connect(mapStateToProps, mapDispatchToProps)(PurchaseSuccessRouter);
