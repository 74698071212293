import React, { memo } from 'react';
import { tenantPrefixUrlDeterminant } from '../../../../actions/user.actions';

const AdminMenu_REDUX = props => {
  const {
    activeSlug,
    groupCode,
    tenantCode,
    onMenuItemClick
  } = props;

  return (
    <div className="mobile-menu-items-immediate">
      {menuItems.map((menuItem, index) => (
        <button
          key={`admin-menu-item-${index}`}
          className={`${menuItem.locationTo === `/${activeSlug}` && 'active-menu-item'}`}
          onClick={() => onMenuItemClick(`${tenantPrefixUrlDeterminant(tenantCode, groupCode)}/admin${menuItem.locationTo}`)}
        >
          {menuItem.icon}
          <span>{menuItem.label}</span>
        </button>
      ))}
    </div>
  );
};

const website_and_app_icon = (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M16.6667 8.75V4.16667C16.6667 3.24583 15.9167 2.5 15 2.5H4.16667C3.24583 2.5 2.5 3.24583 2.5 4.16667V12.5"
      stroke="#666666"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M12.4998 15H2.49984C2.03984 15 1.6665 14.625 1.6665 14.1667V13.3333C1.6665 12.8667 2.03317 12.5 2.49984 12.5H12.4998"
      stroke="#666666"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M17.5 17.5H13.3333C12.8667 17.5 12.5 17.125 12.5 16.6667V9.58333C12.5 9.11667 12.8667 8.75 13.3333 8.75H17.5C17.9583 8.75 18.3333 9.11667 18.3333 9.58333V16.6667C18.3333 17.125 17.9583 17.5 17.5 17.5Z"
      stroke="#666666"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M12.5 15H18.3333"
      stroke="#666666"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

const coaching_icon = (
  <svg
    width="18"
    height="18"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M7.33496 11.5L11.665 9L7.33496 6.5V11.5Z"
      stroke="#666666"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M5.24991 1.50007H12.7499C14.8166 1.50007 16.4916 3.17756 16.4916 5.24673V12.7467C16.4916 14.8134 14.8082 16.4884 12.7416 16.4884H5.23324C3.15824 16.4884 1.48657 14.8051 1.48657 12.7384V5.2334C1.48657 3.1584 3.16157 1.4834 5.23657 1.4834L5.24991 1.50007Z"
      stroke="#666666"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

const users_icon = (
  <svg width="23" height="17" viewBox="0 0 23 17" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M7.50001 1.01953C6.5744 1.01953 5.68671 1.38723 5.03221 2.04173C4.37771 2.69623 4.01001 3.58393 4.01001 4.50953C4.01001 5.43514 4.37771 6.32283 5.03221 6.97733C5.68671 7.63184 6.5744 7.99953 7.50001 7.99953C8.42562 7.99953 9.31331 7.63184 9.96781 6.97733C10.6223 6.32283 10.99 5.43514 10.99 4.50953C10.99 3.58393 10.6223 2.69623 9.96781 2.04173C9.31331 1.38723 8.42562 1.01953 7.50001 1.01953Z" stroke="#38383E" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M16.5 3C15.837 3 15.2011 3.26339 14.7322 3.73223C14.2634 4.20107 14 4.83696 14 5.5C14 6.16304 14.2634 6.79893 14.7322 7.26777C15.2011 7.73661 15.837 8 16.5 8C17.163 8 17.7989 7.73661 18.2678 7.26777C18.7366 6.79893 19 6.16304 19 5.5C19 4.83696 18.7366 4.20107 18.2678 3.73223C17.7989 3.26339 17.163 3 16.5 3V3Z" stroke="#38383E" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M1.5 15.9993V14.9793C1.5 12.7793 3.28 10.9893 5.48 10.9893H9.51C11.71 10.9893 13.49 12.7693 13.49 14.9693V15.9793" stroke="#38383E" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M16.5 11H17.6C19.8 11 21.58 12.78 21.58 14.98V15.99" stroke="#38383E" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
  </svg>
);

const inbox_icon = (
  <svg width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M13.5 17H9.5C8.39 17 7.5 16.1 7.5 15V9.5C7.5 8.39 8.39 7.5 9.5 7.5H17.5C18.6 7.5 19.5 8.39 19.5 9.5V15C19.5 16.1 18.6 17 17.5 17H16.5V19L13.5 17Z" stroke="#38383E" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M16.109 7.50023V3.43023C16.109 2.08023 15.019 0.990234 13.669 0.990234H3.91998C2.56998 0.990234 1.47998 2.08023 1.47998 3.42023V10.1162C1.47998 11.4562 2.56998 12.5462 3.90998 12.5462H5.11998V14.9762L7.46998 13.4002" stroke="#38383E" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
  </svg>
);

const menuItems = [
  {
    label: "Media",
    icon: coaching_icon,
    locationTo: '/media'
  },
  {
    label: "Dashboard",
    icon: website_and_app_icon,
    locationTo: '/dashboard'
  },
  {
    label: "Inbox",
    icon: inbox_icon,
    locationTo: '/inbox'
  },
  {
    label: "People",
    icon: users_icon,
    locationTo: '/people'
  }
];

export default memo(AdminMenu_REDUX);
