import ACTIONS from '../constants/actions';
import STATUS from '../constants/status';

const initialState = {
  tab: 0,
  status: null,
  progress: 0,
  message: ''
};

export default (state = initialState, action) => {
  switch (action.type) {
    case ACTIONS.RESET_STATE:
      return {
        ...state
      };

    case ACTIONS.UPDATE_STATE:
      return {
        ...state,
        ...action.payload
      };
      
    case ACTIONS.UPLOAD_IN_PROGRESS:
      return { ...state, message: '', status: STATUS.IN_PROGRESS };

    case ACTIONS.UPLOAD_PROGRESS:
      return { ...state, progress: action.payload };

    case ACTIONS.UPLOAD_COMPLETED:
      return { ...state, status: STATUS.COMPLETED };

    case ACTIONS.UPLOAD_ERROR:
      return { ...state, message: action.payload, status: STATUS.ERROR };

    default:
      return state;
  }
};
