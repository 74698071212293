import React, { memo, useCallback, useState } from 'react';
import { connect } from 'react-redux';
import ReactDOM from 'react-dom';
import CustomVideoRenderChild from './CustomVideoRender/CustomVideoRenderChild';
import { RequesterContext } from '../../context/requester.context';
import { updateLessonState } from '../../actions/lesson.actions';
import './CustomVideoRender/customVideoRender.scss';

const CustomVideoRender = props => {
  const {
    builderSpecific,
    videoUrl,
    thumbUrl,
    onTimeInit = () => null,
    mediaFit,
    blockSettings,
    onFinish = () => null,
    originalUrl,
    videoUrlOverride,
    onLoadedData,
    hideExpand,
    updateLessonState
  } = props;

  const [fullScreenActive, updateFullScreenActive] = useState();    

  const videoToRender = useCallback(requester => (
    <CustomVideoRenderChild
      requester={requester}
      fullScreenActive={fullScreenActive} 
      updateFullScreenActive={updateFullScreenActive}
      builderSpecific={builderSpecific}
      videoUrl={videoUrl}
      thumbUrl={thumbUrl}
      onTimeInit={onTimeInit}
      mediaFit={mediaFit}
      blockSettings={blockSettings}
      onFinish={onFinish}
      originalUrl={originalUrl}
      videoUrlOverride={videoUrlOverride}
      onLoadedData={onLoadedData}
      hideExpand={hideExpand}
      updateLessonState={updateLessonState}
    />
  ), [fullScreenActive, builderSpecific, videoUrl, thumbUrl, mediaFit, blockSettings, originalUrl, videoUrlOverride, hideExpand]);

  return (
    <RequesterContext.Consumer>
      {requester => (
        fullScreenActive
          ? ReactDOM.createPortal(
              <section className="position-fixed-full-screen">           
                {videoToRender(requester)}
                <button className="back-specific" onClick={() => updateFullScreenActive(false)}>
                  <svg
                    width="8"
                    height="7"
                    viewBox="0 0 8 7"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M1.33337 0.833252L6.66671 6.16659"
                      stroke="#666666"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    ></path>
                    <path
                      d="M6.66671 0.833252L1.33337 6.16659"
                      stroke="#666666"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    ></path>
                  </svg>
                </button>
              </section>,
              document.getElementById('outsideModalPortal-z2')
            )
          : videoToRender(requester)
      )}
    </RequesterContext.Consumer>
  );
};

const mapDispatchToProps = dispatch => ({
  updateLessonState: variables => dispatch(updateLessonState(variables))
});

export default connect(null, mapDispatchToProps)(memo(CustomVideoRender));