import React, { PureComponent } from 'react';
import GeneralLoader from '../../../../components/Shared/GeneralLoader';
import apolloClient from '../../../../apollo.client';
import { connectFacebook, disconnectFacebook } from '../../../../apollo.mutations';
import { parseSearchParams } from '../../../../helper';

import './instagramAuth.scss';

class FacebookAuth extends PureComponent {
  constructor(props) {
    super(props);

    const { disconnectSpecific } = props;

    this.already_connected = false;

    this.state = { error: null };

    const search = window.location.search;
    let { code = false } = search && search !== '' ? parseSearchParams(search) : {};

    if(disconnectSpecific){
      this.disconnectfacebookConnection();
    } else if (code) {
      this.updatefacebookConnection(code);
    } else {
      window.location = `/`;

      return <div>Redirecting...</div>;
    }
  }

  disconnectfacebookConnection = async () => {
    try {
      const data = (
        (await apolloClient.mutate({
          mutation: disconnectFacebook
        })) || {}
      ).data || {};

      if (data.disconnectFacebook) {
        this.setState({ facebook_response_result: true });
      } else {
        alert('There was an error with your request...');

        window.location = `/`;
      }
    } catch (e) {
      alert('There was an error with your request...');

      window.location = `/`;
    }
  };

  updatefacebookConnection = async code => {
    alert('There was an error with your request...');

    window.location = `/`;
    // try {
    //   const data = (
    //     (await apolloClient.mutate({
    //       mutation: connectFacebook,
    //       variables: { code }
    //     })) || {}
    //   ).data || {};

    //   if (data.connectFacebook) {
    //     this.setState({ facebook_response_result: true });
    //   } else {
    //     alert('There was an error with your request...');

    //     window.location = `/`;
    //   }
    // } catch (e) {
    //   alert('There was an error with your request...');

    //   window.location = `/`;
    // }
  };

  render() {
    const { disconnectSpecific } = this.props;
    const { facebook_response_result } = this.state;

    return (
      <div className="success-connect-container">
        <div className="success-connect-white-container">
          {facebook_response_result ? (
            <>
              <img src="/images/process-complete.gif" />
              <h1>Success!</h1>
              <p>Facebook is now {disconnectSpecific ? "disconnected" : "connected"}</p>
              <button onClick={() => (window.location = `/`)}>Continue</button>
            </>
          ) : (
            <GeneralLoader />
          )}
        </div>
      </div>
    );
  }
};

export default FacebookAuth;