import React from 'react';
import moment from 'moment';
import UsersMainTagSpecificRender from './UsersMainTagSpecificRender';
import { Admin_Tags_QueryConcat } from '../../../../actions/user.actions';

const UsersMainTagSpecific = props => {
  const { 
    toggleArchive,
    dontShowSelected,
    hideArchiveButton,
    showArchive, 
    searchLabel,
    twoLevel
  } = props || {};

  const { 
    loading, 
    archiveCount = 0,
    lastAnnouncement,
    data = [] 
  } = Admin_Tags_QueryConcat(props);

  return <>    
    {
      (loading && data.length === 0) ? <>
        <button
          key="all-gallery-tag-1"
          style={{ marginTop: "10px"}}
          className="gallery-tag-immediate one placeholder-specific"
        ></button>
        <button
          key="all-gallery-tag-2"
          className="gallery-tag-immediate two placeholder-specific"
        ></button>
        <button
          key="all-gallery-tag-3"
          className="gallery-tag-immediate three placeholder-specific"
        ></button>
      </> : (
        <UsersMainTagSpecificRender          
          toggleArchive={toggleArchive}
          showArchive={showArchive}
          archiveCount={archiveCount}
          lastAnnouncement={lastAnnouncement}
          hideArchiveButton={hideArchiveButton}
          searchLabel={searchLabel}
          dontShowSelected={dontShowSelected}
          twoLevel={twoLevel}
          parentData={
            data.map(
              (assetItem) => ({
                ...assetItem,
                toSortBy: new Date(assetItem.createdAt).getTime(),
                processedCreatedAt: moment(assetItem.createdAt).fromNow().replace('in ', '')
              })
            )
          }
          {...props} 
        />
      )
    }
  </>;
};

export default UsersMainTagSpecific;
