import React, { memo, useMemo } from 'react';
import ReactGA from 'react-ga';
import replaceall from 'replaceall';
import OrganizationRouter from './OrganizationRouter';
import FallbackRouter from './FallbackRouter';

const ParentRouter = _ => {
  const splitLocationName =  useMemo(() => window.location.pathname.split('/').filter((value = '') => value.length > 0), [window.location.pathname]),
        fallbackLocationName = useMemo(()=> (localStorage.getItem(`${hostNameSlugToUse}-last-active-group-prefix-v5`) || '').split('/').filter((value = '') => value.length > 0), [localStorage.getItem(`${hostNameSlugToUse}-last-active-group-prefix-v5`)]),
        { tenantCode, groupCode } = useMemo(() => splitLocationName.length > 1 ? { tenantCode: splitLocationName[0], groupCode: splitLocationName[1] } : { tenantCode: fallbackLocationName[0], groupCode: fallbackLocationName[1] }, [splitLocationName]),
        currentUserTokenExists = useMemo(() => localStorage.getItem(`${hostNameSlugToUse}-auth-token-v3-12-13-21`), []),
        currentCachedGroupID = useMemo(() => localStorage.getItem(`${hostNameSlugToUse}${originHost ? `-${splitLocationName[0]}-${splitLocationName[1]}` : ''}-active-group-id-v5`), [hostNameSlugToUse, splitLocationName[0], splitLocationName[1]])
  
  return (
    originHost 
      ? ((tenantCode && groupCode) || (currentUserTokenExists && currentCachedGroupID)) 
        ? <OrganizationRouter
            orgVariables={{
              tenantCode: tenantCode,
              groupCode: groupCode
            }}
          />
        : <FallbackRouter />
      : <OrganizationRouter orgVariables={{ hostName }} />
  )
},

hostName = window.location.hostname.toLowerCase(),
hostNameSlugToUse = replaceall('.', '-', hostName),
originHost = hostName.indexOf('coachiq.io') > -1 || hostName.indexOf('localhost') > -1 || hostName.indexOf('127.0.0.1') > -1

ReactGA.initialize('UA-149778966-1')
ReactGA.pageview(window.location.pathname + window.location.search)

export default memo(ParentRouter)