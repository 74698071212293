import React, { memo } from 'react';
import Select from 'react-select';

const SelectInputV2 = ({
  value = '',
  onChange,
  options
}) => {
  return (
    <Select 
      value={value} 
      options={options} 
      onChange={onChange} 
      classNamePrefix="react-select"
    />
  );
};

export default memo(SelectInputV2);
