import gql from 'graphql-tag';
import EBMLReader from 'ts-ebml/lib/EBMLReader';
import { Decoder, tools } from "ts-ebml";
import * as EBML from "ts-ebml";
import { Buffer } from 'buffer';
import apolloClient from '../apollo.client';
import ACTIONS from '../constants/actions';
import TEMPLATES from '../constants/voiceover/templates';
import { isVideoFull } from './general.actions';
import { getOptimizedFromKey } from '../helper';
window.Buffer = Buffer;

const getSignedUrl = gql`
  query($voiceoverId: ID!, $prefix: String, $isOriginal: Boolean!) {
    signedUrl(
      voiceoverId: $voiceoverId
      prefix: $prefix
      isOriginal: $isOriginal
    )
  }
`;

const uploadVoiceoverComplete = gql`
  mutation(
    $voiceoverId: ID!
    $isOriginal: Boolean!
    $webcamOrAudioPrefix: String
  ) {
    uploadVoiceover(
      voiceoverId: $voiceoverId
      isOriginal: $isOriginal
      webcamOrAudioPrefix: $webcamOrAudioPrefix
    ) {
      id
      title
      createdAt
      status
      dueDate
      notes
      voiceover {
        id
        processedUrl
        jobStatus
        videoUrl
        url
      }
      coach {
        id
        role
        status
        user {
          id
          firstName
          lastName
          avatar
        }
      }
      athlete {
        id
        role
        status
        user {
          id
          firstName
          lastName
          email
          avatar
        }
      }
    }
  }
`;

function futch(url, opts = {}, onProgress) {
  return new Promise((res, rej) => {
    const xhr = new XMLHttpRequest();
    xhr.open(opts.method || 'get', url);
    xhr.responseType = 'blob';
    for (var k in opts.headers || {}) xhr.setRequestHeader(k, opts.headers[k]);
    xhr.onload = e => {
      res(xhr.response);
    };
    xhr.onerror = rej;
    if (onProgress) {
      xhr.onprogress = onProgress;
      if(xhr.upload) xhr.upload.onprogress = onProgress;
    }
    xhr.send(opts.body);
  });
}

export const downloadFileDirectReturn = async url => {
  const downloadedFile = await futch(
    `${url}`,
    {
      method: 'get',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'blob'
      }
    },
    e => true
  );

  return downloadedFile;
};

export const getSeekableBlob = (inputBlob, callback) => {
  // EBML.js copyrights goes to: https://github.com/legokichi/ts-ebml
  if(typeof EBML === 'undefined') {
    throw new Error('Please link: https://www.webrtc- experiment.com/EBML.js');
  }

  var reader = new EBMLReader();
  var decoder = new Decoder();

  var fileReader = new FileReader();
  fileReader.onload = function (e) {
    var ebmlElms = decoder.decode(this.result);
    ebmlElms.forEach(function (element) {
        if (element.type !== 'unknown') {
            reader.read(element);
        }
    });
    reader.stop();
    var refinedMetadataBuf = tools.makeMetadataSeekable(reader.metadatas, reader.duration, reader.cues);
    var body = this.result.slice(reader.metadataSize);

    var newBlob = new Blob([refinedMetadataBuf, body], {
        type: 'video/webm'
    });

    //convertStreams(newBlob);
    callback(newBlob);
  };

  fileReader.readAsArrayBuffer(inputBlob);
};

export const updateVoiceoverState = updatedStates => dispatch =>
  dispatch({
    type: ACTIONS.UPDATE_STATE,
    payload: updatedStates
  });

export const initClear = ({
  recordingSlides = [],
  activeSlide = 0,
  onlyOverlays
}) => dispatch => {
  const activeSlideObject = recordingSlides[activeSlide];
  recordingSlides[activeSlide] = {
    ...activeSlideObject,
    ...TEMPLATES[activeSlideObject.type],
    mediaSections: onlyOverlays
      ? activeSlideObject.mediaSections || []
      : (activeSlideObject.mediaSections || []).map(mediaSection => ({
          ...mediaSection,
          mediaDimensions: {
            ...mediaSection.mediaDimensions,
            ...TEMPLATES[activeSlideObject.type].mediaSections[
              mediaSection.fallbackIndexRef
            ].mediaDimensions
          },
          originManip: {
            ...mediaSection.originManip,
            ...TEMPLATES[activeSlideObject.type].mediaSections[
              mediaSection.fallbackIndexRef
            ].originManip
          }
        }))
  };

  dispatch({
    type: ACTIONS.UPDATE_STATE,
    payload: {
      forceMediaResize: true,
      clearDraw: true,
      recordingSlides,
      ...TEMPLATES[`${activeSlideObject.type}_additionalProps`]
    }
  });
};

export const fetchAndCache = ({
  fileUrl, cache
}) => {
  return cache.match(fileUrl)
    .then(cacheResponse => {
      // Let's return cached response if video is already in the cache.
      if (cacheResponse) {
        return cacheResponse;
      }
      // Otherwise, fetch the video from the network.
      return fetch(fileUrl, { headers: { range: 'bytes=0-567139' } })
        .then(networkResponse => networkResponse.arrayBuffer())
        .then(data => {
          const response = new Response(data);
          // Add the response to the cache and return network response in parallel.
          cache.put(fileUrl, response.clone());
          return response;
        });
    });
}

export const mediaSelectedConfirmation = ({
  filesSelected
}) => async dispatch => {
  let filesCompleted = 0;
  let downloadedFiles = [];

  for (let media of Object.values(filesSelected)) {
    const downloadedFile = media.url ? await dispatch(downloadFile_V2(media.url, media.label, null, media.id, media.canvasReference, media.media_type === 'VIDEO' || media.isSMSVideo)) : media;

    filesCompleted++;

    dispatch({
      type: ACTIONS.UPDATE_STATE,
      payload: {
        ...downloadedFile && { [`media_downloaded_${media.id}`]: downloadedFile },
        multipleFileProgress: filesCompleted
      }
    });

    downloadedFiles.push(downloadedFile);

    dispatch({
      type: ACTIONS.DOWNLOAD_FILE_COMPLETED_V2,
      payload: null
    });
  }

  dispatch({
    type: ACTIONS.UPDATE_STATE,
    payload: {
      successAnimation: true,
      multipleFileCount: 0,
      multipleFileProgress: 0,
      downloadProgress: 0,
      status: 'COMPLETED'
    }
  });

  return downloadedFiles;
};

const getDownloadUrl = ({
  url,
  fromRetry,
  imageSpecific,
  canvasReference
}) => {
  let direct_url_to_pass = url;
  if (imageSpecific) {
    const base_s3_url = 'https://s3.us-west-2.amazonaws.com/athletic-outlook/';
    const url_to_check = direct_url_to_pass.toLowerCase();
    const split_url = url_to_check.split(
      url_to_check.indexOf('processed-voiceover') > -1
        ? '/processed-voiceover/undefined/'
        : url_to_check.indexOf('dgpv43ylujmyh.cloudfront.net') >
            -1
          ? '/undefined/'
          : '/athletic-outlook/undefined/'
    );
    const base_key = `${split_url[split_url.length - 1]}`;
    direct_url_to_pass = canvasReference ? `${base_s3_url}${base_key}` : `${base_s3_url}${getOptimizedFromKey('1200x', base_key)}`;
  } else {
    direct_url_to_pass = direct_url_to_pass.toLowerCase();

    if(!fromRetry){
      direct_url_to_pass = direct_url_to_pass.split('.');
      direct_url_to_pass.pop();
      direct_url_to_pass = direct_url_to_pass.join('.');

      if (direct_url_to_pass.indexOf('/evaluation/') === -1) {
        if (direct_url_to_pass.indexOf('/video-desktop/') === -1) {
          direct_url_to_pass = direct_url_to_pass.replace(
            '/undefined/',
            '/video-desktop/undefined/'
          );
        }
      } else if (direct_url_to_pass.indexOf('/processed-voiceover/') === -1) {
        direct_url_to_pass = direct_url_to_pass.replace(
          '/undefined/',
          '/processed-voiceover/undefined/'
        );

        direct_url_to_pass = direct_url_to_pass.replace(
          'video-desktop/',
          ''
        );
      }
  
      direct_url_to_pass = `${direct_url_to_pass}.mp4`;
    }

    // if (direct_url_to_pass.indexOf('dgpv43ylujmyh.cloudfront.net') > -1) {
    //   direct_url_to_pass = direct_url_to_pass.replace(
    //     'dgpv43ylujmyh.cloudfront.net',
    //     's3.us-west-2.amazonaws.com/athletic-outlook'
    //   );
    // }
  };

  // window.caches.open('video-pre-cache')
  //     .then(
  //       cache => 
  //         fetchAndCache({
  //           fileUrl: direct_url_to_pass, 
  //           imageSpecific,
  //           cache
  //         })
  //     );

  return direct_url_to_pass;
};

export const downloadFile_V2 = (
  url,
  label,
  fromRetry,
  mediaId,
  canvasReference,
  isExternalVideo
) => async dispatch => {
  if(url){
    const isExternalBased = url.toLowerCase().indexOf('instagram.com') > -1 || url.toLowerCase().indexOf('api.twilio.com') > -1;
    const imageSpecific = isExternalBased ? !isExternalVideo : !isVideoFull(url);

    let direct_url_to_pass = isExternalBased
      ? `https://v1.coachiq.io/api/download-url`
      : getDownloadUrl({
          url,
          imageSpecific,
          canvasReference
        });
        
    dispatch({
      type: ACTIONS.DOWNLOAD_PROGRESS,
      payload: 1
    });

    let downloadedFile = await futch(
      direct_url_to_pass,
      {
        method: 'get',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'blob',
          ...isExternalBased && { authorization: url }
        }
      },
      e => e && e.loaded && e.total && dispatch({
        type: ACTIONS.DOWNLOAD_PROGRESS,
        payload: (e.loaded / e.total) * 100
      })
    );

    if(downloadedFile.type.indexOf('xml') > -1 && !imageSpecific && !isExternalBased){
      alert(`The processed file was not found. We will try to download the original file if compliant. Some features may not work without a processed file. *After waiting atleast the equivalent length of your video for processing: If this problem continues, try downloading the file via your CoachIQ gallery and re-uploading to re-attempt the processing.`);

      direct_url_to_pass = getDownloadUrl({
        url,
        imageSpecific,
        fromRetry: true,
        canvasReference
      });

      downloadedFile = await futch(
        direct_url_to_pass,
        {
          method: 'get',
          headers: {
            Accept: 'application/json',
            'Content-Type': 'blob'
          }
        },
        e => e && e.loaded && e.total && dispatch({
          type: ACTIONS.DOWNLOAD_PROGRESS,
          payload: (e.loaded / e.total) * 100
        })
      );
    };

    dispatch({
      type: ACTIONS.DOWNLOAD_PROGRESS,
      payload: 100
    });
  
    try {
      if (downloadedFile) {
        if(imageSpecific){
          return {
            id: mediaId,
            originalUrl: url,
            url: direct_url_to_pass,
            label,
            blobUrl: URL.createObjectURL(downloadedFile),
            isImage: true
          };
        } else {
          const durationToPass = await getBlobDuration(downloadedFile);
          
          if(durationToPass){
            const blobUrl = URL.createObjectURL(downloadedFile);       
            
            return downloadedFile ? {
              id: mediaId,
              originalUrl: url,
              url: direct_url_to_pass,
              label,
              blobUrl,
              durationFormatted: secondsToHms(durationToPass),
              duration: durationToPass,
              isImage: imageSpecific ? true : false
            } : null;
          } else {
            alert("Unable to download, please retry again later or contact support for more information.");

            return false;
          };
        };        
      }
    } catch (e) {
      console.log(e);
    }  

    return false;
  }

  return false;
};

export const secondsToHms = d => {
  d = Number(d);

  var h = Math.floor(d / 3600);
  var m = Math.floor((d % 3600) / 60);
  var s = Math.floor((d % 3600) % 60);

  return `${h > 0 ? `${h}:` : ''}${
    h > 0 ? `${('0' + m).slice(-2)}:` : `${m}:`
  }${('0' + s).slice(-2)}`;
};

export const getBlobDuration = async (blob, alertInvalidity) => {
  try {
    const tempVideoEl = document.createElement('video');

    tempVideoEl.src =
      typeof blob === 'string' || blob instanceof String
        ? blob
        : window.URL.createObjectURL(blob);

    const durationP = await new Promise(resolve => {
      let alreadyResolved = false;
      tempVideoEl.addEventListener('loadedmetadata', () => {
        alreadyResolved = true;
        if (tempVideoEl.duration === Infinity) {
          tempVideoEl.currentTime = Number.MAX_SAFE_INTEGER;
          tempVideoEl.ontimeupdate = () => {
            tempVideoEl.ontimeupdate = null;
            resolve(tempVideoEl.duration);
            tempVideoEl.currentTime = 0;
          };
        } else {
          resolve(tempVideoEl.duration);
        }
      });

      setTimeout(() => {
        if (!alreadyResolved && alertInvalidity) {
          alert("This media is invalid or hasn't been fully processed...");
        }

        resolve(null);
      }, 3000);
    });

    tempVideoEl.remove();

    return durationP;
  } catch (e) {
    return false;
  }
};

export const submitVoiceover = ({
  videoFile,
  session
}) => async dispatch => {
  const voiceoverId = session.voiceover.id;

  const videoSignedUrl =
    (await new Promise(resolve =>
      apolloClient
        .query({
          query: getSignedUrl,
          variables: {
            isOriginal: false,
            prefix: 'video',
            voiceoverId
          },
          fetchPolicy: 'network-only'
        })
        .then(({ data }) => resolve((data && data.signedUrl) || null))
        .catch(() => resolve(null))
    )) || {};

  const videoLayer = {
    pathPrefix: 'video',
    postInfo: videoSignedUrl,
    file: new File([videoFile], `${voiceoverId}.webm`, { type: "video/webm" })
  };

  const data = new FormData();
  Object.keys(videoLayer.postInfo.fields).map(key =>
    data.append(key, videoLayer.postInfo.fields[key])
  );

  data.append('file', videoLayer.file);
  const options = {
    method: 'POST',
    body: data
  };

  await futch(
    'https://athletic-outlook.s3-accelerate.amazonaws.com',
    options,
    e =>
      dispatch({
        type: ACTIONS.UPDATE_STATE,
        payload: { uploadProgress: (e.loaded / e.total) * 100 }
      })
  ).catch(e => e);

  dispatch({
    type: ACTIONS.UPDATE_STATE,
    payload: {
      uploadProgress: 100,
      successAnimation: true
    }
  });

  dispatch({
    type: ACTIONS.UPDATE_STATE,
    payload: {
      session: await new Promise(resolve =>
        apolloClient
          .mutate({
            mutation: uploadVoiceoverComplete,
            variables: {
              isOriginal: false,
              voiceoverId: session.voiceover.id
            }
          })
          .then(({ data }) => resolve(data && data.uploadVoiceover))
          .catch(e => resolve(null))
      ),
      processedUrlChecked: true,
      uploadingVoiceover: false
    }
  });
};

const removeSession = gql`
  mutation($sessionId: ID!, $voiceOverSpecific: Boolean) {
    removeSession(
      sessionId: $sessionId
      voiceOverSpecific: $voiceOverSpecific
    ) {
      id
      voiceover {
        id
        processedUrl
        jobStatus
        videoUrl
        url
      }
    }
  }
`;

export const reRecordInit = async sessionId => {
  return await new Promise(resolve =>
    apolloClient
      .mutate({
        mutation: removeSession,
        variables: {
          sessionId,
          voiceOverSpecific: true
        }
      })
      .then(() => resolve(true))
      .catch(() => resolve(true))
  );
};

export const deleteSession = async sessionId =>
  await new Promise(resolve =>
    apolloClient
      .mutate({
        mutation: removeSession,
        variables: {
          sessionId,
          voiceOverSpecific: false
        }
      })
      .then(() => resolve(true))
      .catch(() => resolve(true))
  );

const completeSessionMutation = gql`
  mutation($sessionId: ID!) {
    completeSession(sessionId: $sessionId) {
      id
      title
      status
      completedDate
      createdAt
    }
  }
`;

export const completeSession = async sessionId =>
  await new Promise(resolve =>
    apolloClient
      .mutate({
        mutation: completeSessionMutation,
        variables: { sessionId }
      })
      .then(() => resolve(true))
      .catch(() => resolve(true))
  );

const upsertSession = gql`
  mutation($input: UpsertSessionInput!) {
    upsertSession(input: $input) {
      id
      title
      dueDate
      status
      notes
      createdAt
      completedDate
      coach {
        id
        role
        status
        user {
          id
          firstName
          lastName
          email
        }
      }
    }
  }
`;

export const updateSession = async payload =>
  await new Promise(resolve =>
    apolloClient
      .mutate({
        mutation: upsertSession,
        variables: { input: payload }
      })
      .then(() => resolve(true))
      .catch(() => resolve(true))
  );


const addVoiceoverTemplate = gql`
  mutation($input: VoiceoverTemplateInput!) {
    addVoiceoverTemplate(input: $input)
  }
`;

export const addVoiceoverTemplateInit = async payload =>
  await new Promise(resolve =>
    apolloClient
      .mutate({
        mutation: addVoiceoverTemplate,
        variables: { input: payload }
      })
      .then(({ data }) => resolve((data && data.addVoiceoverTemplate) || true))
      .catch(() => resolve(false))
  );

const removeVoiceoverTemplate = gql`
  mutation($input: RemoveVoiceoverTemplateInput!) {
    removeVoiceoverTemplate(input: $input)
  }
`;

export const removeVoiceoverTemplateInit = async payload =>
  await new Promise(resolve =>
    apolloClient
      .mutate({
        mutation: removeVoiceoverTemplate,
        variables: { input: payload }
      })
      .then(({ data }) => resolve((data && data.removeVoiceoverTemplate) || true))
      .catch(() => resolve(false))
  );

const addVoiceoverTemplateSlide = gql`
  mutation($input: VoiceoverTemplateSlideInput!) {
    addVoiceoverTemplateSlide(input: $input)
  }
`;

export const addVoiceoverTemplateSlideInit = async payload =>
  await new Promise(resolve =>
    apolloClient
      .mutate({
        mutation: addVoiceoverTemplateSlide,
        variables: { input: payload }
      })
      .then(({ data }) => resolve((data && data.addVoiceoverTemplateSlide) || true))
      .catch(() => resolve(false))
  );



/**Updated Media Actions**/

export const removeMediaSection = ({
  mediaIntervalInitialized,
  recordingSlides,
  activeSlide,
  index
}) => dispatch => {
  recordingSlides[activeSlide].mediaSections.splice(index, 1);

  dispatch({
    type: ACTIONS.UPDATE_STATE,
    payload: {
      recordingSlides,
      activeMasterElement: {},
      ...(!mediaIntervalInitialized && { forceReDraw: true })
    }
  });

  return true;
};

export const removeTextSection = ({
  mediaIntervalInitialized,
  recordingSlides,
  activeSlide,
  index
}) => dispatch => {
  recordingSlides[activeSlide].textAssets.splice(index, 1);

  dispatch({
    type: ACTIONS.UPDATE_STATE,
    payload: {
      recordingSlides,
      activeMasterElement: {},
      ...(!mediaIntervalInitialized && { forceReDraw: true })
    }
  });

  return true;
};