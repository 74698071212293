import React, { memo } from 'react';
import GalleryMainMediaMapper_EXISTING from './GalleryMainMediaMapper_EXISTING';
import GalleryMainMediaMapper_NEW from './GalleryMainMediaMapper_NEW';

const GalleryMainMediaMapper = ({ profile, onRadioInit, onRemove, onSelect, hideNewFiles, simplified, itemsSelected, fallbackImage1, fallbackImage2, generalState, updateGeneralState, filteredFilesUploading_Finalized = [], filterImageOnly_QUERIED = [] }) => <>
  {!hideNewFiles && <GalleryMainMediaMapper_NEW onRadioInit={onRadioInit} profile={profile} onRemove={onRemove} onSelect={onSelect} simplified={simplified} itemsSelected={itemsSelected} fallbackImage1={fallbackImage1} fallbackImage2={fallbackImage2} generalState={generalState} updateGeneralState={updateGeneralState} filteredFilesUploading_Finalized={filteredFilesUploading_Finalized} />}
  <GalleryMainMediaMapper_EXISTING onRadioInit={onRadioInit} profile={profile} onRemove={onRemove} onSelect={onSelect} simplified={simplified} itemsSelected={itemsSelected} fallbackImage1={fallbackImage1} fallbackImage2={fallbackImage2} updateGeneralState={updateGeneralState} filterImageOnly_QUERIED={filterImageOnly_QUERIED} />
</>;

export default memo(GalleryMainMediaMapper);
