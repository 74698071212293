import React, { memo, useCallback, useMemo } from 'react';
import StripeFormMapper from './MainMapper/StripeFormMapper';
import TextInput from '../../../../../Shared/TextInput';

const MainMapper = ({ onRef, couponCode, onApplyCoupon, saveCard, lightTheme, cardIdToUse, updateCardIdToUse, updateHideSavedCards, hideSavedCards, getSavedCards, updatedTotal, profile, price, onPromoCodeChange, onError, onToken, onSaveCardChange }) => {
  const parseFloatInit = useCallback(parseFloat, []);

  const parentConditional = useMemo(() => parseFloatInit(price) > 0 && (!updatedTotal || parseFloatInit(updatedTotal) > 0), [price, updatedTotal]);

  return <>
    {parentConditional && (
      <StripeFormMapper
        onRef={onRef}
        onToken={onToken}
        onError={onError}
        saveCard={saveCard}
        lightTheme={lightTheme}
        onSaveCardChange={onSaveCardChange}              
      />
    )}

    <div className="promo-code-section">
      <div className="for-relativity">
        <TextInput
          className="auth-input-and-label-container"
          label="Promocode"
          placeholder="Code"
          value={couponCode}
          type={'text'}
          onChange={onPromoCodeChange}
        />
        <button onClick={onApplyCoupon}>Apply</button>
      </div>
    </div>
  </>;
};


export default memo(MainMapper);
